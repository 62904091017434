import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[appPercentageCustomValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PercentageCustomValidatorDirective,
    multi: true
  }]
})
export class PercentageCustomValidatorDirective implements Validator {

  @Input('appPercentageCustomValidator') total: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    let t = +this.total;
    const val: number = +control.value;
    t += val;
    if (t > 100) {
      return { 'totalpercentage': true };
    }
    else
      return null;
  }

  registerOnValidatorChange?(fn: () => void): void {

  }

  constructor() { }

}
