import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'layoutFilter'
})
export class LayoutFilterPipe implements PipeTransform {

  transform(items: any, field: string, value: number): any {
    if (field === undefined || field === null || items === undefined || items === null || value === undefined || value === null) {
      return items;
    }
    else {
      return items.filter(it => it[field] == value);
    }
  }

}
