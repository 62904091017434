import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPassword} from '@shared/models/iemployee';
import {API_END_POINT} from '@shared/utils/api-end-points';
import {forkJoin, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  currentPayrollYear: number;

  constructor(private http: HttpClient) { }

  GetVisibleInternalImportUtility(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetVisibleInternalImportUtility");
  }

  GetSTEVersion(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetSTEVersion");
  }

  GetSelectedPayrollYear(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetSelectedPayrollYear").pipe(tap(res => this.currentPayrollYear = res));
  }

  GetPayrollYear(): Observable<any> {
    let res1 = this.GetSelectedPayrollYear();
    let res2 = this.http.get<any>(API_END_POINT.account.global + "GetLatestTaxVersionOfYear");
    return forkJoin([res1, res2]);
  }

  IsPayrollFilterActive(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsPayrollFilterActive");
  }

  IsCoreFilterActive(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsCoreFilterActive");
  }

  GetPayrollFilterIDs(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetPayrollFilterIDs");
  }

  GetCoreFilterIDs(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetCoreFilterIDs");
  }

  DecryptPassword(encryptedPwd: IPassword): Observable<any> {
    return this.http.post<any>(API_END_POINT.account.global + "DecryptPassword", encryptedPwd);
  }

  IsDatabaseUpdateRequired(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsDatabaseUpdateRequired");
  }

  UpdateDatabaseVersion(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "UpdateDatabaseVersion");
  }
}
