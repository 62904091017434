import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {IProvinceList} from 'src/app/shared/models/ipayroll-category';
import {CheckTaxCategoryUS, ISystemPreference, ISystemPreferenceInit, ITaxAccountNumber, TaxPayCategoryUS} from 'src/app/shared/models/isystem-preference';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class SystemPreferenceService {

  public systemPreferenceUpdated$ = new Subject<void>();
  public systemPreference$ = new BehaviorSubject<ISystemPreferenceInit[]>(null);

  constructor(private http: HttpClient) {
  }

  updateSystemPreference(sysPref: ISystemPreference): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.systemPreference + 'UpdateSystemPreferences', sysPref)
      .pipe(tap(() => this.systemPreferenceUpdated$.next()));
  }

  getinitSystemPreferences(): Observable<ISystemPreferenceInit[]> {
    //let para = new HttpParams().set("id", String(id));
    //, { params: para }
    return this.http.get<ISystemPreferenceInit[]>(API_END_POINT.payroll.systemPreference + 'GetInitialUserPreferences')
      .pipe(tap(res => this.systemPreference$.next(res)));
  }

  getInitSystemPreferencesForAdmin() {
    // simfyz duplicated this since http method itself returns an observable so don't need to wrap in another
    // observable since it's breaking the chain
    return this.http.get<ISystemPreferenceInit[]>(API_END_POINT.payroll.systemPreference + 'GetInitialUserPreferencesForAdmin');
  }

  getUSTaxAccountNumbers(): Observable<ITaxAccountNumber[]> {
    return this.http.get<ITaxAccountNumber[]>(API_END_POINT.payroll.systemPreference + 'GetUSTaxAccountNumbers');
  }

  getStatesForTaxAccountNumbers(): Observable<IProvinceList[]> {
    return this.http.get<IProvinceList[]>(API_END_POINT.payroll.systemPreference + 'GetStatesForTaxAccountNumbers');
  }

  addUSTaxAccountNumber(accNum: ITaxAccountNumber): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.systemPreference + 'AddUSTaxAccountNumber', accNum);
  }

  editUSTaxAccountNumber(accNum: ITaxAccountNumber): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.systemPreference + 'EditUSTaxAccountNumber', accNum);
  }

  deleteUSTaxAccountNumber(accNum: ITaxAccountNumber): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.systemPreference + 'DeleteUSTaxAccountNumber', accNum);
  }

  //GetCurrentDataBaseName(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.payroll.systemPreference + 'GetCurrentDataBaseName');
  //}

  AddInitialDataCan(): Observable<any> {
    return this.http.get<any>(API_END_POINT.payroll.systemPreference + 'AddInitialDataCan');
  }

  AddInitialDataUSA(): Observable<any> {
    return this.http.get<any>(API_END_POINT.payroll.systemPreference + 'AddInitialDataUSA');
  }

  IsUnlockPaycard(): Observable<any> {
    return this.http.get<any>(API_END_POINT.payroll.systemPreference + 'IsUnlockPaycard');
  }

  RunCANTaxUpgradeScript(version: string): Observable<any> {
    return this.http.get<any>(API_END_POINT.payroll.systemPreference + 'RunCANTaxUpgradeScript?version=' + version);
  }

  GetAllPayrollCategoryUsercodes(): Observable<string[]> {
    return this.http.get<string[]>(API_END_POINT.payroll.systemPreference + 'GetAllPayrollCategoryUsercodes');
  }

  GetSuggestedCategoryCodeForEmployee(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.systemPreference + 'GetSuggestedCategoryCodeForEmployee');
  }

  GetSuggestedCategoryCodeForEmployer(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.systemPreference + 'GetSuggestedCategoryCodeForEmployer');
  }

  AddUsTaxCategory(list: TaxPayCategoryUS[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.systemPreference + 'AddUsTaxCategory', list);
  }

  CheckUsTaxCategoryExist(list: CheckTaxCategoryUS[]): Observable<CheckTaxCategoryUS[]> {
    return this.http.post<CheckTaxCategoryUS[]>(API_END_POINT.payroll.systemPreference + 'CheckUsTaxCategoryExist', list);
  }

//  getHubConnection(): Observable<string> {
//    return this.http.get<string>(API_END_POINT.payroll.systemPreference + 'GetHubConnection');
//  }
}
