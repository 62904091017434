import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReportServiceService} from '@harmony-modules/payroll/services/report/report-service.service';
import {IReport, ReportTableParameters} from '@shared/models/ireport';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})

export class ReportListComponent implements OnInit {
  public showMsg: any;
  public reports: IReport[];
  isActive: boolean[] = [];
  mobile: boolean = false;
  reportParameterDetailsParent: ReportTableParameters;

  constructor(private _reportService: ReportServiceService, private _route: ActivatedRoute) {
  }

  ngOnInit() {
    if (this._route.snapshot.params['module'] === 'Configuration') {
      this._reportService.getReportsForCore().subscribe(data => this.reports = data);
      this._reportService.getReportParameterDetailsForCore().subscribe(data => {
        this.reportParameterDetailsParent = data;
      });
    } else if (this._route.snapshot.params['module'] === 'payroll') {
      this._reportService.getReports().subscribe(data => this.reports = data);
      this._reportService.getReportParameterDetails().subscribe(data => {
        this.reportParameterDetailsParent = data;
      });
    }
  }

  onClick(reportID: number) {
    this.isActive[reportID] = !this.isActive[reportID];
  }
}


