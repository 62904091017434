import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IGetAdjstmntPaycrd} from '@shared/models/ipayroll-group';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {
  IBusinessCentralConfiguration,
  ICertifiedPayrollPaycardExportOutput,
  IConnectionResult,
  IGlExportOutput,
  InterfaceTypeEnum,
  ISageIntacctConfiguration,
  IUpdateCPQueueWithNewSessId
} from 'src/app/shared/models/igl';
import {
  IAgreementDetail,
  ICertifiedPayroll,
  ICertifiedPayrollCategories,
  ICertifiedPayrollData,
  ICertifiedPayrollExportPaycard,
  ICertifiedProjects,
  ICertiFringeBenefits,
  ICostAdjustment,
  ICostData,
  ICpPreferences,
  ICPQueue,
  IDatalist,
  IDimDetail,
  IDimensionCertifiedPayroll,
  IDimensionRoutDetails,
  IDimList,
  IEmployeeLabor,
  IFringeUpdate,
  IImportUnionWorkGrid,
  ILaborData,
  ILaborImportParam,
  ILaborImportResult,
  IlaborOutputWithSession,
  ILaborPicklist,
  ILaborSetup,
  ILaborSetupUpdate,
  ILaborTimesheeInCost,
  INewDimensionCreate,
  IPaycardExportGrid,
  IPayCatListLabor,
  IPayCatsForLaborSetup,
  IPaymentDates,
  IPayrollGroupLabor,
  IPayrollGroupsLabor,
  IPayScheduleForAdjstmntCP,
  IRuleCategory,
  ISaveCertifiedPayrollExportFilters,
  ITimesheetDataLabor,
  IUnionAgreementUpdate,
  IUnionAgrmntGridDef,
  IUnionAgrmntList,
  IUnionCategory,
  IUnionCatgParam,
  IUnionRule,
  IUnionRuleGrid,
  IUpdateDimDetail,
  IUpdateExportData,
  IWorkClasses,
  IWorkDateValidateOutput
} from 'src/app/shared/models/ilabor';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';

@Injectable({
  providedIn: 'root'
})
export class LaborService {

  public labourDimensionUpdated$ = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  private laborSetupList = new BehaviorSubject<any[]>(null);
  laborSetup_list = this.laborSetupList.asObservable();

  changeLaborSetupList(list: ILaborSetup[]) {
    this.laborSetupList.next(list);
    this.labourDimensionUpdated$.next();
  }

  private dimesion_list = new BehaviorSubject<any[]>(null);
  current_dim_list = this.dimesion_list.asObservable();

  changeDimensionList(list: IDimList[]) {
    this.dimesion_list.next(list);
  }

  private dimension = new BehaviorSubject<any>(null);
  current_dimension = this.dimension.asObservable();

  changeDimension(data: any) {
    this.dimension.next(data);
  }

  private dim_code_list = new BehaviorSubject<any>(null);
  current_dim_code_list = this.dim_code_list.asObservable();

  changeDimCodeList(data: any) {
    this.dim_code_list.next(data);
  }

  private page_routing_status = new BehaviorSubject<IDimensionRoutDetails[]>(null);
  current_page_status = this.page_routing_status.asObservable();

  changePageRoutingStatus(data: IDimensionRoutDetails[]) {
    this.page_routing_status.next(data);
  }

  GetLaborSetup(): Observable<ILaborSetup[]> {
    return this.http.get<ILaborSetup[]>(API_END_POINT.payroll.labor + 'GetLaborSetup');
  }

  GetLaborDateFlg(): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.labor + 'GetLaborDateFlg');
  }

  GetPayrollGroupsForLaborSetup(): Observable<IPayrollGroupsLabor[]> {
    return this.http.get<IPayrollGroupsLabor[]>(API_END_POINT.payroll.labor + 'GetPayrollGroupsForLaborSetup');
  }

  GetPaycatsForLaborSetup(): Observable<IPayCatsForLaborSetup[]> {
    return this.http.get<IPayCatsForLaborSetup[]>(API_END_POINT.payroll.labor + 'GetPaycatsForLaborSetup');
  }

  GetLaborDimensionList(dim_id: number): Observable<IDimList[]> {
    return this.http.get<IDimList[]>(API_END_POINT.payroll.labor + 'GetLaborDimensionList?dim_id=' + dim_id);
  }

  GetUnionDimensionList(): Observable<IDimList[]> {
    return this.http.get<IDimList[]>(API_END_POINT.payroll.labor + 'GetUnionDimensionList');
  }

  GetUnionAgreementDimensionList(): Observable<IDimList[]> {
    return this.http.get<IDimList[]>(API_END_POINT.payroll.labor + 'GetUnionAgreementDimensionList');
  }

  GetUnionDimensionForAgreement(id_agr: number): Observable<IDimList> {
    return this.http.get<IDimList>(API_END_POINT.payroll.labor + 'GetUnionDimensionForAgreement?id_agr=' + id_agr);
  }

  GetUnionAgreementList(id_dim: number): Observable<IUnionAgrmntList[]> {
    return this.http.get<IUnionAgrmntList[]>(API_END_POINT.payroll.labor + 'GetUnionAgreementList?id_dim=' + id_dim);
  }

  GetDimensionChildrenList(dim_id: number): Observable<IDimList[]> {
    return this.http.get<IDimList[]>(API_END_POINT.payroll.labor + 'GetDimensionChildrenList?dim_id=' + dim_id);
  }

  GetLAllDimensions(): Observable<IDimList[]> {
    return this.http.get<IDimList[]>(API_END_POINT.payroll.labor + 'GetLAllDimensions');
  }

  GetLaborDimensionDetail(dim_id: number): Observable<IDimDetail[]> {
    return this.http.get<IDimDetail[]>(API_END_POINT.payroll.labor + 'GetLaborDimensionDetail?dim_id=' + dim_id);
  }

  CheckAgreementsExist(): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.labor + 'CheckAgreementsExist');
  }

  GetPicklistsForLaborSetup(): Observable<ILaborPicklist[]> {
    return this.http.get<ILaborPicklist[]>(API_END_POINT.payroll.labor + 'GetPicklistsForLaborSetup');
  }

  CheckDimensionHasValues(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.labor + 'CheckDimensionHasValues');
  }

  GetDataFromMultipleService(): Observable<any> {
    let res1 = this.GetLaborSetup();
    let res2 = this.GetLaborDateFlg();
    let res3 = this.GetPayrollGroupsForLaborSetup();
    let res4 = this.GetPaycatsForLaborSetup();
    let res5 = this.CheckAgreementsExist();
    let res6 = this.GetPicklistsForLaborSetup();
    let res7 = this.CheckDimensionHasValues();
    return forkJoin([res1, res2, res3, res4, res5, res6, res7]);
  }

  GetAllDimensionCodes(lbr_dim_id: number, id_parent: number): Observable<string[]> {
    //console.log(lbr_dim_id);
    //console.log(id_parent);
    return this.http.get<string[]>(API_END_POINT.payroll.labor + 'GetAllDimensionCodes?lbr_dim_id=' + lbr_dim_id + '&id_parent=' + id_parent);
  }

  GetDimensionDetaildForCreate(dim_id: number): Observable<INewDimensionCreate> {
    return this.http.get<INewDimensionCreate>(API_END_POINT.payroll.labor + 'GetDimensionDetaildForCreate?dim_id=' + dim_id);
  }

  GetDataForDimensionCreate(dim_id: number, lbr_dim_id: number, id_parent: number): Observable<any> {
    let res1 = this.GetAllDimensionCodes(lbr_dim_id, id_parent);
    let res2 = this.GetDimensionDetaildForCreate(dim_id);
    return forkJoin([res1, res2]);
  }

  UpdateLaborSetup(setupList: ILaborSetupUpdate[]): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateLaborSetup', setupList);
  }

  UpdateLaborDateFlg(tracDate: any): Observable<number> {
    const formData = new FormData();
    formData.append('tracDate', tracDate.toString());
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateLaborDateFlg', formData);
  }

  UpdateLaborSetupGrpsAndCats(datalist: IDatalist): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateLaborSetupGrpsAndCats', datalist);
  }

  UpdateDimensionDetails(ob: IUpdateDimDetail): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateDimensionDetails', ob);
  }

  DeleteDimension(lbr_dim_id: number, id: number): Observable<any> {
    return this.http.delete<number>(API_END_POINT.payroll.labor + 'DeleteDimension?id=' + id + '&lbr_dim_id=' + lbr_dim_id);
  }

  CreateUnionAgreement(agr: IAgreementDetail): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'CreateUnionAgreement', agr);
  }

  GetWorkCategoryList(): Observable<IUnionCategory[]> {
    return this.http.get<IUnionCategory[]>(API_END_POINT.payroll.labor + 'GetWorkCategoryList');
  }

  GetSelectedWorkGridCategoryList(id_agr: number, headerId: number): Observable<IUnionAgrmntGridDef[]> {
    return this.http.get<IUnionAgrmntGridDef[]>(API_END_POINT.payroll.labor + 'GetSelectedWorkGridCategoryList?id_agr=' + id_agr + '&headerId=' + headerId);
  }

  GetUnionAgreementRuleGrid(id_agr: number): Observable<IUnionRuleGrid[]> {
    return this.http.get<IUnionRuleGrid[]>(API_END_POINT.payroll.labor + 'GetUnionAgreementRuleGrid?id_agr=' + id_agr);
  }

  DeleteUnionAgreement(id_agr: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.labor + 'DeleteUnionAgreement?id_agr=' + id_agr);
  }

  CheckIfUnionAgreementUsedInPayrollProcess(id_agr: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.labor + 'CheckIfUnionAgreementUsedInPayrollProcess?id_agr=' + id_agr);
  }

  DeleteUnionRuleGridRow(id: number): Observable<IUnionRuleGrid[]> {
    return this.http.get<IUnionRuleGrid[]>(API_END_POINT.payroll.labor + 'DeleteUnionRuleGridRow?id=' + id);
  }

  GetCategoryList(): Observable<IUnionCategory[]> {
    return this.http.get<IUnionCategory[]>(API_END_POINT.payroll.labor + 'GetCategoryList');
  }

  GetRuleCategoryList(): Observable<IRuleCategory[]> {
    return this.http.get<IRuleCategory[]>(API_END_POINT.payroll.labor + 'GetRuleCategoryList');
  }

  GetUnionRuleList(): Observable<IUnionRule[]> {
    return this.http.get<IUnionRule[]>(API_END_POINT.payroll.labor + 'GetUnionRuleList');
  }

  UpdateUnionAgreementWorkGrid(obj: IUnionCatgParam): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.labor + 'UpdateUnionAgreementWorkGrid', obj);
  }

  SaveUnionAgreementData(obj: IUnionAgreementUpdate): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'SaveUnionAgreementData', obj);
  }

  ImportUnionAgreementWorkGrid(obj: IImportUnionWorkGrid): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.labor + 'ImportUnionAgreementWorkGrid', obj);
  }

  GetUnionAgreementWorkGrid(obj: IUnionCatgParam): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.labor + 'GetUnionAgreementWorkGrid', obj);
  }

  GetUnionAgreementExportFile(obj: IUnionCatgParam): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.labor + 'GetUnionAgreementExportFile', obj);
  }

  GetTimesheetDataForLabor(prgp: number, sess_id: string, from_date: Date, to_date: Date, year: number, seq_no: number): Observable<ITimesheetDataLabor[]> {
    return this.http.get<ITimesheetDataLabor[]>(API_END_POINT.payroll.labor + 'GetTimesheetDataForLabor?prgp=' + prgp + '&sess_id=' + sess_id + '&from_date=' + from_date + '&to_date=' + to_date + '&year=' + year + '&seq_no=' + seq_no);
  }

  GetPayrollCategoriesForLabor(): Observable<IPayCatListLabor[]> {
    return this.http.get<IPayCatListLabor[]>(API_END_POINT.payroll.labor + 'GetPayrollCategoriesForLabor');
  }

  GetPayrollCategoriesForLaborAdjustment(): Observable<IPayCatListLabor[]> {
    return this.http.get<IPayCatListLabor[]>(API_END_POINT.payroll.labor + 'GetPayrollCategoriesForLaborAdjustment');
  }

  GetPayrollCategoriesForCost(): Observable<IPayCatListLabor[]> {
    return this.http.get<IPayCatListLabor[]>(API_END_POINT.payroll.labor + 'GetPayrollCategoriesForCost');
  }

  GetPayrollCategoriesForCost_V1(): Observable<IPayCatListLabor[]> {
    return this.http.get<IPayCatListLabor[]>(API_END_POINT.payroll.labor + 'GetPayrollCategoriesForCost_V1');
  }

  GetEmployeesForLabor(): Observable<IEmployeeLabor[]> {
    return this.http.get<IEmployeeLabor[]>(API_END_POINT.payroll.labor + 'GetEmployeesForLabor');
  }

  GetPayrollGroupsForCertifiedPayroll(): Observable<IPayrollGroupLabor[]> {
    return this.http.get<IPayrollGroupLabor[]>(API_END_POINT.payroll.labor + 'GetPayrollGroupsForCertifiedPayroll');
  }

  //GetPaycatEligibilitiesForLabor(): Observable<IPaycatEligility[]> {
  //  return this.http.get<IPaycatEligility[]>(API_END_POINT.payroll.labor + 'GetPaycatEligibilitiesForLabor');
  //}

  DeleteAllTimesheetDataLabor(prlg_code: number, from_date: Date, to_date: Date, seq_no: number, year: number): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.labor + 'DeleteAllTimesheetDataLabor?prlg_code=' + prlg_code + '&from_date=' + from_date + '&to_date=' + to_date + '&seq_no=' + seq_no + '&year=' + year);
  }

  SaveTimesheetDataLabor(data: ILaborData): Observable<IlaborOutputWithSession> {
    return this.http.post<IlaborOutputWithSession>(API_END_POINT.payroll.labor + 'SaveTimesheetDataLabor', data);
  }

  processImportLabor(obj: ILaborImportParam): Observable<ILaborImportResult> {
    return this.http.post<ILaborImportResult>(API_END_POINT.payroll.labor + 'ProcessImportLabor', obj);
   }

  processImportLaborXML(fileContent: string, interfaceID: number, seqNo: number, from_date: Date, to_date: Date, year: number, prlgID: number, approved: number, interfaceCode: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Response-Type': 'json'
      }),

      params: new HttpParams().set('interfaceID', interfaceID.toString())
        .set('seqNo', seqNo.toString())
        .set('from_date', this.formatDate(from_date))
        .set('to_date', this.formatDate(to_date))
        .set('year', year.toString())
        .set('prlgID', prlgID.toString())
        .set('approved', approved.toString())
        .set('interfaceCode', interfaceCode)
    };
    return this.http.post<any>(API_END_POINT.payroll.labor + 'ProcessImportLaborXML', fileContent, httpOptions);
  }

  formatDate(jDate: Date): string {
    let dateStr = (new Date(jDate)).toISOString().substring(0, 10);
    return dateStr;
  }

  GetCostData(prgp: number, sess_id: string, from_date: Date, to_date: Date, year: number, seq_no: number, emp_id: number): Observable<ICostData[]> {
    return this.http.get<ICostData[]>(API_END_POINT.payroll.labor + 'GetCostData?prgp=' + prgp + '&sess_id=' + sess_id + '&from_date=' + from_date + '&to_date=' + to_date + '&year=' + year + '&seq_no=' + seq_no + '&emp_id=' + emp_id);
  }

  CatUsedinUnionAgreement(cat_dtl_code: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.labor + 'CatUsedinUnionAgreement?cat_dtl_code=' + cat_dtl_code);
  }

  GetPayrollCategoriesForCertifiedLabor() {
    return this.http.get<ICertifiedPayrollCategories[]>(API_END_POINT.payroll.labor + 'GetPayrollCategoriesForCertifiedLabor');
  }

  UpdateCertifiedLabor(ob: ICertifiedPayrollData) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateCertifiedLabor', ob);
  }

  UpdateCertifiedProjects(list: ICertifiedProjects[]) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateCertifiedProjects', list);
  }

  UpdateWorkClasses(list: IWorkClasses[]) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateWorkClasses', list);
  }

  UpdateCertifiedFringeBenefits(list: ICertiFringeBenefits[]) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateCertifiedFringeBenefits', list);
  }

  UpdateFringeBenefitGroup(list: IFringeUpdate[]) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateFringeBenefitGroup', list);
  }

  GetCertifiedPayrollData() {
    return this.http.get<ICertifiedPayroll>(API_END_POINT.payroll.labor + 'GetCertifiedPayrollData');
  }

  GetDimensionForCertifiedPayroll() {
    return this.http.get<IDimensionCertifiedPayroll[]>(API_END_POINT.payroll.labor + 'GetDimensionForCertifiedPayroll');
  }

  GetCpPreferences() {
    return this.http.get<ICpPreferences>(API_END_POINT.payroll.labor + 'GetCpPreferences');
  }

  GetAllPaymentDates() {
    return this.http.get<IPaymentDates[]>(API_END_POINT.payroll.labor + 'GetAllPaymentDates');
  }

  GetCertifiedPayrolExportPaycards(prlg_code: number, payment_date_status: number, payment_dates: string,
                                   pay_period_start: Date, pay_period_end: Date, unlock_paycrd_flg: number, paycrd_option: number) {
    return this.http.get<ICertifiedPayrollExportPaycard[]>(API_END_POINT.payroll.labor + 'GetCertifiedPayrolExportPaycards?prlg_code=' + prlg_code + '&payment_date_status='
      + payment_date_status + '&payment_dates=' + payment_dates + '&pay_period_start=' + pay_period_start + '&pay_period_end=' + pay_period_end +
      '&unlock_paycrd_flg=' + unlock_paycrd_flg + '&paycrd_option=' + paycrd_option);
  }

  SaveCertifiedPayrollExportFilters(ob: ISaveCertifiedPayrollExportFilters) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'SaveCertifiedPayrollExportFilters', ob);
  }

  UpdateExportData(ob: IUpdateExportData) {
    return this.http.post<number>(API_END_POINT.payroll.labor + 'UpdateExportData', ob);
  }

  GetDataForPaycardExportGrid(sess_id: string, prlg_code: number, proc_frm_dt: Date, proc_to_dt: Date) {
    return this.http.get<IPaycardExportGrid[]>(API_END_POINT.payroll.labor + 'GetDataForPaycardExportGrid?sess_id=' + sess_id + '&prlg_code=' + prlg_code + '&proc_frm_dt=' + proc_frm_dt + '&proc_to_dt=' + proc_to_dt);
  }

  GetDataForPaycardExportFileSave(prlg_code: number, frm_date: Date, to_date: Date, export_type: number) {
    return this.http.get<string>(API_END_POINT.payroll.labor + 'GetDataForPaycardExportFileSave?prlg_code=' + prlg_code + '&frm_date=' + frm_date + '&to_date=' + to_date + '&export_type=' + export_type);
  }

  UpdateCPQueue(list: ICPQueue[]) {
    return this.http.post<string>(API_END_POINT.payroll.labor + 'UpdateCPQueue', list);
  }

  CpWorkDateValidateOutput(list: ICPQueue[]) {
    return this.http.post<IWorkDateValidateOutput[]>(API_END_POINT.payroll.labor + 'CpWorkDateValidateOutput', list);
  }

  GetPayScheduleForCpExport(prlg_code: number) {
    return this.http.get<IPayScheduleForAdjstmntCP[]>(API_END_POINT.payroll.labor + 'GetPayScheduleForCpExport?prlg_code=' + prlg_code);
  }

  CertifiedPayrollExport(export_type: number) {
    return this.http.get<IGlExportOutput>(API_END_POINT.payroll.labor + 'CertifiedPayrollExport?export_type=' + export_type);
  }

  CertifiedPayrollPaycardExport(PROC_SESSION_ID: string, PROC_YEAR: number, PRLG_CODE: number, PROC_TO_DT: Date, REPORTED_PROC_TO_DT: Date) {
    return this.http.get<ICertifiedPayrollPaycardExportOutput>(API_END_POINT.payroll.labor + 'CertifiedPayrollPaycardExport?PROC_SESSION_ID=' + PROC_SESSION_ID + '&PROC_YEAR=' + PROC_YEAR + '&PRLG_CODE=' + PRLG_CODE + '&PROC_TO_DT=' + PROC_TO_DT + '&REPORTED_PROC_TO_DT=' + REPORTED_PROC_TO_DT);
  }

  UpdateCpQueueWithNewSessionId(ob: IUpdateCPQueueWithNewSessId) {
    return this.http.post<string>(API_END_POINT.payroll.labor + 'UpdateCpQueueWithNewSessionId', ob);
  }

  CheckDimensionIsUsedInGLAccSeg(lbr_dim_id: number) {
    return this.http.get<number>(API_END_POINT.payroll.labor + 'CheckDimensionIsUsedInGLAccSeg?lbr_dim_id=' + lbr_dim_id);
  }

  GetTimesheetLaborForAdjustment(prgp: number, sess_id: string, from_date: Date, to_date: Date, year: number, seq_no: number, emp_id: number): Observable<ITimesheetDataLabor[]> {
    return this.http.get<ITimesheetDataLabor[]>(API_END_POINT.payroll.labor + 'GetTimesheetLaborForAdjustment?prgp=' + prgp + '&sess_id=' + sess_id + '&from_date=' + from_date + '&to_date=' + to_date + '&year=' + year + '&seq_no=' + seq_no + '&emp_id=' + emp_id);
  }

  GetEmployeeLaborData(empId: number): Observable<IEmployeeLabor> {
    return this.http.get<IEmployeeLabor>(API_END_POINT.payroll.labor + 'GetEmployeeLaborData?empId=' + empId);
  }

  GetUnionAgreementData(): Observable<IUnionAgrmntList[]> {
    return this.http.get<IUnionAgrmntList[]>(API_END_POINT.payroll.labor + 'GetUnionAgreementData');
  }

  GetUnionAgreementData_V1(): Observable<IUnionAgrmntList[]> {
    return this.http.get<IUnionAgrmntList[]>(API_END_POINT.payroll.labor + 'GetUnionAgreementData_V1');
  }

  SaveAdjustmentTimesheetLabor(data: ILaborData): Observable<IlaborOutputWithSession> {
    return this.http.post<IlaborOutputWithSession>(API_END_POINT.payroll.labor + 'SaveAdjustmentTimesheetLabor', data);
  }

  GetLaborTimesheets(ob: IGetAdjstmntPaycrd): Observable<ILaborTimesheeInCost> {
    return this.http.get<ILaborTimesheeInCost>(API_END_POINT.payroll.labor + 'GetLaborTimesheets?Start_Date=' + ob.Start_Date + '&End_Date=' + ob.End_Date + '&Emp_Number=' + ob.Emp_Number + '&PayrollGroup_Id=' + ob.PayrollGroup_Id + '&Proc_Year=' + ob.Proc_Year + '&SequenceNo=' + ob.SequenceNo);
  }

  GetCostDataForAdjustment(prgp: number, sess_id: string, from_date: Date, to_date: Date, year: number, seq_no: number, emp_id: number): Observable<ICostData[]> {
    return this.http.get<ICostData[]>(API_END_POINT.payroll.labor + 'GetCostDataForAdjustment?prgp=' + prgp + '&sess_id=' + sess_id + '&from_date=' + from_date + '&to_date=' + to_date + '&year=' + year + '&seq_no=' + seq_no + '&emp_id=' + emp_id);
  }

  SaveAdjustmentCost(data: ICostAdjustment): Observable<IlaborOutputWithSession> {
    return this.http.post<IlaborOutputWithSession>(API_END_POINT.payroll.labor + 'SaveAdjustmentCost', data);
  }

  TestConnection(directInterfaceType: InterfaceTypeEnum, directInterfaceConfiguration: string): Observable<IConnectionResult> {
    const jsonObj = JSON.parse(directInterfaceConfiguration); // string to "any" object first

    if (directInterfaceType == InterfaceTypeEnum.BusinessCentral) // (interfaceCode.trim() == 'DYN365DO') || (interfaceCode.trim() == 'DYN365D*')
    {
      const businessCentralConfiguration = jsonObj as IBusinessCentralConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'TestBusinessCentral365Connection?bCTenantId=' + businessCentralConfiguration.TenantId + '&companyName=' + businessCentralConfiguration.Company + '&bcEnvironmentName=' + businessCentralConfiguration.ProductionOrSandbox);
    } else if (directInterfaceType == InterfaceTypeEnum.SageIntacct)
    {
      let sageIntacctConfiguration = jsonObj as ISageIntacctConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'TestSageIntacctConnection?companyId=' + sageIntacctConfiguration.CompanyId + '&entityId=' + sageIntacctConfiguration.EntityId + '&userId=' + sageIntacctConfiguration.UserId + '&password=' + sageIntacctConfiguration.Password);
    }
  }

  processImportLaborSeamless(obj: ILaborImportParam): Observable<ILaborImportResult> {
      return this.http.post<ILaborImportResult>(API_END_POINT.payroll.labor + "ProcessImportLaborSeamless", obj);
  }

}
