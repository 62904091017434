import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, NgForm} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';


export class BackendValidationError {
  isValid: boolean;
  errors: FluentValidatorErrors[];
  ruleSetsExecuted: string[];
}

export interface FluentValidatorErrors {
  propertyName: string;
  errorMessage: string;
}

export interface PreparedErrors {
  controlErrors: FluentValidatorErrors[];
  commonErrors: FluentValidatorErrors[];
}

export interface ImportErrorMessageModel {
  index: number;
  errors: FluentValidatorErrors[];
}

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor(
    private transloco: TranslocoService
  ) {
  }

  prepareErrorMessage(httpError: HttpErrorResponse): PreparedErrors {
    if (httpError?.error?.errors instanceof Array) {
      const errors = httpError?.error?.errors as FluentValidatorErrors[];

      const controlErrors: FluentValidatorErrors[] = [];
      const commonErrors: FluentValidatorErrors[] = [];
      errors.forEach(error => {
        if (error.propertyName.includes('[')) {
          controlErrors.push(error);
        } else {
          commonErrors.push({...error, errorMessage: 'validations.' + error.errorMessage});
        }
      });

      return {controlErrors, commonErrors};
    }
  }

  prepareImportErrorMessage(validationErrors: FluentValidatorErrors[]) {
    if (validationErrors?.length) {
      const errorsMap: ImportErrorMessageModel[] = [];
      validationErrors.forEach(error => {
        const errorPaths = error.propertyName?.split(/[\s,.\[\]]+/)?.filter(value => !!value);
        if (errorPaths.length) {
          const pathIndex = +errorPaths[1];
          const errorObject: FluentValidatorErrors = {
            propertyName: (this.transloco.translate(this.camelCasePropertyName(errorPaths[2]), {}, 'harmonySync')),
            errorMessage: this.translateValidationMessage(`validations.${error?.errorMessage}`)
          };
          if (errorsMap[pathIndex]) {
            errorsMap[pathIndex].errors.push(errorObject);
          } else {
            errorsMap.push({index: pathIndex, errors: [errorObject]});
          }
        }
      });
      return errorsMap;
    }
    return [] as ImportErrorMessageModel[];
  }

  translateValidationMessage(error: string | string[]): string {
    if (Array.isArray(error)) {
      if (!error?.length) {
        return null;
      }

      return error.map(err => this.extractValidationMessage(err)).join(', ');
    } else {
      return this.extractValidationMessage(error);
    }
  }

  private extractValidationMessage(error: string): string {
    const geti18nKey = (key: string) => key?.includes('validations') ? `${key}` : `validations.${key}`;
    if (error?.includes('|')) {
      const [errorKey, params] = error.split('|')?.map(err => err.trim());
      const [value1, value2] = params.split(',');
      switch (errorKey) {
        case 'min.withParams':
        case 'max.withParams':
        case 'minlength.withParams':
        case 'maxlength.withParams':
        case 'sizeRange.withParams':
        case 'fileType.withParams':
        case 'greaterThan':
        case 'lessThan':
        case 'greaterThanOrEqualTo':
        case 'lessThanOrEqualTo':
        case 'decimalPointsNotAllowed':
          return this.transloco.translate(`${geti18nKey(errorKey)}`, {value: params});
        case 'fileExtension.withParams':
        case 'range.withParams':
          return this.transloco.translate(`${geti18nKey(errorKey)}`, {value1, value2});
        case 'maxlength':
          if (params) {
            return this.transloco.translate(`${geti18nKey(errorKey)}.withParams`, {value: params});
          }
          return this.transloco.translate(`${geti18nKey(errorKey)}`, {value1, value2});
        default:
          return this.transloco.translate(`${geti18nKey(errorKey)}`, {value: params});
      }
    } else {
      return this.transloco.translate(geti18nKey(error));
    }
  }

  sinValidator(value: any): boolean {
    const scalar = [1, 2, 1, 2, 1, 2, 1, 2, 1];
    if (value) {
      if (value.toString().length < 9) {
        return true;
      }
      const sumValues = (array: number[]) => {
        return array.reduce((x, y) => {
          if (y > 9) {
            return x + sumValues(y.toString().split('').map(Number));
          }
          return x + y;
        }, 0);
      };
      const remainder = sumValues(value.split('').map((x, index) => +x * scalar[index])) % 10;
      return remainder === 0;
    }
    return true;
  }

  setFormValidationErrors(errors: FluentValidatorErrors[], formRef: NgForm | FormGroup | FormArray): void {
    // get object keys of all error messages
    if (errors && errors.length) {
      errors.forEach(err => {
        // matching error patterns of Fluent Validator
        // formGroup index from error message
        const controlNames = err?.propertyName?.split(/[\s,.\[\]]+/)?.filter(value => !!value); // extract field names

        const extractedFormControl = this.getControl(formRef, controlNames[0]); // get control by the name
        // loop for every error message
        const error = err.errorMessage;
        if (extractedFormControl) {// if form control exists
          switch (true) {
            case extractedFormControl instanceof FormArray:
              this.setFormArrayErrors(extractedFormControl as FormArray, controlNames, error);
              break;
            case extractedFormControl instanceof FormGroup:
              this.setFormGroupError(extractedFormControl as FormGroup, controlNames[0], error);
              break;
            case extractedFormControl instanceof FormControl:
              this.setControlError(extractedFormControl, error);
              break;
            default:
            // if it's not falling under any above

          }
        } else {
          // if it's not a valid formControl or not found in the DOM
        }
        if (formRef instanceof FormGroup) {
          formRef.markAllAsTouched();
        }
      });
    }
  }

  private setFormArrayErrors(controlRef: FormArray, controlNames: string[], error: string) {
// if the control is an array of form controls get the form group object
    const formGroup = this.getControl(controlRef as FormArray, controlNames[1]) as FormGroup;
    if (formGroup && formGroup instanceof FormGroup) {
      // if a valid form group or exists then set validation error then set error message
      this.setFormGroupError(formGroup, controlNames[2], error);
    } else {
      // if not found or invalid, show the error in a toast
      // this.alert.warningToast({translateTitle: {key: error}});
    }
  }

  private setFormGroupError(controlRef: FormGroup, formGroupName: string, error: string) {
    // if the control is a form control then show error in a toast
    const control = this.getControl(controlRef as FormGroup, formGroupName);
    if (control) {
      this.setControlError(control, error);
    } else {
    }
  }

  private setControlError(control: AbstractControl, error): void {
    if (control && error) {
      control.markAsDirty();
      control.markAsTouched();
      control.setErrors({invalid: error});
    }
  }

  private getControl(formRef: NgForm | FormGroup | FormArray, controlName: string)
    : FormControl | FormGroup | FormArray {
    const name = this.camelCasePropertyName(controlName);
    return formRef.controls[name];
  }

  protected camelCasePropertyName(text): string { // if the field names are not camelCase then camelCase it here
    if (!text) {
      return text;
    }
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  }
}
