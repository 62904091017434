<ng-container *transloco="let translate; read: 'timesheetGrid'">
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-between mb-3">
        <div class="col-sm-3">
          <kendo-combobox type="search"
                          class="form-control p-0"
                          [(ngModel)]="groupCode"
                          [data]="payrollGroups"
                          [textField]="'b'"
                          [valueField]="'a'"
                          [disabled]="wizardMode"
                          [filterable]="true"
                          [suggest]="true"
                          [placeholder]="'timesheetGrid.pleaseSelectGroup'|transloco"
                          (valueChange)="onPGDropDownChange($event)"></kendo-combobox>
        </div>
        <div class="col-sm-5">
          <span>{{payfrequency}} - {{payrollPeriod}}</span>
        </div>
        <div class="col-sm-auto d-flex justify-content-end">
          <button class="btn btn-success me-2" form="timeSheet" [disabled]="!timesheetForm.form.valid" type="submit"><i class="fas fa-save"></i></button>
          <button class="btn btn-danger me-2" (click)="checkUnsavedBeforeDeleteRow(1,1,1)" type="button"><i class="fas fa-trash"></i></button>
          <button class="btn btn-primary" openModal modalTarget="ImportPaycardModal" type="button" title="{{translate('import')}}" (click)="onClickImportPayCard()">
            <i class="fas fa-file-import"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <table class="table table-striped table-responsive table-scroll border">
            <tbody style="height: 68vh">
              <tr *ngFor="let c of unSignedEmpList; let i=index">
                <td>
                  <p class="mb-0 text-muted">{{c.n}}</p>
                  <p class="mb-0">{{c.q}}</p>
                </td>
                <td class="text-end" style="width: 50px;">
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="c.disable" (click)="checkUnsaveBeforeInsertingRow(c.p,c.i,c.c,c.u,c.r,[])">
                    <i class="mdi mdi-account-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-9">
          <form #timesheetForm="ngForm" id="timeSheet" (ngSubmit)="onTimeSheetSubmit(timesheetForm)">
            <table class="table table-sm table-striped table-responsive table-scroll border" *ngIf="summaryData && summaryData.length">
              <thead>
                <tr>
                  <th style="width: 30px">
                    <div class="form-check">
                      <input class="form-check-input" [ngModelOptions]="{standalone: true}" [disabled]="disableSelAll" (ngModelChange)="selectAllOnChange()" [ngModel]="selectAll"
                             type="checkbox">
                    </div>
                  </th>
                  <th>
                    <span class="wrap-item-center cursor-pointer sort-header" [class.active-col]="sortOrder=='eno'" (click)="sortOptionChange('eno')">
                      <span transloco="number"></span>
                      <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec||sortOrder!='eno' "></i>
                      <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='eno'"></i>
                    </span>
                  </th>
                  <th>
                    <span class="wrap-item-center cursor-pointer sort-header" [class.active-col]="sortOrder=='en'" (click)="sortOptionChange('en')">
                      <span transloco="name"></span>
                      <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec || sortOrder!='en'"></i>
                      <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='en'"></i>
                    </span>
                  </th>
                  <th>
                    <span class="wrap-item-center cursor-pointer sort-header" [class.active-col]="sortOrder=='cn'" (click)="sortOptionChange('cn')">
                      {{translate('category')}}
                      <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec || sortOrder!='cn'"></i>
                      <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='cn'"></i>
                    </span>
                  </th>
                  <th>
                    <span class="wrap-item-center cursor-pointer sort-header" [class.active-col]="sortOrder=='u'" (click)="sortOptionChange('u')">
                      <span transloco="units"></span>
                      <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec || sortOrder!='u'"></i>
                      <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='u'"></i>
                    </span>
                  </th>
                  <th>
                    <span class="wrap-item-center cursor-pointer sort-header" [class.active-col]="sortOrder=='r'" (click)="sortOptionChange('r')">
                      <span transloco="rate"></span>
                      <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec || sortOrder!='r'"></i>
                      <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='r'"></i>
                    </span>
                  </th>
                  <th>
                    <span class="wrap-item-center cursor-pointer sort-header" [class.active-col]="sortOrder=='total'" (click)="sortOptionChange('total')">
                      {{translate('total')}}
                      <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec || sortOrder !='total'"></i>
                      <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder == 'total'"></i>
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody style="height: 68vh">
                <tr *ngFor="let emp of summaryData  | orderBy: sortOrder:sortdirec">
                  <td class="align-middle" style="width: 30px;">
                    <div class="form-check">
                      <input class="form-check-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="emp.checked" [disabled]="emp.seq"
                             [checked]="selectAll || emp.checked" type="checkbox">
                    </div>
                    <label *ngIf="emp.seq"><i class="fas fa-lock"></i></label>
                  </td>
                  <td class="align-middle">{{'(' + emp.eno + ')'}}</td>
                  <td class="align-middle">{{emp.en}}</td>
                  <td class="align-middle">{{emp.cn}}</td>
                  <td>
                    <input [disabled]="emp.seq" [name]="emp.ei+'-'+emp.c+'u'" class="form-control form-control-sm" type="number"
                           [ngModel]="emp.u" (input)="emp['touched']=true" (ngModelChange)="unitsChange($event, emp)" />
                  </td>
                  <td>
                    <input [disabled]="emp.seq" min=0 [name]="emp.ei+'-'+emp.c+'r'" class="form-control form-control-sm" type="number"
                           [ngModel]="emp.r" (input)="emp['touched']=true" (ngModelChange)="rateChange($event, emp)" />
                  </td>
                  <td>
                    <input disabled [name]="emp.ei+'-'+emp.c+'t'" class="form-control form-control-sm" type="text"
                           (change)="emp.total=(emp.r*emp.u)" [value]="currencySymbol+((emp.r*emp.u) | number:'.2-2')" />
                  </td>
                  <td>
                    <button class="btn btn-outline-primary btn-sm me-2 mb-2" (click)="addCateToEmp(emp.ei,emp.en,emp.c)" [disabled]="emp.seq" type="button">
                      <i class="fas fa-plus"></i>
                    </button>
                    <button class="btn btn-outline-secondary btn-sm me-2 mb-2" (click)="addDailyTimesheet(emp.ei,emp.en,emp.c, timesheetModal)" type="button">
                      <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <button class="btn btn-outline-danger btn-sm mb-2" (click)="checkUnsavedBeforeDeleteRow(emp.ei,emp.c,0)" *ngIf="!emp.seq" type="button">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!--modal for daily timesheet-->
            <ng-template class="modal-content" #timesheetModal>
              <div class="modal-header">
                <h5 class="modal-title">{{modalTitle}}</h5>
                <button type="button" (click)="closeDailyTimesheet()" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <table class="table">
                      <tbody>
                        <ng-container *ngFor=" let emp of gridData">
                          <ng-container *ngFor="let cate of emp.cates; let in=index">
                            <tr>
                              <td colspan="7">
                                <div class="row justify-content-between">
                                  <div class="col-sm-6">
                                    <div class="row">
                                      <div class="col-12">
                                        <div *ngIf="in==0">{{emp.en}} ({{emp.eno}})</div>
                                      </div>
                                      <div class="col-12">
                                        <div class="fw-bold">{{cate.cn}}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="row">
                                      <label for="hourlyRate" class="col-form-label col-sm-auto">{{translate('hourlyRate')}}</label>
                                      <div class="col-sm-4 d-flex align-items-center">
                                        <input id="hourlyRate" [disabled]="cate.seq" required [name]="emp.ei+'-'+cate.c" class="form-control form-control-sm"
                                               type="number" [ngModel]="cate.r" (ngModelChange)="updateRateForEmp(emp.ei,cate.c,$event)" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-auto">
                                    <button class="btn btn-success me-2 mb-2" form="timeSheet" [disabled]="!timesheetForm.form.valid" type="submit">
                                      <i class="fas fa-save"></i>
                                    </button>
                                    <button class="btn btn-danger me-2 mb-2" (click)="checkUnsavedBeforeDeleteRow(emp.ei,cate.c,0)" *ngIf="!cate.seq"
                                            type="button">
                                      <i class="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr class="fw-bold">
                              <th class="text-center text-nowrap">
                                <span>{{translate('mon')}}</span>

                              </th>
                              <th class="text-center text-nowrap">
                                <span>{{translate('tue')}}</span>

                              </th>
                              <th class="text-center text-nowrap">
                                <span>{{translate('wed')}}</span>

                              </th>
                              <th class="text-center text-nowrap">
                                <span>{{translate('thu')}}</span>

                              </th>
                              <th class="text-center text-nowrap">
                                <span>{{translate('fri')}}</span>

                              </th>
                              <th class="text-center text-nowrap">
                                <span>{{translate('sat')}}</span>

                              </th>
                              <th class="text-center text-nowrap">
                                <span>{{translate('sun')}}</span>

                              </th>
                            </tr>
                            <tr>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.mfs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{  (item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #mon="ngModel"
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'" class="form-control form-control-sm border-0 text-center pe-0"
                                         type="number" [ngModel]="item.u" (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.tfs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{ (item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #tue
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                         class="form-control form-control-sm border-0 text-center pe-0" type="number" [ngModel]="item.u"
                                         (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.wfs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{(item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #wes
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'" class="form-control form-control-sm border-0 text-center pe-0"
                                         type="number" [ngModel]="item.u" (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.thfs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{ (item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #thu
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'" class="form-control form-control-sm border-0 text-center pe-0"
                                         type="number" [ngModel]="item.u" (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.ffs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{ (item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #fri
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'" class="form-control form-control-sm border-0 text-center pe-0"
                                         type="number" [ngModel]="item.u" (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.sfs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{ (item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #sat
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'" class="form-control form-control-sm border-0 text-center pe-0"
                                         type="number" [ngModel]="item.u" (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                              <td class="weekday">
                                <div class="mb-2" [ngClass]="{'border': !item.placeholder}" *ngFor="let item of cate.sufs">
                                  <span [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                        class="d-block text-center bg-light pb-1">{{(item.wd | date: 'MMM dd') || 'placeholder'}}</span>
                                  <input [tabindex]="item.tabindex" [disabled]="cate.seq" [min]="0" [name]="emp.ei+'-'+cate.c+'-'+item.wd" #sun
                                         [style.visibility]="item.placeholder ? 'hidden' : 'visible'"
                                         class="form-control form-control-sm border-0 text-center pe-0" type="number" [ngModel]="item.u"
                                         (input)="item['touched']=true" (ngModelChange)="item.u = $event" />

                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!--modal for select payroll categor//fade-->
  <ng-template class="modal-content" #categorySelection>
    <div class="modal-header">
      <h5 class="modal-title">{{modalTitle}}</h5>
      <button type="button" class="btn-close" (click)="categorySelectionModalRef?.hide()" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <kendo-combobox type="search" class="form-control p-0" [(ngModel)]="modalSelectedCate"
                      [data]="modalCateSelectList"
                      [textField]="'cn'"
                      [valueField]="'c'"
                      [filterable]="true"
                      [placeholder]="'timesheetGrid.pleaseSelectCategory'|transloco"
                      (selectionChange)="onModelSelection($event)"></kendo-combobox>
    </div>
  </ng-template>
  <app-import-paycard [payrollPeriodBase]="payrollPeriodBase" [payrollGroupID]="groupCode ? groupCode.a : null"
                      (updateList)="onPGDropDownChange(groupCode)" (formLoaded)="onPayCardFormLoaded($event)"></app-import-paycard>
</ng-container>
