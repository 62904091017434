import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NewsAlertModel} from '@shared/models/news-alert.model';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  news: NewsAlertModel;

  constructor(
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
  }

}
