<ng-container *transloco="let translate; read: 'wizardBar'">
  <div class="card mb-2 d-print-none" *ngIf="wizardBarEle">
    <div class="card-body justify-content-center">
      <div class="row mb-2">
        <div class="col-12 d-flex justify-content-center">
          <div *ngIf="!wizardBarEle" class="btn-toolbar w-100" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group w-100 arrow-steps" role="group" aria-label="First group">
              <button
                type="button"
                [hidden]="item?.sc == 6 && !rptPermission"
                class="btn btn-outline-primary step"
                (click)="changeRoute(item.so)"
                *ngFor="let item of wizardBarEle; let i = index"
              >
                <ng-container [ngSwitch]="item?.sf">
                  <i class="fas fa-check-circle text-success" *ngSwitchCase="1"></i>
                  <i class="fas fa-check-circle text-warning" *ngSwitchCase="2"></i>
                  <i class="far fa-check-circle text-muted" *ngSwitchDefault></i>
                </ng-container>
                {{item?.scap}}
              </button>
            </div>
          </div>
          <div *ngIf="wizardBarEle" class="btn-toolbar w-100" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group w-100" role="group" aria-label="First group">
              <button type="button" [hidden]="item?.sc == 6 && !rptPermission" class="btn btn-outline-primary" [ngClass]="{'active': item?.sc === currentStep}"
                      (click)="changeRoute(item.so)" *ngFor="let item of wizardBarEle; let i = index">
                <ng-container *ngIf="item?.sf == 2; else greenTick"><span class="badge rounded-pill bg-warning">{{i + 1}}</span></ng-container>
                <ng-template #greenTick><span class="badge rounded-pill" [ngClass]="item?.sf ? 'bg-success' : 'bg-light border'">{{i + 1}}</span></ng-template>
                {{item?.scap}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-between" *ngIf="wizardBarEle">
        <div class="col-auto">
          <button class="btn btn-secondary mb-2" (click)="prev(currentOrder)" *ngIf="currentOrder !== 1">
            <i class="fas fa-chevron-left"></i>&nbsp;{{translate('previous')}}
          </button>
        </div>
        <div class="col-auto" *ngIf="currentOrder!=1">
          <span class="me-3">{{PayrollGroupInfoEle?.payrollPeriod}}</span>
          <span class="me-3">{{PayrollGroupInfoEle?.payfrequency}}</span>
          <span>{{PayrollGroupInfoEle?.gn}}</span>
        </div>
        <div class="col-auto">
          <button class="btn btn-secondary me-2 mb-2" (click)="next(currentOrder)" *ngIf="currentOrder !== 8">{{translate('next')}}&nbsp;<i
            class="fas fa-chevron-right"></i></button>
          <button class="btn btn-primary mb-2" [disabled]="wizardBarEle[currentOrder-1]?.sf==1 || (sd && currentStep == 2)" (click)="updateStepFlag(1)"
                  *ngIf="currentStep < 8">
            <i class="fas fa-check"></i>&nbsp;{{translate('complete')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<!--<ul *ngIf="wizardBarEle" class="tabs  primary-nav shadow">-->
<!--  <li *ngFor="let item of wizardBarEle" class="tabs__item" [class.active]="item.sc == currentStep">-->
<!--    <a class="tabs__link" (click)="changeRoute( item.so)">-->
<!--      <label style="pointer-events: none;" class="mr-2" [attr.edited]="item.sf==2"><input [(ngModel)]="item.sf" class="custom-checkbox" type="checkbox"><span-->
<!--        class="label-text"></span></label>-->
<!--      <span>{{item?.scap}}</span> &lt;!&ndash;+'-'+item.mf+item.wf+item.sf+currentStep+currentOrder&ndash;&gt;-->
<!--    </a>-->
<!--  </li>-->
<!--  <li class="wrap-item-center">-->
<!--    <div *ngIf="currentOrder!=1" class="d-flex justify-content-center px-4 payPeriodFrequnecyblock">-->
<!--      <span class="mr-3">{{PayrollGroupInfoEle?.payrollPeriod}}</span>-->
<!--      <span class="mr-3">{{PayrollGroupInfoEle?.payfrequency}}</span>-->
<!--      <span>{{PayrollGroupInfoEle?.gn}}</span>-->
<!--    </div>-->
<!--  </li>-->
<!--  <li class="d-flex align-items-center px-4" *ngIf="currentStep < 8" style="justify-content:flex-end;">-->
<!--    <button [disabled]="wizardBarEle[currentOrder-1].sf==1" (click)="updateStepFlag(1)" class="bluebtn">-->
<!--      <span>Complete</span>-->
<!--    </button>-->
<!--  </li>-->
<!--</ul>-->

<!--<span *ngIf="currentStep>1" class="float-left wizard-nav-btn"><button (click)="prev(currentOrder)" class="wizard-prev"></button></span>-->
<!--<span *ngIf="currentStep<8" class="float-right wizard-nav-btn"><button (click)="next(currentOrder)" class="wizard-next"></button></span>-->
