import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslocoModule} from '@ngneat/transloco';
import {SharedModule} from '@shared/shared.module';

import {UIModule} from '@shared/ui/ui.module';
import {ClickOutsideModule} from 'ng-click-outside';

import {SimplebarAngularModule} from 'simplebar-angular';
import {AccessControlDirectiveModule} from '../../account/access-control/access-control-directive/access-control-directive.module';
import {FooterComponent} from './footer/footer.component';
import {HorizontalComponent} from './horizontal/horizontal.component';
import {HorizontaltopbarComponent} from './horizontaltopbar/horizontaltopbar.component';
import {LayoutComponent} from './layout.component';
import {RightsidebarComponent} from './rightsidebar/rightsidebar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {TopbarComponent} from './topbar/topbar.component';
import {VerticalComponent} from './vertical/vertical.component';
import { ProcessInProgressPopComponent } from './process-in-progress-pop/process-in-progress-pop.component';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';


@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    HorizontalComponent,
    VerticalComponent,
    HorizontaltopbarComponent,
    ProcessInProgressPopComponent
  ],
    imports: [
        CommonModule,
        TranslocoModule,
        RouterModule,
        NgbDropdownModule,
        ClickOutsideModule,
        UIModule,
        SimplebarAngularModule,
        SharedModule,
        AccessControlDirectiveModule,
        ProgressbarModule
    ]
})
export class LayoutsModule {
}
