import {DatePipe} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {EmployeeListService} from '@harmony-modules/payroll/services/employee/employee-list.service';
import {PayrollCategoryEntitlementService} from '@harmony-modules/payroll/services/payroll-setup/payroll-category-entitlement.service';
import {PayrollCategoryService} from '@harmony-modules/payroll/services/payroll-setup/payroll-category.service';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {GlobalService} from '@services/global.service';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {IEmployee} from 'src/app/shared/models/iemployee';
import {
  IPayrollCategoryDefaultEmployeeList,
  IPayrollcategoryEmpWiseList,
  IPayrollCategoryListEmpWiseGet,
  IUpdateEmpHours
} from 'src/app/shared/models/ipayroll-category';
import {IPayScheculeForDefaultEmployeeList} from 'src/app/shared/models/ipayroll-group';
import {DeleteConfirmationService} from 'src/app/shared/services/delete-confirmation.service';
import { BsCustomModalService } from '@shared/bs-custom-modal/bs-custom-modal.service';

declare var showMsg: any;
const scope = 'payrollCategoryEntitlmentEmployeeWise';

@Component({
  selector: 'app-payroll-category-entitlment-employee-wise',
  templateUrl: './payroll-category-entitlment-employee-wise.component.html',
  styleUrls: ['./payroll-category-entitlment-employee-wise.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: { scope: 'payrollCategoryEntitlmentEmployeeWise', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`)) }
  }]
})
export class PayrollCategoryEntitlmentEmployeeWiseComponent implements OnInit {
  private deleteSubscription: any;
  private payScheduleForDefaultEmployeeListSubscription: any;
  private addEmployeeSubscription: any;
  private eidtEmployeeSubscription: any;
  check: number = 0;
  empList: IPayrollCategoryDefaultEmployeeList[] = [];
  empSelected: IPayrollCategoryDefaultEmployeeList;
  empProfilePath: string = null;
  empSource: IPayrollCategoryDefaultEmployeeList[] = [];
  empListInit: IPayrollCategoryDefaultEmployeeList[] = [];
  dropdownListValues: any[] = [{name: '', cssClass: ''}];
  payrollCatList: IPayrollcategoryEmpWiseList[] = [];
  tempPcList: IPayrollcategoryEmpWiseList[];
  obj: IPayrollcategoryEmpWiseList;
  filteredPcList: IPayrollCategoryListEmpWiseGet[] = [];
  tempFilteredPcList: IPayrollCategoryListEmpWiseGet[] = [];
  filteredPcListSrc: IPayrollCategoryListEmpWiseGet[] = [];
  addNewPc: IPayrollCategoryListEmpWiseGet;
  pcSelected: IPayrollcategoryEmpWiseList = {
    dc: null,
    ei: null,
    bp: null,
    ea: null,
    ya: null,
    u: null,
    pf: null,
    pt: null,
    ie: null,
    dn: null,
    uc: null,
    iu: null,
    a: null,
    e: null,
    tc: null,
    t: null,
    k: null,
    idf: null,
    paf: null,
    yaf: null,
    pbeyr: null,
    fc: null,
    oa: null,
    ou: null,
    minf: null,
    minw: null,
    emin: null,
    maxf: null,
    maxm: null,
    maxw: null,
    emax: null
  };
  selectedDropdownValue: string = null;
  isDropDownvisible: boolean = true;
  cssClasses: string[] = [];
  imgClass: string;
  colorClass: string;
  public showMsg: any;
  dateRange: string = 'yyyy-mm-dd - yyyy-mm-dd';
  dateRangeEdit: string = 'yyyy-mm-dd - yyyy-mm-dd';
  payScheduleForDftEmps: IPayScheculeForDefaultEmployeeList[] = [];
  checkedSchedules: IPayScheculeForDefaultEmployeeList[] = [];
  earliestDate: IPayScheculeForDefaultEmployeeList;
  latestDate: IPayScheculeForDefaultEmployeeList;
  lrflg: number;
  startDateFilter: Date;
  endDateFilter: Date;
  empDateRange: string = 'yyyy-mm-dd - yyyy-mm-dd';
  empWithBr: IEmployee[] = [];
  rate: number = 0;
  empIdAdj: number;
  mode: number;
  isOnDateSlt: boolean = false;
  externalp: number = 0;
  allChecked: boolean = false;
  sysPref: any;
  is_unit_enabled: boolean = true;
  working_hrs: number = 0;
  daily_hrs: number = 0;
  update_hrs_ob: IUpdateEmpHours;
  decimalPattern: RegExp;
  regex1: any;
  regex2: any;
  regex3: any;
  regex4: any;
  regex: any;
  isUS: boolean = false;
  hours_edited: boolean = false;
  opnAmtChanged: number = 0;
  proc_year: number;
  modalFlg: number;

  constructor(private _payrollGroupService: PayrollCategoryService, private _deleteConfirmation: DeleteConfirmationService, private _datePipe: DatePipe, private _sysPrefService: SystemPreferenceService, private _empService: EmployeeListService, private _entitlementService: PayrollCategoryEntitlementService,
              private route: ActivatedRoute, private _globlService: GlobalService,
              public wizardService: WizardService,
    private router: Router,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private customModalService: BsCustomModalService) {
  }

  ngOnInit() {
    this.empIdAdj = this.route.snapshot.params.i;
    this.mode = this.route.snapshot.params.f; /*mode==1 from process, otherwise mode==0*/
    //this.externalp = this.route.snapshot.params.e;
    this._sysPrefService.getinitSystemPreferences().subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
        if ((this.sysPref.y != null) && (this.sysPref.y == 82)) {
          this.isUS = true;
        }
        this.decimalPattern = new RegExp('^\d+(\.\d{0, ' + this.sysPref.g + '})?$');
        this.regex1 = '^\\d+(\\.\\d{0,' + this.sysPref.g + '})?$';
        this.regex2 = '^\\d+(\\.\\d{0,' + this.sysPref.h + '})?$';
        this.regex3 = '^-?\\d+(\\.\\d{0,2})?$';
        this.regex4 = '^-?\\d+(\\.\\d{0,' + this.sysPref.h + '})?$';
        this.regex = '^\\d+(\\.\\d{0,2})?$';
        this.empDateRange = (this.sysPref.df ? this.sysPref.df : 'yyyy-mm-dd') + ' - ' + (this.sysPref.df ? this.sysPref.df : 'yyyy-mm-dd');
      }
    });
    this.initialEmployeeLoad();
  }

  initialEmployeeLoad(): void {
    this._entitlementService.getDefaultEmpListForEmployee().subscribe((data) => {
      // this.empIdAdj = this.route.snapshot.params.i;
      //this.mode = this.route.snapshot.params.f; /*mode==1 from process, otherwise mode==0*/
      // this.externalp = this.route.snapshot.params.e;

      this.empListInit = data;
      this.empList = this.empListInit.filter(x => x.ap == 1);
      this.empSource = [].concat(this.empList);
      if (this.mode == 1) {
        this.empSelected = this.empList.find(x => x.ie == this.empIdAdj);
        this.onEmpSelect(this.empSelected.ie, this.externalp);
      }
      this._payrollGroupService.getPayrollCategoryListEmpWise().subscribe(data => {
        this.tempFilteredPcList = data;
        //console.log('externalp==>>');
        //console.log(this.externalp);
        this._payrollGroupService.getPayrollCategoryEmpWise(this.externalp).subscribe(data => {
          this.tempPcList = data;
          this.dropdownListValues = this.getDropdownListValues(this.tempPcList);/*this.labelList = this.getPayrollCategorySignatures("1|2|3")*/
          this.objInit();
          this.pcSelectedInit();
        });
      });
      //Get Pay schedules for default employees
      this.payScheduleForDefaultEmployeeListSubscription = this._entitlementService.getPayScheduleForDefaultEmployeeListForEmp().subscribe(data => {
        this.payScheduleForDftEmps = data;
      });
    });
  }

  checkPayScheduleRange(range: IPayScheculeForDefaultEmployeeList) {
    //this.dateRangeEdit = this.dateFormat(this.pcSelected.pf, this.pcSelected.pt);

    if (this.pcSelected) {
      let s = new Date(range.s);
      let e = new Date(range.e);
      let es = new Date(this.pcSelected.pf);
      let ee = new Date(this.pcSelected.pt);
      let result = (s >= es && s < ee);
      let result2 = (e <= ee && e > es);
      return result && result2;
    }

    return false;
  }

  vldAmtWithIdf(flg: number /*0 is edit, 1 is add*/): any {
    var res = true;
    //if (flg == 0) {
    //  if (this.pcSelected.idf == 0) {
    //    if (this.pcSelected.iu == 0 || this.pcSelected.iu == 3) {
    //      if (this.pcSelected.ea == 0) { res = false; }
    //    }
    //    else if (this.pcSelected.iu == 1) {
    //      if (this.pcSelected.ea == 0 || this.pcSelected.u == 0) { res = false; }
    //    }
    //    else if (this.pcSelected.iu == 2) {
    //      if (this.pcSelected.ea == 0 || this.pcSelected.ya == 0) { res = false; }
    //    }
    //  }
    //}
    //if (flg == 1) {
    //  if (this.addNewPc.idf == 0) {
    //    if (this.addNewPc.iu == 0 || this.addNewPc.iu == 3) {
    //        if (this.obj.ea == 0) { res = false; }
    //    }
    //    else if (this.addNewPc.iu == 1) {
    //        if (this.obj.ea == 0 || this.obj.u == 0) { res = false; }

    //    }
    //    else if (this.addNewPc.iu == 2) {
    //      if (this.obj.ea == 0 || this.obj.ya == 0) { res = false; }
    //    }
    //  }
    //}
    //if (res == false) {
    //  showMsg({
    //    'type': 'sticky',
    //    'status': 'error',
    //    'title': 'Invalid Amount',
    //    'buttons': {
    //      'OK': {
    //        action: function () {
    //          return;
    //        }
    //      },
    //    },
    //    'message': 'The payroll category amount is invalid.'
    //  });
    //}
    return res;
  }

  processAdd(): any {
    this.obj.dc = this.addNewPc.o;
    this.obj.ei = this.empSelected.ie;
    this.obj.ie = 1;
    this.obj.emin = this.addNewPc.emin;
    this.obj.emax = this.addNewPc.emax;
    if (this.addNewPc.t == 1 || this.addNewPc.t == 3) {
      this.obj.pf = new Date();
      this.obj.pt = new Date();
      this.obj.bp = 0;
    } else if (this.addNewPc.t == 2) {
      if (this.obj.pf == null || this.obj.pt == null || this.obj.bp == null) {
        let s = new Date(this.empSelected.c.split(' - ')[0].split(' ')[0]);
        let e = new Date(this.empSelected.c.split(' - ')[1].split(' ')[0]);
        this.obj.pf = s;//this.empSelected.s;
        this.obj.pt = e;//this.empSelected.e;
        this.obj.bp = 1;
      }
    }
    if (this.addNewPc.p == 3 && this.addNewPc.iu == 2) {
      //if is contribution percentage
      if (!this.isPercentageValid(this.obj.ea * 1, this.obj.ya * 1)) {
        var title = this.transloco.translate('error');
        var msg = this.transloco.translate('alerts.theSumOfEmployeeAndEmployerAmountShouldNotExceed100', {}, 'payrollCategoryEntitlmentEmployeeWise');
        var btn = this.transloco.translate('ok');
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [btn]: {
              action: function() {
                return;
              }
            }
          },
          'message': msg
        });
        return false;
      }
    }
    if (!this.vldAmtWithIdf(1)) {
      return false;
    }
    return true;
  }

  onSubmit(f: NgForm): void {
    if (this.processAdd()) {
      //console.log('this.obj=>');
      //console.log(this.obj);
      this._empService.CheckEmployeeUnlockedPaycardsForAss(this.empSelected.ie).subscribe(d => {
        if (d > 0) {
          var title = this.transloco.translate('warning');
          var msg = this.transloco.translate('alerts.doYouWantToRollbackPaycardAndSaveChanges', {}, 'payrollCategoryEntitlmentEmployeeWise');
          var btn1 = this.transloco.translate('yes');
          var btn2 = this.transloco.translate('no');
          var that = this;
          showMsg({
            'type': 'sticky',
            'status': 'warning',
            'title': title,
            'buttons': {
              [btn1]: {
                action: function() {
                  that._payrollGroupService.DeleteUnlockedPaycardByEmpId(that.empSelected.ie).subscribe(x => {
                      that.addEmployeeSubscription = that._entitlementService.addEmpToPayrollCategoryEmpWise(that.obj).subscribe(
                        suc => {
                          var title = that.transloco.translate('success');
                          var msg = that.transloco.translate('alerts.thePayrollCategoryIsAddedSuccessfully', {}, 'payrollCategoryEntitlmentEmployeeWise');
                          showMsg({
                            'type': 'nonsticky',
                            'status': 'success',
                            'title': title,
                            'buttons': {},
                            'message': msg
                          });
                          that._payrollGroupService.getPayrollCategoryEmpWise(that.externalp).subscribe(data => {
                            that.tempPcList = data;
                            that.payrollCatList = that.tempPcList.filter(x => x.ei == that.empSelected.ie).sort((a, b) => a.uc > b.uc ? 1 : -1);
                            that.dateRange = that.empSelected.c == null ? 'yyyy-mm-dd - yyyy-mm-dd' : that._datePipe.transform(that.empSelected.s, that.sysPref.df ? that.sysPref.df : 'yyyy-MM-dd') + ' - ' + that._datePipe.transform(that.empSelected.e, that.sysPref.df ? that.sysPref.df : 'yyyy-MM-dd');
                            that.objInit();
                            that.pcSelectedInit();
                            that.onEmpSelect(that.empSelected.ie, that.externalp);
                            that.addNewPc = null;
                            that.is_unit_enabled = true;
                            for (let i = 0; i < that.payrollCatList.length; i++) {
                              if (that.payrollCatList[i].e == 4 && that.payrollCatList[i].iu == 1) {
                                that.is_unit_enabled = false;
                              }
                            }
                          });
                        }
                      );
                    },
                    err => {
                      var title = that.transloco.translate('error');
                      var msg = that.transloco.translate('alerts.errorOccurredWhenRollbackUnpostedPaycards', {}, 'payrollCategoryEntitlmentEmployeeWise');
                      var btn = that.transloco.translate('ok');
                      showMsg({
                        'type': 'sticky',
                        'status': 'error',
                        'title': title,
                        'buttons': {
                          [btn]: {
                            action: function() {
                            }
                          }
                        },
                        'message': msg
                      });
                    });
                }
              },
              [btn2]: {
                action: function() {
                }
              }
            },
            'message': msg
          });
        } else {
          this.addEmployeeSubscription = this._entitlementService.addEmpToPayrollCategoryEmpWise(this.obj).subscribe(
            suc => {
              var title = this.transloco.translate('success');
              var msg = this.transloco.translate('alerts.thePayrollCategoryIsAddedSuccessfully', {}, 'payrollCategoryEntitlmentEmployeeWise');
              showMsg({
                'type': 'nonsticky',
                'status': 'success',
                'title': title,
                'buttons': {},
                'message': msg
              });
              this._payrollGroupService.getPayrollCategoryEmpWise(this.externalp).subscribe(data => {
                this.tempPcList = data;
                this.payrollCatList = this.tempPcList.filter(x => x.ei == this.empSelected.ie).sort((a, b) => a.uc > b.uc ? 1 : -1);
                this.dateRange = this.empSelected.c == null ? 'yyyy-mm-dd - yyyy-mm-dd' : this._datePipe.transform(this.empSelected.s, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd') + ' - ' + this._datePipe.transform(this.empSelected.e, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd');
                this.objInit();
                this.pcSelectedInit();
                this.onEmpSelect(this.empSelected.ie, this.externalp);
                this.addNewPc = null;
                this.is_unit_enabled = true;
                for (let i = 0; i < this.payrollCatList.length; i++) {
                  if (this.payrollCatList[i].e == 4 && this.payrollCatList[i].iu == 1) {
                    this.is_unit_enabled = false;
                  }
                }
              });
            }
          );
        }
      });
    }
  }

  processEdit(): any {
    if (this.pcSelected.t == 1 || this.pcSelected.t == 3) {
      this.pcSelected.pf = new Date();
      this.pcSelected.pt = new Date();
      this.pcSelected.bp = 0;
    }
    if (this.pcSelected.e == 3 && this.pcSelected.iu == 2) {
      //if is contribution percentage
      if (!this.isPercentageValid(this.pcSelected.ea * 1, this.pcSelected.ya * 1)) {
        var title = this.transloco.translate('error');
        var msg = this.transloco.translate('alerts.theSumOfEmployeeAndEmployerAmountShouldBe100', {}, 'payrollCategoryEntitlmentEmployeeWise');
        var btn = this.transloco.translate('ok');
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [btn]: {
              action: function() {
                return;
              }
            }
          },
          'message': msg
        });
        return false;
      }
    }
    if (!this.vldAmtWithIdf(0)) {
      return false;
    }
    return true;
  }

  edit() {
    if (this.processEdit()) {
      let empid = this.pcSelected.ei;
      let catid = this.pcSelected.dc;
      this._empService.CheckEmployeeUnlockedPaycardsForAss(this.empSelected.ie).subscribe(d => {
        if (d > 0) {
          var title = this.transloco.translate('warning');
          var msg = this.transloco.translate('alerts.doYouWantToRollbackPaycardAndSaveChanges', {}, 'payrollCategoryEntitlmentEmployeeWise');
          var btn1 = this.transloco.translate('yes');
          var btn2 = this.transloco.translate('no');
          var that = this;
          showMsg({
            'type': 'sticky',
            'status': 'warning',
            'title': title,
            'buttons': {
              [btn1]: {
                action: function() {
                  that._payrollGroupService.DeleteUnlockedPaycardByEmpId(that.empSelected.ie).subscribe(x => {
                      that.eidtEmployeeSubscription = that._entitlementService.editEntitledEmployeeEmpWise(that.pcSelected).subscribe(
                        suc => {
                          if (this.opnAmtChanged == 1) {
                            this._globlService.GetSelectedPayrollYear().subscribe(
                              res => {
                                this.proc_year = res;
                                this._entitlementService.updateEmployeeYTDWholeYearOneCategory(empid, this.proc_year, catid).subscribe(d => {
                                  this.opnAmtChanged = 0;
                                });
                              }
                            );
                          }
                          var title = that.transloco.translate('success');
                          var msg = that.transloco.translate('alerts.thePayrollCategoryIsUpdatedSuccessfully', {}, 'payrollCategoryEntitlmentEmployeeWise');
                          showMsg({
                            'type': 'nonsticky',
                            'status': 'success',
                            'title': title,
                            'buttons': {},
                            'message': msg
                          });
                          that._payrollGroupService.getPayrollCategoryEmpWise(that.externalp).subscribe(data => {
                            that.tempPcList = data;
                            that.payrollCatList = that.tempPcList.filter(x => x.ei == that.empSelected.ie).sort((a, b) => a.uc > b.uc ? 1 : -1);
                            that.onEmpSelect(that.empSelected.ie, that.externalp);
                            that.pcSelected = null;
                          });
                        }
                      );
                    },
                    err => {
                      var title = that.transloco.translate('success');
                      var msg = that.transloco.translate('alerts.errorOccurredWhenRollbackUnpostedPaycards', {}, 'payrollCategoryEntitlmentEmployeeWise');
                      var btn = that.transloco.translate('ok');
                      showMsg({
                        'type': 'sticky',
                        'status': 'error',
                        'title': title,
                        'buttons': {
                          [btn]: {
                            action: function() {
                            }
                          }
                        },
                        'message': msg
                      });
                    });
                }
              },
              [btn2]: {
                action: function() {
                }
              }
            },
            'message': msg
          });
        } else {
          this.eidtEmployeeSubscription = this._entitlementService.editEntitledEmployeeEmpWise(this.pcSelected).subscribe(
            suc => {
              if (this.opnAmtChanged == 1) {
                this._globlService.GetSelectedPayrollYear().subscribe(
                  res => {
                    this.proc_year = res;
                    this._entitlementService.updateEmployeeYTDWholeYearOneCategory(empid, this.proc_year, catid).subscribe(d => {
                      this.opnAmtChanged = 0;
                    });
                  }
                );
              }
              this._payrollGroupService.getPayrollCategoryEmpWise(this.externalp).subscribe(data => {
                this.tempPcList = data;
                this.payrollCatList = this.tempPcList.filter(x => x.ei == this.empSelected.ie).sort((a, b) => a.uc > b.uc ? 1 : -1);
                this.onEmpSelect(this.empSelected.ie, this.externalp);
                this.pcSelected = null;
              });
              var title = this.transloco.translate('success');
              var msg = this.transloco.translate('alerts.thePayrollCategoryIsUpdatedSuccessfully', {}, 'payrollCategoryEntitlmentEmployeeWise');
              showMsg({
                'type': 'nonsticky',
                'status': 'success',
                'title': title,
                'buttons': {},
                'message': msg
              });
            }
          );
        }
      });
    }
  }

  onEmpSelect(id: number, ex: number) {
    if (id) {
      let tempEmp = this.empList.find(x => (x.ie == id));
      this.daily_hrs = tempEmp.dh;
      this.working_hrs = tempEmp.hp;
      this._payrollGroupService.getPayrollCategoryEmpWise(ex).subscribe(data => {
        this.empProfilePath = '/assets/image_user/' + id + '-' + this.sysPref.dbname + '.png';
        this.tempPcList = data;
        this.payrollCatList = this.tempPcList.filter(x => x.ei == id).sort((a, b) => a.uc > b.uc ? 1 : -1);
        //console.log('payrollCatList==>>>>');
        //console.log(this.payrollCatList);
        //this.dateRange = this.empSelected.c == null ? 'yyyy-mm-dd - yyyy-mm-dd' : this._datePipe.transform(this.empSelected.s, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd') + ' - ' + this._datePipe.transform(this.empSelected.e, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd');
        this._payrollGroupService.getPayrollCategoryListEmpWise().subscribe(data => {
          this.tempFilteredPcList = data;
          this.filteredPcList = [].concat(this.tempFilteredPcList);
          this.filteredPcList = this.filteredPcList.filter(x => x.q == 1);
          this.is_unit_enabled = true;
          for (let i = 0; i < this.payrollCatList.length; i++) {
            if (this.payrollCatList[i].e == 4 && this.payrollCatList[i].iu == 1) {
              this.is_unit_enabled = false;
            }
            this.filteredPcList = this.filteredPcList.filter(x => x.o != this.payrollCatList[i].dc);
            this.filteredPcListSrc = [].concat(this.filteredPcList);
          }
          this.filteredPcList = this.filteredPcList.sort((a, b) => a.l > b.l ? 1 : -1);
          let s = this.empSelected.c.split(' - ')[0].split(' ')[0];
          let e = this.empSelected.c.split(' - ')[1].split(' ')[0];
          if (s && e) {
            this.dateRange = s + ' - ' + e;
            this.empDateRange = (this.sysPref.df ? this.sysPref.df : 'yyyy-mm-dd') + ' - ' + (this.sysPref.df ? this.sysPref.df : 'yyyy-mm-dd');
          } else {
            this.dateRange = null;
            this.empDateRange = null;
          }
          //this.empDateRange = this._datePipe.transform(this.empSelected.s, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd') + ' - ' + this._datePipe.transform(this.empSelected.e, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd')//this.empSelected.c;
        });
      });
    }
  }

  onClose() {
    //alert("closed *****")
    this.empProfilePath = null;
  }

  //Add new category to employee
  onPcSelect(dc: number) {
    this.addNewPc = this.filteredPcList.find(x => x.o == dc);
    //console.log(this.addNewPc);
    this.rate = this.addNewPc.ea;
    var temp;
    if (this.addNewPc.iu == 1) {
      this._empService.getEmployeeListForAssign(this.isUS).subscribe(data => {
        this.empWithBr = data;
        temp = this.empWithBr.find(x => x.j == this.empSelected.ie);
        //console.log(temp);
        if (this.addNewPc.paf == null || this.addNewPc.paf == 0) {
          this.obj.ea = temp.t;
        }
      });

      this.obj.u = 0;
      this.obj.u = this.obj.u.toFixed(2);
    } else {
      if (this.obj.ea != null) {
        this.obj.ea = this.addNewPc.ea != null ? this.addNewPc.ea.toFixed(2) : null;
      }
      if (this.obj.ya != null) {
        this.obj.ya = this.addNewPc.ya != null ? this.addNewPc.ya.toFixed(2) : null;
      }
    }

    if (this.addNewPc.iu == 0 || this.addNewPc.iu == 1 || this.addNewPc.iu == 2) {
      if (this.addNewPc.paf == 1) {
        if (this.obj.ea || this.obj.ea == 0) {
          this.obj.ea = this.addNewPc.ea.toFixed(this.sysPref.g);
        }
        if (this.obj.ya || this.obj.ya == 0) {
          this.obj.ya = Number(this.addNewPc.ya).toFixed(this.sysPref.g);
        }
      }
    }
    if (this.addNewPc.paf == 1) {
      this.obj.ea = this.addNewPc.ea;
    }
    if (this.addNewPc.yaf == 1) {
      this.obj.ya = this.addNewPc.ya;
    }
    this.addNewPc.pbeyr == 1 ? this.addNewPc.emin = this.addNewPc.eminyr : this.addNewPc.emin = this.addNewPc.emin;
  }

  onPcEdit(dc: number) {
    var temp: IPayrollcategoryEmpWiseList = {
      dc: null,
      ei: null,
      bp: null,
      ea: null,
      ya: null,
      u: null,
      pf: null,
      pt: null,
      ie: null,
      dn: null,
      uc: null,
      iu: null,
      a: null,
      e: null,
      tc: null,
      t: null,
      k: null,
      idf: null,
      paf: null,
      yaf: null,
      pbeyr: null,
      fc: null,
      oa: null,
      ou: null,
      emax: null,
      minw: null,
      minf: null,
      emin: null,
      maxw: null,
      maxm: null,
      maxf: null
    };
    temp = this.payrollCatList.find(x => x.dc == dc);

    if (temp.iu == 1) {
      if (temp.ea || temp.ea == 0) {
        temp.ea = parseFloat(temp.ea).toFixed(this.sysPref.h);
      }
      if (temp.ya || temp.ya == 0) {
        temp.ya = parseFloat(temp.ya).toFixed(this.sysPref.h);
      }
      if (temp.u || temp.u == 0) {
        temp.u = parseFloat(temp.u).toFixed(this.sysPref.h);
      }
      if (temp.ou || temp.ou == 0) {
        temp.ou = parseFloat(temp.ou).toFixed(this.sysPref.h);
      }
    } else if (temp.iu == 2) {
      if (temp.ea || temp.ea == 0) {
        temp.ea = parseFloat(temp.ea).toFixed(this.sysPref.g);
      }
      if (temp.ya || temp.ya == 0) {
        temp.ya = parseFloat(temp.ya).toFixed(this.sysPref.g);
      }
    } else {
      if (temp.ea || temp.ea == 0) {
        temp.ea = parseFloat(temp.ea).toFixed(2);
      }
      if (temp.ya || temp.ya == 0) {
        temp.ya = parseFloat(temp.ya).toFixed(2);
      }
      if (temp.oa || temp.oa == 0) {
        temp.oa = parseFloat(temp.oa).toFixed(2);
      }
    }
    if (temp != undefined && temp != null) {
      this.pcSelected = temp;
    }
    this.pcSelectedInitWithVal(temp.dc, temp.ei, temp.bp, temp.ea, temp.ya, temp.u, temp.pf, temp.pt, temp.ie, temp.dn, temp.uc, temp.iu, temp.a, temp.e, temp.tc, temp.t, temp.k, temp.idf, temp.paf, temp.yaf, temp.pbeyr, temp.fc, temp.oa, temp.ou, temp.emax, temp.emin, temp.minf, temp.maxf, temp.minw, temp.maxw, temp.maxm);
    this.dateRangeEdit = this.dateFormat(this.pcSelected.pf, this.pcSelected.pt);
    if (this.checkedSchedules && this.checkedSchedules.length > 0) {
      this.checkedSchedules = [];
    }
  }

  setEmpStatus(et: any, dc: number) {
    if (!et.target.checked) {
      //Uncheck
      this._entitlementService.setEntitledEmpStatus(this.empSelected.ie, dc, 0).subscribe(
        suc => {
          var title = this.transloco.translate('success');
          var msg = this.transloco.translate('alerts.theEmployeeIsDisabled', {}, 'payrollCategoryEntitlmentEmployeeWise');
          showMsg({
            'type': 'nonsticky',
            'status': 'success',
            'title': title,
            'buttons': {},
            'message': msg
          });
        }
      );

    } else if (et.target.checked) {
      //Check
      this._entitlementService.setEntitledEmpStatus(this.empSelected.ie, dc, 1).subscribe(
        suc => {
          var title = this.transloco.translate('success');
          var msg = this.transloco.translate('alerts.theEmployeeIsEnabled', {}, 'payrollCategoryEntitlmentEmployeeWise');
          showMsg({
            'type': 'nonsticky',
            'status': 'success',
            'title': title,
            'buttons': {},
            'message': msg
          });
        }
      );
    }
  }

  deleteImp(dc: number, ei: number) {
    this._entitlementService.checkEmployeePayrollCategorylockedaycard(dc, ei).subscribe(
      data => {
        if (data == 0) {
          this._empService.CheckEmployeeUnlockedPaycardsForAss(this.empSelected.ie).subscribe(d => {
            if (d > 0) {
              var title = this.transloco.translate('warning');
              var msg = this.transloco.translate('alerts.doYouWantToRollbackPaycardAndSaveChanges', {}, 'payrollCategoryEntitlmentEmployeeWise');
              var btn1 = this.transloco.translate('yes');
              var btn2 = this.transloco.translate('no');
              var that = this;
              showMsg({
                'type': 'sticky',
                'status': 'warning',
                'title': title,
                'buttons': {
                  [btn1]: {
                    action: function() {
                      that._payrollGroupService.DeleteUnlockedPaycardByEmpId(that.empSelected.ie).subscribe(x => {
                          that.deleteSubscription = that._entitlementService.deleteEntitledEmployeeEmpWise(dc, ei).subscribe(
                            suc => {
                              if (suc == 0) {
                                var title = that.transloco.translate('success');
                                var msg = that.transloco.translate('alerts.theEmployeeIsDeletedFromCurrentPayrollCategory', {}, 'payrollCategoryEntitlmentEmployeeWise');
                                showMsg({
                                  'type': 'nonsticky',
                                  'status': 'success',
                                  'title': title,
                                  'buttons': {},
                                  'message': msg
                                });

                                that.payrollCatList = [];
                                that._payrollGroupService.getPayrollCategoryEmpWise(that.externalp).subscribe(data => {
                                  that.tempPcList = data;
                                  that.payrollCatList = that.tempPcList.filter(x => x.ei == ei).sort((a, b) => a.uc > b.uc ? 1 : -1);
                                  that._payrollGroupService.getPayrollCategoryListEmpWise().subscribe(data => {
                                    that.tempFilteredPcList = data;
                                    that.filteredPcList = [].concat(that.tempFilteredPcList);
                                    that.filteredPcList = that.filteredPcList.filter(x => x.q == 1);
                                    that.is_unit_enabled = true;
                                    for (let i = 0; i < that.payrollCatList.length; i++) {
                                      if (that.payrollCatList[i].e == 4 && that.payrollCatList[i].iu == 1) {
                                        that.is_unit_enabled = false;
                                      }
                                      that.filteredPcList = that.filteredPcList.filter(x => x.o != that.payrollCatList[i].dc);
                                      that.filteredPcListSrc = [].concat(that.filteredPcList);
                                    }
                                    that.filteredPcList = that.filteredPcList.sort((a, b) => a.l > b.l ? 1 : -1);
                                  });
                                });
                              } else {
                                var title = that.transloco.translate('error');
                                var msg = that.transloco.translate('alerts.theEmployeeCanNotBeDeletedFromCurrentPayrollCategory', {}, 'payrollCategoryEntitlmentEmployeeWise');
                                showMsg({
                                  'type': 'nonsticky',
                                  'status': 'error',
                                  'title': title,
                                  'buttons': {},
                                  'message': msg
                                });
                              }
                            }
                          );
                        },
                        err => {
                          var title = that.transloco.translate('error');
                          var msg = that.transloco.translate('alerts.errorOccurredWhenRollbackUnpostedPaycards', {}, 'payrollCategoryEntitlmentEmployeeWise');
                          var btn = that.transloco.translate('ok');
                          showMsg({
                            'type': 'sticky',
                            'status': 'error',
                            'title': title,
                            'buttons': {
                              [btn]: {
                                action: function() {
                                }
                              }
                            },
                            'message': msg
                          });
                        });
                    }
                  },
                  [btn2]: {
                    action: function() {
                    }
                  }
                },
                'message': msg
              });
            } else {
              this.deleteSubscription = this._entitlementService.deleteEntitledEmployeeEmpWise(dc, ei).subscribe(
                suc => {
                  if (suc == 0) {
                    var title = this.transloco.translate('success');
                    var msg = this.transloco.translate('alerts.theEmployeeIsDeletedFromCurrentPayrollCategory', {}, 'payrollCategoryEntitlmentEmployeeWise');
                    showMsg({
                      'type': 'nonsticky',
                      'status': 'success',
                      'title': title,
                      'buttons': {},
                      'message': msg
                    });

                    this.payrollCatList = [];
                    this._payrollGroupService.getPayrollCategoryEmpWise(this.externalp).subscribe(data => {
                      this.tempPcList = data;
                      this.payrollCatList = this.tempPcList.filter(x => x.ei == ei).sort((a, b) => a.uc > b.uc ? 1 : -1);
                      this._payrollGroupService.getPayrollCategoryListEmpWise().subscribe(data => {
                        this.tempFilteredPcList = data;
                        this.filteredPcList = [].concat(this.tempFilteredPcList);
                        this.filteredPcList = this.filteredPcList.filter(x => x.q == 1);
                        this.is_unit_enabled = true;
                        for (let i = 0; i < this.payrollCatList.length; i++) {
                          if (this.payrollCatList[i].e == 4 && this.payrollCatList[i].iu == 1) {
                            this.is_unit_enabled = false;
                          }
                          this.filteredPcList = this.filteredPcList.filter(x => x.o != this.payrollCatList[i].dc);
                          this.filteredPcListSrc = [].concat(this.filteredPcList);
                        }
                        this.filteredPcList = this.filteredPcList.sort((a, b) => a.l > b.l ? 1 : -1);
                     });
                    });
                  } else {
                    var title = this.transloco.translate('error');
                    var msg = this.transloco.translate('alerts.theEmployeeCanNotBeDeletedFromCurrentPayrollCategory', {}, 'payrollCategoryEntitlmentEmployeeWise');
                    showMsg({
                      'type': 'nonsticky',
                      'status': 'error',
                      'title': title,
                      'buttons': {},
                      'message': msg
                    });
                  }
                }
              );
            }
          });
        } else {
          var title = this.transloco.translate('error');
          var msg = this.transloco.translate('alerts.thisItemCanNotDeletedSinceItHasYTDValue', {}, 'payrollCategoryEntitlmentEmployeeWise');
          var btn = this.transloco.translate('ok');
          showMsg({
            'type': 'sticky',
            'status': 'error',
            'title': title,
            'buttons': {
              [btn]: {
                action: function() {
                  return;
                }
              }
            },
            'message': msg
          });
        }
      }
    );
  }

  delete(dc: number, ei: number) {
    var msg = this.transloco.translate('alerts.areYouSureYouWantToDeleteThisPayrollCategory', {}, 'payrollCategoryEntitlmentEmployeeWise');
    this._deleteConfirmation.deleteConfirmation(this.deleteImp.bind(this, dc, ei), msg);
  }

  onLrflgClick(flg: number) {
    for (let i = 0; i < this.payScheduleForDftEmps.length && flg == 1; i++) {
      if (this.payScheduleForDftEmps[i].ie == this.empSelected.ie && this.checkPayScheduleRange(this.payScheduleForDftEmps[i])) {
        this.payScheduleForDftEmps[i]['isChecked'] = 1;
        this.checkedSchedules.push(this.payScheduleForDftEmps[i]);
      }
    }
    let temp1 = this.checkedSchedules.length;
    let temp2 = this.payScheduleForDftEmps.filter(s => s.ie == this.empSelected.ie).length;

    this.allChecked = this.checkedSchedules.length == this.payScheduleForDftEmps.filter(s => s.ie == this.empSelected.ie).length;

    this.isOnDateSlt = true;
    this.lrflg = flg;
    this.customModalService.open('dateEditModalLeft');
    this.modalFlg = flg;
  }

  cancelAdd() {
    this.objInit();
  }

  cancelEdit() {
    this.pcSelectedInit();
  }

  isPercentageValid(ea: number, ya: number): any {
    var sum = ea + ya;
    if (sum > 100) {
      return false;
    } else if (sum <= 100 && sum >= 0) {
      return true;
    }
  }

  findCurSelectedDateBySdlId(freId: number, sdlId: number): any {
    var temp = this.payScheduleForDftEmps.filter(obj => {
      return obj.ie == this.empSelected.ie && obj.f == freId;
    });

    var curObj = temp.find(obj => {
      return obj.i == sdlId;
    });
    return curObj;
  }

  checkOnClick(et: any, freId: number, sdlId: number) {

    var s = this.findCurSelectedDateBySdlId(freId, sdlId);
    s['isChecked'] = et.target.checked;
    if (!et.target.checked) {
      //Uncheck
      if (this.checkedSchedules.length == 1) {
        this.checkedSchedules.pop();
      }
      for (let i = 0; i < this.checkedSchedules.length; i++) {
        if (this.checkedSchedules[i].s == s.s) {
          this.checkedSchedules.splice(i, 1);
        }
      }
    } else if (et.target.checked) {
      //Check
      this.checkedSchedules.push(s);
    }
  }

  dateToggleAll(evt: any) {
    if (evt.target.checked) {
      this.checkedSchedules = this.payScheduleForDftEmps.filter(x => x.ie == this.empSelected.ie);
      this.checkedSchedules.forEach(x => x['isChecked'] = 1);
    } else {
      this.checkedSchedules = [];
      this.payScheduleForDftEmps.filter(x => x.ie == this.empSelected.ie).forEach(x => x['isChecked'] = null);
    }
  }

  processDateSelection() {
    if (this.checkedSchedules.length > 0) {
      //if user checked some dates
      this.earliestDate = this.checkedSchedules.reduce((prev, current) => {
        return (prev.s < current.s) ? prev : current;
      });

      this.latestDate = this.checkedSchedules.reduce((prev, current) => {
        return (prev.s > current.s) ? prev : current;
      });
    } else {
      //if no date checked
      let s = new Date(this.empSelected.c.split(' - ')[0].split(' ')[0]);
      let e = new Date(this.empSelected.c.split(' - ')[1].split(' ')[0]);
      this.earliestDate = {
        ie: null,
        ap: null,
        us: null,
        f: null,
        y: null,
        c: null,
        s: s,//this.empSelected.s,
        e: null,
        i: null
      };
      this.latestDate = {
        ie: null,
        ap: null,
        us: null,
        f: null,
        y: null,
        c: null,
        s: e,//this.empSelected.e,
        e: null,
        i: null
      };

      this.earliestDate.s = s;//this.empSelected.s;
      this.latestDate.e = e;//this.empSelected.e;
      this.filterPcListByDate(s, e, 3);//(this.empSelected.s, this.empSelected.e, 3);
    }
    if (this.lrflg == 0) {
      //if is add new
      this.dateRange = this.dateFormat(this.earliestDate.s, this.latestDate.e);

      this.obj.pf = this.earliestDate.s;
      this.obj.pt = this.latestDate.e;
      //Calculate bp
      this.obj.bp = this.checkedSchedules.length;
    } else if (this.lrflg == 1) {
      //if is edit
      this.dateRangeEdit = this.dateFormat(this.earliestDate.s, this.latestDate.e);
      this.pcSelected.pf = this.earliestDate.s;
      this.pcSelected.pt = this.latestDate.e;
      this.pcSelected.bp = this.checkedSchedules.length;
    } else if (this.lrflg == 2 && this.checkedSchedules.length > 0) {
      //if is date filter
      this.empDateRange = this.dateFormat(this.earliestDate.s, this.latestDate.e);
      this.filterPcListByDate(this.earliestDate.s, this.latestDate.e, 1);
    }

    this.checkedSchedules = [];
    this.payScheduleForDftEmps.filter(x => x.ie == this.empSelected.ie).forEach(x => x['isChecked'] = null);
    this.isOnDateSlt = false;
    //this.isOnDateEdit = false;
    if (this.modalFlg == 0) {
      this.customModalService.open('addcat');
    }
    if (this.modalFlg == 1) {
      this.customModalService.open('editcat');
    }
  }

  cancelDateSelection() {
    this.checkedSchedules = [];
    this.payScheduleForDftEmps.filter(x => x.ie == this.empSelected.ie).forEach(x => x['isChecked'] = null);
    this.isOnDateSlt = false;
    if (this.modalFlg == 0) {
      this.customModalService.open('addcat');
    }
    if (this.modalFlg == 1) {
      this.customModalService.open('editcat');
    }
    //this.isOnDateEdit = false;
  }


  filterPcListByDate(f: Date, t: Date, flg: number) {
    //if (flg != 3 && flg != 0) {
    //  this.payrollCatList = this.payrollCatList.filter(x => (x.pf >= f && x.pt <= t) || (x.pf >= f && x.pf <= t && x.pt >= t) || (x.pt >= f && x.pt <= t && x.pf <= f) || (x.pf == null && x.pt == null));
    //}
    if (flg != 3) {
      this.empDateRange = this.dateFormat(f, t);
      this.payrollCatList = [];
      this.payrollCatList = this.tempPcList.filter(x => x.ei == this.empSelected.ie);
      this.payrollCatList = this.payrollCatList.filter(x => (x.pf >= f && x.pt <= t) || (x.pf <= f && x.pt >= t) || (x.pf >= f && x.pf <= t && x.pt >= t) || (x.pt >= f && x.pt <= t && x.pf <= f) || (x.pf == null && x.pt == null)).sort((a, b) => a.uc > b.uc ? 1 : -1);
    }
    if (flg == 3) {
      this.empDateRange = (this.sysPref.df ? this.sysPref.df : 'yyyy-mm-dd') + ' - ' + (this.sysPref.df ? this.sysPref.df : 'yyyy-mm-dd');
      this.payrollCatList = [];
      this.payrollCatList = this.tempPcList.filter(x => x.ei == this.empSelected.ie).sort((a, b) => a.uc > b.uc ? 1 : -1);
    }
  }

  dateFormat(startDate: Date, endDate: Date): string {


    if (startDate != null && endDate != null) {
      var ts = new Date(startDate);
      var te = new Date(endDate);
      var s;
      var e;
      s = ts.getFullYear() + '-' + (ts.getMonth() + 1) + '-' + ts.getDate();
      e = te.getFullYear() + '-' + (te.getMonth() + 1) + '-' + te.getDate();
      let sdate = this._datePipe.transform(startDate, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd');
      let edate = this._datePipe.transform(endDate, this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd');
      return sdate + ' - ' + edate;
    }
    return 'yyyy-mm-dd - yyyy-mm-dd';
  }

  objInit() {
    this.obj = {
      dc: null,
      ei: null,
      bp: null,
      ea: null,
      ya: null,
      u: null,
      pf: null,
      pt: null,
      ie: null,
      dn: null,
      uc: null,
      iu: null,
      a: null,
      e: null,
      tc: null,
      t: null,
      k: null,
      idf: null,
      paf: null,
      yaf: null,
      pbeyr: null,
      fc: null,
      oa: null,
      ou: null,
      emax: null,
      emin: null,
      minf: null,
      maxf: null,
      minw: null,
      maxw: null,
      maxm: null
    };
  }

  pcSelectedInit() {
    this.pcSelected = {
      dc: null,
      ei: null,
      bp: null,
      ea: null,
      ya: null,
      u: null,
      pf: null,
      pt: null,
      ie: null,
      dn: null,
      uc: null,
      iu: null,
      a: null,
      e: null,
      tc: null,
      t: null,
      k: null,
      idf: null,
      paf: null,
      yaf: null,
      pbeyr: null,
      fc: null,
      oa: null,
      ou: null,
      emax: null,
      emin: null,
      minf: null,
      maxf: null,
      minw: null,
      maxw: null,
      maxm: null
    };
  }

  pcSelectedInitWithVal(dc: number, ei: number, bp: number, ea: number, ya: number, u: number, pf: Date, pt: Date, ie: number, dn: string, uc: string, iu: number, a: string, e: number, tc: number, t: number, k: string, idf: number, paf: number, yaf: number, pbeyr: number, fc: number, oa: number, ou: number, emax: number, emin: number, minf: number, maxf: number, minw: number, maxw: number, maxm: number) {
    this.pcSelected = {
      dc: dc,
      ei: ei,
      bp: bp,
      ea: ea,
      ya: ya,
      u: u,
      pf: pf,
      pt: pt,
      ie: ie,
      dn: dn,
      uc: uc,
      iu: iu,
      a: a,
      e: e,
      tc: tc,
      t: t,
      k: k,
      idf: idf,
      paf: paf,
      yaf: yaf,
      pbeyr: pbeyr,
      fc: fc,
      oa: oa,
      ou: ou,
      emax: emax,
      emin: emin,
      minf: minf,
      maxf: maxf,
      minw: minw,
      maxw: maxw,
      maxm: maxm
    };
  }

  setObjDftVal() {
    this.obj.ea = this.addNewPc.ea;
    this.obj.ya = this.addNewPc.ya;
  }

  handleFilterEmp(value) {
    this.empList = this.empSource.filter((s) => s.a.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  handleFilterCat(value) {
    this.filteredPcList = this.filteredPcListSrc.filter((s) => (s.a.toLowerCase().indexOf(value.toLowerCase()) !== -1)).sort((a, b) => a.l > b.l ? 1 : -1);
  }

  onSelectList(item: string): void {
    this.payrollCatList = [];
    this.payrollCatList = this.tempPcList.filter(x => x.ei == this.empSelected.ie);
    this.payrollCatList = this.payrollCatList.filter((x) => (x.k.toLocaleLowerCase().indexOf(item.toLocaleLowerCase()) !== -1)).sort((a, b) => a.uc > b.uc ? 1 : -1);
    this.isDropDownvisible = false;
  }

  getDropdownListValues(data: IPayrollcategoryEmpWiseList[]): any[] {
    this.dropdownListValues.length = 0;
    let tempArr: any[];
    if (this.tempFilteredPcList != null) {
      tempArr = Array.from(new Set(this.tempFilteredPcList.map(s => s.k))).map(k => {
        return {name: k, code: this.tempFilteredPcList.find(s => s.k === k).p};
      });
    }
    for (let i of tempArr) {
      if (i.code === 1) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgFEIcon2 pull-right'});
      } else if (i.code === 2) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgFDIcon pull-right'});
      } else if (i.code === 3) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgFCIcon pull-right'});
      } else if (i.code === 4) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgBSIcon pull-right'});
      } else if (i.code === 5) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgFExIcon pull-right'});
      } else if (i.code === 6) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgFLIcon pull-right'});
      } else if (i.code === 7) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgAIcon pull-right'});
      } else if (i.code === 8) {
        this.dropdownListValues.push({name: i.name, cssClass: 'cImgFDIcon pull-right'});
      }
    }

    return this.dropdownListValues;
  }

  onHoursSave(): void {
    this.update_hrs_ob = {emp_id: this.empSelected.ie, daily_hrs: this.daily_hrs, hrs_per_period: this.working_hrs};
    this._payrollGroupService.updateEmployeeHours(this.update_hrs_ob).subscribe(
      res => {
        var title = this.transloco.translate('success');
        var msg = this.transloco.translate('alerts.hoursSuccessfullyUpdated', {}, 'payrollCategoryEntitlmentEmployeeWise');
        showMsg({
          'type': 'nonsticky',
          'status': 'success',
          'title': title,
          'buttons': {},
          'message': msg
        });
        this.initialEmployeeLoad();
        this.hours_edited = false;
      },
      err => {
        var title = this.transloco.translate('error');
        var msg = this.transloco.translate('errorOccurred');
        showMsg({
          'type': 'nonsticky',
          'status': 'error',
          'title': title,
          'buttons': {},
          'message': msg
        });
      }
    );
  }

  hoursChange() {
    this.hours_edited = true;
  }

  onInputChange(event: any): void {
    let regex: RegExp = new RegExp('^\\d*\.?\\d{0,' + this.sysPref.g + '}$', 'g');
    if (this.obj.ea && !String(this.obj.ea).match(regex)) {
      event.preventDefault();
    }
  }
}
