import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  IChequePaystubParams,
  IEmailPayStub,
  IEmployeeChequePrinting,
  IGetPdf,
  IObjList,
  IReturnEmpList,
  ISendPdf,
  IUpdatePayrollGroupPaymentDate
} from 'src/app/shared/models/icheque-printing';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class ChequePrintingService {

  constructor(private http: HttpClient) { }

  getEmployeeList(): Observable<IEmployeeChequePrinting[]> {

    return this.http.get<IEmployeeChequePrinting[]>(API_END_POINT.payroll.chequePrinting + "GetEmployeeChequePrinting");
  }

  getChequePaystubParamsByUserID(): Observable<IChequePaystubParams> {
    return this.http.get<IChequePaystubParams>(API_END_POINT.payroll.chequePrinting + "GetChequePaystubParamsByUserID");
  }

  updateChequePaystubParamsByUserID(chqParams: IChequePaystubParams): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.chequePrinting + "UpdateChequePaystubParamsByUserID", chqParams);
  }

  updatePrintQueueTable(empList: IEmployeeChequePrinting[], sortOrder: number, startingChequeNumber: number): Observable<string> {
    var o = { 'empList': empList, 'sortOrder': sortOrder, 'startingChequeNumber': startingChequeNumber };
    return this.http.post<string>(API_END_POINT.payroll.chequePrinting + "UpdatePrintQueueTable", o);
  }
  updatePrintQueueTableForEmail(empList: IEmployeeChequePrinting[], sortOrder: number, startingChequeNumber: number): Observable<IEmailPayStub[]> {
    var o = { 'empList': empList, 'sortOrder': sortOrder, 'startingChequeNumber': startingChequeNumber };
    return this.http.post<IEmailPayStub[]>(API_END_POINT.payroll.chequePrinting + "UpdatePrintQueueTableForEmail", o);
  }
  UpdatePayrollGroupPaymentDate(list: IUpdatePayrollGroupPaymentDate[]): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.chequePrinting + "UpdatePayrollGroupPaymentDate", list);
  }
  sendPaystubToEmps(obj: IObjList): Observable<IReturnEmpList> {
    //let ob = { sess_list: obj.sess_list, emp_email_list: obj.emp_email_list, subjct: obj.subjct, body: obj.body, left_marg: obj.left_marg, top_marg: obj.top_marg };
    return this.http.post<IReturnEmpList>(API_END_POINT.payroll.chequePrinting + "SendPaystubToEmps", obj);
  }
  checkEmailSettingSet(): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.chequePrinting + "checkEmailSettingSet");
  }

  GetFromEmail(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.chequePrinting + "GetFromEmail");
  }
  GetPdf(list: IGetPdf[]): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.chequePrinting + "GetPdf", list);
  }
  SendPdf(emp_list: ISendPdf[]): Observable<number> {
    return this.http.post<any>(API_END_POINT.payroll.chequePrinting + "SendPdf", emp_list);
  }
}
