import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, NgForm, Validators} from '@angular/forms';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {LaborService} from '@harmony-modules/payroll/services/payroll-setup/labor.service';
import {PayrollCategoryService} from '@harmony-modules/payroll/services/payroll-setup/payroll-category.service';
import {ReportServiceService} from '@harmony-modules/payroll/services/report/report-service.service';
import {IDimList, ILaborSetup} from '@shared/models/ilabor';
import {IPayrollCategory, IPayrollCatgryDetail, IProvinceList} from '@shared/models/ipayroll-category';
import {
  Departments,
  EIGroup,
  Employees,
  IEmployeeItem,
  IPayrollCat,
  IProvinceAndRule,
  IReport,
  IReportDropdown,
  IReportFilter,
  IReportLastVal,
  IUpdateReportLastVal,
  IWorkingWeek,
  Locations,
  Payrollcategory,
  PayrollGroup,
  ReportTableParameters
} from '@shared/models/ireport';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';
import { AuthenticationService } from '@services/authentication.service';
import { GlobalService } from '@services/global.service';
import { scopeLoader } from '@shared/i18n/transloco-scoped-loader.helper.';
import { EnvService } from '@shared/services/env.service';
import { CustomValidators } from '@narik/custom-validators';
import { Subscription } from 'rxjs';
import { CoreEmployeeService } from '@harmony-modules/configuration/services/employee/core-employee.service';
import { IEmptypes } from '../../../../../../../shared/models/iemployee';

declare var showMsg: any;
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-report-child',
  templateUrl: './report-child.component.html',
  styleUrls: ['./report-child.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: { scope: 'reportChild', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`)) }
  }],
  encapsulation: ViewEncapsulation.None
})
export class ReportChildComponent implements OnInit {


  private report_para_detail: ReportTableParameters = {
    departmentslist: [{
      ID: null,
      ParentID: null,
      Name: null,
      Description: null
    }],
    employeeslist: [{
      ID: null,
      FirstName: null,
      LastName: null,
      IsManager: null,
      Name: null
    }],
    locationslist: [{
      ID: null,
      ParentID: null,
      Name: null,
      Description: null
    }],
    payrollGroupList: [{
      PRLG_CODE: null,
      PRLG_USER_CODE: null
    }],
    payrollcategoryList: [{
      CAT_TYP_CODE: null,
      CAT_TYP_NAME: null
    }]
  };

  EIgrouplist: EIGroup[] = [{
    UDF_COLL_TEXT: null,
    UDF_COLL_INDEX: null,
    UDF_COLL_VALUE: null
  }];

  public report_sent: IReport = {
    REPORT_ID: null,
    FILE_NAME: null,
    TYPE: null,
    IS_ACTIVE_FLG: null,
    PAR_PAY_DATE: null,
    PAR_TO_DATE: null,
    PAR_LOCATION_ID: null,
    PAR_DEPARTMENT_ID: null,
    PAR_EMPLOYEE_ID: null,
    DB_PAY_DATE: null,
    DB_TO_DATE: null,
    DB_LOCATION_ID: null,
    DB_DEPARTMENT_ID: null,
    DB_EMPLOYEE_ID: null,
    PAR_DIVISION: null,
    DB_DIVISION: null,
    PAR_PAYROLL_GROUP: null,
    DB_PAYROLL_GROUP: null,
    PAR_EI_GROUP: null,
    DB_EI_GROUP: null,
    PAR_PAYROLL_RUNS: null,
    DB_PAYROLL_RUNS: null,
    PAR_CALCULATION_METHOD: null,
    DB_CALCULATION_METHOD: null,
    PAR_PENSION_GROUP: null,
    DB_PENSION_GROUP: null,
    PAR_PAYROLL_TYPE: null,
    DB_PAYROLL_TYPE: null,
    PAR_PAYROLL_CLASS_TYPE: null,
    DB_PAYROLL_CLASS_TYPE: null,
    PAR_PAYMENT_METHOD: null,
    DB_PAYMENT_METHOD: null,
    PAR_TAXATION_YEAR: null,
    DB_TAXATION_YEAR: null,
    PAR_RCT_NUMBER: null,
    DB_RCT_NUMBER: null,
    PAR_BUSINESS_NUMBER: null,
    DB_BUSINESS_NUMBER: null,
    PAR_PAYROLL_CATEGORY: null,
    DB_PAYROLL_CATEGORY: null,
    PAR_PAYROLL_KIND: null,
    DB_PAYROLL_KIND: null,
    REPT_NAME: null,
    REPT_DESC: null,
    REP_CAT_ID: null,
    CATEGORY_NAME: null,
    ID_USER: null,
    fav_flag: false,
    REP_FV_PARA: null,
    PAR_HOLIDAY_DATE: null,
    DB_HOLIDAY_DATE: null,
    PAR_WAGES: null,
    DB_WAGES: null,
    PAR_WORKING_WEEK_START: null,
    DB_WORKING_WEEK_START: null,
    PAR_PROVINCE_RULE: null,
    DB_PROVINCE_RULR: null,
    PAR_INCLUDE_ALL_EMPS: null,
    DB_INCLUDE_ALL_EMPS: null,
    DB_PROVINCE: null,
    PAR_EMPLOYEE_STATUS: null,
    DB_EMPLOYEE_STATUS: null,
    PAR_ORDER_BY: null,
    DB_ORDER_BY_EMP_NUM: null,
    DB_ORDER_BY_EMP_NAME: null,
    DB_ORDER_BY_DEPARTMENT: null,
    COUNTRY: null,
    token: null
  };
  filterByDates: string[] = ['payDate', 'periodEndDate'];
  listDepartments: Departments[] = [];
  listEmployees: Employees[] = [];
  listLocations: Locations[] = [];
  listpayrollgroup: PayrollGroup[] = [];
  listpayrollCategories: Payrollcategory[] = [];
  listEIgroup: EIGroup[] = [];
  paycatlist: IPayrollCat[] = [];
  paycatlistFiltered: IPayrollCat[] = [];
  paycatlistFilteredForStatutory: IPayrollCat[] = [];
  paycatlistFilteredForWorkerCompensation: IPayrollCat[] = [];
  cur_pg: PayrollGroup = { PRLG_CODE: null, PRLG_USER_CODE: null };
  cur_pg_code: number;
  wizard_mode: boolean;
  listSupervisor: Employees[] = [];
  listAltManager: Employees[] = [];
  listStates: IProvinceList[] = [];
  localTaxList: IPayrollCatgryDetail[] = [];
  wcbCategoryList: IPayrollCatgryDetail[] = [];
  dimUnionList: IDimList[] = [];
  unionAgrList: IDimList[] = [];
  agreementDate: Date;
  sessionId: string;
  dimList33: ILaborSetup[] = [];
  dimList34: ILaborSetup[] = [];
  dimList37: ILaborSetup[] = [];
  //allDimList: ILaborSetup[] = [];
  multiEmployees: any = [];
  reportFilters: IReportFilter[] = [];
  companyCode: string;
  userId: string;
  months: string;

  province_rule_list: IProvinceAndRule[] = [
    {
      id: 1,
      province_id: 0,
      province_name: 'ab',
      rule: 'ONE_20TH_OF_4_WEEKS',
      rule_desc: 'one20thOf4Weeks'
    },
    {
      id: 2,
      province_id: 0,
      province_name: 'ab',
      rule: 'FOUR_POINT_2_PERCENT_OF_4_WEEKS',
      rule_desc: 'fourPoint2PercentOf4Weeks'
    },
    {
      id: 3,
      province_id: 1,
      province_name: 'bc',
      rule: 'AVERAGE_BASED_ON_30',
      rule_desc: 'averageBasedOn30'
    },
    { id: 4, province_id: 2, province_name: 'mb', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    { id: 5, province_id: 3, province_name: 'nb', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    { id: 6, province_id: 4, province_name: 'nl', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    { id: 7, province_id: 6, province_name: 'ns', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    {
      id: 8,
      province_id: 6,
      province_name: 'ns',
      rule: 'AVERAGE_BASED_ON_30',
      rule_desc: 'averageBasedOn30'
    },
    { id: 9, province_id: 5, province_name: 'nt', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    {
      id: 10,
      province_id: 8,
      province_name: 'on',
      rule: 'ONE_20TH_OF_4_WEEKS',
      rule_desc: 'one20thOf4Weeks'
    },
    { id: 11, province_id: 9, province_name: 'pe', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    {
      id: 12,
      province_id: 9,
      province_name: 'pe',
      rule: 'AVERAGE_BASED_ON_30',
      rule_desc: 'averageBasedOn30'
    },
    {
      id: 13,
      province_id: 10,
      province_name: 'qc',
      rule: 'ONE_20TH_OF_4_WEEKS',
      rule_desc: 'one20thOf4Weeks'
    },
    {
      id: 20,
      province_id: 10,
      province_name: 'qc',
      rule: 'ONE_60TH_OF_12_WEEKS',
      rule_desc: 'one60thOf12Weeks'
    },
    {
      id: 14,
      province_id: 11,
      province_name: 'sk',
      rule: 'ONE_20TH_OF_4_WEEKS',
      rule_desc: 'one20thOf4Weeks'
    },
    { id: 15, province_id: 12, province_name: 'yt', rule: 'ONE_DAY_REGULAR_PAY', rule_desc: 'oneDayRegularPay' },
    { id: 16, province_id: 12, province_name: 'yt', rule: 'DAILY_AVERAGE', rule_desc: 'dailyAverage' },
    {
      id: 17,
      province_id: 12,
      province_name: 'yt',
      rule: 'TEN_PERCENT_OF_2_WEEKS',
      rule_desc: 'tenPercentOf2Weeks'
    },
    {
      id: 18,
      province_id: 14,
      province_name: 'fa',
      rule: 'ONE_20TH_OF_4_WEEKS',
      rule_desc: 'one20thOf4Weeks'
    },
    {
      id: 19,
      province_id: 14,
      province_name: 'fa',
      rule: 'ONE_60TH_OF_12_WEEKS',
      rule_desc: 'one60thOf12Weeks'
    }
  ];

  working_week: IWorkingWeek[] = [
    { id: 1, value: 'monday' },
    { id: 2, value: 'tuesday' },
    { id: 3, value: 'wednesday' },
    { id: 4, value: 'thursday' },
    { id: 5, value: 'friday' },
    { id: 6, value: 'saturday' },
    { id: 7, value: 'sunday' }
  ];

  empItemList: IEmployeeItem[] = [
    { id: 1, name: 'employeeNumber', pmName: 'E.Number', colName: 'Employee #', isDate: false },
    { id: 2, name: 'firstName', pmName: 'E.FirstName', colName: 'First name', isDate: false },
    { id: 3, name: 'lastName', pmName: 'E.LastName', colName: 'Last name', isDate: false },
    { id: 4, name: 'jobTitle', pmName: 'E.JobTitle', colName: 'Job title', isDate: false },
    { id: 5, name: 'department', pmName: 'D.Name', colName: 'Depa.', isDate: false },
    { id: 6, name: 'supervisor', pmName: 'S.FirstName+\' \'+S.LastName', colName: 'Supervisor', isDate: false },
    { id: 7, name: 'cellphone', pmName: 'E.Cellphone', colName: 'Cell', isDate: false },
    { id: 8, name: 'startDate', pmName: 'E.StartDate', colName: 'Start date', isDate: true },
    { id: 9, name: 'workEmail', pmName: 'E.CompanyEmail', colName: 'Work email', isDate: false },
    { id: 10, name: 'middleName', pmName: 'E.MiddleName', colName: 'Middel name', isDate: false },
    { id: 11, name: 'sin', pmName: 'E.SocialNumber', colName: 'SIN', isDate: false },
    { id: 12, name: 'gender', pmName: 'G.Name', colName: 'Gender', isDate: false },
    { id: 13, name: 'dateOfBirth', pmName: 'E.DateOfBirth', colName: 'Date of birth', isDate: true },
    { id: 14, name: 'maritalStatus', pmName: 'M.Name', colName: 'Marital status', isDate: false },
    { id: 15, name: 'homePhone', pmName: 'E.HomePhone', colName: 'Home phone', isDate: false },
    { id: 16, name: 'personEmail', pmName: 'E.PersonalEmail', colName: 'Person email', isDate: false },
    { id: 17, name: 'address', pmName: 'E.Address1', colName: 'Address', isDate: false },
    { id: 18, name: 'alternativeManager', pmName: 'A.FirstName+\' \'+A.LastName', colName: 'Alt. manager', isDate: false },
    { id: 19, name: 'postalZIPCode', pmName: 'E.PostalCode', colName: 'Postal/ZIP code', isDate: false },
    { id: 20, name: 'cityTown', pmName: 'E.City', colName: 'City/Town', isDate: false },
    { id: 21, name: 'provinceState', pmName: 'P.Name', colName: 'Province/State', isDate: false },
    { id: 22, name: 'country', pmName: 'C.Name', colName: 'Country', isDate: false },
    { id: 23, name: 'employeeStatus', pmName: 'T.Name', colName: 'Status', isDate: false },
    { id: 24, name: 'employeeType', pmName: 'Y.Name', colName: 'Type', isDate: false },
    { id: 25, name: 'terminationDate', pmName: 'E.TerminationDate', colName: 'Ter. date', isDate: true },
    { id: 26, name: 'lastHireDate', pmName: 'E.LastHireDate', colName: 'Last hire date', isDate: true },
    { id: 27, name: 'workPhone', pmName: 'E.WorkPhone', colName: 'Work phone', isDate: false },
    { id: 28, name: 'workLocation', pmName: 'L.Name', colName: 'Work location', isDate: false },
    { id: 29, name: 'isManager', pmName: 'E.IsManager', colName: 'Is manager', isDate: false },
    { id: 30, name: 'suiteUnit', pmName: 'E.Address2', colName: 'Suite/Unit', isDate: false },
    { id: 31, name: 'seniorityDate', pmName: 'E.SeniorityDate', colName: 'Seniority date', isDate: true },
    { id: 32, name: 'otherId', pmName: 'E.OtherId', colName: 'Other ID', isDate: false },
    { id: 33, name: 'ethnicOrigin', pmName: 'H.NAME', colName: 'Ethnic Origin', isDate: false }
  ];

  genderList: IReportDropdown[] = [
    { id: 1, name: 'male' },
    { id: 2, name: 'female' },
    { id: 527, name: 'nonBinary' },
    { id: 528, name: 'twoSpirit' },
    { id: 529, name: 'preferNotToSay' },
    { id: 530, name: 'another' }
  ];

  statusList: IReportDropdown[] = [
    { id: 3, name: 'active' },
    { id: 4, name: 'inactive' },
    { id: 5, name: 'terminated' }
  ];

  orderByList: IReportDropdown[] = [
    { id: 1, name: 'employeeNumber' },
    { id: 2, name: 'employeeName' },
    { id: 3, name: 'department' }
  ];

  categoryTypeList: IReportDropdown[] = [
    { id: 1, name: 'activeCategories' },
    { id: 2, name: 'inactiveCategories' },
    { id: 3, name: 'allCategories' }
  ];
  empTypeList: IEmptypes[];
  report_last_val_update: IUpdateReportLastVal = { wages: null, week_start_date: null, province: null, rule: null, all_emp_incl: null };
  reportLastVal: IReportLastVal = { a: null, b: null, c: null, d: null, e: null };

  @Input()
  set wizard_mode_from_parent(wizard_mode_from_parent: boolean) {
    this.wizard_mode = wizard_mode_from_parent;
  }

  get wizard_mode_from_parent(): boolean {
    return this.wizard_mode;
  }

  @Input()
  set cur_wizard_pg_frm_parent(cur_payroll_grp: PayrollGroup) {
    this.cur_pg = cur_payroll_grp;
  }

  get cur_wizard_pg_frm_parent(): PayrollGroup {
    return this.cur_pg;
  }

  filter: boolean;
  fav_rep_param: string = '';
  frmReset: boolean = false;
  private fromemail: string = null;

  @Input()
  set report_fromparent(report_fromparent: IReport) {
    this.report_sent = report_fromparent;
    this.report_sent.token = this.token;
    console.log(this.report_sent);
    this.reportForm.reset();
    this.reportForm.get('filterByDt').setValue('payDate');
    this.setValidators(this.report_sent);
    if (this.report_sent.COUNTRY == 82 && this.empItemList.find(x => x.id == 11).colName != 'SSN') {
      this.empItemList.find(x => x.id == 11).name = this.transloco.translate('ssn', {}, 'reportChild');
      this.empItemList.find(x => x.id == 11).colName = 'SSN';
      this.empItemList.find(x => x.id == 11).pmName = 'E.SSNnumber';
      this.empItemList.push({ id: 34, name: 'ssnType', pmName: 'E.SSNType', colName: 'SSNType', isDate: false });
      this.empItemList.find(x => x.id == 34).name = this.transloco.translate('ssnType', {}, 'reportChild');
      this.empItemList.sort(function(a, b){
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }
    if (report_fromparent.PAR_PAYROLL_CATEGORY == 2) {
      this.paycatlistFiltered = this.paycatlist.filter(x => x.c == 7);
    }
    if (report_fromparent.PAR_WAGES == 1) {
      this.paycatlistFilteredForStatutory = this.paycatlist.filter(x => (x.c == 1 || x.c == 4));
    }
    if (report_fromparent.PAR_PAYROLL_CATEGORY == 3) {
      this.paycatlistFilteredForWorkerCompensation = this.paycatlist.filter(x => (x.d == 8));
    }
    var rf = this.reportFilters ? this.reportFilters.find(x => x.reportId == this.report_sent.REPORT_ID) : null;
    this.reportForm.get('wcbCategory').markAsTouched();
    this.reportForm.get('payrollcatdetail').markAllAsTouched();
    this.reportForm.get('dimensionmulti').markAllAsTouched();
    if (rf != undefined && rf != null) {
      if (this.report_sent.PAR_TO_DATE == 1) {
        this.reportForm.get('filterByDt').setValue(rf.filterControls.find(x => x.name == 'filterByDt') ? rf.filterControls.find(x => x.name == 'filterByDt').value : 'payDate');
        this.reportForm.get('frmDate').setValue(rf.filterControls.find(x => x.name == 'frmDate') ? rf.filterControls.find(x => x.name == 'frmDate').value : null);
        this.reportForm.get('toDate').setValue(rf.filterControls.find(x => x.name == 'toDate') ? rf.filterControls.find(x => x.name == 'toDate').value : null);
      }
      if (this.report_sent.PAR_DEPARTMENT_ID == 1 || this.report_sent.REPORT_ID == 23 || this.report_sent.REPORT_ID == 26 || this.report_sent.REPORT_ID == 27) {
        var dep = rf.filterControls.find(x => x.name == 'department') ? rf.filterControls.find(x => x.name == 'department').value : null;
        if (dep) {
          dep = this.listDepartments.find(d => d.ID == dep.ID);
        }
        this.reportForm.get('department').setValue(dep);
      }
      if (this.report_sent.PAR_LOCATION_ID == 1 || this.report_sent.REPORT_ID == 23 || this.report_sent.REPORT_ID == 26 || this.report_sent.REPORT_ID == 27) {
        var loc = rf.filterControls.find(x => x.name == 'location') ? rf.filterControls.find(x => x.name == 'location').value : null;
        if (loc) {
          loc = this.listLocations.find(d => d.ID == loc.ID);
        }
        this.reportForm.get('location').setValue(loc);
      }
      if (this.report_sent.PAR_EMPLOYEE_ID == 1) {
        this.reportForm.get('multiEmployee').setValue(rf.filterControls.find(x => x.name == 'multiEmployee') ? rf.filterControls.find(x => x.name == 'multiEmployee').value : null);
      }
      if (this.report_sent.PAR_PAYROLL_RUNS == 1) {
        this.reportForm.get('payrollrun').setValue(rf.filterControls.find(x => x.name == 'payrollrun') ? rf.filterControls.find(x => x.name == 'payrollrun').value : '');
      }
      if (this.report_sent.PAR_CALCULATION_METHOD == 1) {
        this.reportForm.get('calmethod').setValue(rf.filterControls.find(x => x.name == 'calmethod') ? rf.filterControls.find(x => x.name == 'calmethod').value : '');
      }
      if (this.report_sent.PAR_PENSION_GROUP == 1) {
        this.reportForm.get('pensiongrp').setValue(rf.filterControls.find(x => x.name == 'pensiongrp') ? rf.filterControls.find(x => x.name == 'pensiongrp').value : '');
      }
      if (this.report_sent.PAR_PAYROLL_CLASS_TYPE == 1) {
        this.reportForm.get('payrollclass').setValue(rf.filterControls.find(x => x.name == 'payrollclass') ? rf.filterControls.find(x => x.name == 'payrollclass').value : '');
      }
      if (this.report_sent.PAR_PAYMENT_METHOD == 1) {
        this.reportForm.get('paymentmethod').setValue(rf.filterControls.find(x => x.name == 'paymentmethod') ? rf.filterControls.find(x => x.name == 'paymentmethod').value : '');
      }
      if (this.report_sent.PAR_WAGES == 1) {
        this.reportForm.get('wages').setValue(rf.filterControls.find(x => x.name == 'wages') ? rf.filterControls.find(x => x.name == 'wages').value : null);
      }
      if (this.report_sent.PAR_HOLIDAY_DATE == 1) {
        this.reportForm.get('holidayDate').setValue(rf.filterControls.find(x => x.name == 'holidayDate') ? rf.filterControls.find(x => x.name == 'holidayDate').value : null);
      }
      if (this.report_sent.PAR_TAXATION_YEAR == 1) {
        this.reportForm.get('taxyear').setValue(rf.filterControls.find(x => x.name == 'taxyear') ? rf.filterControls.find(x => x.name == 'taxyear').value : '');
      }
      if (this.report_sent.PAR_RCT_NUMBER == 1) {
        this.reportForm.get('rctno').setValue(rf.filterControls.find(x => x.name == 'rctno') ? rf.filterControls.find(x => x.name == 'rctno').value : '');
      }
      if ((this.report_sent.REPORT_ID >= 2 && this.report_sent.REPORT_ID <= 6) || (this.report_sent.REPORT_ID >= 13 && this.report_sent.REPORT_ID <= 21) || this.report_sent.REPORT_ID == 39 || 
        this.report_sent.REPORT_ID == 28 || this.report_sent.REPORT_ID == 29 || this.report_sent.REPORT_ID == 30 || this.report_sent.REPORT_ID == 31 || this.report_sent.REPORT_ID == 37) {
        this.reportForm.get('include_unlocked_paycards').setValue(rf.filterControls.find(x => x.name == 'include_unlocked_paycards') ? rf.filterControls.find(x => x.name == 'include_unlocked_paycards').value : null);
      }
      if (this.report_sent.REPORT_ID == 20) {
        this.reportForm.get('include_user_defined_gross').setValue(rf.filterControls.find(x => x.name == 'include_user_defined_gross') ? rf.filterControls.find(x => x.name == 'include_user_defined_gross').value : null);
      }
      if (this.report_sent.REPORT_ID == 36) {
        this.reportForm.get('include_blank_note').setValue(rf.filterControls.find(x => x.name == 'include_blank_note') ? rf.filterControls.find(x => x.name == 'include_blank_note').value : null);
      }
      if (this.report_sent.PAR_INCLUDE_ALL_EMPS == 1) {
        this.reportForm.get('include_all_emps').setValue(rf.filterControls.find(x => x.name == 'include_all_emps') ? rf.filterControls.find(x => x.name == 'include_all_emps').value : null);
      }
      if (this.report_sent.REPORT_ID == 30) {
        var st = rf.filterControls.find(x => x.name == 'state') ? rf.filterControls.find(x => x.name == 'state').value : null;
        if (st) {
          st = this.listStates.find(d => d.a == st.id);
        }
        this.reportForm.get('state').setValue(st);
        this.reportForm.get('localTaxItems').setValue(rf.filterControls.find(x => x.name == 'localTaxItems') ? rf.filterControls.find(x => x.name == 'localTaxItems').value : null);
        this.reportForm.get('include_employer_contributions').setValue(rf.filterControls.find(x => x.name == 'include_employer_contributions') ? rf.filterControls.find(x => x.name == 'include_employer_contributions').value : null);
      }
      if (this.report_sent.REPORT_ID == 31) {
        this.reportForm.get('wcbCategory').setValue(rf.filterControls.find(x => x.name == 'wcbCategory') ? rf.filterControls.find(x => x.name == 'wcbCategory').value : null);
      }
      if (this.report_sent.PAR_BUSINESS_NUMBER == 1) {
        this.reportForm.get('businessno').setValue(rf.filterControls.find(x => x.name == 'businessno') ? rf.filterControls.find(x => x.name == 'businessno').value : '');
      }
      if (this.report_sent.REPORT_ID == 32) {
        var u = rf.filterControls.find(x => x.name == 'union') ? rf.filterControls.find(x => x.name == 'union').value : null;
        if (u) {
          u = this.dimUnionList.find(d => d.ID_DIMENSION == u.ID_DIMENSION);
        }
        this.reportForm.get('union').setValue(u);
        var ua = rf.filterControls.find(x => x.name == 'unionAgreement') ? rf.filterControls.find(x => x.name == 'unionAgreement').value : null;
        if (ua) {
          ua = this.unionAgrList.find(d => d.ID_DIMENSION == ua.ID_DIMENSION);
        }
        this.reportForm.get('unionAgreement').setValue(ua);
        this.reportForm.get('agrDate').setValue(rf.filterControls.find(x => x.name == 'agrDate') ? rf.filterControls.find(x => x.name == 'agrDate').value : '');
        this.reportForm.get('include_inactive_dates').setValue(rf.filterControls.find(x => x.name == 'include_inactive_dates') ? rf.filterControls.find(x => x.name == 'include_inactive_dates').value : null);
      }
      if (this.report_sent.PAR_PAYROLL_KIND == 1) {
        var pc = rf.filterControls.find(x => x.name == 'payrollcat') ? rf.filterControls.find(x => x.name == 'payrollcat').value : null;
        if (pc) {
          pc = this.listpayrollCategories.find(d => d.CAT_TYP_CODE == pc.CAT_TYP_CODE);
        }
        this.reportForm.get('payrollcat').setValue(pc);
      }
      if (this.report_sent.PAR_PROVINCE_RULE == 1) {
        var pr = rf.filterControls.find(x => x.name == 'province_rule') ? rf.filterControls.find(x => x.name == 'province_rule').value : null;
        if (pr) {
          pr = this.province_rule_list.find(d => d.id == pr.id);
        }
        this.reportForm.get('province_rule').setValue(pr);
      }
      if (this.report_sent.PAR_WORKING_WEEK_START == 1) {
        var wws = rf.filterControls.find(x => x.name == 'working_week_start') ? rf.filterControls.find(x => x.name == 'working_week_start').value : null;
        if (wws) {
          wws = this.working_week.find(d => d.id == wws.id);
        }
        this.reportForm.get('working_week_start').setValue(wws);
      }
      if (this.report_sent.PAR_PAYROLL_TYPE == 1) {
        var pt = rf.filterControls.find(x => x.name == 'payrolltype') ? rf.filterControls.find(x => x.name == 'payrolltype').value : null;
        if (pt) {
          pt = this.basicList.find(d => d.id == pt.id);
        }
        this.reportForm.get('payrolltype').setValue(pt);
      }
      if (this.report_sent.PAR_EMPLOYEE_STATUS == 1) {
        var st = rf.filterControls.find(x => x.name == 'status') ? rf.filterControls.find(x => x.name == 'status').value : null;
        if (st) {
          st = this.statusList.find(d => d.id == st.id);
        }
        this.reportForm.get('status').setValue(st);
      }
      if (this.report_sent.REPORT_ID == 36) {
        var et = rf.filterControls.find(x => x.name == 'empType') ? rf.filterControls.find(x => x.name == 'empType').value : null;
        if (et) {
          et = this.empTypeList.find(d => d.ID == et.ID);
        }
        this.reportForm.get('empType').setValue(et);
      }
      if (this.report_sent.PAR_PAYROLL_GROUP == 1 && this.report_sent.REPORT_ID != 22) {
        var pg = rf.filterControls.find(x => x.name == 'payrollgrp') ? rf.filterControls.find(x => x.name == 'payrollgrp').value : null;
        if (pg) {
          pg = this.listpayrollgroup.find(d => d.PRLG_CODE == pg.PRLG_CODE);
        }
        this.reportForm.get('payrollgrp').setValue(pg);
      }
      if (this.report_sent.REPORT_ID == 22) {
        var pg = rf.filterControls.find(x => x.name == 'payrollgrpStat') ? rf.filterControls.find(x => x.name == 'payrollgrpStat').value : null;
        if (pg) {
          pg = this.listpayrollgroup.find(d => d.PRLG_CODE == pg.PRLG_CODE);
        }
        this.reportForm.get('payrollgrpStat').setValue(pg);
      }
      if (this.report_sent.PAR_EI_GROUP == 1 && this.report_sent.COUNTRY != 82) {
        var eg = rf.filterControls.find(x => x.name == 'eigroup') ? rf.filterControls.find(x => x.name == 'eigroup').value : null;
        if (eg) {
          eg = this.listEIgroup.find(d => d.UDF_COLL_INDEX == eg.UDF_COLL_INDEX);
        }
        this.reportForm.get('eigroup').setValue(eg);
      }
      if (this.report_sent.PAR_PAYROLL_CATEGORY != null && this.report_sent.REPORT_ID != 24 && this.report_sent.REPORT_ID != 40) {
        this.reportForm.get('payrollcatdetail').setValue(rf.filterControls.find(x => x.name == 'payrollcatdetail') ? rf.filterControls.find(x => x.name == 'payrollcatdetail').value : null);
      }
      if (this.report_sent.REPORT_ID == 38) {
        this.reportForm.get('payrollcatType').setValue(rf.filterControls.find(x => x.name == 'payrollcatType') ? rf.filterControls.find(x => x.name == 'payrollcatType').value : null);
      }
      if (this.report_sent.PAR_ORDER_BY == 1) {
        var ob = rf.filterControls.find(x => x.name == 'orderBy') ? rf.filterControls.find(x => x.name == 'orderBy').value : null;
        if (ob) {
          ob = this.orderByList.find(d => d.id == ob.id);
        }
        this.reportForm.get('orderBy').setValue(ob);
      }
      if (this.report_sent.REPORT_ID == 23) {
        this.reportForm.get('emplyeeItems').setValue(rf.filterControls.find(x => x.name == 'emplyeeItems') ? rf.filterControls.find(x => x.name == 'emplyeeItems').value : null);
        this.reportForm.get('isManager').setValue(rf.filterControls.find(x => x.name == 'isManager') ? rf.filterControls.find(x => x.name == 'isManager').value : false);

        var sup = rf.filterControls.find(x => x.name == 'supervisor') ? rf.filterControls.find(x => x.name == 'supervisor').value : null;
        if (sup) {
          sup = this.listSupervisor.find(d => d.ID == sup.ID);
        }
        this.reportForm.get('supervisor').setValue(sup);

        var amg = rf.filterControls.find(x => x.name == 'altManager') ? rf.filterControls.find(x => x.name == 'altManager').value : null;
        if (amg) {
          amg = this.listAltManager.find(d => d.ID == amg.ID);
        }
        this.reportForm.get('altManager').setValue(amg);
      }
      if (this.report_sent.REPORT_ID == 23 || this.report_sent.REPORT_ID == 26) {
        var gd = rf.filterControls.find(x => x.name == 'gender') ? rf.filterControls.find(x => x.name == 'gender').value : null;
        if (gd) {
          gd = this.genderList.find(d => d.id == gd.id);
        }
        this.reportForm.get('gender').setValue(gd);
        this.reportForm.get('jobTitle').setValue(rf.filterControls.find(x => x.name == 'jobTitle') ? rf.filterControls.find(x => x.name == 'jobTitle').value : '');
      }
      if (this.report_sent.REPORT_ID == 33) {
        var dim = rf.filterControls.find(x => x.name == 'dimension') ? rf.filterControls.find(x => x.name == 'dimension').value : null;
        if (dim) {
          dim = this.dimList33.find(d => d.LABOR_DIM_ID == dim.LABOR_DIM_ID);
        }
        this.reportForm.get('dimension').setValue(dim);
      }
      if (this.report_sent.REPORT_ID == 34) {
        var dim = rf.filterControls.find(x => x.name == 'dimension') ? rf.filterControls.find(x => x.name == 'dimension').value : null;
        if (dim) {
          dim = this.dimList34.find(d => d.LABOR_DIM_ID == dim.LABOR_DIM_ID);
        }
        this.reportForm.get('dimension').setValue(dim);
      }
      if (this.report_sent.REPORT_ID == 35) {
        this.reportForm.get('include_zero_categories').setValue(rf.filterControls.find(x => x.name == 'include_zero_categories') ? rf.filterControls.find(x => x.name == 'include_zero_categories').value : null);
        var ct = rf.filterControls.find(x => x.name == 'categoryType') ? rf.filterControls.find(x => x.name == 'categoryType').value : null;
        if (ct) {
          ct = this.categoryTypeList.find(d => d.id == ct.id);
        }
        this.reportForm.get('categoryType').setValue(ct);
      }
      if (this.report_sent.REPORT_ID == 23 || this.report_sent.REPORT_ID == 26 || this.report_sent.REPORT_ID == 27) {
        this.reportForm.get('city').setValue(rf.filterControls.find(x => x.name == 'city') ? rf.filterControls.find(x => x.name == 'city').value : '');
      }
      if (this.report_sent.REPORT_ID == 25) {
        var emp = rf.filterControls.find(x => x.name == 'employee') ? rf.filterControls.find(x => x.name == 'employee').value : null;
        if (emp) {
          emp = this.listEmployees.find(d => d.ID == emp.ID);
        }
        this.reportForm.get('employee').setValue(emp);
        this.reportForm.get('include_zero_earnings').setValue(rf.filterControls.find(x => x.name == 'include_zero_earnings') ? rf.filterControls.find(x => x.name == 'include_zero_earnings').value : null);
      }
      if (this.report_sent.REPORT_ID == 11) {
        this.reportForm.get('include_year_to_date_amounts').setValue(rf.filterControls.find(x => x.name == 'include_year_to_date_amounts') ? rf.filterControls.find(x => x.name == 'include_year_to_date_amounts').value : null);
        this.reportForm.get('group_by_department').setValue(rf.filterControls.find(x => x.name == 'group_by_department') ? rf.filterControls.find(x => x.name == 'group_by_department').value : null);
      }
      if (this.report_sent.REPORT_ID == 1) {
        this.reportForm.get('include_script_text').setValue(rf.filterControls.find(x => x.name == 'include_script_text') ? rf.filterControls.find(x => x.name == 'include_script_text').value : null);
      }
      if (this.report_sent.REPORT_ID == 37) {
        this.reportForm.get('dimensionmulti').setValue(rf.filterControls.find(x => x.name == 'dimensionmulti') ? rf.filterControls.find(x => x.name == 'dimensionmulti').value : null);
      }
    }
  }

  get report_fromparent(): IReport {
    return this.report_sent;
  }

  @Input()
  set filter_fromparent(filter_fromparent: boolean) {
    this.filter = filter_fromparent;
  }

  get filter_fromparent(): boolean {
    return this.filter;
  }

  @Input()
  set report_para_details(report_para_details: ReportTableParameters) {
    this.report_para_detail = report_para_details;
    this.listEmployees = this.report_para_detail.employeeslist;
    this.multiEmployees = [...this.listEmployees];
    let allEmp: Employees = { ID: -1, FirstName: 'All', LastName: 'All', IsManager: false, Name: this.transloco.translate('all') };
    this.multiEmployees.unshift(allEmp);
    this.listDepartments = this.report_para_detail.departmentslist;
    this.listLocations = this.report_para_detail.locationslist;
    this.listpayrollgroup = this.report_para_detail.payrollGroupList;
    this.listpayrollCategories = this.report_para_detail.payrollcategoryList;
    this.listSupervisor = this.report_para_detail.employeeslist.filter(e => e.IsManager);
    this.listAltManager = this.report_para_detail.employeeslist.filter(e => e.IsManager);
  }

  get report_para_details(): ReportTableParameters {
    return this.report_para_detail;
  }

  @Input()
  set eigroup_from_parent(eigroup_from_parent: EIGroup[]) {
    this.listEIgroup = eigroup_from_parent;
  }

  get eigroup_from_parent(): EIGroup[] {
    return this.listEIgroup;
  }

  @Input()
  set payrollcat_from_parent(payrollcat_from_parent: IPayrollCat[]) {
    this.paycatlist = payrollcat_from_parent;
  }

  get payrollcat_from_parent(): IPayrollCat[] {
    return this.paycatlist;
  }

  mobile: boolean = false;


  @Input()
  set reportval_from_parent(reportval_from_parent: IReportLastVal) {
    this.reportLastVal = reportval_from_parent;
  }

  get reportval_from_parent(): IReportLastVal {
    return this.reportLastVal;
  }

  date_format: string;
  time_format: string;
  unit_format: string;
  rate_format: string;
  holidayDateVal: Date;
  firstDayofWeekVal: number;
  calcMthdVal: string;
  provVal: number;
  pgCodeVal: number;


  reprtSrce: { report: string, token: string } = { report: '', token: '' };
  reprtSr: { report: string, parameters: { category: string, dateFormat: string, timeFormat: string, token: string } } = {
    report: '',
    parameters: { category: '', dateFormat: '', timeFormat: '', token: '' }
  };
  rprtSrceScript: { report: string, parameters: { param: string, script: number, dateFormat: string, timeFormat: string, token: string } } = {
    report: '',
    parameters: { param: '', script: 0, dateFormat: '', timeFormat: '', token: '' }
  };
  rprtSrce: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', token: '' }
  };
  rprtSrceWithRate: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, rateFormat: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', rateFormat: '', token: '' }
  };
  rprtSrcePaycardFilter: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', paycardFilter: 0, token: '' }
  };
  rprtSrceOrderBy: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, orderBy: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', orderBy: '', token: '' }
  };
  rprtSrceOrderByPaycardFilter: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, orderBy: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', orderBy: '', paycardFilter: 0, token: '' }
  };
  rprtSrceGroupByMonth: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, orderBy: string, months: string, paycardFilter: number, isPaydate: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', orderBy: '', months: '', paycardFilter: 0, isPaydate: 1, token: '' }
  };
  rprtSrce2: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, procYear: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', procYear: null, token: '' }
  };
  rprtSrceYTD2: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, rateFormat: string, procYear: number, YTD: number, groupBy: number, orderBy: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', rateFormat: '', procYear: null, YTD: 0, groupBy: 0, orderBy: '', token: '' }
  };
  rprtSrce2OrderBy: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, procYear: number, orderBy: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', procYear: null, orderBy: '', token: '' }
  };
  rprtSrce2WithPaycardFilter: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, procYear: number, orderBy: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', procYear: null, orderBy: '', paycardFilter: 0, token: '' }
  };
  rprtSrceForEmpNote: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, orderBy: string, blankNoteFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', orderBy: '', blankNoteFilter: 0, token: '' }
  };
  rprtSrceForEmpMaster: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, procYear: number, orderBy: string, inclzero: number, categoryTyp: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', procYear: null, orderBy: '', inclzero: 0, categoryTyp: 3, token: '' }
  };
  rprtSrce3WithPaycardFilter: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, rateFormat: string, procYear: number, orderBy: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', rateFormat: '', procYear: null, orderBy: '', paycardFilter: 0, token: '' }
  };
  rprtSrceForAdjustment: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, rateFormat: string, procYear: number, orderBy: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', rateFormat: '', procYear: null, orderBy: '', token: '' }
  };
  rprtSrce2WithPFilter: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, procYear: number, paycardFilter: number, userDefinedGrossFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', procYear: null, paycardFilter: 0, userDefinedGrossFilter: 0, token: '' }
  };
  rptSource: { report: string, parameters: { parFilter: string, dateFormat: string, timeFormat: string, token: string } } = {
    report: '',
    parameters: { parFilter: '', dateFormat: '', timeFormat: '', token: '' }
  };
  reprtSrceWithCat: { report: string, parameters: { param: string, parFilter: string, category: string, dateFormat: string, timeFormat: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', category: '', dateFormat: '', timeFormat: '', token: '' }
  };
  reprtSrceWithCatPaycardFilter: { report: string, parameters: { param: string, parFilter: string, category: string, dateFormat: string, timeFormat: string, unitFormat: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', category: '', dateFormat: '', timeFormat: '', unitFormat: '', paycardFilter: 0, token: '' }
  };
  reprtSrceWithCatOrderBy: { report: string, parameters: { param: string, parFilter: string, category: string, dateFormat: string, timeFormat: string, orderBy: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', category: '', dateFormat: '', timeFormat: '', orderBy: '', token: '' }
  };
  reprtSrceWithCatOrderByPaycardFilter: { report: string, parameters: { param: string, parFilter: string, category: string, dateFormat: string, timeFormat: string, unitFormat: string, orderBy: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', category: '', dateFormat: '', timeFormat: '', unitFormat: '', orderBy: '', paycardFilter: 0, token: '' }
  };
  reprtSrceWithThreeParam: { report: string, parameters: { param: string, parFilter: string, paramPeriod: string, dateFormat: string, timeFormat: string, procYear: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', paramPeriod: '', dateFormat: '', timeFormat: '', procYear: null, token: '' }
  };
  reprtSrceWithFourParam: { report: string, parameters: { param: string, parFilter: string, category: string, paramPeriod: string, dateFormat: string, timeFormat: string, procYear: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', category: '', paramPeriod: '', dateFormat: '', timeFormat: '', procYear: null, token: '' }
  };
  reprtSrceWithFourParamOrderBy: { report: string, parameters: { param: string, parFilter: string, category: string, paramPeriod: string, dateFormat: string, timeFormat: string, unitFormat: string, procYear: number, orderBy: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: {
      param: '',
      parFilter: '',
      category: '',
      paramPeriod: '',
      dateFormat: '',
      timeFormat: '',
      unitFormat: '',
      procYear: null,
      orderBy: '',
      paycardFilter: 0, token: ''
    }
  };
  reprtSrceWithFourParamPaycardFilter: { report: string, parameters: { param: string, parFilter: string, category: string, paramPeriod: string, dateFormat: string, timeFormat: string, unitFormat: string, procYear: number, paycardFilter: number, token: string } } = {
    report: '',
    parameters: {
      param: '',
      parFilter: '',
      category: '',
      paramPeriod: '',
      dateFormat: '',
      timeFormat: '',
      unitFormat: '',
      procYear: null,
      paycardFilter: 0, token: ''
    }
  };
  reprtSrceWithFiveParam: { report: string, parameters: { param: string, parFilter: string, category: string, ytdFilter: number, dateFormat: string, timeFormat: string, procYear: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', category: '', ytdFilter: null, dateFormat: '', timeFormat: '', procYear: null, token: '' }
  };
  statReport: { report: string, parameters: { param: string, parFilter: string, timeFormat: string, dateFormat: string, holidayDate: Date, firstDayofWeek: number, calcMthd: string, prov: number, pgCode: number, procYear: number, token: string } };
  rprtSrceEmp: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, paramColumn: string, paramColName: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', paramColumn: '', paramColName: '', token: '' }
  };
  rprtSrceEmpAll: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', token: '' }
  };
  rprtSrceWithPaycardFilter: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, paycardFilter: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', paycardFilter: 0, token: '' }
  };
  stateEarningRprtSrce: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, paycardFilter: number, selCat1: number, selCat2: number, showEYRContribution: number, token: string } } = {
    report: '',
    parameters: {
      param: '',
      parFilter: '',
      dateFormat: '',
      timeFormat: '',
      paycardFilter: 0,
      selCat1: 0,
      selCat2: 0,
      showEYRContribution: 0, token: ''
    }
  };
  usWcbRprtSrce: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, paycardFilter: number, WCBCAT: number, procYear: number, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', paycardFilter: 0, WCBCAT: 0, procYear: null, token: '' }
  };
  unionRprtSrce: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, rateFormat: string, sessionId: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', rateFormat: '', sessionId: '', token: '' }
  };
  wrkcmpReprtSrce: { report: string, parameters: { param: string, parFilter: string, category: string, ytdFilter: number, dateFormat: string, timeFormat: string, procYear: number, empFilter: string, token: string } } = {
    report: '',
    parameters: {
      param: '',
      parFilter: '',
      category: '',
      ytdFilter: null,
      dateFormat: '',
      timeFormat: '',
      procYear: null,
      empFilter: null,
      token: ''
    }
  };

  rprtSrceWithPaycardFilterAndDimensions: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, unitFormat: string, rateFormat: string, procYear: number, orderBy: string, paycardFilter: number, dimensionList: string, dimensionCaption: string, token: string } } = {
    report: '',
    parameters: { param: '', parFilter: '', dateFormat: '', timeFormat: '', unitFormat: '', rateFormat: '', procYear: null, orderBy: '', paycardFilter: 0, dimensionList: '', dimensionCaption: '', token: '' }
  };

  viewerContainerStyle = {
    position: 'relative',
    display: 'flex',
    //width: '100%',
    height: '100%',
    ['font-family']: 'ms sans serif'
  };

  viewerSendEmail = { enabled: true, from: '', to: '', cc: '', subject: '', body: '', format: '' };
  viewermail = { enabled: true, from: '', to: '', cc: '', subject: '' };

  @Input()
  set parent_viewerSendEmail(parent_viewerSendEmail: any) {
    console.log(this.viewerSendEmail);
    this.viewerSendEmail.enabled = parent_viewerSendEmail.enabled;
    this.viewerSendEmail.from = parent_viewerSendEmail.from;
    this.viewerSendEmail.to = parent_viewerSendEmail.to;
    this.viewerSendEmail.cc = parent_viewerSendEmail.cc;
    this.viewerSendEmail.subject = parent_viewerSendEmail.subject;
    //this.viewerSendEmail.body = parent_viewerSendEmail.body;
    // this.viewerSendEmail.format = parent_viewerSendEmail.format;

  }

  get parent_viewerSendEmail(): any {
    return this.viewerSendEmail;
  }

  DB_DATE_para: string;
  DB_TO_DATE_para: string;
  DB_LOCATION_ID_para: string;
  DB_DEPARTMENT_ID_para: string;
  DB_EMPLOYEE_ID_para: string;
  DB_DIVISION_para: string;
  DB_PAYROLL_GROUP_para: string;
  DB_EI_GROUP_para: string;
  DB_PAYROLL_RUNS_para: string;
  DB_CALCULATION_METHOD_para: string;
  DB_PENSION_GROUP_para: string;
  DB_PAYROLL_KIND_para: string;
  DB_PAYROLL_CLASS_TYPE_para: string;
  DB_PAYMENT_METHOD_para: string;
  DB_TAXATION_YEAR_para: string;
  DB_RCT_NUMBER_para: string;
  DB_BUSINESS_NUMBER_para: string;
  DB_PAYROLL_TYPE_para: string;
  DB_PAYROLL_CATEGORY_para: string;
  DB_EMPLOYEE_STATUS_para: string;
  DB_ORDER_BY_para: string;

  isPaydate: number;
  parameter: string = '';
  other_parameter: string = '';
  para_category: number;
  para_zero_earnings: number;
  para_ytd: number;
  para_gbd: number;
  para_script: number;
  paraPeriod: string = '';
  paramColName: string = '';
  paramColumn: string = '';
  para_unlocked_paycards: number;
  para_user_defined_gross: number;
  para_blank_note: number;
  para_employer_contributions: number = 0;
  para_selCat1: number = 0;
  para_selCat2: number = 0;
  para_orderBy: string = '';
  para_inclzero: number;
  para_cateTyp: number;
  dimension_list: string;
  dimension_caption: string;

  IsCoreFilterActive: boolean;
  IsPayrollFilterActive: boolean;
  PayrollFilterIDs: string;
  CoreFilterIDs: string;

  js_string: string = '';

  @ViewChild('viewer1') viewer: TelerikReportViewerComponent;
  @ViewChild('reportForm') frm: NgForm;

  @Output() onBack: EventEmitter<boolean> = new EventEmitter();
  @Output() onJSstringUpdate: EventEmitter<string> = new EventEmitter();

  public opened = false;
  reportSource: any;
  token: string;
  baseURL: string = this.envService?.glReportUrl;
  payrollCatTypeList: IPayrollCategory[] = [];

  public basicList: any[] = [{ id: 0, name: 'salary' }, { id: 1, name: 'unit' }, { id: 2, name: 'percentage' }, { id: 3, name: 'calculated' }, { id: 4, name: 'computed' }, { id: 5, name: 'script' }];
  reportForm = this.fb.group({
    filterByDt: ['payDate'],
    frmDate: [''],
    toDate: [''],
    emplyeeItems: [null],
    gender: [null],
    status: [null],
    empType: [null],
    jobTitle: [''],
    location: [null],
    department: [null],
    isManager: false,
    supervisor: [null],
    altManager: [null],
    city: [''],
    employee: [null],
    multiEmployee: [null],
    payrollgrp: [null],
    payrollgrpStat: [null],
    eigroup: [null],
    payrollrun: [''],
    calmethod: [null],
    pensiongrp: [null],
    payrollcat: [''],
    payrolltype: [null],
    payrollclass: [''],
    paymentmethod: [''],
    taxyear: [''],
    rctno: [''],
    businessno: [''],
    payrollcatdetail: [null],
    payrollcatType: [null],
    dimensionmulti: [null],
    holidayDate: [''],
    wages: [null],
    working_week_start: [''],
    province_rule: [null],
    include_all_emps: [''],
    include_year_to_date_amounts: [''],
    group_by_department: [''],
    include_script_text: [''],
    include_zero_earnings: [''],
    state: [null],
    localTaxItems: [null],
    wcbCategory: [null],
    include_unlocked_paycards: [''],
    include_employer_contributions: [''],
    union: [null],
    unionAgreement: [null],
    agrDate: [''],
    categoryType: [this.categoryTypeList[2]],
    include_inactive_dates: [''],
    include_blank_note: [''],
    dimension: [null],
    include_zero_categories: [''],
    include_user_defined_gross: [''],
    orderBy: [null]

  });


  name: string = '';
  getFromEmailAddressService: Subscription;
  formcontrolSubscription: Subscription;

  payCatvalue: string = '';
  listOfcat: IPayrollCat[] = [];
  sysPref: any;
  empItem: string = '';
  empFlt: string = '';

  proc_year: number;

  constructor(private cd: ChangeDetectorRef, private fb: FormBuilder,
    private _coreEmpService: CoreEmployeeService,
    private _reportService: ReportServiceService,
    private _sysPrefService: SystemPreferenceService,
    private _globlService: GlobalService,
    private _laborService: LaborService,
    private _payrollCategoryService: PayrollCategoryService,
    private authenticationService: AuthenticationService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private envService: EnvService) {
  }

  ngOnInit() {
    //$("#reportModal").kendoWindow({
    //  width: "90%",
    //  height: "90%",
    //  title: "Responsive dialog",
    //  visible: false,
    //  actions: [
    //    "Close"
    //  ]
    //});
      this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe((e) => {
        this.empItemList = this.empItemList.map(i => ({ ...i, name: this.transloco.translate(i.name, {}, 'reportChild') }));
      });
      this.empItemList.sort(function(a, b){
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe((e) => {
      this.categoryTypeList = this.categoryTypeList.map(i => ({ ...i, name: this.transloco.translate(i.name, {}, 'reportChild') }));
    });
    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe((e) => {
      this.orderByList = this.orderByList.map(i => ({ ...i, name: this.transloco.translate(i.name, {}, 'reportChild') }));
    });
    this._sysPrefService.getinitSystemPreferences().subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
        this.date_format = this.sysPref.df;
        this.time_format = this.sysPref.tf;
        this.unit_format = this.sysPref.h;
        this.rate_format = this.sysPref.g;
        this._globlService.GetSelectedPayrollYear().subscribe(
          res => {
            this.proc_year = res;
          }
        );
      }
    });
    this.formcontrolSubscription = this.reportForm.get('frmDate').valueChanges.subscribe(
      val => {
        let tempDate = val;
        if (tempDate !== null && tempDate != '') {
          this.reportForm.get('toDate').setValidators([Validators.required, CustomValidators.minDate(tempDate)]);
          this.reportForm.get('toDate').updateValueAndValidity();
        }
      }
    );
    this.reportForm.get('filterByDt').patchValue('payDate');
    this.authenticationService.currentUser.subscribe(x => {
      this.token = x.token;
      this.companyCode = x.selectedCompany.Name;
      this.userId = x.id;
      this.reportFilters = JSON.parse(localStorage.getItem('rptFlt' + this.companyCode + this.userId));
    });
    this._globlService.IsPayrollFilterActive().subscribe(
      res => {
        this.IsPayrollFilterActive = res;
      }
    );
    this._globlService.IsCoreFilterActive().subscribe(
      res => {
        this.IsCoreFilterActive = res;
      }
    );
    this._globlService.GetPayrollFilterIDs().subscribe(
      res => {
        this.PayrollFilterIDs = res;
      }
    );
    this._globlService.GetCoreFilterIDs().subscribe(
      res => {
        this.CoreFilterIDs = res;
      }
    );
    this._payrollCategoryService.getProvincesForPayrollCategory().subscribe(
      res => {
        this.listStates = res;
      }
    );
    this._payrollCategoryService.getPayrollCategoryForLocalTax().subscribe(
      res => {
        this.localTaxList = res;
      }
    );
    this._payrollCategoryService.getPayrollCategoryForUSWCB().subscribe(
      res => {
        this.wcbCategoryList = res;
      }
    );
    this._payrollCategoryService.getPayrollCategoryType().subscribe(
      res => {
        this.payrollCatTypeList = res.filter(d => d.c >= 1 && d.c <= 8);
      }
    );
    this._laborService.GetUnionDimensionList().subscribe(
      res => {
        this.dimUnionList = res;
      }
    );
    this._laborService.GetUnionAgreementDimensionList().subscribe(
      res => {
        this.unionAgrList = res;
      }
    );
    this._coreEmpService.GetEmployeeTypes().subscribe(
      res => {
        this.empTypeList = res;
      }
    )
    this._laborService.GetLaborSetup().subscribe(
      res => {
        this.dimList33 = res.filter(x => x.LABOR_DIM_IS_ACTIVE_FLG == 1 && x.LABOR_DIM_ID < 7);
        this.dimList34 = res.filter(x => x.LABOR_DIM_IS_ACTIVE_FLG == 1 && x.LABOR_DIM_ID < 7 && x.LABOR_DIM_PARENT_CHILD == 2);
        this.dimList37 = res.filter(x => x.LABOR_DIM_IS_ACTIVE_FLG == 1);
      }
    );
  }

  ngOnChanges(fav_para_change: SimpleChanges) {
    this.cd.detectChanges();
    if (this.wizard_mode && this.cur_pg != null) {
      this.reportForm.get('payrollgrp').patchValue(this.cur_pg);
    }
    if (this.report_fromparent.REPORT_ID == 22 && this.reportLastVal !== null) {
      let start_date: IWorkingWeek = this.working_week.find(x => x.id == this.reportLastVal.b);
      let rule_prov: IProvinceAndRule = this.province_rule_list.find(x => (x.province_id == this.reportLastVal.c && x.rule == this.reportLastVal.d));
      let allemp: boolean = this.reportLastVal.e == 1 ? true : false;
      if (this.reportLastVal.a != null) {
        let catArr = this.reportLastVal.a.split(' , ');
        let tempcatArr: IPayrollCat[] = [];
        for (let item of catArr) {
          tempcatArr.push(this.paycatlistFilteredForStatutory.find(x => x.a == +item));
        }
        this.reportForm.patchValue({ wages: tempcatArr });
      }
      this.reportForm.patchValue({ working_week_start: start_date });
      this.reportForm.patchValue({ province_rule: rule_prov });
      this.reportForm.patchValue({ include_all_emps: allemp });
    }
  }


  onSubmit(frm): void {
    this.parameter = '';
    this.other_parameter = '';
    this.payCatvalue = '';
    this.paraPeriod = '';
    this.report_sent.token = this.token;
    let tempfrm = frm.value;
    if (tempfrm !== null && (this.report_sent.REPORT_ID === 23 || this.report_sent.REPORT_ID === 26 || this.report_sent.REPORT_ID === 27)) {
      let tempname;
      let tempid;
      if (this.report_sent.REPORT_ID === 23 && (tempfrm.emplyeeItems != null || tempfrm.emplyeeItems != '')) {
        this.paramColumn = this.parameter.concat(' ');
        let tem = tempfrm.emplyeeItems;
        this.paramColumn = '';
        this.paramColName = '';
        for (let i = 0; i < tem.length; i++) {
          if (!tem[i].isDate) {
            this.empItem = tem[i].pmName + ' as C' + (i + 1).toString();
          } else {
            this.empItem = 'Format(' + tem[i].pmName + ', \'' + this.date_format + '\') as C' + (i + 1).toString();
          }
          this.paramColumn = this.paramColumn.concat(this.empItem + ', ');
          this.paramColName = this.paramColName.concat('|' + tem[i].colName);
        }
        if (tem.length < 9) {
          for (let i = tem.length + 1; i < 10; i++) {
            this.paramColumn = this.paramColumn.concat('\' \' as C' + i.toString() + ', ');
            this.paramColName = this.paramColName.concat('|');
          }
        }
        this.paramColName = this.paramColName.substring(1);
      }

      if (tempfrm.department == '' || tempfrm.department == null || tempfrm.department.ID == null) {
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.department.Name;
        tempid = tempfrm.department.ID;
        this.parameter = this.parameter.concat(' AND E.ID_DEPARTMENT=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Department: ' + tempname);
      }
      if (tempfrm.location == null || tempfrm.location == '') {
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.location.Name;
        tempid = tempfrm.location.ID;
        this.parameter = this.parameter.concat(' AND E.ID_LOCATION=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Location: ' + tempname);
      }

      if (tempfrm.gender == null || tempfrm.gender == '') {
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.gender.name;
        tempid = tempfrm.gender.id;
        this.parameter = this.parameter.concat(' AND E.IDP_Gender=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Gender: ' + tempname);
      }

      if (tempfrm.jobTitle == null || tempfrm.jobTitle == '') {
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.jobTitle;
        this.parameter = this.parameter.concat(' AND LOWER(E.JobTitle) Like \'' + tempfrm.jobTitle.toString().toLowerCase() + '%\' ');
        this.other_parameter = this.other_parameter.concat('|' + 'Job title: ' + tempname);
      }
      if (this.report_sent.REPORT_ID === 23 && (tempfrm.supervisor == null || tempfrm.supervisor == '')) {
        this.parameter = this.parameter.concat(' ');
      } else if (this.report_sent.REPORT_ID === 23 && (tempfrm.supervisor != null || tempfrm.supervisor != '')) {
        tempname = tempfrm.supervisor.FirstName + ' ' + tempfrm.supervisor.LastName;
        tempid = tempfrm.supervisor.ID;
        this.parameter = this.parameter.concat(' AND E.ID_MANAGER=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Supervisor: ' + tempname);
      }
      if (this.report_sent.REPORT_ID === 23 && (tempfrm.altManager == null || tempfrm.altManager == '')) {
        this.parameter = this.parameter.concat(' ');
      } else if (this.report_sent.REPORT_ID === 23 && (tempfrm.altManager != null || tempfrm.altManager != '')) {
        tempname = tempfrm.altManager.FirstName + ' ' + tempfrm.altManager.LastName;
        tempid = tempfrm.altManager.ID;
        this.parameter = this.parameter.concat(' AND E.ID_ALTMANAGER=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Alternative manager: ' + tempname);
      }
      if (this.report_sent.REPORT_ID === 23 && (tempfrm.isManager)) {
        this.parameter = this.parameter.concat(' AND E.IsManager=1 ');
        this.other_parameter = this.other_parameter.concat('|' + 'Position: Manager');
      }

      if (tempfrm.status == null || tempfrm.status == '') {
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.status.name;
        tempid = tempfrm.status.id;
        this.parameter = this.parameter.concat(' AND E.IDP_Status=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Status: ' + tempname);
      }

      if (tempfrm.city == null || tempfrm.city == '') {
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.city;
        this.parameter = this.parameter.concat(' AND LOWER(E.City) Like \'' + tempfrm.city.toString().toLowerCase() + '%\' ');
        this.other_parameter = this.other_parameter.concat('|' + 'City: ' + tempname);
      }

      if (this.IsCoreFilterActive && (this.CoreFilterIDs != null) && (this.CoreFilterIDs != undefined) && (this.CoreFilterIDs != '')) {
        this.parameter = this.parameter.concat(' AND E.ID_DEPARTMENT in (' + this.CoreFilterIDs + ')');
      }
    }

    if ((tempfrm !== null && this.report_sent.PAR_DEPARTMENT_ID === 1)) {

      this.DB_DEPARTMENT_ID_para = this.report_sent.DB_DEPARTMENT_ID;
      let tempname;
      let tempid;
      if (tempfrm.department == '' || tempfrm.department == null || tempfrm.department.ID == null) {
        tempname = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.department.Name;
        tempid = tempfrm.department.ID;
        this.parameter = this.parameter.concat(' AND ' + this.DB_DEPARTMENT_ID_para + '=' + tempid);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Department: ' + tempname);
    }

    if (this.report_sent.PAR_LOCATION_ID === 1) {
      this.DB_LOCATION_ID_para = this.report_sent.DB_LOCATION_ID;
      let tempname;
      let tempid;
      if (tempfrm.location == null || tempfrm.location == '') {
        tempname = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.location.Name;
        tempid = tempfrm.location.ID;
        this.parameter = this.parameter.concat(' AND ' + this.DB_LOCATION_ID_para + '=' + tempid);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Location: ' + tempname);
    }

    if (this.report_sent.PAR_PAYROLL_GROUP === 1 && this.report_sent.REPORT_ID != 22) {

      this.DB_PAYROLL_GROUP_para = this.report_sent.DB_PAYROLL_GROUP;
      let tempname;
      let tempid;
      if (this.wizard_mode) {
        tempname = this.cur_pg.PRLG_USER_CODE;
        tempid = this.cur_pg.PRLG_CODE;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_GROUP_para + '=' + tempid);
      } else {
        if (tempfrm.payrollgrp == '' || tempfrm.payrollgrp == null || tempfrm.payrollgrp.PRLG_USER_CODE == null) {
          tempname = 'All';
          this.parameter = this.parameter.concat(' ');
        } else {
          tempname = tempfrm.payrollgrp.PRLG_USER_CODE;
          tempid = tempfrm.payrollgrp.PRLG_CODE;
          this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_GROUP_para + '=' + tempid);
        }
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Payroll group: ' + tempname);
    }
    if (this.report_sent.REPORT_ID == 22) {
      this.DB_PAYROLL_GROUP_para = this.report_sent.DB_PAYROLL_GROUP;
      this.pgCodeVal = tempfrm.payrollgrpStat.PRLG_CODE;
      let tempname = tempfrm.payrollgrpStat.PRLG_USER_CODE;
      let tempid = tempfrm.payrollgrpStat.PRLG_CODE;
      this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_GROUP_para + '=' + tempid);
      this.other_parameter = this.other_parameter.concat('|' + 'Payroll group: ' + tempname);
    }
    if (this.report_sent.PAR_EI_GROUP === 1 && this.report_sent.COUNTRY != 82) {
      this.DB_EI_GROUP_para = this.report_sent.DB_EI_GROUP;
      let tempname;
      let tempid;
      if (tempfrm.eigroup == '' || tempfrm.eigroup == null || tempfrm.eigroup.UDF_COLL_TEXT == '') {
        tempname = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.eigroup.UDF_COLL_TEXT;
        tempid = tempfrm.eigroup.UDF_COLL_INDEX;
        this.parameter = this.parameter.concat(' AND ' + this.DB_EI_GROUP_para + '=' + tempid);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'EI group: ' + tempname);
    }
    if (this.report_sent.PAR_HOLIDAY_DATE == 1) {
      this.holidayDateVal = tempfrm.holidayDate;
    }
    if (this.report_sent.PAR_PROVINCE_RULE == 1) {
      this.calcMthdVal = tempfrm.province_rule.rule;
      this.provVal = tempfrm.province_rule.province_id;
    }
    if (this.report_sent.PAR_INCLUDE_ALL_EMPS == 1) {
      if (tempfrm.include_all_emps == false) {
        if (tempfrm.province_rule.province_id != 14) {
          this.parameter = this.parameter.concat(' AND ' + this.report_sent.DB_PROVINCE + '=' + tempfrm.province_rule.province_id);
        }
      }
    }
    if (this.report_sent.REPORT_ID == 25) {
      if (tempfrm.include_zero_earnings == false) {
        this.para_zero_earnings = 0;
      } else {
        this.para_zero_earnings = 1;
      }
    }
    if (this.report_sent.REPORT_ID == 1) {
      if (tempfrm.include_script_text == null || tempfrm.include_script_text == false) {
        this.para_script = 0;
      } else {
        this.para_script = 1;
      }
    }
    if (this.report_sent.REPORT_ID == 11) {
      if (tempfrm.include_year_to_date_amounts == null || tempfrm.include_year_to_date_amounts == false) {
        this.para_ytd = 0;
      } else {
        this.para_ytd = 1;
      }
      if (tempfrm.group_by_department == null || tempfrm.group_by_department == false) {
        this.para_gbd = 0;
      } else {
        this.para_gbd = 1;
      }
    }
    if (this.report_sent.PAR_WAGES == 1) {
      let tempstring = '';
      for (let i = 0; i < tempfrm.wages.length; i++) {
        i != tempfrm.wages.length - 1 ? this.payCatvalue = this.payCatvalue.concat(this.report_sent.DB_WAGES + '=' + tempfrm.wages[i].a + ' OR ') : this.payCatvalue = this.payCatvalue.concat(this.report_sent.DB_WAGES + '=' + tempfrm.wages[i].a);
        let tempcat = this.paycatlist.find(x => x.a == tempfrm.wages[i].a);
        i != tempfrm.wages.length - 1 ? tempstring = tempstring.concat(tempcat.b + ', ') : tempstring = tempstring.concat(tempcat.b);
      }
      this.parameter = this.parameter.concat(' AND (' + this.payCatvalue + ')');
      this.other_parameter = this.other_parameter.concat('|' + 'Payroll categories: ' + tempstring);
    }
    if (this.report_sent.PAR_WORKING_WEEK_START == 1) {
      this.firstDayofWeekVal = tempfrm.working_week_start.id;
    }
    if (this.report_sent.PAR_PAY_DATE === 1 || this.report_sent.PAR_TO_DATE === 1) {
      if (tempfrm.filterByDt === 'payDate') {
        this.DB_DATE_para = this.report_sent.DB_PAY_DATE;
        this.other_parameter = this.other_parameter.concat('|' + 'For pay date: ' + tempfrm.frmDate + ' to ' + tempfrm.toDate);
        this.isPaydate = 1;
      } else {
        this.DB_DATE_para = this.report_sent.DB_TO_DATE;
        this.other_parameter = this.other_parameter.concat('|' + 'For period end date: ' + tempfrm.frmDate + ' to ' + tempfrm.toDate);
        this.isPaydate = 0;
      }
      if (this.report_sent.PAR_PAYROLL_CATEGORY === 2) {
        if (tempfrm.frmDate !== undefined) {
          this.paraPeriod = this.paraPeriod.concat(' AND ' + this.DB_DATE_para + '>= \'' + tempfrm.frmDate + '\'');
          this.paraPeriod = this.paraPeriod.concat(' ');
        }
        if (tempfrm.toDate !== undefined) {
          this.paraPeriod = this.paraPeriod.concat(' AND ' + this.DB_DATE_para + '<= \'' + tempfrm.toDate + '\'');
        }
      } else {
        if (tempfrm.frmDate !== undefined) {
          this.parameter = this.parameter.concat(' AND ' + this.DB_DATE_para + '>= \'' + tempfrm.frmDate + '\'');
          this.parameter = this.parameter.concat(' ');
        }
        if (tempfrm.toDate !== undefined) {
          this.parameter = this.parameter.concat(' AND ' + this.DB_DATE_para + '<= \'' + tempfrm.toDate + '\'');
        }
      }
    }

    if (this.report_sent.REPORT_ID == 36) {
      if (tempfrm.empType == null || tempfrm.empType == '') {
        this.parameter = this.parameter.concat(' ');
      } else {
        var tempname = tempfrm.empType.Name;
        var tempid = tempfrm.empType.ID;
        this.parameter = this.parameter.concat(' AND E.ID_EMPLOYEETYPE=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Employee type: ' + tempname);
      }
    }

    //if (this.report_sent.REPORT_ID == 25) {
    //    this.DB_EMPLOYEE_ID_para = this.report_sent.DB_EMPLOYEE_ID;
    //    let temp;
    //    let tempid;
    //    if (tempfrm.employee == '' || tempfrm.employee == null || tempfrm.employee.ID == null) {
    //        temp = 'All';
    //        this.parameter = this.parameter.concat(' ');
    //    } else {
    //        temp = tempfrm.employee.FirstName + ' ' + tempfrm.employee.LastName;
    //        tempid = tempfrm.employee.ID;
    //        this.parameter = this.parameter.concat(' AND ' + this.DB_EMPLOYEE_ID_para + '=' + tempid);
    //    }
    //    this.other_parameter = this.other_parameter.concat('|' + 'Employee: ' + temp);
    //}
    if (this.report_sent.PAR_EMPLOYEE_ID === 1) {
      this.DB_EMPLOYEE_ID_para = this.report_sent.DB_EMPLOYEE_ID;
      let tempstring = '';
      let temp = '';
      let tem = tempfrm.multiEmployee;
      this.empFlt = '';
      if (tempfrm.multiEmployee == '' || tempfrm.multiEmployee == null) {
        tempstring = 'All';
        this.parameter = this.parameter.concat(' ');
      } else if (tempfrm.multiEmployee.length == 1 && tempfrm.multiEmployee[0].ID == -1) {
        tempstring = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        for (let i = 0; i < tem.length; i++) {
          i != tem.length - 1 ? temp = temp.concat(this.DB_EMPLOYEE_ID_para + '=' + tem[i].ID + ' OR ') : temp = temp.concat(this.DB_EMPLOYEE_ID_para + '=' + tem[i].ID);
          i != tem.length - 1 ? tempstring = tempstring.concat(tem[i].Name + '; ') : tempstring = tempstring.concat(tem[i].Name);
        }
        this.parameter = this.parameter.concat(' AND (' + temp + ')');
        this.empFlt = ' AND (' + temp + ')';
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Employee: ' + tempstring);
    }
    if (this.report_sent.PAR_EMPLOYEE_STATUS === 1) {
      this.DB_EMPLOYEE_STATUS_para = this.report_sent.DB_EMPLOYEE_STATUS;
      let temp;
      if (tempfrm.status == null || tempfrm.status == '') {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.status.name;
        let tempid = tempfrm.status.id;
        this.parameter = this.parameter.concat(' AND ' + this.DB_EMPLOYEE_STATUS_para + '=' + tempid);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Employee status: ' + temp);
    }

    if (this.report_sent.REPORT_ID == 35) {
      if (tempfrm.include_zero_categories == null || tempfrm.include_zero_categories == false) {
        this.para_inclzero = 0;
        this.other_parameter = this.other_parameter.concat('|');
      } else {
        this.para_inclzero = 1;
        this.other_parameter = this.other_parameter.concat('|' + 'Include categories that have 0.00 value in all columns');
      }
      this.para_cateTyp = tempfrm.categoryType?.id;
      let tempname = tempfrm.categoryType.name;
      this.other_parameter = this.other_parameter.concat('|' + 'Report type: ' + tempname);
    }

    if (this.report_sent.PAR_ORDER_BY === 1) {
      this.DB_ORDER_BY_para = this.report_sent.DB_ORDER_BY_EMP_NUM;
      if (tempfrm.orderBy == null || tempfrm.orderBy == '') {
        this.para_orderBy = ' ';
      } else {
        let tempname = tempfrm.orderBy.name;
        switch (tempfrm.orderBy.id) {
          case 1:
            this.DB_ORDER_BY_para = this.report_sent.DB_ORDER_BY_EMP_NUM;
            break;
          case 2:
            this.DB_ORDER_BY_para = this.report_sent.DB_ORDER_BY_EMP_NAME;
            break;
          case 3:
            this.DB_ORDER_BY_para = this.report_sent.DB_ORDER_BY_DEPARTMENT;
            break;
        }
        this.para_orderBy = ' order by ' + this.DB_ORDER_BY_para;
        this.other_parameter = this.other_parameter.concat('|' + 'Sort by: ' + tempname);
      }
    }
    if (this.report_sent.PAR_PAYROLL_RUNS === 1) {
      this.DB_PAYROLL_RUNS_para = this.report_sent.DB_PAYROLL_RUNS;
      let temp;
      if (tempfrm.payrollrun == '' || tempfrm.payrollrun == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.payrollrun;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_RUNS_para + '=\'' + temp + '\'');
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Payroll runs: ' + temp);
    }
    if (this.report_sent.PAR_CALCULATION_METHOD === 1) {
      this.DB_CALCULATION_METHOD_para = this.report_sent.DB_CALCULATION_METHOD;
      let temp;
      if (tempfrm.calmethod == '' || tempfrm.calmethod == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.calmethod;
        this.parameter = this.parameter.concat(' AND ' + this.DB_CALCULATION_METHOD_para + '=\'' + temp + '\'');
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Calculation method: ' + temp);
    }
    if (this.report_sent.PAR_PENSION_GROUP === 1) {
      this.DB_PENSION_GROUP_para = this.report_sent.DB_PENSION_GROUP;
      let temp;
      if (tempfrm.pensiongrp == '' || tempfrm.pensiongrp == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.pensiongrp;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PENSION_GROUP_para + '=\'' + temp + '\'');
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Pension group: ' + temp);
    }
    if (this.report_sent.PAR_PAYROLL_KIND === 1) {
      this.DB_PAYROLL_KIND_para = this.report_sent.DB_PAYROLL_KIND;
      let tempname;
      let tempid;
      if (tempfrm.payrollcat == null || (tempfrm.payrollcat !== null && (tempfrm.payrollcat.CAT_TYP_NAME == '' || tempfrm.payrollcat.CAT_TYP_NAME == null))) {
        tempname = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.payrollcat.CAT_TYP_NAME;
        tempid = tempfrm.payrollcat.CAT_TYP_CODE;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_KIND_para + '=' + tempid);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Payroll category: ' + tempname);
    }
    if (this.report_sent.PAR_PAYROLL_TYPE === 1) {
      this.DB_PAYROLL_TYPE_para = this.report_sent.DB_PAYROLL_TYPE;
      let tempname;
      let tempid;
      if (tempfrm.payrolltype == null || (tempfrm.payrolltype !== null && (tempfrm.payrolltype.name == '' || tempfrm.payrolltype.name == null))) {
        tempname = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        tempname = tempfrm.payrolltype.name;
        tempid = tempfrm.payrolltype.id;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_TYPE_para + '=' + tempid);
      }

      this.other_parameter = this.other_parameter.concat('|' + 'Payroll type: ' + tempname);
    }
    if (this.report_sent.PAR_PAYROLL_CLASS_TYPE === 1) {
      this.DB_PAYROLL_CLASS_TYPE_para = this.report_sent.DB_PAYROLL_CLASS_TYPE;
      let temp;
      if (tempfrm.payrollclass == '' || tempfrm.payrollclass == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.payrollclass;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PAYROLL_CLASS_TYPE_para + '=\'' + temp + '\'');
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Payroll class type: ' + temp);
    }
    if (this.report_sent.PAR_PAYMENT_METHOD === 1) {
      this.DB_PAYMENT_METHOD_para = this.report_sent.DB_PAYMENT_METHOD;
      let temp;
      if (tempfrm.paymentmethod == '' || tempfrm.paymentmethod == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.paymentmethod;
        this.parameter = this.parameter.concat(' AND ' + this.DB_PAYMENT_METHOD_para + '=\'' + temp + '\'');
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Payment method: ' + temp);
    }
    if (this.report_sent.PAR_TAXATION_YEAR === 1) {
      this.DB_TAXATION_YEAR_para = this.report_sent.DB_TAXATION_YEAR;
      let temp;
      if (tempfrm.taxyear == '' || tempfrm.taxyear == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.taxyear;
        this.parameter = this.parameter.concat(' AND ' + this.DB_TAXATION_YEAR_para + '=\'' + temp + '\'');
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Taxation year: ' + temp);
    }
    if (this.report_sent.PAR_RCT_NUMBER === 1) {
      this.DB_RCT_NUMBER_para = this.report_sent.DB_RCT_NUMBER;
      let temp;
      if (tempfrm.rctno == '' || tempfrm.rctno == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.rctno;
        this.parameter = this.parameter.concat(' AND ' + this.DB_RCT_NUMBER_para + '=' + temp);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'RCT number: ' + temp);
    }
    if (this.report_sent.PAR_BUSINESS_NUMBER === 1) {
      this.DB_BUSINESS_NUMBER_para = this.report_sent.DB_BUSINESS_NUMBER;
      let temp;
      if (tempfrm.businessno == '' || tempfrm.businessno == null) {
        temp = 'All';
        this.parameter = this.parameter.concat(' ');
      } else {
        temp = tempfrm.businessno;
        this.parameter = this.parameter.concat(' AND ' + this.DB_BUSINESS_NUMBER_para + '=' + temp);
      }
      this.other_parameter = this.other_parameter.concat('|' + 'Business number: ' + temp);
    }

    if (this.report_sent.REPORT_ID == 31) {
      if (tempfrm.wcbCategory != null && tempfrm.wcbCategory != '') {
        let tem = tempfrm.wcbCategory;
        if (tem.length == 1) {
          this.para_category = tem[0].CAT_DTL_CODE;
          this.other_parameter = this.other_parameter.concat('|' + 'Selected category: ' + tem[0].CAT_DTL_PAYSTUB_ABBRI);
        }
      }
    }

    if ((this.report_sent.REPORT_ID >= 2 && this.report_sent.REPORT_ID <= 6) || (this.report_sent.REPORT_ID >= 13 && this.report_sent.REPORT_ID <= 21) ||
      this.report_sent.REPORT_ID == 28 || this.report_sent.REPORT_ID == 29 || this.report_sent.REPORT_ID == 30 || this.report_sent.REPORT_ID == 31 || this.report_sent.REPORT_ID == 37 || this.report_sent.REPORT_ID == 39) {
      if (tempfrm.include_unlocked_paycards == null || tempfrm.include_unlocked_paycards == false) {
        this.para_unlocked_paycards = 0;
        this.other_parameter = this.other_parameter.concat('|');
      } else {
        this.para_unlocked_paycards = 1;
        this.other_parameter = this.other_parameter.concat('|' + 'Include unlocked paycards');
      }
    }

    if (this.report_sent.REPORT_ID == 20) {
      if (tempfrm.include_user_defined_gross == null || tempfrm.include_user_defined_gross == false) {
        this.para_user_defined_gross = 0;
        this.other_parameter = this.other_parameter.concat('|');
      } else {
        this.para_user_defined_gross = 1;
        this.other_parameter = this.other_parameter.concat('|' + 'Include gross of user-defined federal tax categories into Total reported gross');
      }
    }

    if (this.report_sent.REPORT_ID == 36) {
      if (tempfrm.include_blank_note == null || tempfrm.include_blank_note == false) {
        this.para_blank_note = 0;
        this.other_parameter = this.other_parameter.concat('|');
      } else {
        this.para_blank_note = 1;
        this.other_parameter = this.other_parameter.concat('|' + 'Show employee with blank notepad');
      }
    }

    if (this.report_sent.REPORT_ID == 3 || this.report_sent.REPORT_ID == 4 || this.report_sent.REPORT_ID == 5 || this.report_sent.REPORT_ID == 18 || this.report_sent.REPORT_ID == 37) {
      if (this.IsPayrollFilterActive && (this.PayrollFilterIDs != null) && (this.PayrollFilterIDs != undefined) && (this.PayrollFilterIDs != '')) {
        this.parameter = this.parameter.concat(' AND P.PRLG_CODE in (' + this.PayrollFilterIDs + ')');
      }
    }

    if (this.report_sent.REPORT_ID == 6 || this.report_sent.REPORT_ID == 13 || this.report_sent.REPORT_ID == 14 || this.report_sent.REPORT_ID == 15 || this.report_sent.REPORT_ID == 16) {
      if (this.IsPayrollFilterActive && (this.PayrollFilterIDs != null) && (this.PayrollFilterIDs != undefined) && (this.PayrollFilterIDs != '')) {
        this.parameter = this.parameter.concat(' AND PC.PRLG_CODE in (' + this.PayrollFilterIDs + ')');
      }
    }

    if (this.report_sent.REPORT_ID == 30) {
      if (tempfrm.include_employer_contributions == null || tempfrm.include_employer_contributions == false) {
        this.para_employer_contributions = 0;
      } else {
        this.para_employer_contributions = 1;
      }

      if (tempfrm.state != '' && tempfrm.state != null && tempfrm.state.a != null) {
        let tempname = tempfrm.state.c;
        let tempid = tempfrm.state.a;
        this.parameter = this.parameter.concat(' AND PV.ID=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'State : ' + tempname);
      }

      if (tempfrm.localTaxItems != null && tempfrm.localTaxItems != '') {
        let tem = tempfrm.localTaxItems;
        for (let i = 0; i < tem.length; i++) {
          if (i == 0) {
            this.para_selCat1 = tem[i].CAT_DTL_CODE;
            this.other_parameter = this.other_parameter.concat('|' + 'Local #1 :' + tem[i].CAT_DTL_PAYSTUB_ABBRI);
          } else {
            this.para_selCat2 = tem[i].CAT_DTL_CODE;
            this.other_parameter = this.other_parameter.concat('|' + 'Local #2 :' + tem[i].CAT_DTL_PAYSTUB_ABBRI);
          }
        }
      }
    }

    if (this.report_sent.REPORT_ID == 32) {
      if (tempfrm.union != '' && tempfrm.union != null && tempfrm.union.ID_DIMENSION != null) {
        let tempname = tempfrm.union.DIM_NAME + ' ' + tempfrm.union.DIM_DESCRIPTION;
        let tempid = tempfrm.union.ID_DIMENSION;
        this.parameter = this.parameter.concat(' AND N.DIM_UNION_ID=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Union : ' + tempname);
      } else {
        this.other_parameter = this.other_parameter.concat('|' + 'Union : All');
      }

      if (tempfrm.unionAgreement != '' && tempfrm.unionAgreement != null && tempfrm.unionAgreement.ID_DIMENSION != null) {
        let tempname = tempfrm.unionAgreement.DIM_NAME + ' ' + tempfrm.unionAgreement.DIM_DESCRIPTION;
        let tempid = tempfrm.unionAgreement.ID_DIMENSION;
        this.parameter = this.parameter.concat(' AND T.ID_DIMENSION=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + 'Union agreement : ' + tempname);
      } else {
        this.other_parameter = this.other_parameter.concat('|' + 'Union agreement : All');
      }

      if (tempfrm.agrDate != '' && tempfrm.agrDate != null) {
        this.parameter = this.parameter.concat(' AND A.AGREEMENT_DATE=\'' + tempfrm.agrDate + '\'');
        this.other_parameter = this.other_parameter.concat('|' + 'Agreement date: ' + tempfrm.agrDate);
      } else {
        this.other_parameter = this.other_parameter.concat('|' + 'Agreement date : All');
      }

      if (tempfrm.include_inactive_dates == null || tempfrm.include_inactive_dates == false) {
        this.parameter = this.parameter.concat(' AND A.AGREEMENT_IS_ACTIVE_FLG=1');
      } else {
        this.other_parameter = this.other_parameter.concat('|' + 'Include inactive dates ');
      }
    }

    if (this.report_sent.REPORT_ID == 33 || this.report_sent.REPORT_ID == 34) {
      if (tempfrm.dimension != '' && tempfrm.dimension != null && tempfrm.dimension.LABOR_DIM_ID != null) {
        let tempname = tempfrm.dimension.LABOR_DIM_CAPTION;
        let tempid = tempfrm.dimension.LABOR_DIM_ID;
        this.parameter = this.parameter.concat(' AND s.LABOR_DIM_ID=' + tempid);
        this.other_parameter = this.other_parameter.concat('|' + tempname);
      }
    }

    if (this.report_sent.PAR_PAYROLL_CATEGORY === 1) {
      let tempid;
      if (tempfrm.payrollcatdetail == '' || tempfrm.payrollcatdetail == null) {
        tempid = 0;
        this.parameter = this.parameter.concat(' ');
      } else {
        tempid = tempfrm.payrollcatdetail[0].a;
      }
      this.para_category = tempid;
      let temp = this.other_parameter.substring(1);

      if (this.parameter == '' && temp == '') {
        this.reprtSr = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            category: this.para_category.toString(),
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            token: this.token
          }
        };
        let r: JSON = JSON.parse(JSON.stringify(this.reprtSr));
        this.reportSource = r;

      } else if (this.report_sent.PAR_ORDER_BY == 1) {
        let r: JSON;
        if (this.report_sent.REPORT_ID == 6 || this.report_sent.REPORT_ID == 16) {
          this.reprtSrceWithCatOrderByPaycardFilter = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              category: this.para_category.toString(),
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              orderBy: this.para_orderBy,
              paycardFilter: this.para_unlocked_paycards, token: this.token
            }
          };
          console.log('this.reprtSrceWithCatOrderByPaycardFilter -> ' + this.reprtSrceWithCatOrderByPaycardFilter);
          r = JSON.parse(JSON.stringify(this.reprtSrceWithCatOrderByPaycardFilter));
        } else {
          this.reprtSrceWithCatOrderBy = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              category: this.para_category.toString(),
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              orderBy: this.para_orderBy, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.reprtSrceWithCatOrderBy));
        }
        this.reportSource = r;

      } else {
        let r: JSON;
        if (this.report_sent.REPORT_ID == 17) {
          this.reprtSrceWithCatPaycardFilter = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              category: this.para_category.toString(),
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              paycardFilter: this.para_unlocked_paycards, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.reprtSrceWithCatPaycardFilter));
        } else {
          this.reprtSrceWithCat = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              category: this.para_category.toString(),
              dateFormat: this.date_format,
              timeFormat: this.time_format, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.reprtSrceWithCat));
        }
        this.reportSource = r;

      }
    } else if (this.report_sent.PAR_PAYROLL_CATEGORY === 3) {
      let tempid;
      tempid = tempfrm.payrollcatdetail[0].a;
      this.para_category = tempid;
      let temp = this.other_parameter.substring(1);
      let r: JSON;
      if (this.report_sent.REPORT_ID == 25) {
        this.wrkcmpReprtSrce = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            param: this.parameter,
            parFilter: temp,
            category: this.para_category.toString(),
            ytdFilter: this.para_zero_earnings,
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            procYear: this.proc_year,
            empFilter: this.empFlt,
            token: this.token
          }
        };
        r = JSON.parse(JSON.stringify(this.wrkcmpReprtSrce));
      } else {
        this.reprtSrceWithFiveParam = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            param: this.parameter,
            parFilter: temp,
            category: this.para_category.toString(),
            ytdFilter: this.para_zero_earnings,
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            procYear: this.proc_year, token: this.token
          }
        };
        r = JSON.parse(JSON.stringify(this.reprtSrceWithFiveParam));
      }
      this.reportSource = r;

    } else if (this.report_sent.PAR_PAYROLL_CATEGORY === 2) {
      if (tempfrm !== null && (this.report_sent.REPORT_ID === 24 || this.report_sent.REPORT_ID === 40)) {
        let temp = this.other_parameter.substring(1);
        this.reprtSrceWithThreeParam = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            param: this.parameter,
            parFilter: temp,
            paramPeriod: this.paraPeriod,
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            procYear: this.proc_year, token: this.token
          }
        };
        let r: JSON = JSON.parse(JSON.stringify(this.reprtSrceWithThreeParam));
        this.reportSource = r;

      } else if (this.report_sent.REPORT_ID === 18) {
        let tempid;
        tempid = tempfrm.payrollcatdetail[0].a;
        this.para_category = tempid;
        let temp = this.other_parameter.substring(1);
        this.reprtSrceWithFourParamOrderBy = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            param: this.parameter,
            parFilter: temp,
            category: this.para_category.toString(),
            paramPeriod: this.paraPeriod,
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            unitFormat: this.unit_format,
            procYear: this.proc_year,
            orderBy: this.para_orderBy,
            paycardFilter: this.para_unlocked_paycards, token: this.token
          }
        };
        let r: JSON = JSON.parse(JSON.stringify(this.reprtSrceWithFourParamOrderBy));
        this.reportSource = r;

      } else if (this.report_sent.REPORT_ID === 19) {
        let tempid;
        tempid = tempfrm.payrollcatdetail[0].a;
        this.para_category = tempid;
        let temp = this.other_parameter.substring(1);
        this.reprtSrceWithFourParamPaycardFilter = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            param: this.parameter,
            parFilter: temp,
            category: this.para_category.toString(),
            paramPeriod: this.paraPeriod,
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            unitFormat: this.unit_format,
            procYear: this.proc_year,
            paycardFilter: this.para_unlocked_paycards, token: this.token
          }
        };
        let r: JSON = JSON.parse(JSON.stringify(this.reprtSrceWithFourParamPaycardFilter));
        this.reportSource = r;

      } else {
        let tempid;
        tempid = tempfrm.payrollcatdetail[0].a;
        this.para_category = tempid;
        let temp = this.other_parameter.substring(1);
        this.reprtSrceWithFourParam = {
          report: this.report_sent.FILE_NAME,
          parameters: {
            param: this.parameter,
            parFilter: temp,
            category: this.para_category.toString(),
            paramPeriod: this.paraPeriod,
            dateFormat: this.date_format,
            timeFormat: this.time_format,
            procYear: this.proc_year, token: this.token
          }
        };
        let r: JSON = JSON.parse(JSON.stringify(this.reprtSrceWithFourParam));
        this.reportSource = r;

      }
    } else {
      if (this.report_sent.PAR_PAYROLL_CATEGORY === 5) {
        let tempstring = '';
        this.DB_PAYROLL_CATEGORY_para = this.report_sent.DB_PAYROLL_CATEGORY;
        let tem;
        if (tempfrm.payrollcatdetail == '' || tempfrm.payrollcatdetail == null) {
          tem = 'All';
          this.parameter = this.parameter.concat(' ');
        } else {
          tem = tempfrm.payrollcatdetail;
          for (let i = 0; i < tem.length; i++) {
            i != tem.length - 1 ? this.payCatvalue = this.payCatvalue.concat(this.DB_PAYROLL_CATEGORY_para + '=' + tem[i].a + ' OR ') : this.payCatvalue = this.payCatvalue.concat(this.DB_PAYROLL_CATEGORY_para + '=' + tem[i].a);
            let tempcat = this.paycatlist.find(x => x.a == tem[i].a);
            i != tem.length - 1 ? tempstring = tempstring.concat(tempcat.b + ', ') : tempstring = tempstring.concat(tempcat.b);
          }
          this.parameter = this.parameter.concat(' AND (' + this.payCatvalue + ')');
        }
        this.other_parameter = this.other_parameter.concat('|' + 'Payroll categories: ' + tempstring);
      }
      if (this.report_sent.REPORT_ID === 38) {
        let tempstring = '';
        //let catType = this.report_sent.DB_PAYROLL_CATEGORY;
        let tem;
        if (tempfrm.payrollcatType == '' || tempfrm.payrollcatType == null) {
          tem = 'All';
          this.parameter = this.parameter.concat(' ');
        } else {
          tem = tempfrm.payrollcatType;
          for (let i = 0; i < tem.length; i++) {
            i != tem.length - 1 ? this.payCatvalue = this.payCatvalue.concat('CAT_TYP_CODE=' + tem[i].c + ' OR ') : this.payCatvalue = this.payCatvalue.concat('CAT_TYP_CODE=' + tem[i].c);
            let tempcat = this.payrollCatTypeList.find(x => x.c == tem[i].c);
            i != tem.length - 1 ? tempstring = tempstring.concat(tempcat.o + ', ') : tempstring = tempstring.concat(tempcat.o);
          }
          this.parameter = this.parameter.concat(' AND (' + this.payCatvalue + ')');
        }
        this.other_parameter = this.other_parameter.concat('|' + 'Payroll category types: ' + tempstring);
      }
      let temp = this.other_parameter.substring(1);
      if (this.parameter == '' && temp == '') {
        this.reprtSrce = { report: this.report_sent.FILE_NAME, token: this.token };
        let r: JSON = JSON.parse(JSON.stringify(this.reprtSrce));
        this.reportSource = r;

        //this.viewer.setReportSource(r);
      } else if (this.parameter == '' && temp !== '' && this.report_sent.REPORT_ID != 32) {
        this.rptSource = {
          report: this.report_sent.FILE_NAME,
          parameters: { parFilter: temp, dateFormat: this.date_format, timeFormat: this.time_format, token: this.token }
        };
        let r: JSON = JSON.parse(JSON.stringify(this.rptSource));
        this.reportSource = r;

        //this.viewer.setReportSource(r);
      } else {
        let r: JSON;
        if (this.report_sent.REPORT_ID == 22) {
          this.statReport = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              timeFormat: this.time_format,
              dateFormat: this.date_format,
              holidayDate: this.holidayDateVal,
              firstDayofWeek: this.firstDayofWeekVal,
              calcMthd: this.calcMthdVal,
              prov: this.provVal,
              pgCode: this.pgCodeVal,
              procYear: this.proc_year, token: this.token
            }
          };
          console.log(this.statReport);
          r = JSON.parse(JSON.stringify(this.statReport));
          let tempWageStr: string = '';
          let tempprovince: number = tempfrm.province_rule.province_id;
          let temprule: string = tempfrm.province_rule.rule;
          let tempstartdate: number = tempfrm.working_week_start.id;
          let tempallemp: number = tempfrm.include_all_emps == true ? 1 : 0;
          for (let i = 0; i < tempfrm.wages.length; i++) {
            if (i == (tempfrm.wages.length - 1)) {
              tempWageStr = tempWageStr.concat(tempfrm.wages[i].a);
            } else {
              tempWageStr = tempWageStr.concat(tempfrm.wages[i].a + ' , ');
            }
          }
          this.report_last_val_update = {
            wages: tempWageStr,
            week_start_date: tempstartdate,
            province: tempprovince,
            rule: temprule,
            all_emp_incl: tempallemp
          };
          this.updateReportLastValues(this.report_last_val_update);
        } else if (this.report_sent.REPORT_ID == 11) {
          this.rprtSrceYTD2 = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              rateFormat: this.rate_format,
              procYear: this.proc_year,
              YTD: this.para_ytd,
              groupBy: this.para_gbd,
              orderBy: this.para_orderBy,
              token: this.token
            }
          };
          console.log(this.rprtSrceYTD2);
          r = JSON.parse(JSON.stringify(this.rprtSrceYTD2));
        } else if (this.report_sent.REPORT_ID == 12 || this.report_sent.REPORT_ID == 41) {
          this.rprtSrce2 = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              procYear: this.proc_year, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrce2));
        } else if (this.report_sent.REPORT_ID == 7 || this.report_sent.REPORT_ID == 9) {
          this.rprtSrce2OrderBy = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              procYear: this.proc_year,
              orderBy: this.para_orderBy, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrce2OrderBy));
        } else if (this.report_sent.REPORT_ID == 4 || this.report_sent.REPORT_ID == 5) {
          this.rprtSrce2WithPaycardFilter = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              procYear: this.proc_year,
              orderBy: this.para_orderBy,
              paycardFilter: this.para_unlocked_paycards, token: this.token
            }
          };
          console.log(this.rprtSrce2WithPaycardFilter);
          r = JSON.parse(JSON.stringify(this.rprtSrce2WithPaycardFilter));
        } else if (this.report_sent.REPORT_ID == 36) {
          this.rprtSrceForEmpNote = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              orderBy: this.para_orderBy,
              blankNoteFilter: this.para_blank_note, token: this.token
            }
          };
          console.log(this.rprtSrceForEmpNote);
          r = JSON.parse(JSON.stringify(this.rprtSrceForEmpNote));
        } else if (this.report_sent.REPORT_ID == 3) {
          this.rprtSrce3WithPaycardFilter = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              rateFormat: this.rate_format,
              procYear: this.proc_year,
              orderBy: this.para_orderBy,
              paycardFilter: this.para_unlocked_paycards, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrce3WithPaycardFilter));
        } else if (this.report_sent.REPORT_ID == 37) {
          if (tempfrm.dimensionmulti == '' || tempfrm.dimensionmulti == null) {
            this.dimension_list = '';
            this.dimension_caption = '';
          } else {
            let tem = tempfrm.dimensionmulti;
            this.dimension_list = '';
            this.dimension_caption = '';
            for (let i = 0; i < tem.length; i++) {
              i != tem.length - 1 ? this.dimension_list = this.dimension_list.concat(tem[i].LABOR_DIM_ID + ',') : this.dimension_list = this.dimension_list.concat(tem[i].LABOR_DIM_ID);
              i != tem.length - 1 ? this.dimension_caption = this.dimension_caption.concat(tem[i].LABOR_DIM_CAPTION + ',') : this.dimension_caption = this.dimension_caption.concat(tem[i].LABOR_DIM_CAPTION);
            }
          }
          this.rprtSrceWithPaycardFilterAndDimensions = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              rateFormat: this.rate_format,
              procYear: this.proc_year,
              orderBy: this.para_orderBy,
              paycardFilter: this.para_unlocked_paycards,
              dimensionList: this.dimension_list,
              dimensionCaption: this.dimension_caption,
              token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrceWithPaycardFilterAndDimensions));
        } else if (this.report_sent.REPORT_ID == 20) {
          this.rprtSrce2WithPFilter = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              procYear: this.proc_year,
              paycardFilter: this.para_unlocked_paycards,
              userDefinedGrossFilter: this.para_user_defined_gross,
              token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrce2WithPFilter));
        } else if (this.report_sent.REPORT_ID == 23) {
          this.rprtSrceEmp = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              paramColumn: this.paramColumn,
              paramColName: this.paramColName, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrceEmp));
        } else if (this.report_sent.REPORT_ID == 28 || this.report_sent.REPORT_ID == 29) {
          this.rprtSrceWithPaycardFilter = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              paycardFilter: this.para_unlocked_paycards, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.rprtSrceWithPaycardFilter));
        } else if (this.report_sent.REPORT_ID == 30) {
          this.stateEarningRprtSrce = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              paycardFilter: this.para_unlocked_paycards,
              selCat1: this.para_selCat1,
              selCat2: this.para_selCat2,
              showEYRContribution: this.para_employer_contributions, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.stateEarningRprtSrce));
        } else if (this.report_sent.REPORT_ID == 31) {
          this.usWcbRprtSrce = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              paycardFilter: this.para_unlocked_paycards,
              WCBCAT: this.para_category,
              procYear: this.proc_year, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.usWcbRprtSrce));
        } else if (this.report_sent.REPORT_ID == 32) {
          this._reportService.GetSessionId().subscribe(
            res => {
              this.sessionId = res;
            },
            err => {
              console.log('error occurred');
            }
          );
          this.unionRprtSrce = {
            report: this.report_sent.FILE_NAME,
            parameters: {
              param: this.parameter,
              parFilter: temp,
              dateFormat: this.date_format,
              timeFormat: this.time_format,
              unitFormat: this.unit_format,
              rateFormat: this.rate_format,
              sessionId: this.sessionId, token: this.token
            }
          };
          r = JSON.parse(JSON.stringify(this.unionRprtSrce));
        } else if (this.report_sent.PAR_ORDER_BY == 1) {
          if (this.report_sent.REPORT_ID == 2 || this.report_sent.REPORT_ID == 13 || this.report_sent.REPORT_ID == 14) {
            this.rprtSrceOrderByPaycardFilter = {
              report: this.report_sent.FILE_NAME,
              parameters: {
                param: this.parameter,
                parFilter: temp,
                dateFormat: this.date_format,
                timeFormat: this.time_format,
                orderBy: this.para_orderBy,
                paycardFilter: this.para_unlocked_paycards, token: this.token
              }
            };
            r = JSON.parse(JSON.stringify(this.rprtSrceOrderByPaycardFilter));
          } else if (this.report_sent.REPORT_ID == 39) {
            let sd = new Date(new Date(tempfrm.frmDate).getTime() + new Date(tempfrm.frmDate).getTimezoneOffset() * 60000);  
            let td = new Date(new Date(tempfrm.toDate).getTime() + new Date(tempfrm.toDate).getTimezoneOffset() * 60000);  
            if (sd.getDate() != 1 || td.getDate() != (new Date(td.getFullYear(), td.getMonth() + 1, 0)).getDate()) {
              temp = temp.concat('|Selected dates do not cover the whole month');
            }
            sd.setDate(1);
            td.setDate(1);
            this.months = sd.getFullYear().toString() + '-' + (sd.getMonth() + 1).toString();
            while (sd < td) {
              sd.setMonth(sd.getMonth() + 1);
              this.months = this.months.concat('|' + sd.getFullYear().toString() + '-' + (sd.getMonth() + 1).toString())
            };
            this.rprtSrceGroupByMonth = {
              report: this.report_sent.FILE_NAME,
              parameters: {
                param: this.parameter,
                parFilter: temp,
                dateFormat: this.date_format,
                timeFormat: this.time_format,
                orderBy: this.para_orderBy,
                months: this.months,
                paycardFilter: this.para_unlocked_paycards,
                isPaydate: this.isPaydate,
                token: this.token
              }
            };
            r = JSON.parse(JSON.stringify(this.rprtSrceGroupByMonth));
          }
          else {
            if (this.report_sent.REPORT_ID == 35) {
              this.rprtSrceForEmpMaster = {
                report: this.report_sent.FILE_NAME,
                parameters: {
                  param: this.parameter,
                  parFilter: temp,
                  dateFormat: this.date_format,
                  timeFormat: this.time_format,
                  unitFormat: this.unit_format,
                  procYear: this.proc_year,
                  orderBy: this.para_orderBy,
                  inclzero: this.para_inclzero,
                  categoryTyp: this.para_cateTyp,
                  token: this.token
                }
              };
              console.log(this.rprtSrceForEmpMaster);
              r = JSON.parse(JSON.stringify(this.rprtSrceForEmpMaster));
            }
            else {
              if (this.report_sent.REPORT_ID == 38) {
                this.rprtSrceForAdjustment = {
                  report: this.report_sent.FILE_NAME,
                  parameters: {
                    param: this.parameter,
                    parFilter: temp,
                    dateFormat: this.date_format,
                    timeFormat: this.time_format,
                    unitFormat: this.unit_format,
                    rateFormat: this.rate_format,
                    procYear: this.proc_year,
                    orderBy: this.para_orderBy,
                    token: this.token
                  }
                };
                console.log(this.rprtSrceForAdjustment);
                r = JSON.parse(JSON.stringify(this.rprtSrceForAdjustment));
              }
              else {
                this.rprtSrceOrderBy = {
                  report: this.report_sent.FILE_NAME,
                  parameters: {
                    param: this.parameter,
                    parFilter: temp,
                    dateFormat: this.date_format,
                    timeFormat: this.time_format,
                    orderBy: this.para_orderBy, token: this.token
                  }
                }
                r = JSON.parse(JSON.stringify(this.rprtSrceOrderBy));
              };
            }
          }
        } else {
          if (this.report_sent.REPORT_ID == 15 || this.report_sent.REPORT_ID == 21) {
            this.rprtSrcePaycardFilter = {
              report: this.report_sent.FILE_NAME,
              parameters: {
                param: this.parameter,
                parFilter: temp,
                dateFormat: this.date_format,
                timeFormat: this.time_format,
                paycardFilter: this.para_unlocked_paycards, token: this.token
              }
            };
            r = JSON.parse(JSON.stringify(this.rprtSrcePaycardFilter));
          } else {
            if (this.report_sent.REPORT_ID == 1) {
              this.rprtSrceScript = {
                report: this.report_sent.FILE_NAME,
                parameters: {
                  param: this.parameter,
                  script: this.para_script,
                  dateFormat: this.date_format,
                  timeFormat: this.time_format,
                  token: this.token
                }
              };
              r = JSON.parse(JSON.stringify(this.rprtSrceScript));
            }
            else {
              if (this.report_sent.REPORT_ID == 33 || this.report_sent.REPORT_ID == 34) {
                console.log('bbbbb');
                this.rprtSrceWithRate = {
                  report: this.report_sent.FILE_NAME,
                  parameters: {
                    param: this.parameter,
                    parFilter: temp,
                    dateFormat: this.date_format,
                    timeFormat: this.time_format,
                    rateFormat: this.rate_format,
                    token: this.token
                  }
                };
                r = JSON.parse(JSON.stringify(this.rprtSrceWithRate));
              } else {
                this.rprtSrce = {
                  report: this.report_sent.FILE_NAME,
                  parameters: {
                    param: this.parameter,
                    parFilter: temp,
                    dateFormat: this.date_format,
                    timeFormat: this.time_format,
                    token: this.token
                  }
                };
                r = JSON.parse(JSON.stringify(this.rprtSrce));
              }
            }
          }
        }
        this.reportSource = r;

      }
    }
    this.opened = true;
  }

  public close() {
    this.opened = false;
  }

  updateReportLastValues(ob: IUpdateReportLastVal): void {
    this._reportService.udateReportLastValues(ob).subscribe(
      res => {
      },
      err => {
        console.log('error occurred');
      }
    );
  }

  onClickBack(): void {
    this.onBack.emit(false);
  }


  onFromDateChange(): void {
    let temp = this.reportForm.get('frmDate').value;
    this.js_string = this.js_string.concat('|' + 'frmDate,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('frmDate');
  }

  updateReportFilter(ctlName: string): void {
    let rf = this.reportFilters ? this.reportFilters.find(d => d.reportId == this.report_sent.REPORT_ID) : null;
    if (rf) {
      let fb = rf.filterControls.find(d => d.name == ctlName);
      if (fb) {
        fb.value = this.reportForm.get(ctlName).value;
      } else {
        let newfb = { name: ctlName, value: this.reportForm.get(ctlName).value };
        rf.filterControls.push(newfb);
      }
    } else {
      rf = { reportId: this.report_sent.REPORT_ID, filterControls: [] };
      let fb = { name: ctlName, value: this.reportForm.get(ctlName).value };
      rf.filterControls.push(fb);
      if (!this.reportFilters) {
        this.reportFilters = [];
      }
      this.reportFilters.push(rf);
    }
    localStorage.setItem('rptFlt' + this.companyCode + this.userId, JSON.stringify(this.reportFilters));
  }

  onFilterByDtChange(event: any): void {
    this.updateReportFilter('filterByDt');
  }

  onToDateChange(): void {
    let temp = this.reportForm.get('toDate').value;
    this.js_string = this.js_string.concat('|' + 'toDate,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('toDate');
  }

  onLocationChange(): void {
    let temp = '';
    if (this.reportForm.get('location').value !== null) {
      temp = this.reportForm.get('location').value.Name;
    }
    if (temp) this.js_string = this.js_string.concat('|' + 'location,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('location');
  }

  onDepartmentChange(): void {
    let temp = '';
    if (this.reportForm.get('department').value !== null) {
      temp = this.reportForm.get('department').value.Name;
    }
    if (temp) this.js_string = this.js_string.concat('|' + 'department,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('department');
  }

  onStateChange(): void {
    let temp = '';
    if (this.reportForm.get('state').value !== null) {
      temp = this.reportForm.get('state').value.Name;
    }
    this.js_string = this.js_string.concat('|' + 'state,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('state');
  }

  onUnionChange(): void {
    let temp = '';
    if (this.reportForm.get('union').value !== null) {
      temp = this.reportForm.get('union').value.Name;
    }
    this.js_string = this.js_string.concat('|' + 'union,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('union');
  }

  onDimensionChange(): void {
    let temp = '';
    if (this.reportForm.get('dimension').value !== null) {
      temp = this.reportForm.get('dimension').value.Name;
    }
    this.js_string = this.js_string.concat('|' + 'dimension,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('dimension');
  }

  onUnionAgreementChange(): void {
    let temp = '';
    if (this.reportForm.get('unionAgreement').value !== null) {
      temp = this.reportForm.get('unionAgreement').value.Name;
    }
    this.js_string = this.js_string.concat('|' + 'unionAgreement,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('unionAgreement');
  }

  onEmpItemDropDownChange(selected: any): void {
    const toPaycatControl = this.reportForm.get('emplyeeItems');
    if (selected.length > 9) {
      selected.pop();
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.canNotSelectMoreThanNineEmployeeItems', {}, 'reportChild');
      showMsg({
        'type': 'nonsticky',
        'status': 'error',
        'title': title,
        'buttons': {},
        'message': msg,
        'delay': '2000' // in Milliseconds
      });
    }
    this.updateReportFilter('emplyeeItems');
  }

  onlocalTaxDropDownChange(selected: any): void {
    const toPaycatControl = this.reportForm.get('localTaxItems');
    if (selected.length > 2) {
      selected.pop();
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.canNotSelectMoreThanTwoLocalTaxes', {}, 'reportChild');
      showMsg({
        'type': 'nonsticky',
        'status': 'error',
        'title': title,
        'buttons': {},
        'message': msg,
        'delay': '2000' // in Milliseconds
      });
    }
    this.updateReportFilter('localTaxItems');
  }

  onWcbCategoryChange(selected: any): void {
    var that = this;
    const wcbCategory = this.reportForm.get('wcbCategory');
    if (selected.length > 1) {
      selected.pop();
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.canNotSelectMoreThanTwoLocalTaxes', {}, 'reportChild');
      var button = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [button]: {
            action: function () {
              that.reportForm.get('wcbCategory').setValue(null);
            }
          }
        },
        'message': msg
      });
    }
    this.updateReportFilter('wcbCategory');
  }

  onMultiEmpDropDownChange(selected: any): void {
    const multiEmployee = this.reportForm.get('multiEmployee');
    let that = this;
    if (selected.length > 1 && (selected[selected.length - 1].ID == -1 || selected[selected.length - 2].ID == -1)) {
      selected.pop();
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.canNotSelectBothIndividualEmployeeAndAllEmployees', {}, 'reportChild');
      var button = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [button]: {
            action: function () {
              that.reportForm.get('multiEmployee').setValue(null);
            }
          }
        },
        'message': msg
      });
    }
    this.updateReportFilter('multiEmployee');
  }

  onGenderChange(): void {
    let temp = '';
    if (this.reportForm.get('gender').value !== null) {
      temp = this.reportForm.get('gender').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'gender,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('gender');
  }

  onIsManagerChange(event: any): void {
    let temp = '';
    if (this.reportForm.get('isManager').value !== null) {
      temp = this.reportForm.get('isManager').value.toString();
    }
    this.js_string = this.js_string.concat('|' + 'isManager,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('isManager');
  }

  onStatusChange(): void {
    let temp = '';
    if (this.reportForm.get('status').value !== null) {
      temp = this.reportForm.get('status').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'status,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('status');
  }

  onEmpTypeChange(): void {
    let temp = '';
    if (this.reportForm.get('empType').value !== null) {
      temp = this.reportForm.get('empType').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'empType,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('empType');
  }

  onOrderByChange(): void {
    let temp = '';
    if (this.reportForm.get('orderBy').value !== null) {
      temp = this.reportForm.get('orderBy').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'orderBy,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('orderBy');
  }

  onCategoryTypeChange(): void {
    let temp = '';
    if (this.reportForm.get('categoryType').value !== null) {
      temp = this.reportForm.get('categoryType').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'categoryType' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('categoryType');
  }

  onJobTitleChange(): void {
    let temp = '';
    if (this.reportForm.get('jobTitle').value !== null) {
      temp = this.reportForm.get('jobTitle').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'jobTitle,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('jobTitle');
  }

  onSupervisorChange(): void {
    let temp = '';
    if (this.reportForm.get('supervisor').value !== null) {
      temp = this.reportForm.get('supervisor').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'supervisor,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('supervisor');
  }

  onAltManagerChange(): void {
    let temp = '';
    if (this.reportForm.get('altManager').value !== null) {
      temp = this.reportForm.get('altManager').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'altManager,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('altManager');
  }

  onCityChange(): void {
    let temp = '';
    if (this.reportForm.get('city').value !== null) {
      temp = this.reportForm.get('city').value.id;
    }
    this.js_string = this.js_string.concat('|' + 'city,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('city');
  }

  onEmployeeChange(): void {
    let temp = this.reportForm.get('employee').value.FirstName + ' ' + this.reportForm.get('employee').value.LasttName;
    this.js_string = this.js_string.concat('|' + 'employee,' + temp);
    this.updateReportFilter('employee');
  }

  onDivisionChange(): void {
    let temp = this.reportForm.get('division').value;
    this.js_string = this.js_string.concat('|' + 'division,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
  }

  onEIGroupChange(): void {
    let temp = this.reportForm.get('eigroup').value?.UDF_COLL_INDEX;
    this.js_string = this.js_string.concat('|' + 'eigroup,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('eigroup');
  }

  onPaycatDetlChange(): void {

  }

  onPayrollrunsChange(): void {
    let temp = this.reportForm.get('payrollrun').value;
    this.js_string = this.js_string.concat('|' + 'payrollrun,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('payrollrun');
  }

  onCalMethodChange(): void {
    let temp = this.reportForm.get('calmethod').value;
    this.js_string = this.js_string.concat('|' + 'calmethod,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('calmethod');
  }

  onPensionGroupChange(): void {
    let temp = this.reportForm.get('pensiongrp').value;
    this.js_string = this.js_string.concat('|' + 'pensiongrp,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('pensiongrp');
  }

  onPayrollCategoryChange(): void {
    let temp = this.reportForm.get('payrollcat').value.CAT_DTL_USER_CODE;
    this.js_string = this.js_string.concat('|' + 'payrollcat,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('payrollcat');
  }

  onPayrollClassChange(): void {
    let temp = this.reportForm.get('payrollclass').value;
    this.js_string = this.js_string.concat('|' + 'payrollclass,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('payrollclass');
  }

  onPayrollTypeChange(): void {
    let temp = this.reportForm.get('payrolltype').value.id;
    this.js_string = this.js_string.concat('|' + 'payrolltype,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('payrolltype');
  }

  onPaymentMethodChange(): void {
    let temp = this.reportForm.get('paymentmethod').value;
    this.js_string = this.js_string.concat('|' + 'paymentmethod,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('paymentmethod');
  }

  onTaxYearChange(): void {
    let temp = this.reportForm.get('taxyear').value;
    this.js_string = this.js_string.concat('|' + 'taxyear,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('taxyear');
  }

  onRCTChange(): void {
    let temp = this.reportForm.get('rctno').value;
    this.js_string = this.js_string.concat('|' + 'rctno,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('rctno');
  }

  onBusinessNumberChange(): void {
    let temp = this.reportForm.get('businessno').value;
    this.js_string = this.js_string.concat('|' + 'businessno,' + temp);
    this.onJSstringUpdate.emit(this.js_string);
    this.updateReportFilter('businessno');
  }

  setValidators(rpt: IReport): void {
    const toDateControl = this.reportForm.get('toDate');
    const fromDateControl = this.reportForm.get('frmDate');
    const holidayDateControl = this.reportForm.get('holidayDate');
    const payrollcategoryControl = this.reportForm.get('payrollcatdetail');
    const payrollgrpControl = this.reportForm.get('payrollgrpStat');
    const wagesControl = this.reportForm.get('wages');
    const working_week_start = this.reportForm.get('working_week_start');
    const province_rule_control = this.reportForm.get('province_rule');
    const employeeItemControl = this.reportForm.get('emplyeeItems');
    const stateItemControl = this.reportForm.get('state');
    const wcbCategoryControl = this.reportForm.get('wcbCategory');
    const dimCategoryControl = this.reportForm.get('dimension');
    const categoryTypeControl = this.reportForm.get('categoryType');

    if (this.wizard_mode) {
      this.reportForm.get('payrollgrp').disable();
    } else {
      this.reportForm.get('payrollgrp').enable();
    }
    if (rpt.PAR_TO_DATE == 1) {
      toDateControl.setValidators([Validators.required]);
    } else {
      toDateControl.clearValidators();
    }
    if (rpt.PAR_PAY_DATE == 1) {
      let today = new Date();
      const validators = [Validators.required]; //, CustomValidators.maxDate(today)
      fromDateControl.setValidators(validators);
    } else {
      fromDateControl.clearValidators();
    }
    if ((rpt.PAR_PAYROLL_CATEGORY == 1 || rpt.PAR_PAYROLL_CATEGORY == 5 || rpt.PAR_PAYROLL_CATEGORY == 2 || rpt.PAR_PAYROLL_CATEGORY == 3) && rpt.REPORT_ID != 24 && rpt.REPORT_ID != 40) {
      payrollcategoryControl.setValidators([Validators.required]);
    } else {
      payrollcategoryControl.clearValidators();
    }
    if (rpt.PAR_HOLIDAY_DATE == 1) {
      holidayDateControl.setValidators([Validators.required]);
    } else {
      holidayDateControl.clearValidators();
    }
    if (rpt.REPORT_ID == 22 && rpt.PAR_PAYROLL_GROUP == 1) {
      payrollgrpControl.setValidators([Validators.required]);
    } else {
      payrollgrpControl.clearValidators();
    }
    if (rpt.REPORT_ID == 22 && rpt.PAR_WAGES == 1) {
      wagesControl.setValidators([Validators.required]);
    } else {
      wagesControl.clearValidators();
    }
    if (rpt.PAR_WORKING_WEEK_START == 1) {
      working_week_start.setValidators([Validators.required]);
    } else {
      working_week_start.clearValidators();
    }
    if (rpt.PAR_PROVINCE_RULE == 1) {
      province_rule_control.setValidators([Validators.required]);
    } else {
      province_rule_control.clearValidators();
    }
    if (rpt.REPORT_ID == 23) {
      employeeItemControl.setValidators([Validators.required]);
    } else {
      employeeItemControl.clearValidators();
    }
    if (rpt.REPORT_ID == 30) {
      stateItemControl.setValidators([Validators.required]);
    } else {
      stateItemControl.clearValidators();
    }
    if (rpt.REPORT_ID == 31) {
      wcbCategoryControl.setValidators([Validators.required]);
    } else {
      wcbCategoryControl.clearValidators();
    }
    if (rpt.REPORT_ID == 33 || rpt.REPORT_ID == 34) {
      dimCategoryControl.setValidators([Validators.required]);
    } else {
      dimCategoryControl.clearValidators();
    }
    if (rpt.REPORT_ID == 35) {
      categoryTypeControl.setValidators([Validators.required]);
    } else {
      categoryTypeControl.clearValidators();
    }

    toDateControl.updateValueAndValidity();
    fromDateControl.updateValueAndValidity();
    payrollcategoryControl.updateValueAndValidity();
    holidayDateControl.updateValueAndValidity();
    payrollgrpControl.updateValueAndValidity();
    wagesControl.updateValueAndValidity();
    working_week_start.updateValueAndValidity();
    province_rule_control.updateValueAndValidity();
    employeeItemControl.updateValueAndValidity();
    stateItemControl.updateValueAndValidity();
    wcbCategoryControl.updateValueAndValidity();
    dimCategoryControl.updateValueAndValidity();
    categoryTypeControl.updateValueAndValidity();
  }

  onClickFormReset(): void {

    this.reportForm.reset();
    this.reportForm.get('filterByDt').setValue('payDate');
  }

  onPayrollcatTypeChange(selected: any): void {
    this.updateReportFilter('payrollcatType');
  }

  onPayCatDropDownChange(selected: any): void {
    const toPaycatControl = this.reportForm.get('payrollcatdetail');
    let that = this;
    if (this.report_sent.PAR_PAYROLL_CATEGORY != null && (this.report_sent.PAR_PAYROLL_CATEGORY == 1 || this.report_sent.PAR_PAYROLL_CATEGORY == 2 || this.report_sent.PAR_PAYROLL_CATEGORY == 3)) {
      if (selected.length > 1) {
        var title = this.transloco.translate('error');
        var msg = this.transloco.translate('alerts.canNotSelectMoreThanOnePayrollCategory', {}, 'reportChild');
        var button = this.transloco.translate('ok');
        selected.pop();
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [button]: {
              action: function () {
                that.reportForm.get('payrollcatdetail').setValue(null);
              }
            }
          },
          'message': msg
        });
      }
    } else if (this.report_sent.PAR_PAYROLL_CATEGORY != null && this.report_sent.PAR_PAYROLL_CATEGORY == 5) {
      if (selected.length > 5) {
        selected.pop();
        var title = this.transloco.translate('error');
        var msg = this.transloco.translate('alerts.canNotSelectMoreThanFivePayrollCategories', {}, 'reportChild');
        var button = this.transloco.translate('ok');
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [button]: {
              action: function () {
                that.reportForm.get('payrollcatdetail').setValue(null);
              }
            }
          },
          'message': msg
        });
      }
    }
    this.updateReportFilter('payrollcatdetail');
  }

  onDimensionMultiDropDownChange(selected: any): void {
    const toPaycatControl = this.reportForm.get('dimensionmulti');
    let that = this;
    if (selected.length > 3) {
      selected.pop();
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.canNotSelectMoreThanThreeDimensions', {}, 'reportChild');
      var button = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [button]: {
            action: function () {
              that.reportForm.get('dimensionmulti').setValue(null);
            }
          }
        },
        'message': msg
      });
    }
    this.updateReportFilter('dimensionmulti');
  }

  ngOnDestroy() {
    if (this.getFromEmailAddressService) {
      this.getFromEmailAddressService.unsubscribe();
    }
    if (this.formcontrolSubscription) {
      this.formcontrolSubscription.unsubscribe();
    }
  }

  test(test: any) {
  }

  ngAfterViewInit(): void {
  }

}
