<ng-container *transloco="let translate; read: 'reportParent'">
  <div class="row mb-3" *ngIf="!_wizardService.onWizardMode">
    <div class="col-12">
      <h4>{{translate('reports')}}</h4>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6">
          <div class="row mb-3">
            <div class="col-12">
              <div class="btn-group me-3">
                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown"
                     *ngFor="let category of filteredCategories; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    ngbDropdownToggle
                    (click)="getFilteredReportList(category.REP_CAT_ID)"
                    [ngClass]="{'active' : category.REP_CAT_ID == active_tab}"
                  >
                    <i [ngClass]="getIcon(category.REP_CAT_CSS_CLASS)"></i>&nbsp;&nbsp;{{ category.CATEGORY_NAME }}
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu" ngbDropdownMenu *ngIf="category.REP_CAT_ID !== 1">
                    <ng-container *ngFor="let subcategory of categories; let k = index">
                      <button
                        ngbDropdownItem
                        *ngIf="category.REP_CAT_ID === subcategory.REP_CAT_PARENT_ID"
                        (click)="getFilteredReportList(subcategory.REP_CAT_ID, category.REP_CAT_ID)"
                        [disabled]="wizard_mode && subcategory.REP_CAT_ID != 8"
                      >{{subcategory.CATEGORY_NAME}}
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group overflow-y-auto" *ngIf="current_reports; else othercontent" style="height: 65vh">
            <div class="list-group-item list-group-item-action cursor-pointer"
                 [ngClass]="{'active-item': (selectedIndex == j)}"
                 *ngFor="let item of current_reports; let j = index" (click)="selectedItem(j)">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-2">
                      <div class="list-icon"><i class="fas" [ngClass]="{'fa-table' : item.TYPE === 1, 'fa-chart-bar' : item.TYPE === 2}"></i></div>
                    </div>
                    <div class="col-7 d-flex flex-column justify-content-center">
                      <p class="mb-0">{{item.REPT_NAME}}</p>
                      <p class="mb-0"><small>{{item.REPT_DESC}}</small></p>
                    </div>
                    <div class="col-3 d-flex justify-content-end align-items-center">
                      <button class="btn btn-outline-light btn-sm me-2 mb-2" (click)="onFavouriteClick(item)">
                        <i [ngClass]="item.ID_USER !== null || item.fav_flag == true ? 'fas fa-star text-warning' : 'far fa-star'"></i>
                      </button>
                      <button class="btn btn-outline-secondary btn-sm me-2 mb-2" (click)="onMoreIconClick(item)"><i class="fas fa-ellipsis-v"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #othercontent>
            {{translate('thereAreNoReportsToDisplayUnderThisCategory')}}
          </ng-template>
        </div>
        <div class="col-sm-6">
          <app-report-child [reportval_from_parent]="report_last_val" [wizard_mode_from_parent]="wizard_mode" [cur_wizard_pg_frm_parent]="cur_payroll_grp"
                            [report_fromparent]="selected_report" [payrollcat_from_parent]="payrollCatList" [eigroup_from_parent]="EIgrouplist"
                            [report_para_details]="reportParameterDetailsParent" [filter_fromparent]="filter_visible" [parent_viewerSendEmail]="viewerSendMail"
                            (onBack)="getBack($event)" (onJSstringUpdate)="getJSstringUpdate($event)" #childcom></app-report-child>
        </div>
      </div>
    </div>
  </div>
</ng-container>
