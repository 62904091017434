import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
  selector: 'app-validation-error',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorMessageComponent {

  _text: string;
  _hide = true;

  @Input() set text(value) {
    if (value !== this._text) {
      this._text = value;
      this._hide = !value;
      this.cdr.detectChanges();
    } else {
      this._text = null;
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

}
