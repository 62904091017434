import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {LicenseManager} from 'ag-grid-enterprise';

import {AppModule} from './app.module';
import {environment} from './environments/environment';


/***...ag-grid license key...**/
LicenseManager.setLicenseKey('CompanyName=Paymate Software Corporation,LicensedApplication=Harmony,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-031632,SupportServicesEnd=9_November_2022_[v2]_MTY2Nzk1MjAwMDAwMA==05ace0229a79e2a1d9e26881f1b22299');

/***...ag-grid license key ends...**/

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
