import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  constructor(
    private ngxSpinner: NgxSpinnerService
  ) {
  }

  startSpinner(options?: {isFullScreen?: boolean, spinnerName?: string}) {
    this.ngxSpinner.show(
  options.spinnerName || 'pageSpinner',
      {
        fullScreen: options.isFullScreen || false,
        type: 'ball-pulse',
        size: 'large',
        bdColor: 'rgba(100,149,237, 0.8)',
        color: 'white'
      }
    );
  }

  stopSpinner(name?: string) {
    this.ngxSpinner.hide( name || 'pageSpinner');
  }
}
