import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-search-navigation',
  templateUrl: './search-navigation.component.html',
  styleUrls: ['./search-navigation.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => SearchNavigationComponent)}]
})
export class SearchNavigationComponent implements ControlValueAccessor {
  searchValue: any;

  @Input() placeholder: string = '';
  @Input() disableBack: boolean = false;
  @Input() disableSearchButton: boolean = false;
  @Input() disableNext: boolean = false;

  @Output() redirectUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() next: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() back: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();


  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  constructor() {
  }

  redirectTo(value: any) {
    this.redirectUser.emit(value);
  }

  onBack($event: MouseEvent) {
    this.back.emit($event);
  }

  onNext($event: MouseEvent) {
    this.next.emit($event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.searchValue = obj;
  }
}
