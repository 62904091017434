import {Directive, ElementRef, HostListener} from '@angular/core';

declare var $: any;

@Directive({
  selector: '[appModalClose], [data-dismiss="modal"]'
})
export class ModalCloseDirective {

  private modal: any;

  @HostListener('click')
  onClick(): void {
    // $(this.modal).modal('hide');
  }

  constructor(private elRef: ElementRef) {
    const modalList = $(this.elRef.nativeElement).closest('.modal');
    this.modal = modalList?.length ? modalList[0] : null;
  }
}
