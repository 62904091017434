import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'datatype'
})
export class DatatypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 1: {
        //statements;
        return 'number'
      }
      case 2: {
        //statements;
        return 'string'
      }
      case 3: {
        return 'boolean'
      }

      case 4: {
        return 'Datetime'
      }

      case 5: {
        return 'Unicode'
      }

      case 6: {
        return 'Collection'
      }

    }
  }

}
