<ng-container *transloco="let translate; read: 'switchCompany'">
  <div class="modal-header">
    <h4 class="modal-title">{{translate('switchCompany')}}</h4>
    <button class="btn-close" (click)="bsModalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="card card bg-primary bg-opacity-10 mb-3">
      <div class="card-body p-2">
        <div class="d-flex align-items-center">
          <img class="avatar-md bg-white border rounded-3 me-1" src="assets/building.svg" alt="company logo or placeholder">
          <div class="flex-grow-1 px-1">
            <div class="row">
              <div class="col-12">
                <p class="fs-4 mb-0">{{user.selectedCompany?.Name}}</p>
              </div>
              <div class="col-12">
                <span class="badge bg-success font-11 me-1">{{translate('loggedIn')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="companyList?.length > 5">
      <div class="col-12 mb-2">
        <app-search-box (search)="search = $event"></app-search-box>
      </div>
    </div>
    <ngx-simplebar
      style="position: relative; max-height: 40vh;"
      class="border rounded list-group mb-2"
    >
      <ng-container *ngFor="let company of (companyList | searchfilter: search); let last=last">
        <button
          type="button"
          class="list-group-item list-group-item-action p-2 border-0"
          [ngClass]="{ 'active': company.selected, 'border-bottom': !last }"
          (click)="onSelect(company)"
          (dblclick)="onDoubleClick(company)"
        >
          <div class="d-flex align-items-center">
            <img class="avatar-sm bg-white border rounded-3 me-1" src="assets/building.svg" alt="company logo or placeholder">
            <div class="row px-1">
              <div class="col-12">
                <p class="fs-5 mb-0">{{company.Name}}</p>
              </div>
              <div class="col-12">
                <span class="badge bg-success me-1" *ngIf="company.CompanyId === user.selectedCompany.companyId">{{translate('loggedIn')}}</span>
              </div>
            </div>
          </div>
        </button>
      </ng-container>
    </ngx-simplebar>
    <div class="row g-3">
      <label for="payrollYear" class="col-form-label col-sm-auto">{{translate('payrollYear')}}</label>
      <div class="col-sm-6 d-flex align-items-center">
        <input
          id="payrollYear"
          class="form-control form-control-sm"
          [(ngModel)]="payrollYear"
          type="number"
          name="payrollYear"
          max="2100"
          min="1980"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer" [ngClass]="{'justify-content-between': user?.canCreateCompany}">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onCreateCompany()"
      *ngIf="user?.canCreateCompany"
    >
      {{translate('createCompany')}}
    </button>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="loading || !selectedCompany || (selectedCompany?.CompanyId === user?.selectedCompany.companyId && currentPayrollYear ===
      payrollYear)"
      (click)="onSwitch(selectedCompany)"
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>&nbsp;{{translate('switch')}}
    </button>
  </div>

  <ng-template #otpModal>
    <div class="modal-header">
      <h4 class="modal-title">{{translate('otpModalTitle')}}</h4>
      <button class="btn-close" (click)="otpModalRef?.hide()"></button>
    </div>
    <div class="modal-body">
      <app-two-fa-verify (verify)="onVerifyOtp($event)" [hideLogout]="true" [hideTitle]="true"></app-two-fa-verify>
    </div>
  </ng-template>
</ng-container>
