import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ICoreEmailSetting, ICoreEmailTemplate, ISendTestMail, IUpdateCoreEmailSeting, EmailLog} from 'src/app/shared/models/iemail';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  GetEmailSettings(): Observable<ICoreEmailSetting> {
    return this.http.get<ICoreEmailSetting>(API_END_POINT.core.email + "GetEmailSettings");
  }

  UpdateEmailSettings(ob: IUpdateCoreEmailSeting): Observable<any> {
    return this.http.post<number>(API_END_POINT.core.email + "UpdateEmailSettings", ob);
  }

  GetEmailTemplates(): Observable<ICoreEmailTemplate[]> {
    return this.http.get<ICoreEmailTemplate[]>(API_END_POINT.core.email + "GetEmailTemplates");
  }

  UpdateEmailTemplate(ob: ICoreEmailTemplate): Observable<any> {
    return this.http.post<number>(API_END_POINT.core.email + "UpdateEmailTemplate", ob);
  }

  DeleteTemplate(idlist: number[]): Observable<any> {
    return this.http.post<void>(API_END_POINT.core.email + "DeleteTemplate", idlist);
  }

  SendTestEmail(ob: ISendTestMail): Observable<any> {
    return this.http.post<string>(API_END_POINT.core.email + "SendTestEmail", ob);
  }

  checkEmailSettingsEnabled(): Observable<boolean> {
    return this.http.get<boolean>(API_END_POINT.core.email + 'Enabled');
  }

  getAllUnSentEmails() {
    return this.http.get<EmailLog[]>(API_END_POINT.core.email + 'GetAllUnSent');
  }

  reSendEmails(ids: number[]) {
    return this.http.post<void>(API_END_POINT.core.email + 'ReSend', {ids});
  }

  discardEmails(ids: number[]) {
    return this.http.post<void>(API_END_POINT.core.email + 'Discard', {ids});
  }

}
