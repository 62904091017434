import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface EnvConfig {
  baseApiUrl: string;
  tnaUrl: string;
  glBackEndApiUrl: string;
  documentationUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  private readonly http: HttpClient;
  private _envConfig: EnvConfig;

  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  get envConfig(): EnvConfig {
    return this._envConfig;
  }

  get baseApiUrl(): string {
    return this._envConfig?.baseApiUrl + '/api/';
  }

  get glApiUrl(): string {
    return this._envConfig?.glBackEndApiUrl + '/api/';
  }

  get tnaApiUrl(): string {
    return this._envConfig?.tnaUrl + '/api/';
  }

  get glReportUrl(): string {
    return this.baseApiUrl + 'ReportImportViewer';
  }

  get signalRHubUrl(): string {
    return this._envConfig.baseApiUrl;
  }

  get documentationUrl(): string {
    return this._envConfig?.documentationUrl;
  }

  getConfig(): Observable<EnvConfig> {
    return this.http.get<EnvConfig>('/assets/env.json').pipe(tap(config => {
      this._envConfig = config;
    }));
  }
}
