<div class="modal-body p-0">
  <alert type="warning" class="news-alert">
    <h4>{{news?.title}}</h4>
    <p>
      {{news?.description}}
    </p>
    <div class="row justify-content-center">
      <div class="col-4">
        <button class="btn btn-secondary w-100" transloco="gotIt" (click)="bsModalRef.hide()"></button>
      </div>
    </div>
  </alert>
</div>
