import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {
  EIGroup,
  ICategory,
  IDeleteFavReport,
  ImportedReport,
  IPayrollCat,
  IReport,
  IReportLastVal,
  IUpdateReportFav,
  IUpdateReportLastVal,
  ReportTableParameters
} from 'src/app/shared/models/ireport';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';

@Injectable({
  providedIn: 'root'
})
export class ReportServiceService {

  private _url: string = API_END_POINT.payroll.reportnavigator + "GetReportDetailData";

  constructor(private http: HttpClient) { }
  //public topFiltervalue: string = "core";
  ngOnInit() {

  }
  getReports(): Observable<IReport[]> {
    return this.http.get<IReport[]>(this._url);
  }
  getReportsForCore(): Observable<IReport[]> {
    return this.http.get<IReport[]>(API_END_POINT.payroll.reportnavigator + "GetReportDetailDataForCore");
  }

  //getFavouriteReports(): Observable<IFavReport[]> {

  //  return this.http.get<IFavReport[]>("../api/reportnavigator/GetFavouriteReports");
  //}

  getCategoryDetails(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(API_END_POINT.payroll.reportnavigator + "GetCategorytDetailData");
  }
  getCategoryDetailsForCore(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(API_END_POINT.payroll.reportnavigator + "GetCategorytDetailDataForCore");
  }

  getReportParameterDetails(): Observable<ReportTableParameters> {
    return this.http.get<ReportTableParameters>(API_END_POINT.payroll.reportnavigator + "GetSideReportParameters");
  }

  getReportParameterDetailsForCore(): Observable<ReportTableParameters> {
    return this.http.get<ReportTableParameters>(API_END_POINT.payroll.reportnavigator + "GetSideReportParametersForCore");
  }

  getEIGroups(): Observable<EIGroup[]> {
    return this.http.get<EIGroup[]>(API_END_POINT.payroll.reportnavigator + "GetEIGroups");
  }

  getEIGroupsForCore(): Observable<EIGroup[]> {
    return this.http.get<EIGroup[]>(API_END_POINT.payroll.reportnavigator + "GetEIGroupsForCore");
  }

  getPayrollCategories(): Observable<IPayrollCat[]> {
    return this.http.get<IPayrollCat[]>(API_END_POINT.payroll.reportnavigator + "GePayrollcategoryDetails");
  }

  getPayrollCategoriesForCore(): Observable<IPayrollCat[]> {
    return this.http.get<IPayrollCat[]>(API_END_POINT.payroll.reportnavigator + "GePayrollcategoryDetailsForCore");
  }

  requestdatafrommultipleservices(): Observable<any[]> {

    let response1 = this.getCategoryDetails();
    let response2 = this.getReports();

    return forkJoin([response1, response2]);
  }

  requestdatafrommultipleservicesForCore(): Observable<any[]> {

    let response1 = this.getCategoryDetailsForCore();
    let response2 = this.getReportsForCore();

    return forkJoin([response1, response2]);
  }


  updateReportFavourite(ob: IUpdateReportFav): Observable<number> {

    return this.http.post<number>(API_END_POINT.payroll.reportnavigator + "UpdateReportFavourite", ob);
  }

  deleteFavouriteReport(ob: IDeleteFavReport): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.reportnavigator + "DeleteFavouriteReport", ob);
  }

  getFromEmailAddress(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.reportviewer + "GetFromEmail");
  }
  getFromEmailAddressForPayroll(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.reportviewer + "GetFromEmailForPayroll");
  }
  getFromEmailAddressForCore(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.reportviewer + "GetFromEmailForCore");
  }

  udateReportLastValues(ob: IUpdateReportLastVal): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.reportnavigator + "UdateReportLastValues", ob);
  }

  getreportLastvalues(): Observable<IReportLastVal> {
    return this.http.get<IReportLastVal>(API_END_POINT.payroll.reportnavigator + "GetreportLastvalues");
  }

  AddReport(file: File, report_type: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('report_type', report_type.toString());
    return this.http.post<any>(API_END_POINT.payroll.reportnavigator + "AddReport", formData);
  }

  DeleteCustomReport(id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.reportnavigator + "DeleteCustomReport?id=" + id);
  }

  GetImportedReportList(): Observable<ImportedReport[]> {
    return this.http.get<ImportedReport[]>(API_END_POINT.payroll.reportnavigator + "GetImportedReportList");
  }

  GetImportedReportListForImport(): Observable<ImportedReport[]> {
    return this.http.get<ImportedReport[]>(API_END_POINT.payroll.reportnavigator + "GetImportedReportListForImport");
  }

  GetImportedReportListForCustomRpt(): Observable<ImportedReport[]> {
    return this.http.get<ImportedReport[]>(API_END_POINT.payroll.reportnavigator + "GetImportedReportListForCustomRpt");
  }

  GetSessionId(): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.reportnavigator + "GetSessionId");
  }
}
