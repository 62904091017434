import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive} from '@angular/core';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[passwordValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true}]
})
export class PasswordValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const valid = validatePassword(control);
    return valid ? {password: valid} : null;
  }

  registerOnValidatorChange(fn: () => void): void {
  }
}

export function validatePassword(control: AbstractControl) {
  /** t(
   * validations.password.haveLowercase,
   * validations.password.haveUppercase,
   * validations.password.haveNumber,
   * validations.password.specialCharacter,
   * validations.password.minimum8Characters
   * )
   */

  const value: string = control?.value;
  if (value) {
    const checks: { condition: RegExp, message: string }[] = [
      {condition: (/[a-z]/), message: 'password.haveLowercase'},
      {condition: (/[A-Z]/), message: 'password.haveUppercase'},
      {condition: (/[0-9]/), message: 'password.haveNumber'},
      {condition: (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/), message: 'password.specialCharacter'}
    ];

    const errors: string[] = [];
    if (value?.length < 8) {
      errors.push('password.minimum8Characters');
    }
    checks.forEach((check) => {
      if (!check.condition.test(value)) {
        return errors.push(check.message);
      }
    });
    return errors?.length ? {password: errors} : null;
  }
  return null;
}
