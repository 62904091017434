import {HttpClient} from '@angular/common/http/';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {API_END_POINT} from '../utils/api-end-points';


@Injectable()
export class CompanyService {
  // private headers = new HttpHeaders({'content-type': 'application/json', 'Accept': 'application/json'});

  constructor(private http: HttpClient) {
  }

  addCompany(company) {
    return this.http.post<any>(API_END_POINT.account.account + 'addnewcompany', company)
      .pipe(map(response => {
        return response;
      }));
    //`${this.baseUrl}/api/account/addnewcompany
  }

  deleteCompany(company) {
    return this.http.post<any>(API_END_POINT.account.account + `deleteCompany`, company)
      .pipe(map(response => {
        return response;
      }));
  }

}
