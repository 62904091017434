import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {ReportServiceService} from '@harmony-modules/payroll/services/report/report-service.service';
import {
  EIGroup,
  ICategory,
  IDeleteFavReport,
  IPayrollCat,
  IReport,
  IReportLastVal,
  IUpdateReportFav,
  PayrollGroup,
  ReportTableParameters
} from '@shared/models/ireport';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GlobalService} from '@services/global.service';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {concatMap, tap} from 'rxjs/operators';
import {AccessControlService} from '../../../../../../../account/access-control/services/access-control.service';
import {SecurityKeys} from '@shared/models/ISecurity';
import {PermissionsList} from '../../../../../../../account/access-control/access-permission.model';


declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-report-parent',
  templateUrl: './report-parent.component.html',
  styleUrls: ['./report-parent.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: {scope: 'reportParent', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
  }]
})
export class ReportParentComponent implements OnInit, OnDestroy {

  categories: ICategory[] = [];
  filteredCategories: ICategory[] = [];
  reports: IReport[] = [];
  current_reports: IReport[] = [];
  selected_report: IReport = {
    REPORT_ID: null,
    FILE_NAME: null,
    TYPE: null,
    IS_ACTIVE_FLG: null,
    PAR_PAY_DATE: null,
    PAR_TO_DATE: null,
    PAR_LOCATION_ID: null,
    PAR_DEPARTMENT_ID: null,
    PAR_EMPLOYEE_ID: null,
    DB_PAY_DATE: null,
    DB_TO_DATE: null,
    DB_LOCATION_ID: null,
    DB_DEPARTMENT_ID: null,
    DB_EMPLOYEE_ID: null,
    PAR_DIVISION: null,
    DB_DIVISION: null,
    PAR_PAYROLL_GROUP: null,
    DB_PAYROLL_GROUP: null,
    PAR_EI_GROUP: null,
    DB_EI_GROUP: null,
    PAR_PAYROLL_RUNS: null,
    DB_PAYROLL_RUNS: null,
    PAR_CALCULATION_METHOD: null,
    DB_CALCULATION_METHOD: null,
    PAR_PENSION_GROUP: null,
    DB_PENSION_GROUP: null,
    PAR_PAYROLL_KIND: null,
    DB_PAYROLL_KIND: null,
    PAR_PAYROLL_TYPE: null,
    DB_PAYROLL_TYPE: null,
    PAR_PAYROLL_CLASS_TYPE: null,
    DB_PAYROLL_CLASS_TYPE: null,
    PAR_PAYMENT_METHOD: null,
    DB_PAYMENT_METHOD: null,
    PAR_TAXATION_YEAR: null,
    DB_TAXATION_YEAR: null,
    PAR_RCT_NUMBER: null,
    DB_RCT_NUMBER: null,
    PAR_BUSINESS_NUMBER: null,
    DB_BUSINESS_NUMBER: null,
    PAR_PAYROLL_CATEGORY: null,
    DB_PAYROLL_CATEGORY: null,
    REPT_NAME: null,
    REPT_DESC: null,
    REP_CAT_ID: null,
    CATEGORY_NAME: null,
    ID_USER: null,
    fav_flag: false,
    REP_FV_PARA: null,
    PAR_HOLIDAY_DATE: null,
    DB_HOLIDAY_DATE: null,
    PAR_WAGES: null,
    DB_WAGES: null,
    PAR_WORKING_WEEK_START: null,
    DB_WORKING_WEEK_START: null,
    PAR_PROVINCE_RULE: null,
    DB_PROVINCE_RULR: null,
    PAR_INCLUDE_ALL_EMPS: null,
    DB_INCLUDE_ALL_EMPS: null,
    DB_PROVINCE: null,
    PAR_EMPLOYEE_STATUS: null,
    DB_EMPLOYEE_STATUS: null,
    PAR_ORDER_BY: null,
    DB_ORDER_BY_EMP_NUM: null,
    DB_ORDER_BY_EMP_NAME: null,
    DB_ORDER_BY_DEPARTMENT: null,
    COUNTRY: null,
    token: null
  };

  active_tab: number;
  reportParameterDetailsParent: ReportTableParameters = {
    departmentslist: [{
      ID: null,
      ParentID: null,
      Name: null,
      Description: null
    }],
    employeeslist: [{
      ID: null,
      FirstName: null,
      LastName: null,
      IsManager: null,
      Name: null
    }],
    locationslist: [{
      ID: null,
      ParentID: null,
      Name: null,
      Description: null
    }],
    payrollGroupList: [{
      PRLG_CODE: null,
      PRLG_USER_CODE: null
    }],
    payrollcategoryList: [{
      CAT_TYP_CODE: null,
      CAT_TYP_NAME: null
    }]// ,
    // jobList: [{
    //  ID: null,
    //  Name: null
    // }]
  };

  EIgrouplist: EIGroup[] = [{
    UDF_COLL_TEXT: null,
    UDF_COLL_INDEX: null,
    UDF_COLL_VALUE: null
  }];

  payrollCatList: IPayrollCat[] = [];
  cur_payroll_grp: PayrollGroup = {PRLG_CODE: null, PRLG_USER_CODE: null};

  filter_visible = false;
  jsString = '';
  updateFav: IUpdateReportFav = {a: null, b: null};
  report_to_delete: IDeleteFavReport = {report_id: null};
  selectedIndex = -1;
  fromEmail = '';
  wizard_mode = false;

  viewerSendMail = {enabled: true, from: '', to: '', cc: '', subject: '', body: '', format: ''};

  getCategoryDetailsService: Subscription;
  getReportsService: Subscription;
  multipleService: Subscription;
  getReportParameterDetailsService: Subscription;
  updateReportFavouriteService: Subscription;
  deleteFavouriteReportService: Subscription;
  GetEIGroupsService: Subscription;
  getPayrollCategoriesService: Subscription;
  getFromEmailAddressService: Subscription;
  componentDestroyed$: Subject<boolean> = new Subject();
  cur_wizard_pg: number = null;

  report_last_val: IReportLastVal = {a: null, b: null, c: null, d: null, e: null};
  module: string;
  sysPref: any;

  constructor(
    private _reportService: ReportServiceService,
    public _wizardService: WizardService,
    private _globalService: GlobalService,
    private _sysPrefService: SystemPreferenceService,
    private _route: ActivatedRoute,
    private accessControlService: AccessControlService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.module = this._route.snapshot.params['module'];
    if (this.module === 'Configuration') {
      const ob = this._reportService.requestdatafrommultipleservicesForCore();
      const ob1 = this._reportService.getReportParameterDetailsForCore();
      const ob2 = this._reportService.getEIGroupsForCore();
      const ob3 = this._reportService.getPayrollCategoriesForCore();
      const ob4 = this._reportService.getFromEmailAddressForCore();
      const ob5 = this._sysPrefService.getinitSystemPreferences();

      const apiCalls = forkJoin([ob, ob1, ob2, ob3, ob4, ob5]);

      this._route.params.pipe(
        tap(() => {
            const permissions = this.accessControlService
              .getAccessPermissions('core', SecurityKeys.coreModule.employees.employeeReportsMenu) as PermissionsList;
            if (!permissions.view) {
              this.router.navigateByUrl(this.accessControlService.routeToAccessDenied());
            }
        }),
        concatMap(() => apiCalls)
      ).subscribe(([ob, ob1, ob2, ob3, ob4, ob5]) => {
        this.categories = ob[0];
        this.reports = ob[1];
        this.sysPref = ob5[0];
          this.filteredCategories = this.categories.filter(x => (x.REP_CAT_ID == 1 || x.REP_CAT_ID == 2));
        this.reportParameterDetailsParent = ob1;
        this.EIgrouplist = ob2;
        this.payrollCatList = ob3;
        this.fromEmail = ob4;
        // console.log(this.fromEmail);
        if (this.fromEmail) {
          this.viewerSendMail = { enabled: true, from: this.fromEmail, to: '', cc: '', subject: '', body: '', format: '' };
        }
        this._wizardService.wizardMode$.takeUntil(this.componentDestroyed$).subscribe(data => {
          this.wizard_mode = data;
          if (data) {
            if (this.filteredCategories !== null && this.reports !== null) {
              this.getFilteredReportList(8);
              this.active_tab = 8;
            }
            this._wizardService.currentGroupInfo$.pipe(untilDestroyed(this)).subscribe(data => {
              if (data) {
                this.cur_wizard_pg = data.gc;
                this.cur_payroll_grp = this.reportParameterDetailsParent.payrollGroupList.filter(x => (x.PRLG_CODE == this.cur_wizard_pg))[0];
              }
            });
          } else {
            if (this.filteredCategories !== null && this.reports !== null) {
                this.getFilteredReportList(2);
                this.active_tab = 2;
            }
          }
        });
      });
    } else if (this.module === 'Payroll') {
      const ob = this._reportService.requestdatafrommultipleservices();
      const ob1 = this._reportService.getReportParameterDetails();
      const ob2 = this._reportService.getEIGroups();
      const ob3 = this._reportService.getPayrollCategories();
      const ob4 = this._reportService.getFromEmailAddressForPayroll();
      const ob5 = this._sysPrefService.getinitSystemPreferences();

      const apiCalls = forkJoin([ob, ob1, ob2, ob3, ob4, ob5]);

      this._route.params.pipe(
        tap(({ module }) => this.module = module),
        tap(() => {
            const permissions = this.accessControlService
              .getAccessPermissions('payroll', SecurityKeys.payrollReportsMain.payrollReports) as PermissionsList;
            if (!permissions.view) {
              this.router.navigateByUrl(this.accessControlService.routeToAccessDenied());
            }
        }),
        concatMap(() => apiCalls)
      ).subscribe(([ob, ob1, ob2, ob3, ob4, ob5]) => {
        this.categories = ob[0];
        this.reports = ob[1];
        this.sysPref = ob5[0];
          if (this.sysPref.l == 1 && this.sysPref.u == 1) { // add labor and union button
            this.filteredCategories = this.categories.filter(x => (x.REP_CAT_ID == 1 || x.REP_CAT_ID == 4 || x.REP_CAT_ID == 10 || x.REP_CAT_ID == 13));
          } else if (this.sysPref.l == 1 && this.sysPref.u != 1) { // add labor  button
            this.filteredCategories = this.categories.filter(x => (x.REP_CAT_ID == 1 || x.REP_CAT_ID == 4 || x.REP_CAT_ID == 10));
          } else if (this.sysPref.l != 1 && this.sysPref.u == 1) { // add union button
            this.filteredCategories = this.categories.filter(x => (x.REP_CAT_ID == 1 || x.REP_CAT_ID == 4 || x.REP_CAT_ID == 13));
          } else {
            this.filteredCategories = this.categories.filter(x => (x.REP_CAT_ID == 1 || x.REP_CAT_ID == 4));
          }
        this.reportParameterDetailsParent = ob1;
        this.EIgrouplist = ob2;
        this.payrollCatList = ob3;
        this.fromEmail = ob4;
        // console.log(this.fromEmail);
        if (this.fromEmail) {
          this.viewerSendMail = { enabled: true, from: this.fromEmail, to: '', cc: '', subject: '', body: '', format: '' };
        }
        this._wizardService.wizardMode$.takeUntil(this.componentDestroyed$).subscribe(data => {
          this.wizard_mode = data;
          if (data) {
            if (this.filteredCategories !== null && this.reports !== null) {
              this.getFilteredReportList(8);
              this.active_tab = 8;
            }
            this._wizardService.currentGroupInfo$.pipe(untilDestroyed(this)).subscribe(data => {
              if (data) {
                this.cur_wizard_pg = data.gc;
                this.cur_payroll_grp = this.reportParameterDetailsParent.payrollGroupList.filter(x => (x.PRLG_CODE == this.cur_wizard_pg))[0];
              }
            });
          } else {
            if (this.filteredCategories !== null && this.reports !== null) {
                this.getFilteredReportList(9);
                this.active_tab = 9;
            }
          }
        });
      });
    }

  }


  getFilteredReportList(value: any, parentCat?: any): void {
    this.selectedIndex = -1;
    this.selected_report = {
    REPORT_ID: null,
    FILE_NAME: null,
    TYPE: null,
    IS_ACTIVE_FLG: null,
    PAR_PAY_DATE: null,
    PAR_TO_DATE: null,
    PAR_LOCATION_ID: null,
    PAR_DEPARTMENT_ID: null,
    PAR_EMPLOYEE_ID: null,
    DB_PAY_DATE: null,
    DB_TO_DATE: null,
    DB_LOCATION_ID: null,
    DB_DEPARTMENT_ID: null,
    DB_EMPLOYEE_ID: null,
    PAR_DIVISION: null,
    DB_DIVISION: null,
    PAR_PAYROLL_GROUP: null,
    DB_PAYROLL_GROUP: null,
    PAR_EI_GROUP: null,
    DB_EI_GROUP: null,
    PAR_PAYROLL_RUNS: null,
    DB_PAYROLL_RUNS: null,
    PAR_CALCULATION_METHOD: null,
    DB_CALCULATION_METHOD: null,
    PAR_PENSION_GROUP: null,
    DB_PENSION_GROUP: null,
    PAR_PAYROLL_KIND: null,
    DB_PAYROLL_KIND: null,
    PAR_PAYROLL_TYPE: null,
    DB_PAYROLL_TYPE: null,
    PAR_PAYROLL_CLASS_TYPE: null,
    DB_PAYROLL_CLASS_TYPE: null,
    PAR_PAYMENT_METHOD: null,
    DB_PAYMENT_METHOD: null,
    PAR_TAXATION_YEAR: null,
    DB_TAXATION_YEAR: null,
    PAR_RCT_NUMBER: null,
    DB_RCT_NUMBER: null,
    PAR_BUSINESS_NUMBER: null,
    DB_BUSINESS_NUMBER: null,
    PAR_PAYROLL_CATEGORY: null,
    DB_PAYROLL_CATEGORY: null,
    REPT_NAME: null,
    REPT_DESC: null,
    REP_CAT_ID: null,
    CATEGORY_NAME: null,
    ID_USER: null,
    fav_flag: false,
    REP_FV_PARA: null,
    PAR_HOLIDAY_DATE: null,
    DB_HOLIDAY_DATE: null,
    PAR_WAGES: null,
    DB_WAGES: null,
    PAR_WORKING_WEEK_START: null,
    DB_WORKING_WEEK_START: null,
    PAR_PROVINCE_RULE: null,
    DB_PROVINCE_RULR: null,
    PAR_INCLUDE_ALL_EMPS: null,
    DB_INCLUDE_ALL_EMPS: null,
    DB_PROVINCE: null,
    PAR_EMPLOYEE_STATUS: null,
    DB_EMPLOYEE_STATUS: null,
    PAR_ORDER_BY: null,
    DB_ORDER_BY_EMP_NUM: null,
    DB_ORDER_BY_EMP_NAME: null,
    DB_ORDER_BY_DEPARTMENT: null,
    COUNTRY: null,
    token: null
  };
    if (this.filter_visible == true) {
      this.filter_visible = false;
    }

    this.current_reports.length = 0;
    const temp_val = +value;
    this.active_tab = parentCat ?? temp_val;
    if (temp_val === 1) {
      if (this.module == 'Payroll') {
        this.current_reports = this.reports.filter(x => x.ID_USER !== null && x.REP_CAT_ID !== 2);
      } else {
        this.current_reports = this.reports.filter(x => x.ID_USER !== null && x.REP_CAT_ID === 2);
      }
      for (const item of this.current_reports) {
        item.fav_flag = true;
      }
    } else {
      this.current_reports = this.reports.filter(x => x.REP_CAT_ID === temp_val);
      for (const item of this.current_reports) {
        item.ID_USER !== null ? item.fav_flag = true : item.fav_flag = false;
      }
    }

  }

  onMoreIconClick(rep: IReport): void {
    this.selected_report = rep;
    this.filter_visible = true;
    if (this.cur_wizard_pg != null) {
      this.cur_payroll_grp = this.reportParameterDetailsParent.payrollGroupList.filter(x => (x.PRLG_CODE == this.cur_wizard_pg))[0];
    }
    if (rep.REPORT_ID == 22) {
      this._reportService.getreportLastvalues().subscribe(
        res => {
          this.report_last_val = res;
        },
        err => {
          console.log('error occurred');
        }
      );
    }
  }

  onFavouriteClick(value: IReport): void {
    const temp_rep: IReport[] = this.current_reports.filter(x => x.REPORT_ID == value.REPORT_ID);
    const index = this.current_reports.findIndex(x => x.REPORT_ID == value.REPORT_ID);
    if (this.current_reports[index].fav_flag == false) {
      this.current_reports[index].fav_flag = true;
      const temp_a = value.REPORT_ID;
      let temp_b;
      this.jsString == '' ? temp_b = ' ' : temp_b = this.jsString;

      this.updateFav = {a: temp_a, b: temp_b};
      this.updateReportFavouriteService = this._reportService.updateReportFavourite(this.updateFav).subscribe(
        res => {
          this.current_reports[index].ID_USER = res;
          this.current_reports[index].fav_flag = true;
          this.current_reports[index].REP_FV_PARA = this.jsString;
        },
        err => {
          console.log('error occurred');
        }
      );
    } else {

      const temp_a = value.REPORT_ID;
      this.report_to_delete = {report_id: temp_a};

      this.deleteFavouriteReportService = this._reportService.deleteFavouriteReport(this.report_to_delete).subscribe(
        res => {
          console.log('deleted');
          this.current_reports[index].ID_USER = null;
          this.current_reports[index].fav_flag = false;
        },
        err => {
          console.log('error occurred');
        }
      );
    }


  }

  getBack(value: boolean): void {
    this.filter_visible = value;
  }

  getJSstringUpdate(value: string): void {
    this.jsString = value;
  }

  selectedItem(index): void {
    this.selectedIndex = index;
  }

  getIcon(value: string) {
    switch (true) {
      case value.toLowerCase().includes('paroll'):
        return 'fas fa-money';
      case value.toLowerCase().includes('favorite'):
        return 'fas fa-star';
      default:
        return null;
    }
  }

  ngOnDestroy() {
    if (this.getCategoryDetailsService) {
      this.getCategoryDetailsService.unsubscribe();
    }
    if (this.getReportsService) {
      this.getReportsService.unsubscribe();
    }
    if (this.multipleService) {
      this.multipleService.unsubscribe();
    }
    if (this.getReportParameterDetailsService) {
      this.getReportParameterDetailsService.unsubscribe();
    }
    if (this.updateReportFavouriteService) {
      this.updateReportFavouriteService.unsubscribe();
    }
    if (this.deleteFavouriteReportService) {
      this.deleteFavouriteReportService.unsubscribe();
    }
    if (this.GetEIGroupsService) {
      this.GetEIGroupsService.unsubscribe();
    }
    if (this.getPayrollCategoriesService) {
      this.getPayrollCategoriesService.unsubscribe();
    }
    if (this.getFromEmailAddressService) {
      this.getFromEmailAddressService.unsubscribe();
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  checkIfSubCategoryExists(category: ICategory): boolean {
    const exists = !!this.categories.filter(x => (x.REP_CAT_ID == category.REP_CAT_ID))?.length;
    console.log(exists);
    console.log(this.filteredCategories);
    return exists;
  }
}
