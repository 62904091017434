import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GlService} from '@harmony-modules/payroll/services/payroll-setup/gl.service';
import {LaborService} from '@harmony-modules/payroll/services/payroll-setup/labor.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {IDepartmentGLAccount, IDimensionArry, IDimensionForGlSetup, ILaborSetupGL, ILocationListGlSetup} from '@shared/models/igl';
import {ICostData, ILaborTimesheeInCost, IPayCatListLabor, IUnionAgrmntList} from '@shared/models/ilabor';
import {ICostList, ICostUpdate, IPayrollActiveEmpList, IProcessPayroll} from '@shared/models/ipayroll-group';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {forkJoin, of, Subscription} from 'rxjs';
import {catchError, take, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';


declare var showMsg: any;
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-cost-modal',
  templateUrl: './cost-modal.component.html',
  styleUrls: ['./cost-modal.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'costModal', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`)) }
    }]
})
export class CostModalComponent implements OnInit {

  @Input()
  set sysPref(sysPref: any) {
    this.sys_Pref = sysPref;
    if (sysPref) {
      this.is_working_date = sysPref.LABOR_DATE_FLG && sysPref.LABOR_DATE_FLG == 1 ? true : false;
    }
  }
  get sysPref(): any {
    return this.sys_Pref;
  }
  @Input()
  set employeeId(employeeId: number) {
    this.emp_id = employeeId;
  }
  get employeeId(): number {
    return this.emp_id;
  }
  @Input() empNo: string;
  @Input() empN: string;
  @Input() sdate: string;
  @Input() edate: string;
  @Input()
  set payrollGrpId(payrollGrpId: number) {
    this.payrollGrp_Id = payrollGrpId;
    if (this.payrollGrp_Id && this.payrollCategories) {
      this.payrollCategories_copy = this.payrollCategories.filter(x => x.PRLG_CODE == this.payrollGrp_Id);
    }
  }
  get payrollGrpId(): number {
    return this.payrollGrp_Id;
  }
  @Input() prProcessYear: number;
  @Input() prProcessStartdate: Date;
  @Input() prProcessEndtdate: Date;
  @Input() proc_seq_number: number;
  @Input() filteredPRActiveEmployeesList: IPayrollActiveEmpList[];
  @Input() laborTimesheetList: ILaborTimesheeInCost;
  @Input()
  set sessionId(sessionId: string) {
    this.session_id = sessionId;
  }
  get sessionId(): string {
    return this.session_id;
  }
  @Input()
  set costData(costData: ICostData[]) {
    this.cost_Data = costData;
    if (costData && costData.length > 0) {
      this.setGridData(costData);
    }
  }
  get costData(): ICostData[] {
    return this.cost_Data;
  }
  @Output() sessionid_cost = new EventEmitter<any>();
  @Input() connectionId: string;

  payrollGrp_Id: number;
  sess_id: string;
  session_id: string;
  emp_id: number;
  laborSetupList: ILaborSetupGL[] = [];
  dimensionList: IDimensionForGlSetup[] = [];
  dimensionList1: IDimensionForGlSetup[] = [];
  dimensionList2: IDimensionForGlSetup[] = [];
  dimensionList3: IDimensionForGlSetup[] = [];
  dimensionList4: IDimensionForGlSetup[] = [];
  dimensionList5: IDimensionForGlSetup[] = [];
  dimensionList6: IDimensionForGlSetup[] = [];
  array_of_dimension: IDimensionArry[] = [];
  locationList: ILocationListGlSetup[] = [];
  departmentList: IDepartmentGLAccount[] = [];
  payrollCategories: IPayCatListLabor[] = [];
  payrollCategories_copy: IPayCatListLabor[] = [];
  costForm: FormGroup;
  is_working_date: boolean = false;
  cost_Data: ICostData[] = [];
  cssClasses: string[] = [];
  colorClass: string;
  cost_update: ICostUpdate;
  sys_Pref: any;
  testdate: any[] = [];
  unionAgrmntList: IUnionAgrmntList[] = [];
  unionAgrmntAry: IUnionAgrmntList[][] = [];

  private subscription: Subscription;

  constructor(
    private _fb: FormBuilder,
    private _laborService: LaborService,
    private _glService: GlService,
    private _payrollService: PayrollGroupService,
    private _datePipe: DatePipe,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService) { }

  ngOnInit() {
    this.costForm = this._fb.group({
      cost_grid: this._fb.array([])
    });

    forkJoin(
      this._laborService.GetLaborSetup().pipe(tap(lbr => {
        this.laborSetupList = lbr;
      }),
        catchError(err => of(err.status))),
      this._payrollService.GetDimensionsForGlSetup().pipe(tap(dim => {
        this.dimensionList = dim;
        this.dimensionList1 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 1);
        this.dimensionList2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2);
        this.dimensionList3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3);
        this.dimensionList4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4);
        this.dimensionList5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5);
        this.dimensionList6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6);
      }),
        catchError(err => of(undefined))),
      this._glService.getDepartmentForGLAccounts_V1().pipe(tap(dep => { this.departmentList = dep; }),
        catchError(err => of({ isError: true, error: err }))),
      this._payrollService.GetLocationListForGlSetupForPayroll().pipe(tap(loc => { this.locationList = loc; }),
        catchError(err => of(err.status))),
      this._laborService.GetUnionAgreementData_V1().pipe(tap(uad => {
        this.unionAgrmntList = uad;
      }),
        catchError(err => of(err.status))),
      this._laborService.GetPayrollCategoriesForCost_V1().pipe(tap(cats => {
        this.payrollCategories = cats;
      }),
        catchError(err => of(err.status)))
    ).pipe(untilDestroyed(this)).subscribe(([, , , , , ,]) => {
      if (this.payrollGrp_Id) {
        this.payrollCategories_copy = this.payrollCategories.filter(x => x.PRLG_CODE == this.payrollGrp_Id);
      }
    },
      err => {
        console.log('Error occurred loading initial data');
      });
  }

  get cost_grid(): FormArray {
    return this.costForm.get('cost_grid') as FormArray;
  }

  newCostGridRow(): FormGroup {
    return this._fb.group({
      id: [''],
      status: [''],
      seq_no: [''],
      employee: [''],
      timesheet: [''],
      date: [new Date()],//, Validators.required
      category: ['', Validators.required],
      units: [''],
      rate: [''],
      amount: [''],
      union: [''],
      dim1: [''],
      dim2: [''],
      dim3: [''],
      dim4: [''],
      dim5: [''],
      dim6: [''],
      dep: [''],
      loc: [''],
      txt1: [''],
      txt2: [''],
      txt3: [''],
      txt4: [''],
      id_union_agg: [''],
      overwrite: [''],
      grid_id: ['']
    });
  }

  getCostData() {
    this.subscription = this._laborService.GetCostData(this.payrollGrp_Id, this.sess_id, this.prProcessStartdate, this.prProcessEndtdate, this.prProcessYear, this.proc_seq_number, this.emp_id).pipe(take(1), untilDestroyed(this)).subscribe(data => {
      console.log('cost data =>>');
      console.log(data);
      if (data) {
        if (data?.length) {
          data = data?.map(value =>  ({...value, PROC_OUT_MSG: this.transloco.translate(value.PROC_OUT_MSG)}));
        }
        this.setGridData(data); // ????? data is ICostData[] and ICostData.PROC_OUT_MSG is key from backend to translate ????
      }
    },
      err => {
        console.log('Error occurred while getting cost data');
      });
  }

  /******
  *
  * status => PROC_OUT_TYPE or -2 or -1
  * status => -2 => row is edited
  * status => -1 => row is deleted
  *
  * *****/
  setGridData(data: ICostData[]): void {
    //console.log(this.sys_Pref.df);
    this.clearFormArray(this.cost_grid);
    this.array_of_dimension.length = 0;
    this.testdate.length = 0;
    for (let i = 0; i < data.length; i++) {
      this.cost_grid.push(this.newCostGridRow());
      this.cost_grid.controls[i].setValue({
        id: data[i].ID_COST,
        status: data[i].PROC_OUT_TYPE,
        seq_no: data[i].PROC_SEQ_NO,
        employee: data[i].ID_EMPLOYEE,
        date: data[i].WORKING_DATE ? this.formatDate(data[i].WORKING_DATE) : new Date(Date.now()),
        category: data[i].CAT_DTL_CODE,
        units: data[i].PROC_UNITS,
        rate: data[i].PROC_RATE,
        amount: data[i].PROC_AMOUNT,
        union: data[i].ID_UNION_AGREEMENT,
        dim1: data[i].ID_DIMENSION1,
        dim2: data[i].ID_DIMENSION2,
        dim3: data[i].ID_DIMENSION3,
        dim4: data[i].ID_DIMENSION4,
        dim5: data[i].ID_DIMENSION5,
        dim6: data[i].ID_DIMENSION6,
        dep: data[i].ID_DEPARTMENT,
        loc: data[i].ID_LOCATION,
        txt1: data[i].TEXT1,
        txt2: data[i].TEXT2,
        txt3: data[i].TEXT3,
        txt4: data[i].TEXT4,
        id_union_agg: data[i].ID_UNION_AGREEMENT,
        overwrite: data[i].OVERWRITE,
        timesheet: data[i].ID_TIMESHEET,
        grid_id: i + 1
      });
      if (!data[i].OVERWRITE) {
        //this.cost_grid.controls[i].disable();
        this.cost_grid.controls[i].get('employee').disable();
        this.cost_grid.controls[i].get('date').disable();
        this.cost_grid.controls[i].get('category').disable();
        this.cost_grid.controls[i].get('units').disable();
        this.cost_grid.controls[i].get('rate').disable();
        this.cost_grid.controls[i].get('amount').disable();
        this.cost_grid.controls[i].get('union').disable();
        this.cost_grid.controls[i].get('dim1').disable();
        this.cost_grid.controls[i].get('dim2').disable();
        this.cost_grid.controls[i].get('dim3').disable();
        this.cost_grid.controls[i].get('dim4').disable();
        this.cost_grid.controls[i].get('dim5').disable();
        this.cost_grid.controls[i].get('dim6').disable();
        this.cost_grid.controls[i].get('dep').disable();
        this.cost_grid.controls[i].get('loc').disable();
        this.cost_grid.controls[i].get('txt1').disable();
        this.cost_grid.controls[i].get('txt2').disable();
        this.cost_grid.controls[i].get('txt3').disable();
        this.cost_grid.controls[i].get('txt4').disable();
        //console.log(this.cost_grid.controls[i].value);
      }
      else {
        let is_cat_unit_based = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_IS_UNIT_FLG : null;
        let is_cat_sched_deduc = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_TYP_CODE : null;
        if (this.sysPref.ct == 1) {
          //this.cost_grid.controls[i].get('rate').enable();
          ////let is_cat_sched_deduc = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_TYP_CODE : null;
          //if (is_cat_unit_based && is_cat_unit_based == 1 && is_cat_sched_deduc && is_cat_sched_deduc == 14) {
          //  this.cost_grid.controls[i].get('units').enable();
          //}
          //else {
          //  this.cost_grid.controls[i].get('units').disable();
          //}
          this.cost_grid.controls[i].get('dim1').disable();
          this.cost_grid.controls[i].get('dim2').disable();
          this.cost_grid.controls[i].get('dim3').disable();
          this.cost_grid.controls[i].get('dim4').disable();
          this.cost_grid.controls[i].get('dim5').disable();
          this.cost_grid.controls[i].get('dim6').disable();
          this.cost_grid.controls[i].get('dep').disable();
          this.cost_grid.controls[i].get('loc').disable();
          this.cost_grid.controls[i].get('txt1').disable();
          this.cost_grid.controls[i].get('txt2').disable();
          this.cost_grid.controls[i].get('txt3').disable();
          this.cost_grid.controls[i].get('txt4').disable();
        }
        else {
          this.cost_grid.controls[i].get('dim1').enable();
          this.cost_grid.controls[i].get('dim2').enable();
          this.cost_grid.controls[i].get('dim3').enable();
          this.cost_grid.controls[i].get('dim4').enable();
          this.cost_grid.controls[i].get('dim5').enable();
          this.cost_grid.controls[i].get('dim6').enable();
          this.cost_grid.controls[i].get('dep').enable();
          this.cost_grid.controls[i].get('loc').enable();
          this.cost_grid.controls[i].get('txt1').enable();
          this.cost_grid.controls[i].get('txt2').enable();
          this.cost_grid.controls[i].get('txt3').enable();
          this.cost_grid.controls[i].get('txt4').enable();
        }
        //else {
        //  if (is_cat_unit_based && is_cat_unit_based == 1) {
        //    this.cost_grid.controls[i].get('rate').enable();
        //    this.cost_grid.controls[i].get('units').enable();
        //  }
        //  else {
        //    this.cost_grid.controls[i].get('rate').disable();
        //    this.cost_grid.controls[i].get('units').disable();
        //  }
        //}
        if (is_cat_unit_based && is_cat_unit_based == 1 && is_cat_sched_deduc && is_cat_sched_deduc == 14) {
          this.cost_grid.controls[i].get('units').enable();
          this.cost_grid.controls[i].get('rate').enable();
          this.cost_grid.controls[i].get('amount').disable();
        }
        else if (is_cat_unit_based && is_cat_unit_based == 1 && is_cat_sched_deduc && is_cat_sched_deduc != 14) {
          this.cost_grid.controls[i].get('units').disable();
          this.cost_grid.controls[i].get('rate').enable();
          this.cost_grid.controls[i].get('amount').disable();
        }
        else {
          this.cost_grid.controls[i].get('units').disable();
          this.cost_grid.controls[i].get('rate').disable();
          this.cost_grid.controls[i].get('amount').enable();
        }
      }
      this.testdate.push(data[i].WORKING_DATE ? this.formatDate(data[i].WORKING_DATE) : new Date(Date.now()));
      this.cost_grid.controls[i].get('employee').disable();
      this.cost_grid.controls[i].get('date').disable();
      this.cost_grid.controls[i].get('category').disable();
      this.cost_grid.controls[i].get('overwrite').enable();
      this.cost_grid.controls[i].get('timesheet').disable();
      this.cost_grid.controls[i].get('id_union_agg').disable();
      let temp_dim_arry: IDimensionArry = {
        list1: this.dimensionList1,
        list2: this.dimensionList2,
        list3: this.dimensionList3,
        list4: this.dimensionList4,
        list5: this.dimensionList5,
        list6: this.dimensionList6,
        dim_array_index: i + 1
      };
      this.array_of_dimension.push(temp_dim_arry);
      let tmp_ua = [];
      this.unionAgrmntAry.push([]);
      let dimension: IDimensionForGlSetup = data[i].ID_DIMENSION1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION1) : null;
      let lbr_dim_id: number = dimension ? dimension.LABOR_DIM_ID : null;
      if (lbr_dim_id) { this.filterDimensions(dimension, data[i].ID_DIMENSION1, lbr_dim_id, i, 1); }
      if (dimension?.LABOR_DIM_UNION_AGREEMENT == 6) {
        tmp_ua = this.unionAgrmntList.filter(x => x.ID_DIMENSION == data[i].ID_DIMENSION1);
      }
      dimension = data[i].ID_DIMENSION2 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION2) : null;
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      if (lbr_dim_id) { this.filterDimensions(dimension, data[i].ID_DIMENSION2, lbr_dim_id, i, 1); }
      if (dimension?.LABOR_DIM_UNION_AGREEMENT == 6) {
        tmp_ua = this.unionAgrmntList.filter(x => x.ID_DIMENSION == data[i].ID_DIMENSION2);
      }
      dimension = data[i].ID_DIMENSION3 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION3) : null;
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      if (lbr_dim_id) { this.filterDimensions(dimension, data[i].ID_DIMENSION3, lbr_dim_id, i, 1); }
      if (dimension?.LABOR_DIM_UNION_AGREEMENT == 6) {
        tmp_ua = this.unionAgrmntList.filter(x => x.ID_DIMENSION == data[i].ID_DIMENSION3);
      }
      dimension = data[i].ID_DIMENSION4 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION4) : null;
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      if (lbr_dim_id) { this.filterDimensions(dimension, data[i].ID_DIMENSION4, lbr_dim_id, i, 1); }
      if (dimension?.LABOR_DIM_UNION_AGREEMENT == 6) {
        tmp_ua = this.unionAgrmntList.filter(x => x.ID_DIMENSION == data[i].ID_DIMENSION4);
      }
      dimension = data[i].ID_DIMENSION5 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION5) : null;
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      if (lbr_dim_id) { this.filterDimensions(dimension, data[i].ID_DIMENSION5, lbr_dim_id, i, 1); }
      if (dimension?.LABOR_DIM_UNION_AGREEMENT == 6) {
        tmp_ua = this.unionAgrmntList.filter(x => x.ID_DIMENSION == data[i].ID_DIMENSION5);
      }
      dimension = data[i].ID_DIMENSION6 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION6) : null;
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      if (lbr_dim_id) { this.filterDimensions(dimension, data[i].ID_DIMENSION6, lbr_dim_id, i, 1); }
      if (dimension?.LABOR_DIM_UNION_AGREEMENT == 6) {
        tmp_ua = this.unionAgrmntList.filter(x => x.ID_DIMENSION == data[i].ID_DIMENSION6);
      }
      this.unionAgrmntAry.push(tmp_ua);
    }
  }

  clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  onValueEdit(i: number) {
    this.cost_grid.controls[i].patchValue({
      status: -2
    });
  }

  onDateValueEdit(i: number, date: any) {
    //console.log(i);
    //console.log(date);
    this.testdate[i] = date;
    this.cost_grid.controls[i].patchValue({
      status: -2
    });
  }
  onCategoryEdit(i: number) {
    this.cost_grid.controls[i].patchValue({
      status: -2
    });
    let is_cat_unit_based = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_IS_UNIT_FLG : null;
    if (is_cat_unit_based && is_cat_unit_based == 1) {
      this.cost_grid.controls[i].get('rate').enable();
      this.cost_grid.controls[i].get('units').enable();
    }
    else {
      this.cost_grid.controls[i].get('rate').disable();
      this.cost_grid.controls[i].get('units').disable();
    }
  }
  onDimensionCahnge(event: any, i: number, lbrsetup: ILaborSetupGL) {
    let dim_id = event.target.value;
    if (dim_id && dim_id != 'null') {
      let dimension: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      let lbr_dim_id: number = dimension.LABOR_DIM_ID;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, i, 0);
      if (dimension.LABOR_DIM_UNION_AGREEMENT == 6) {
        this.cost_grid.controls[i].patchValue({
          id_union_agg: null
        });
      }
    }
    else {
      this.unfilterDimensions(lbrsetup, i);
      if (lbrsetup.LABOR_DIM_UNION_AGREEMENT == 6) {
        this.unionAgrmntAry[i] = [];
        this.cost_grid.controls[i].patchValue({
          id_union_agg: null
        });
      }
    }
    this.cost_grid.controls[i].patchValue({
      status: -2
    });
  }
  onOverwriteCheck(event: any, i: number) {
    this.cost_grid.controls[i].patchValue({
      status: -2
    });
    if (event.target.checked) {
      let is_cat_unit_based = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_IS_UNIT_FLG : null;
      let is_cat_sched_deduc = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_TYP_CODE : null;
      if (this.sysPref.ct == 1) {
        //this.cost_grid.controls[i].get('rate').enable();
        //let is_cat_sched_deduc = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_TYP_CODE : null;
        //if (is_cat_unit_based && is_cat_unit_based == 1 && is_cat_sched_deduc && is_cat_sched_deduc == 14) {
        //  this.cost_grid.controls[i].get('units').enable();
        //}
        //else {
        //  this.cost_grid.controls[i].get('units').disable();
        //}
        this.cost_grid.controls[i].get('dim1').disable();
        this.cost_grid.controls[i].get('dim2').disable();
        this.cost_grid.controls[i].get('dim3').disable();
        this.cost_grid.controls[i].get('dim4').disable();
        this.cost_grid.controls[i].get('dim5').disable();
        this.cost_grid.controls[i].get('dim6').disable();
        this.cost_grid.controls[i].get('dep').disable();
        this.cost_grid.controls[i].get('loc').disable();
        this.cost_grid.controls[i].get('txt1').disable();
        this.cost_grid.controls[i].get('txt2').disable();
        this.cost_grid.controls[i].get('txt3').disable();
        this.cost_grid.controls[i].get('txt4').disable();
      }
      else {
        //if (is_cat_unit_based && is_cat_unit_based == 1) {
        //  this.cost_grid.controls[i].get('rate').enable();
        //  this.cost_grid.controls[i].get('units').enable();
        //}
        //else {
        //  this.cost_grid.controls[i].get('rate').disable();
        //  this.cost_grid.controls[i].get('units').disable();
        //}
        this.cost_grid.controls[i].get('dim1').enable();
        this.cost_grid.controls[i].get('dim2').enable();
        this.cost_grid.controls[i].get('dim3').enable();
        this.cost_grid.controls[i].get('dim4').enable();
        this.cost_grid.controls[i].get('dim5').enable();
        this.cost_grid.controls[i].get('dim6').enable();
        this.cost_grid.controls[i].get('dep').enable();
        this.cost_grid.controls[i].get('loc').enable();
        this.cost_grid.controls[i].get('txt1').enable();
        this.cost_grid.controls[i].get('txt2').enable();
        this.cost_grid.controls[i].get('txt3').enable();
        this.cost_grid.controls[i].get('txt4').enable();
      }
      //this.cost_grid.controls[i].get('status').enable();
      //this.cost_grid.controls[i].get('date').enable();
      //this.cost_grid.controls[i].get('category').enable();
      //this.cost_grid.controls[i].get('amount').enable();
      //this.cost_grid.controls[i].get('dep').enable();
      //this.cost_grid.controls[i].get('loc').enable();
      //this.cost_grid.controls[i].get('txt1').enable();
      //this.cost_grid.controls[i].get('txt2').enable();
      //this.cost_grid.controls[i].get('txt3').enable();
      //this.cost_grid.controls[i].get('txt4').enable();
      if (is_cat_unit_based && is_cat_unit_based == 1 && is_cat_sched_deduc && is_cat_sched_deduc == 14) {
        this.cost_grid.controls[i].get('units').enable();
        this.cost_grid.controls[i].get('rate').enable();
        this.cost_grid.controls[i].get('amount').disable();
      }
      else if (is_cat_unit_based && is_cat_unit_based == 1 && is_cat_sched_deduc && is_cat_sched_deduc != 14) {
        this.cost_grid.controls[i].get('units').disable();
        this.cost_grid.controls[i].get('rate').enable();
        this.cost_grid.controls[i].get('amount').disable();
      }
      else {
        this.cost_grid.controls[i].get('units').disable();
        this.cost_grid.controls[i].get('rate').disable();
        this.cost_grid.controls[i].get('amount').enable();
      }
    }
    else if (!event.target.checked) {
      //this.cost_grid.controls[i].disable();
      this.cost_grid.controls[i].get('employee').disable();
      this.cost_grid.controls[i].get('date').disable();
      this.cost_grid.controls[i].get('category').disable();
      this.cost_grid.controls[i].get('units').disable();
      this.cost_grid.controls[i].get('rate').disable();
      this.cost_grid.controls[i].get('amount').disable();
      this.cost_grid.controls[i].get('union').disable();
      this.cost_grid.controls[i].get('dim1').disable();
      this.cost_grid.controls[i].get('dim2').disable();
      this.cost_grid.controls[i].get('dim3').disable();
      this.cost_grid.controls[i].get('dim4').disable();
      this.cost_grid.controls[i].get('dim5').disable();
      this.cost_grid.controls[i].get('dim6').disable();
      this.cost_grid.controls[i].get('dep').disable();
      this.cost_grid.controls[i].get('loc').disable();
      this.cost_grid.controls[i].get('txt1').disable();
      this.cost_grid.controls[i].get('txt2').disable();
      this.cost_grid.controls[i].get('txt3').disable();
      this.cost_grid.controls[i].get('txt4').disable();
      this.cost_grid.controls[i].get('status').enable();
      this.cost_grid.controls[i].get('overwrite').enable();
    }
  }

  unfilterDimensions(lbrsetup: ILaborSetupGL, index: number) {
    let lbr_dim_id: number = lbrsetup.LABOR_DIM_ID;
    if (lbrsetup.LABOR_DIM_PARENT_CHILD == 2) {
      switch (lbr_dim_id) {
        case 1: {
          this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) : null;
          this.cost_grid.controls[index].patchValue({
            dim2: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 2), index);
          break;
        }
        case 2: {
          this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) : null;
          this.cost_grid.controls[index].patchValue({
            dim3: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 3), index);
          break;
        }
        case 3: {
          this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) : null;
          this.cost_grid.controls[index].patchValue({
            dim4: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 4), index);
          break;
        }
        case 4: {
          this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) : null;
          this.cost_grid.controls[index].patchValue({
            dim5: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 5), index);
          break;
        }
        case 5: {
          this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) : null;
          this.cost_grid.controls[index].patchValue({
            dim6: null
          });
          break;
        }
      }
    }
    else if (lbrsetup.LABOR_DIM_PARENT_CHILD == 3) {
      switch (lbr_dim_id) {
        case 1: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 2).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) : null;
            this.cost_grid.controls[index].patchValue({
              dim2: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 2), index);
          }
          break;
        }
        case 2: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 3).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) : null;
            this.cost_grid.controls[index].patchValue({
              dim3: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 3), index);
          }
          break;
        }
        case 3: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 4).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) : null;
            this.cost_grid.controls[index].patchValue({
              dim4: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 4), index);
          }
          break;
        }
        case 4: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 5).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) : null;
            this.cost_grid.controls[index].patchValue({
              dim5: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 5), index);
          }
          break;
        }
        case 5: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 6).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) : null;
            this.cost_grid.controls[index].patchValue({
              dim6: null
            });
          }
          break;
        }
      }
    }
  }

  filterDimensions(dimension: IDimensionForGlSetup, dim_id: number, lbr_dim_id: number, index: number, init: number) {
    if (dimension) {
      if (dimension.LABOR_DIM_PARENT_CHILD == 2) {
        switch (lbr_dim_id) {
          case 1: {
            this.array_of_dimension[index].list2 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 2 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 2 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.cost_grid.controls[index].patchValue({
                dim2: null
              });
            }
            break;
          }
          case 2: {
            this.array_of_dimension[index].list3 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.cost_grid.controls[index].patchValue({
                dim3: null
              });
            }
            break;
          }
          case 3: {
            this.array_of_dimension[index].list4 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.cost_grid.controls[index].patchValue({
                dim4: null
              });
            }
            break;
          }
          case 4: {
            this.array_of_dimension[index].list5 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.cost_grid.controls[index].patchValue({
                dim5: null
              });
            }
            break;
          }
          case 5: {
            this.array_of_dimension[index].list6 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.cost_grid.controls[index].patchValue({
                dim6: null
              });
            }
            break;
          }
          default: {
            break;
          }
        }
      }
      else if (dimension.LABOR_DIM_PARENT_CHILD == 3) {
        switch (lbr_dim_id) {
          case 2: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 3).LABOR_DIM_PARENT_CHILD == 3) {
              this.array_of_dimension[index].list3 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim3: null
                });
              }
            }
            break;
          }
          case 3: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 4).LABOR_DIM_PARENT_CHILD == 3) {
              this.array_of_dimension[index].list4 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim4: null
                });
              }
            }
            break;
          }
          case 4: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 5).LABOR_DIM_PARENT_CHILD == 3) {
              this.array_of_dimension[index].list5 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim5: null
                });
              }
            }
            break;
          }
          case 5: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 6).LABOR_DIM_PARENT_CHILD == 3) {
              this.array_of_dimension[index].list6 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim6: null
                });
              }
            }
            break;
          }
          default: {
            break;
          }
        }
      }
      if (dimension.is_union > 0) {
        let agreement_list: any[] = dimension.list.split(',').map(x => +x);
        let agreement_lbr_dim_id: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6).LABOR_DIM_ID : null;
        if (agreement_lbr_dim_id) {
          switch (agreement_lbr_dim_id) {
            case 1: {
              this.array_of_dimension[index].list1 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 1 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 1 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim1: null
                });
              }
              break;
            }
            case 2: {
              this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim2: null
                });
              }
              break;
            }
            case 3: {
              this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim3: null
                });
              }
              break;
            }
            case 4: {
              this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim4: null
                });
              }
              break;
            }
            case 5: {
              this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim5: null
                });
              }
              break;
            }
            case 6: {
              this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.cost_grid.controls[index].patchValue({
                  dim6: null
                });
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      }
      else if (dimension.is_union == 0) {
        let agreement_lbr_dim_id: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6).LABOR_DIM_ID : null;
        switch (agreement_lbr_dim_id) {
          case 1: {
            this.array_of_dimension[index].list1 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 1) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 1) : null;
            break;
          }
          case 2: {
            this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) : null;
            break;
          }
          case 3: {
            this.dimensionList3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) : null;
            break;
          }
          case 4: {
            this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) : null;
            break;
          }
          case 5: {
            this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) : null;
            break;
          }
          case 6: {
            this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) : null;
            break;
          }
          default: {
            break;
          }
        }
      }
      if (dimension.LABOR_DIM_UNION_AGREEMENT == 6) {
        this.unionAgrmntAry[index] = this.unionAgrmntList.filter(x => x.ID_DIMENSION == dim_id);
        if (init == 0) {
          this.cost_grid.controls[index].patchValue({
            id_union_agg: null
          });
        }
      }
    }
  }

  isFormDirty(): boolean {
    for (let x of this.cost_grid.controls) {
      if (x.dirty) {
        return true;
      }
    }
    return false;
  }
  onCostFormSubmit() {
    let errorMarkUp = "";
    let warningMarkUp = "";
    let infoMarkUp = "";
    if (!this.isFormDirty()) {
      let msg = this.transloco.translate('alerts.noChange', {}, 'costModal');
      infoMarkUp = msg;
    }
    else {
      for (let i = 0; i < this.cost_grid.controls.length; i++) {
        if (this.cost_grid.controls[i].dirty) {
          let wdate = this.cost_grid.controls[i].get('date').value;
          let units = this.cost_grid.controls[i].get('units').value;
          let category = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.cost_grid.controls[i].get('category').value).CAT_DTL_USER_CODE;
          if (!this.dimensionParentChildRelationValid(this.cost_grid.controls[i].get('dim1').value, this.cost_grid.controls[i].get('dim2').value, this.cost_grid.controls[i].get('dim3').value, this.cost_grid.controls[i].get('dim4').value, this.cost_grid.controls[i].get('dim5').value, this.cost_grid.controls[i].get('dim6').value)) {
            let msg = this.transloco.translate('alerts.isInvalidParentChildRelation', {}, 'costModal');
            errorMarkUp += "<span class=\"msg-cell\">" + this.empN + " - " + wdate + " - " + category + " - " + units + " - " + msg + "</span>";
          }
          else if (!this.dimensionUnionAggreementRelationValid(this.cost_grid.controls[i].get('dim1').value, this.cost_grid.controls[i].get('dim2').value, this.cost_grid.controls[i].get('dim3').value, this.cost_grid.controls[i].get('dim4').value, this.cost_grid.controls[i].get('dim5').value, this.cost_grid.controls[i].get('dim6').value)) {
            let msg = this.transloco.translate('alerts.isInvalidUnionAggreementRelation', {}, 'costModal');
            errorMarkUp += "<span class=\"msg-cell\">" + this.empN + " - " + wdate + " - " + category + " - " + units + " - " + msg + "</span>";
          }
          else if (this.is_working_date && !this.dateIsValid(this.cost_grid.controls[i].get('date').value)) {
            let msg = this.transloco.translate('alerts.dateIsOutsideOfSelectedPeriod', {}, 'costModal');
            warningMarkUp += "<span class=\"msg-cell\">" + this.empN + " - " + wdate + " - " + category + " - " + units + " - " + msg + "</span>";
          }
        }
      }
    }
    if (infoMarkUp) {
      const title = this.transloco.translate('info');
      const buttonOk = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'info',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function () {
              return;
            }
          },
        },
        'message': infoMarkUp
      });
    }
    else if (errorMarkUp) {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function () {
              return;
            }
          },
        },
        'message': errorMarkUp
      });
    }
    else if (warningMarkUp) {
      let t = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonCancel = this.transloco.translate('cancel');
      showMsg({
        'type': 'sticky',
        'status': 'warning',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function () {
              t.costProcess();
              return;
            }
          },
          [buttonCancel]: {
            action: function () {
              return;
            }
          },
        },
        'message': warningMarkUp
      });
    }
    else {
      this.costProcess();
    }
  }

  costProcess() {
    let cost_list: ICostList[] = [];
    for (let x of this.costForm.getRawValue().cost_grid) {
      if (x.status == -2) {
        let temp: ICostList = {
          ID_COST: +x.id,
          PROC_SEQ_NO: +x.seq_no,
          CAT_DTL_CODE: +x.category,
          ID_EMPLOYEE: +x.employee,
          PROC_FROM_DT: this.prProcessStartdate,
          PROC_TO_DT: this.prProcessEndtdate,
          PRLG_CODE: this.payrollGrp_Id,
          WORKING_DATE: this.is_working_date ? x.date : this.prProcessEndtdate,
          PROC_UNITS: +x.units,
          PROC_RATE: +x.rate,
          PROC_AMOUNT: +x.amount,
          PROC_YEAR: this.prProcessYear,
          ID_DIMENSION1: +x.dim1,
          ID_DIMENSION2: +x.dim2,
          ID_DIMENSION3: +x.dim3,
          ID_DIMENSION4: +x.dim4,
          ID_DIMENSION5: +x.dim5,
          ID_DIMENSION6: +x.dim6,
          ID_DEPARTMENT: +x.dep,
          ID_LOCATION: +x.loc,
          TEXT1: x.txt1,
          TEXT2: x.txt2,
          TEXT3: x.txt3,
          TEXT4: x.txt4,
          OVERWRITE: x.overwrite,
          ID_UNION_AGREEMENT: +x.id_union_agg,
          ID_TIMESHEET: +x.timesheet
        };
        if (temp) { cost_list.push(temp); }
      }
    }
    let temp_data: IProcessPayroll = {
      f: 0,
      c: this.payrollGrp_Id,
      y: this.prProcessYear,
      sd: this.prProcessStartdate,
      ed: this.prProcessEndtdate,
      eid: this.emp_id.toString(),
      exflg: 0,
      cat: '',
      seq_no: this.proc_seq_number,
      connectionId: this.connectionId
    }
    this.cost_update = {
      ob: temp_data,
      costList: cost_list
    };
    if (this.cost_update) {
      this._payrollService.ProcessCost(this.cost_update).pipe(untilDestroyed(this)).subscribe(res => {
        console.log(res);
        if (res) {
          this.sessionid_cost.emit(res);
          //this.getCostData();
        }
        //$('#costModal').modal('hide');
      });
    }
  }

  dimensionParentChildRelationValid(dim1: number, dim2: number, dim3: number, dim4: number, dim5: number, dim6: number): boolean {
    let dimension1: IDimensionForGlSetup = dim1 ? this.dimensionList.find(x => x.ID_DIMENSION == dim1) : null;
    let dimension2: IDimensionForGlSetup = dim2 ? this.dimensionList.find(x => x.ID_DIMENSION == dim2) : null;
    let dimension3: IDimensionForGlSetup = dim3 ? this.dimensionList.find(x => x.ID_DIMENSION == dim3) : null;
    let dimension4: IDimensionForGlSetup = dim4 ? this.dimensionList.find(x => x.ID_DIMENSION == dim4) : null;
    let dimension5: IDimensionForGlSetup = dim5 ? this.dimensionList.find(x => x.ID_DIMENSION == dim5) : null;
    let dimension6: IDimensionForGlSetup = dim6 ? this.dimensionList.find(x => x.ID_DIMENSION == dim6) : null;

    if (dimension2 && dimension2.LABOR_DIM_PARENT_CHILD == 3 && !dimension1) {
      return false;
    }
    else if (dimension2 && dimension2.LABOR_DIM_PARENT_CHILD == 3 && dimension1 && dimension1.ID_DIMENSION != dimension2.DIM_PARENT_ID) {
      return false;
    }
    else if (dimension3 && dimension3.LABOR_DIM_PARENT_CHILD == 3 && !dimension2) {
      return false;
    }
    else if (dimension3 && dimension3.LABOR_DIM_PARENT_CHILD == 3 && dimension2 && dimension2.ID_DIMENSION != dimension3.DIM_PARENT_ID) {
      return false;
    }
    else if (dimension4 && dimension4.LABOR_DIM_PARENT_CHILD == 3 && !dimension3) {
      return false;
    }
    else if (dimension4 && dimension4.LABOR_DIM_PARENT_CHILD == 3 && dimension3 && dimension3.ID_DIMENSION != dimension4.DIM_PARENT_ID) {
      return false;
    }
    else if (dimension5 && dimension5.LABOR_DIM_PARENT_CHILD == 3 && !dimension4) {
      return false;
    }
    else if (dimension5 && dimension5.LABOR_DIM_PARENT_CHILD == 3 && dimension4 && dimension4.ID_DIMENSION != dimension5.DIM_PARENT_ID) {
      return false;
    }
    else if (dimension6 && dimension6.LABOR_DIM_PARENT_CHILD == 3 && !dimension5) {
      return false;
    }
    else if (dimension6 && dimension6.LABOR_DIM_PARENT_CHILD == 3 && dimension5 && dimension5.ID_DIMENSION != dimension6.DIM_PARENT_ID) {
      return false;
    }
    return true;
  }

  dimensionUnionAggreementRelationValid(dim1: number, dim2: number, dim3: number, dim4: number, dim5: number, dim6: number): boolean {
    let lbr_dim_id_aggreement: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6).LABOR_DIM_ID : null;
    let lbr_dim_id_union: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 5) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 5).LABOR_DIM_ID : null;
    let union_dimension: IDimensionForGlSetup;
    let aggreement_dimension: IDimensionForGlSetup;
    if (lbr_dim_id_aggreement && lbr_dim_id_union) {
      let dimension1: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim1);
      let dimension2: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim2);
      let dimension3: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim3);
      let dimension4: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim4);
      let dimension5: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim5);
      let dimension6: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim6);
      let dimensions: IDimensionForGlSetup[] = [dimension1, dimension2, dimension3, dimension4, dimension5, dimension6];
      for (let item of dimensions) {
        if (item && item.LABOR_DIM_ID == lbr_dim_id_aggreement) {
          aggreement_dimension = item;
        }
        else if (item && item.LABOR_DIM_ID == lbr_dim_id_union) {
          union_dimension = item;
        }
      }
      if (union_dimension && aggreement_dimension && !union_dimension.list.includes(aggreement_dimension.ID_DIMENSION.toString())) {
        return false;
      }
    }
    return true;
  }

  dateIsValid(date: Date): boolean {
    let x = this.prProcessStartdate;
    let y = this.prProcessEndtdate;
    if (new Date(date).getTime() <= new Date(y.toString().substring(0, 10)).getTime() && new Date(date).getTime() >= new Date(x.toString().substring(0, 10)).getTime()) {
      return true;
    }
    else {
      return false;
    }
  }

  getClassesForCostModalIcon(proc_status: number) {
    this.cssClasses.length = 0;
    this.colorClass = '';
    switch (proc_status) {
      case 1: {
        this.colorClass = 'tick-icon-green';
        break;
      }
      case 2: {
        this.colorClass = 'tick-icon-orange';
        break;
      }
      case 3: {
        this.colorClass = 'tick-icon-red';
        break;
      }
      default: {
        this.colorClass = 'no_icon';
        break;
      }
    }
    this.cssClasses.push(this.colorClass);
    return this.cssClasses;
  }

  /*..format dates..*/
  formatDate(jDate: any): string {
    let dateStr = (new Date(jDate.substring(0, 10))).toISOString().substring(0, 10);
    return dateStr;
  }
  fromJsonDate(jDate: Date): string {
    const bDate: Date = new Date(jDate);//bDate.toISOString()
    //let str = this._datePipe.transform(jDate.toString().substring(0, 10), this.sys_Pref && this.sys_Pref.df ? this.sys_Pref.df : 'yyyy-MM-dd')
    let str = this._datePipe.transform(jDate.toString().substring(0, 10), 'yyyy-MM-dd')

    return str;
  }

  /*..format dates end..*/

  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

}
