import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[appDuplicateOrder]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DuplicateOrderDirective,
    multi: true
  }]
})
export class DuplicateOrderDirective implements Validator {

  @Input('appDuplicateOrder') orderArr: string;


  validate(control: AbstractControl): { [key: string]: any } | null {
    let arr = this.orderArr.split(" ");
    let str: string = String(control.value);
    if (arr.includes(str)) {
      return { 'orderExist': true };
    }
    else
      return null;
  }

  registerOnValidatorChange?(fn: () => void): void {

  }

  constructor() { }

}
