import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {
  GLFilterModel,
  IActiveFlgUpdate,
  IAllocatedPaycat,
  IBusinessCentralConfiguration,
  ICategoryForCatCondition,
  ICodeFinder,
  IConnectionResult,
  IDepartmentGLAccount,
  IDimensionForGlSetup,
  IEmpDtlCode,
  IEmployeeGLAccount,
  IEmployeeIdentification,
  IEmployeeListForGlSetup,
  IEmployeeListForGlSetupEmpTab,
  IEmployeeListForRiversePaycard,
  IEmpsPaystubHistory,
  IEmpStructureEditObject,
  IGlCreateOutput,
  IGlCustomSegmentList,
  IGLDimensionTab,
  IGlEmpGroupAllocation,
  IGLEmployeeStructure,
  IGlExport,
  IGLExportDimensionList,
  IGlExportOutput,
  IGlExportQueueRecord,
  IGLGroupAccount,
  IGLinterfaceList,
  IGLLocationUpdate,
  IGLPaycard,
  IGlPayrollGroup,
  IGlQueueRecord,
  IGlRecordNew,
  IGLsetupDimension,
  IGlSetupOverwriteList,
  IGLStructure,
  IInterfaceParameter,
  ILaborCategoryCondition,
  ILaborSetupGL,
  ILayout,
  ILocationListGlSetup,
  INewGLInterface,
  INewInterfaceParameter,
  InterfaceTypeEnum,
  IParamType,
  IPaycatDtlCode,
  IPaycatDtlCodeEmp,
  IPayrollCategoryGLAccount,
  IReversePaycard,
  ISapConfiguration,
  IStructureDropdown,
  IStructureEditObject,
  IUpdateCodeFinderCharacters,
  IUpdateCompanyInterfaceFlg,
  IUpdateGLInterface,
  ISageIntacctConfiguration
} from 'src/app/shared/models/igl';
import {ISystemPreferenceInit} from 'src/app/shared/models/isystem-preference';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class GlService {

  constructor(private http: HttpClient) {
  }

  private payrollGLList = new BehaviorSubject<IGLStructure[]>([]);
  parentList = this.payrollGLList.asObservable();
  private payrollEmpList = new BehaviorSubject<IGLEmployeeStructure[]>([]);
  parentEmpList = this.payrollEmpList.asObservable();

  private interfaceList = new BehaviorSubject<IGLinterfaceList[]>(null);
  currentInterfaceList = this.interfaceList.asObservable();

  GetGLInterfaceList(interfaceKind: number): Observable<IGLinterfaceList[]> {
    return this.http.get<IGLinterfaceList[]>(API_END_POINT.payroll.gl + 'GetGLInterfaceList?interfaceKind='+interfaceKind);
  }

  GetGLInterfaceListForInterface(interfaceKind: number): Observable<IGLinterfaceList[]> {
    return this.http.get<IGLinterfaceList[]>(API_END_POINT.payroll.gl + "GetGLInterfaceListForInterface?interfaceKind="+interfaceKind);
  }

  GetGLInterfaceListForJobCost(interfaceKind: number): Observable<IGLinterfaceList[]> {
    return this.http.get<IGLinterfaceList[]>(API_END_POINT.payroll.gl + "GetGLInterfaceListForJobCost?interfaceKind="+interfaceKind);
  }

  parentListChanged(l) {
    this.payrollGLList.next(l);
  }

  parentEmpListChanged(l) {
    this.payrollEmpList.next(l);
  }

  changeInterfaceList(list: IGLinterfaceList[]) {
    this.interfaceList.next(list);
  }

  UpdateCompanyInterfaceFlag(list: IUpdateCompanyInterfaceFlg[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateCompanyInterfaceFlag', list);
  }

  GetLayoutList(interfaceKind: number): Observable<ILayout[]> {
    return this.http.get<ILayout[]>(API_END_POINT.payroll.gl + 'GetLayoutList?interfaceKind='+interfaceKind);
  }

  GetParamTypeList(): Observable<IParamType[]> {
    return this.http.get<IParamType[]>(API_END_POINT.payroll.gl + 'GetParamTypeList');
  }

  GetInterfaceParameterList(id: number): Observable<IInterfaceParameter[]> {
    return this.http.get<IInterfaceParameter[]>(API_END_POINT.payroll.gl + 'GetInterfaceParameterList?id=' + id);
  }

  GetEmployeeIdentificationList(): Observable<IEmployeeIdentification[]> {
    return this.http.get<IEmployeeIdentification[]>(API_END_POINT.payroll.gl + 'GetEmployeeIdentificationList');
  }

  GetDataFromMultipleServices(interfaceKind: number): Observable<any> {
    const res1 = this.GetLayoutList(interfaceKind);
    const res2 = this.GetParamTypeList();
    return forkJoin([res1, res2]);
  }

  GetGlExportDimensionList(): Observable<IGLExportDimensionList[]> {
    return this.http.get<IGLExportDimensionList[]>(API_END_POINT.payroll.gl + 'GetGlExportDimensionList');
  }
  GetDataForGlExport(id: number): Observable<any> {
    const res1 = this.GetInterfaceParameterList(id);
    const res2 = this.GetParamTypeList();
    const res3 = this.GetEmployeeIdentificationList();
    const res4 = this.GetGlExportDimensionList();
    return forkJoin([res1, res2, res3, res4]);
  }

  AddNewGLInterface(ob: INewGLInterface): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'AddNewGLInterface', ob);
  }

  UpdateGLInterface(ob: IUpdateGLInterface): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateGLInterface', ob);
  }

  AddNewGLInterfaceParameter(list: INewInterfaceParameter[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'AddNewGLInterfaceParameter', list);
  }

  DeleteGLInterface(id: number): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'DeleteGLInterface?id=' + id);
  }

  DeleteGLInterfaceParameter(id: number): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'DeleteGLInterfaceParameter?id=' + id);
  }

  getPayrollCatForGLAccounts(): Observable<IPayrollCategoryGLAccount[]> {
    return this.http.get<IPayrollCategoryGLAccount[]>(API_END_POINT.payroll.gl + 'GetPayrollCatForGLAccounts');
  }
  getEmployeeForGLAccounts(): Observable<IEmployeeGLAccount[]> {
    return this.http.get<IEmployeeGLAccount[]>(API_END_POINT.payroll.gl + 'GetEmployeeForGLAccounts');
  }
  GetPayrollCatForGLAccountsAllocation(): Observable<IPayrollCategoryGLAccount[]> {
    return this.http.get<IPayrollCategoryGLAccount[]>(API_END_POINT.payroll.gl + 'GetPayrollCatForGLAccountsAllocation');
  }
  getDepartmentForGLAccounts(): Observable<IDepartmentGLAccount[]> {
    return this.http.get<IDepartmentGLAccount[]>(API_END_POINT.payroll.gl + 'GetDepartmentForGLAccounts');
  }
  getDepartmentForGLAccounts_V1(): Observable<IDepartmentGLAccount[]> {
    return this.http.get<IDepartmentGLAccount[]>(API_END_POINT.payroll.gl + 'GetDepartmentForGLAccounts_V1');
  }

  updateDepartmentGLAccount(list: IDepartmentGLAccount[]): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.gl + 'UpdateDepartmentGLAccount', list);
  }

  updatePayrollCatGLAccount(list: IPayrollCategoryGLAccount[]): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.gl + 'UpdatePayrollCatGLAccount', list);
  }

  GetGlPaycardList(filters: GLFilterModel): Observable<IGLPaycard[]> {
    return this.http.post<IGLPaycard[]>(API_END_POINT.payroll.gl + 'GetGlPaycardList', filters);
  }

  GetReversePaycardList(): Observable<IReversePaycard[]> {
    return this.http.get<IReversePaycard[]>(API_END_POINT.payroll.gl + 'GetReversePaycardList');
  }

  GetGlPayrollGroupList(): Observable<IGlPayrollGroup[]> {
    return this.http.get<IGlPayrollGroup[]>(API_END_POINT.payroll.gl + 'GetGlPayrollGroupList');
  }

  GetGLFilterData() {
    return this.http.get<any>(API_END_POINT.payroll.gl + 'GetGLFilterData');
  }

  GetGlPayrollGroupListForPaycard(): Observable<IGlPayrollGroup[]> {
    return this.http.get<IGlPayrollGroup[]>(API_END_POINT.payroll.gl + 'GetGlPayrollGroupListForPaycard');
  }

  GetGlPayrollGroupListForPaystub(): Observable<IGlPayrollGroup[]> {
    return this.http.get<IGlPayrollGroup[]>(API_END_POINT.payroll.gl + 'GetGlPayrollGroupListForPaystub');
  }

  GetGlPayrollGroupList_V1(): Observable<IGlPayrollGroup[]> {
    return this.http.get<IGlPayrollGroup[]>(API_END_POINT.payroll.gl + 'GetGlPayrollGroupList_V1');
  }

  GetGlRecordsForPaycard(id_paycard: number, Emp_Number: number, Proc_Year: number): Observable<IGlRecordNew[]> {
    return this.http.get<IGlRecordNew[]>(API_END_POINT.payroll.gl + 'GetGlRecordsForPaycard?id_paycard=' + id_paycard + '&Emp_Number=' + Emp_Number + '&Proc_Year=' + Proc_Year);
  }
  GetGlDimensionTabDetails(): Observable<IGLDimensionTab[]> {
    return this.http.get<IGLDimensionTab[]>(API_END_POINT.payroll.gl + 'GetGlDimensionTabDetails');
  }

  AddNewRecordToGlQueue(record_list: IGlQueueRecord[]): Observable<string> {
    return this.http.post<string>(API_END_POINT.payroll.gl + 'AddNewRecordToGlQueue', record_list);
  }
  AddNewRecordToGlExportQueue(record_list: IGlExportQueueRecord[]): Observable<string> {
    return this.http.post<string>(API_END_POINT.payroll.gl + 'AddNewRecordToGlExportQueue', record_list);
  }

  UpdateCompanyInterfaceFlagFromExport(ob: IUpdateCompanyInterfaceFlg): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateCompanyInterfaceFlagFromExport', ob);
  }

  GetGlCreateOutput(sess_id: string): Observable<IGlCreateOutput[]> {
    return this.http.get<IGlCreateOutput[]>(API_END_POINT.payroll.gl + 'GetGlCreateOutput?sess_id=' + sess_id);
  }

  FlushGlQueue(sess_id: string): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'FlushGlQueue?sess_id=' + sess_id);
  }

  FlushGlQueueForUser(): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'FlushGlQueueForUser');
  }

  GetGlStructureList(): Observable<IGLStructure[]> {
    return this.http.get<IGLStructure[]>(API_END_POINT.payroll.gl + 'GetGlStructureList');
  }

  GetGlEmployeeStructureList(): Observable<IGLEmployeeStructure[]> {
    return this.http.get<IGLEmployeeStructure[]>(API_END_POINT.payroll.gl + 'GetGlEmployeeStructureList');
  }

  UpdateGlCatGroupIsActiveFlag(ob: IActiveFlgUpdate): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.gl + 'UpdateGlCatGroupIsActiveFlag', ob);
  }

  UpdateGlEmpGroupIsActiveFlag(ob: IActiveFlgUpdate): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.gl + 'UpdateGlEmpGroupIsActiveFlag', ob);
  }

  GetGlStructureDropdownList(): Observable<IStructureDropdown[]> {
    return this.http.get<IStructureDropdown[]>(API_END_POINT.payroll.gl + 'GetGlStructureDropdownList');
  }

  GetGlStructureMultiselectDropdownList(): Observable<IStructureDropdown[]> {
    return this.http.get<IStructureDropdown[]>(API_END_POINT.payroll.gl + 'GetGlStructureMultiselectDropdownList');
  }

  GetEmpAllocateBasedDropdownList(): Observable<IStructureDropdown[]> {
    return this.http.get<IStructureDropdown[]>(API_END_POINT.payroll.gl + 'GetEmpAllocateBasedDropdownList');
  }

  GetIncludedPaycatDtlCodeForGlStructure(): Observable<IPaycatDtlCode[]> {
    return this.http.get<IPaycatDtlCode[]>(API_END_POINT.payroll.gl + 'GetIncludedPaycatDtlCodeForGlStructure');
  }
  GetIncludedPaycatDtlCodeForGlEmpStructure(): Observable<IPaycatDtlCodeEmp[]> {
    return this.http.get<IPaycatDtlCodeEmp[]>(API_END_POINT.payroll.gl + 'GetIncludedPaycatDtlCodeForGlEmpStructure');
  }

  GetIncludedEmployeeDtlCodeForGlStructure(): Observable<IEmpDtlCode[]> {
    return this.http.get<IEmpDtlCode[]>(API_END_POINT.payroll.gl + 'GetIncludedEmployeeDtlCodeForGlStructure');
  }
  getInitSystemPreferences2() {
    return this.http.get<ISystemPreferenceInit[]>(API_END_POINT.payroll.systemPreference + 'GetInitialUserPreferences');
  }

  GetEmployeeCategoryAllocation(): Observable<IGlEmpGroupAllocation[]> {
    return this.http.get<IGlEmpGroupAllocation[]>(API_END_POINT.payroll.gl + 'GetEmployeeCategoryAllocation');
  }
  GetDataFromMultipleServicesForGlEmpStructure(): Observable<any> {
    const res1 = this.getEmployeeForGLAccounts();
    const res2 = this.GetIncludedEmployeeDtlCodeForGlStructure();
    const res3 = this.GetGlEmployeeStructureList();
    const res4 = this.GetGlStructureMultiselectDropdownList();
    const res5 = this.GetIncludedPaycatDtlCodeForGlEmpStructure();
    const res6 = this.getPayrollCatForGLAccounts();
    const res7 = this.GetEmpAllocateBasedDropdownList();
    const res8 = this.GetLaborSetup();
    const res9 = this.GetEmployeeCategoryAllocation();
    const res10 = this.getDepartmentForGLAccounts();
    const res11 = this.GetLocationListForGlSetup();
    const res12 = this.GetDimensionsForGlSetup();
    return forkJoin([res1, res2, res3, res4, res5, res6, res7, res8, res9, res10, res11, res12]);
  }

  GetDataFromMultipleServicesForGlStructure(): Observable<any> {
    const res1 = this.getInitSystemPreferences2();
    const res2 = this.getPayrollCatForGLAccounts();
    const res3 = this.GetGlStructureDropdownList();
    const res4 = this.GetGlStructureMultiselectDropdownList();
    const res5 = this.GetGlStructureList();
    const res6 = this.GetIncludedPaycatDtlCodeForGlStructure();
    const res7 = this.GetLaborSetup();
    const res8 = this.GetGlGroupAccount();
    const res9 = this.GetPayrollCatForGLAccountsAllocation();
    return forkJoin([res1, res2, res3, res4, res5, res6, res7, res8, res9]);
  }
  GetAllocatedPaycats(struc_id: number): Observable<IAllocatedPaycat[]> {
    return this.http.get<IAllocatedPaycat[]>(API_END_POINT.payroll.gl + 'GetAllocatedPaycats?struc_id=' + struc_id);
  }

  GlStructureEdit(ob: IStructureEditObject): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.gl + 'GlStructureEdit', ob);
  }

  GlEmpStructureEdit(ob: IEmpStructureEditObject): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.gl + 'GlEmpStructureEdit', ob);
  }

  DeleteGlStructure(id: number): Observable<any> {
    const ob = {id: id};
    return this.http.post<any>(API_END_POINT.payroll.gl + 'DeleteGlStructure', ob);
  }

  DeleteGlEmpStructure(id: number): Observable<any> {
    const ob = {id: id};
    return this.http.post<any>(API_END_POINT.payroll.gl + 'DeleteGlEmpStructure', ob);
  }

  Glexport(ob: IGlExport): Observable<IGlExportOutput> {
    return this.http.post<IGlExportOutput>(API_END_POINT.payroll.gl + 'Glexport', ob);
  }

  // DeleteDataFromExportQueue(id_paycard: number, proc_user: string) {
  //  return this.http.delete<number>("../api/GL/DeleteDataFromExportQueue?id_paycard=" + id_paycard + "&proc_user=" + proc_user);
  // }
  GetLaborSetup(): Observable<ILaborSetupGL[]> {
    return this.http.get<ILaborSetupGL[]>(API_END_POINT.payroll.gl + 'GetLaborSetup');
  }
  GetLaborSetupForCondition(): Observable<ILaborSetupGL[]> {
    return this.http.get<ILaborSetupGL[]>(API_END_POINT.payroll.gl + 'GetLaborSetupForCondition');
  }
  GetDimensionsForGlSetup(): Observable<IDimensionForGlSetup[]> {
    return this.http.get<IDimensionForGlSetup[]>(API_END_POINT.payroll.gl + 'GetDimensionsForGlSetup');
  }
  GetLocationListForGlSetup(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.gl + 'GetLocationListForGlSetup');
  }
  GetEmployeeListForGlSetup(): Observable<IEmployeeListForGlSetup[]> {
    return this.http.get<IEmployeeListForGlSetup[]>(API_END_POINT.payroll.gl + 'GetEmployeeListForGlSetup');
  }
  GetEmployeeListForGlSetupEmployeeTab(): Observable<IEmployeeListForGlSetupEmpTab[]> {
    return this.http.get<IEmployeeListForGlSetupEmpTab[]>(API_END_POINT.payroll.gl + 'GetEmployeeListForGlSetupEmployeeTab');
  }
  GetGlAccOverwriteList(): Observable<IGlSetupOverwriteList[]> {
    return this.http.get<IGlSetupOverwriteList[]>(API_END_POINT.payroll.gl + 'GetGlAccOverwriteList');
  }
  UpdateGlSetupOverwriteList(list: IGlSetupOverwriteList[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateGlSetupOverwriteList', list);
  }
  DeleteGlAccOverwriteList(id: number): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'DeleteGlAccOverwriteList?id=' + id);
  }
  GetGlCustomSegmentList(): Observable<IGlCustomSegmentList[]> {
    return this.http.get<IGlCustomSegmentList[]>(API_END_POINT.payroll.gl + 'GetGlCustomSegmentList');
  }
  UpdateCustomSegmentList(list: IGlCustomSegmentList[]): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateCustomSegmentList', list);
  }
  DeleteCustomSegment(id: number): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'DeleteCustomSegment?id=' + id);
  }

  GetEmployeesForRiversePaycard(): Observable<IEmployeeListForRiversePaycard[]> {
    return this.http.get<IEmployeeListForRiversePaycard[]>(API_END_POINT.payroll.gl + 'GetEmployeesForRiversePaycard');
  }

  GetGlGroupAccount(): Observable<IGLGroupAccount[]> {
    return this.http.get<IGLGroupAccount[]>(API_END_POINT.payroll.gl + 'GetGlGroupAccount');
  }
  UpdateGLDimensionList(list: IGLsetupDimension[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateGLDimensionList', list);
  }
  UpdateGLsetupLocationList(list: IGLLocationUpdate[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateGLsetupLocationList', list);
  }
  UpdateGLsetupEmployeeList(list: IGLLocationUpdate[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateGLsetupEmployeeList', list);
  }
  GetPaycatForCategoryCondition(): Observable<ICategoryForCatCondition[]> {
    return this.http.get<ICategoryForCatCondition[]>(API_END_POINT.payroll.gl + 'GetPaycatForCategoryCondition');
  }
  GetLaborCategoryCondition(): Observable<ILaborCategoryCondition[]> {
    return this.http.get<ILaborCategoryCondition[]>(API_END_POINT.payroll.gl + 'GetLaborCategoryCondition');
  }
  DeleteLaborCategoryCondition(id: number): Observable<number> {
    return this.http.delete<number>(API_END_POINT.payroll.gl + 'DeleteLaborCategoryCondition?id=' + id);
  }
  UpdateLaborCategoryCondition(list: ILaborCategoryCondition[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateLaborCategoryCondition', list);
  }

  GetGEmployeeListForPaystubHistory(): Observable<IEmpsPaystubHistory[]> {
    return this.http.get<IEmpsPaystubHistory[]>(API_END_POINT.payroll.gl + 'GetGEmployeeListForPaystubHistory');
  }

  UpdateCodeFinderCharacters(ob: IUpdateCodeFinderCharacters): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.gl + 'UpdateCodeFinderCharacters', ob);
  }
  GetGlCodeFinderList(): Observable<ICodeFinder[]> {
    return this.http.get<ICodeFinder[]>(API_END_POINT.payroll.gl + 'GetGlCodeFinderList');
  }

  TestConnection(directInterfaceType: InterfaceTypeEnum, directInterfaceConfiguration: string): Observable<IConnectionResult> {
    const jsonObj = JSON.parse(directInterfaceConfiguration); // string to "any" object first

    if (directInterfaceType == InterfaceTypeEnum.Sap)      // (interfaceCode.trim() == 'SAPCLDD*') || (interfaceCode.trim() == 'SAPCLDS*')
    {
      const sapConfiguration = jsonObj as ISapConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'TestSAPConnection?conn_url=' + sapConfiguration.Url + '&conn_company=' + sapConfiguration.Company + '&conn_user=' + sapConfiguration.User + '&conn_pass=' + sapConfiguration.Password);
    } else if (directInterfaceType == InterfaceTypeEnum.BusinessCentral) // (interfaceCode.trim() == 'DYN365DO') || (interfaceCode.trim() == 'DYN365D*')
    {
      const businessCentralConfiguration = jsonObj as IBusinessCentralConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'TestBusinessCentral365Connection?bCTenantId=' + businessCentralConfiguration.TenantId + '&companyName=' + businessCentralConfiguration.Company + '&bcEnvironmentName=' + businessCentralConfiguration.ProductionOrSandbox);
    } else if (directInterfaceType == InterfaceTypeEnum.SageIntacct) 
    {
      let sageIntacctConfiguration = jsonObj as ISageIntacctConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'TestSageIntacctConnection?companyId=' + sageIntacctConfiguration.CompanyId + '&entityId=' + sageIntacctConfiguration.EntityId + '&userId=' + sageIntacctConfiguration.UserId + '&password=' + sageIntacctConfiguration.Password);
    }
  }

  GetAndUpdateCodeFinder(directInterfaceType: InterfaceTypeEnum, directInterfaceConfiguration: string): Observable<IConnectionResult> {
    const jsonObj = JSON.parse(directInterfaceConfiguration); // string to "any" object first
    if (directInterfaceType == InterfaceTypeEnum.Sap)      // (interfaceCode.trim() == 'SAPCLDD*') || (interfaceCode.trim() == 'SAPCLDS*')
    {
      const sapConfiguration = jsonObj as ISapConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'GetAndUpdateSAPChartOfAccounts?conn_url=' + sapConfiguration.Url + '&conn_company=' + sapConfiguration.Company + '&conn_user=' + sapConfiguration.User + '&conn_pass=' + sapConfiguration.Password);
    } else if (directInterfaceType == InterfaceTypeEnum.BusinessCentral) // (interfaceCode.trim() == 'DYN365DO') || (interfaceCode.trim() == 'DYN365D*')
    {
      const businessCentralConfiguration = jsonObj as IBusinessCentralConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'GetAndUpdateBusinessCentral365ChartOfAccounts?bCTenantId=' + businessCentralConfiguration.TenantId + '&companyName=' + businessCentralConfiguration.Company + '&bcEnvironmentName=' + businessCentralConfiguration.ProductionOrSandbox);
    } else if (directInterfaceType == InterfaceTypeEnum.SageIntacct) //(interfaceCode.trim() == 'DYN365DO') || (interfaceCode.trim() == 'DYN365D*')
    {
      let sageIntacctConfiguration = jsonObj as ISageIntacctConfiguration;
      return this.http.get<IConnectionResult>(API_END_POINT.payroll.gl + 'GetAndUpdateSageIntacctChartOfAccounts?companyId=' + sageIntacctConfiguration.CompanyId + '&entityId=' + sageIntacctConfiguration.EntityId + '&userId=' + sageIntacctConfiguration.UserId + '&password=' + sageIntacctConfiguration.Password);
    }
  }

  SeamlessGLExport(directInterfaceType: InterfaceTypeEnum, directInterfaceConfiguration: string, gl_export_output: IGlExportOutput): Observable<IConnectionResult> {
    const jsonObj = JSON.parse(directInterfaceConfiguration); // string to "any" object first
    if (directInterfaceType == InterfaceTypeEnum.Sap)      // (interfaceCode.trim() == 'SAPCLDD*') || (interfaceCode.trim() == 'SAPCLDS*')
    {
      const sapConfiguration = jsonObj as ISapConfiguration;
      return this.http.post<IConnectionResult>(API_END_POINT.payroll.gl + 'SeamlessSAPGLExport?conn_url=' + sapConfiguration.Url + '&conn_company=' + sapConfiguration.Company + '&conn_user=' + sapConfiguration.User + '&conn_pass=' + sapConfiguration.Password, gl_export_output.output);
    } else if (directInterfaceType == InterfaceTypeEnum.BusinessCentral) // (interfaceCode.trim() == 'DYN365DO') || (interfaceCode.trim() == 'DYN365D*')
    {
      const businessCentralConfiguration = jsonObj as IBusinessCentralConfiguration;
      return this.http.post<IConnectionResult>(API_END_POINT.payroll.gl + 'SeamlessBusinessCentral365GLExport?bCTenantId=' + businessCentralConfiguration.TenantId + '&companyName=' + businessCentralConfiguration.Company + '&bcEnvironmentName=' + businessCentralConfiguration.ProductionOrSandbox, gl_export_output.output);
    }
    else if (directInterfaceType == InterfaceTypeEnum.SageIntacct) // (interfaceCode.trim() == 'DYN365DO') || (interfaceCode.trim() == 'DYN365D*')
    {
      let sageIntacctConfiguration = jsonObj as ISageIntacctConfiguration;
      return this.http.post<IConnectionResult>(API_END_POINT.payroll.gl + 'SeamlessSageIntacctGLExport?companyId=' + sageIntacctConfiguration.CompanyId + '&entityId=' + sageIntacctConfiguration.EntityId + '&userId=' + sageIntacctConfiguration.UserId + '&password=' + sageIntacctConfiguration.Password, gl_export_output.output);
    }

  }
}
