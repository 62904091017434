import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BsCustomModalCloseDirective} from './bs-custom-modal-close.directive';
import {BsCustomModalOpenDirective} from './bs-custom-modal-open.directive';


@NgModule({
    declarations: [
        BsCustomModalOpenDirective,
        BsCustomModalCloseDirective
    ],
  exports: [
    BsCustomModalOpenDirective,
    BsCustomModalCloseDirective
  ],
    imports: [
        CommonModule
    ]
})
export class BsCustomModalModule {
}
