import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccessPermissionTypes, PermissionsList} from '../access-permission.model';
import {AccessControlService} from '../services/access-control.service';

export interface SaveButtonAccessConfig {
  area: string;
  id: any;
}

@Directive({
  selector: '[appSaveButtonAccessControl]'
})
export class SaveButtonAccessControlDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private accessControlService: AccessControlService
  ) {
  }

  @Input() set appSaveButtonAccessControl(config: SaveButtonAccessConfig) {
    this.viewContainerRef.clear();
    if (config.area) {
      let permission: boolean | PermissionsList;
      if (config.id) {
        permission = this.getPermissions(config.area, 'modify');
      } else {
        permission = this.getPermissions(config.area, 'add');
      }
      if (permission) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  private getPermissions(componentOrAction: string, accessType: AccessPermissionTypes) {
    const activeModule = this.accessControlService.activeModule;
    return this.accessControlService.getAccessPermissions(activeModule, componentOrAction, accessType);
  }

}
