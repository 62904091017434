<ng-container *transloco="let translate; read: 'chequePrintingDetails'">
  <div class="row" [formGroup]="parentForm">
    <div class="col-sm-6">
      <div class="row justify-content-between mb-2">
        <div class="col-3">
          <label for="payMethod" class="col-form-label">{{translate('payBy')}}</label>
        </div>
        <div class="col-9">
          <select id="payMethod" name="payMethod" formControlName="payMethod" class="form-select">
            <option *ngFor="let item of payMethodArr" [value]="item.no">{{item.name}}</option>
          </select>
          <div class="invalid-feedback" *ngIf="(payMethod.dirty || payMethod.touched) && payMethod.invalid">
            <span *ngIf="payMethod.errors.required" transloco="required"></span>
          </div>
        </div>
      </div>
      <div class="row justify-content-between mb-2">
        <div class="col-3">
          <label for="chqStyle" class="col-form-label">{{translate('style')}}</label>
        </div>
        <div class="col-9">
          <select id="chqStyle" name="chqStyle" formControlName="chqStyle" class="form-select" required>
            <option *ngFor="let item of chqStyleArr" [value]="item.no">{{item.name}}</option>
          </select>
          <div class="invalid-feedback" *ngIf="(chqStyle.dirty || chqStyle.touched) && chqStyle.invalid">
            <span *ngIf="chqStyle.errors.required" transloco="required"></span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="imprRptVisible">
        <div class="row justify-content-between mb-2">
          <div class="col-3">
            <label for="report_list" class="col-form-label">{{translate('importedReports')}}</label>&nbsp;&nbsp;
          </div>
          <div class="col-9">
            <select id="report_list" class="form-select" name="importFile" formControlName="importFile">
              <option *ngFor="let item of r_list" [value]="item.ID">{{item.NAME}}</option>
            </select>
          </div>
        </div>
      </ng-container>
      <div class="row justify-content-between mb-3">
        <div class="col-3">
          <label for="chqSortOrder" class="col-form-label">{{translate('sortOrder')}}</label>
        </div>
        <div class="col-9">
          <select id="chqSortOrder" name="chqSortOrder" class="form-select" formControlName="chqSortOrder">
            <option *ngFor="let item of chqSortOrderArr" [value]="item.no">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <accordion [isAnimated]="true">
            <accordion-group>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" accordion-heading type="button">
                {{translate('itemsToBePrintedOnTheCheque')}}
              </button>
              <div class="row">
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="chqNumCheque" id="chqNumCheque" />
                    <label class="form-check-label" for="chqNumCheque">{{translate('chequeVoucherNumber')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="comNameCheque" id="comNameCheque" />
                    <label class="form-check-label" for="comNameCheque">{{translate('companyName')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="letterAmnCheque" id="letterAmnCheque" />
                    <label class="form-check-label" for="letterAmnCheque">{{translate('printTheDollarInLetters')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="comAddrCheque" id="comAddrCheque" />
                    <label class="form-check-label" for="comAddrCheque">{{translate('companyAddress')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="dollarSignCheque" id="dollarSignCheque" />
                    <label class="form-check-label" for="dollarSignCheque">{{translate('printDollarSign', { value: currencySymbol})}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="dateIdentifier" id="dateIdentifier" />
                    <label class="form-check-label" for="dateIdentifier">{{translate('printDateIdentifier')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2 offset-sm-6 mt-3">
                  <label for="dateFormatCheque" class="form-label">{{translate('chequeDateFormat')}}</label>
                  <select class="form-select form-select-sm" id="dateFormatCheque" name="dateFormatCheque" formControlName="dateFormatCheque">
                    <option *ngFor="let item of dateFormatChequeArr" [ngValue]="item">{{item}}</option>
                  </select>
                </div>
              </div>
            </accordion-group>
            <accordion-group>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" accordion-heading type="button">
                {{translate('itemsToBePrintedOnThePaystub')}}
              </button>
              <div class="row">
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="empNamePaystub" id="empNamePaystub" />
                    <label class="form-check-label" for="empNamePaystub">{{translate('employeeName')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="comNamePaystub" id="comNamePaystub" />
                    <label class="form-check-label" for="comNamePaystub">{{translate('companyName')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="empNumPaystub" id="empNumPaystub" />
                    <label class="form-check-label" for="empNumPaystub">{{translate('employeeNumber')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="comAddrPaystub" id="comAddrPaystub" />
                    <label class="form-check-label" for="comAddrPaystub">{{translate('companyAddress')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="empAddrPaystub" id="empAddrPaystub" />
                    <label class="form-check-label" for="empAddrPaystub">{{translate('employeeAddress')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="deptPaystub" id="deptPaystub" />
                    <label class="form-check-label" for="deptPaystub">{{translate('department')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="empSINPaystub" id="empSINPaystub" />
                    <label class="form-check-label" for="empSINPaystub">{{translate('sin')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="jobTitlePaystub" id="jobTitlePaystub" />
                    <label class="form-check-label" for="jobTitlePaystub">{{translate('jobTitle')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="empBankAccPaystub" id="empBankAccPaystub" />
                    <label class="form-check-label" for="empBankAccPaystub">{{translate('employeeBankAccounts')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="payGrpPaystub" id="payGrpPaystub" />
                    <label class="form-check-label" for="payGrpPaystub">{{translate('payrollGroup')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="chqNumPaystub" id="chqNumPaystub" />
                    <label class="form-check-label" for="chqNumPaystub">{{translate('chequeVoucherNumber')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="payPrdPaystub" id="payPrdPaystub" />
                    <label class="form-check-label" for="payPrdPaystub">{{translate('payPeriodDates')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="chqDatePaystub" id="chqDatePaystub" />
                    <label class="form-check-label" for="chqDatePaystub">{{translate('chequeDate')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="ytdAmntPaystub" id="ytdAmntPaystub" />
                    <label class="form-check-label" for="ytdAmntPaystub">{{translate('yearToDateAmounts')}}</label>
                  </div>
                </div>
                <div class="col-sm-6 mb-2 offset-sm-6 mt-3">
                  <label class="form-label" for="dateFormatPayStub">{{translate('paystubDateFormat')}}</label>
                  <select class="form-select form-select-sm" id="dateFormatPayStub" name="dateFormatPayStub" formControlName="dateFormatPayStub">
                    <option *ngFor="let item of dateFormatPayStubArr" [value]="item">{{item}}</option>
                  </select>
                </div>
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="row">
        <div class="col-8">
          <app-search-box [ngModel]="employeeSearchText" (keyup)="searchEmployeeList($event)" [ngModelOptions]="{standalone: true}" placeholder="{{translate('searchEmployeeHere')}}"></app-search-box>
        </div>
        <div class="col-auto">
          <div class="btn-group" dropdown container="body">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
              <i class="fas fa-filter"></i><span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" (click)="onclickClear(1)"><span class="dropdown-item">{{translate('withEmailAddress')}}</span></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem" (click)="onclickClear(0)"><span class="dropdown-item">Clear Filter</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped table-scroll table-responsive table-sm">
            <thead>
              <tr>
                <th style="width: 40px">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="allbutton" [checked]="isAllselected" formControlName="allbutton"
                           (change)="employeeListCheckAll($event)">
                  </div>
                </th>
                <th style="width: 40px"></th>
                <th>
                  <span class=" cursor-pointer sort-header" [class.active-col]="sortOrder=='i'" (click)="sortOptionChange('i')">
                    <span transloco="number"></span>
                    <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec||sortOrder!='i' "></i>
                    <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='i'"></i>
                  </span>
                </th>
                <th>
                  <span class=" cursor-pointer sort-header" [class.active-col]="sortOrder=='n'" (click)="sortOptionChange('n')">
                    <span transloco="name"></span>
                    <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec||sortOrder!='n' "></i>
                    <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='n'"></i>
                  </span>
                </th>
                <!--<th>Cheque No</th>-->
                <th>
                  <span class=" cursor-pointer sort-header" [class.active-col]="sortOrder=='pf'" (click)="sortOptionChange('pf')">
                    {{translate('payPeriod')}}
                    <i class="fas fa-chevron-down sort-Icon ml-2" *ngIf="!sortdirec||sortOrder!='pf' "></i>
                    <i class="fas fa-chevron-up sort-Icon ml-2" *ngIf="sortdirec && sortOrder=='pf'"></i>
                  </span>
                </th>
                <th style="width: 40px"><i class="fas fa-envelope"></i></th>
                <th style="width: 40px"><i class="fas fa-print"></i></th>
                <th style="width: 40px"><i class="fas fa-file-pdf"></i></th>
              </tr>
            </thead>
            <tbody style="height: 35vh">
              <ng-container *ngIf="filteredEmployeeList">
                <tr *ngFor="let c of filteredEmployeeList | orderBy: sortOrder:sortdirec; let ind=index">
                  <td style="width: 40px">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="{{ind + c.i}}" [checked]="c.flg==1" (change)="checkBoxClicked($event, c.j, c.sp)">
                    </div>
                  </td>
                  <td style="width: 40px">
                    <ng-container *ngIf="(c.pma != null && c.pma != '') || (c.ma != null && c.ma != '') && (c.em != null && c.em) && (c.pw != null && c.pw != '')">
                      <i class="far fa-envelope"></i>
                    </ng-container>
                  </td>
                  <td>
                    <span class="sort-cell">{{c.i}}</span>
                  </td>
                  <td>
                    <span class="sort-cell">{{c.n}}</span>
                  </td>
                  <td>
                    <span class="sort-cell">{{c.pf}}</span>
                  </td>
                  <td style="width: 40px">
                    <ng-container *ngIf="c.pe == 1">
                      <i class="fas fa-check"></i>
                    </ng-container>
                  </td>
                  <td style="width: 40px">
                    <ng-container *ngIf="c.pp == 1">
                      <i class="fas fa-check"></i>
                    </ng-container>
                  </td>
                  <td style="width: 40px">
                    <ng-container *ngIf="c.pdf == 1">
                      <i class="fas fa-check"></i>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

