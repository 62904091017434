import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BackDropDirective} from './back-drop.directive';
import {BackdropComponent} from './backdrop/backdrop.component';
import {OverlayDropComponent} from './overlay-drop/overlay-drop.component';


@NgModule({
  declarations: [BackdropComponent, BackDropDirective, OverlayDropComponent],
  imports: [
    CommonModule
  ],
  exports: [
    BackDropDirective,
    BackdropComponent
  ]
})
export class BackdropModule {
}
