import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule} from '@ngneat/transloco';
import {cookiesStorage, TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangModule} from '@ngneat/transloco-persist-lang';
import {environment} from '../../../environments/environment';
import {LANGUAGES_AVAILABLE} from './i18.config';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  imports: [
    TranslocoPersistLangModule.forRoot({
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: cookiesStorage()
      }
    })
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: LANGUAGES_AVAILABLE,
        defaultLang: LANGUAGES_AVAILABLE[0],
        // Remove this option if your application doesn't support changing language in runtime.
        fallbackLang: LANGUAGES_AVAILABLE[0],
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader}
  ]
})
export class TranslocoRootModule {
}
