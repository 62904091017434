import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'payrollCategoryClass'
})
export class PayrollCategoryClassPipe implements PipeTransform {

  transform(value: unknown, type?: unknown): string {
    let imgClass = '';
    let colorClass = '';
    switch (value) {
      case 1:
      case 9: {
        imgClass = 'cImgFEIcon2';
        colorClass = 'Earning';
        break;
      }
      case 2:
      case 10: {
        imgClass = 'cImgFDIcon';
        colorClass = 'Deduction';
        break;
      }
      case 3:
      case 11: {
        imgClass = 'cImgFCIcon';
        colorClass = 'Contributions';
        break;
      }
      case 4: {
        imgClass = 'cImgBSIcon';
        colorClass = 'Basic-salary';
        break;
      }
      case 5: {
        imgClass = 'cImgFExIcon';
        colorClass = 'Exemptions';
        break;
      }
      case 6:
      case 13: {
        imgClass = 'cImgFLIcon';
        colorClass = 'Benefits';
        break;
      }
      case 7: {
        imgClass = 'cImgAIcon';
        colorClass = 'Accruals';
        break;
      }
      case 8: {
        imgClass = 'cImgIT';
        colorClass = 'IncomeTax';
        break;
      }
      case 14: {
        imgClass = 'cImgSDIcon';
        colorClass = 'Deduction';
        break;
      }
      case 15: {
        imgClass = 'cImgSHPcon';
        colorClass = 'Earning';
        break;
      }
      default: {
        imgClass = '';
        colorClass = '';
        break;
      }
    }

    if (type && type === 2){
      return `${imgClass} ${colorClass} variable-cat`;
    }
    return `${imgClass} ${colorClass}`;
  }

}
