<div class="p-3">
  <div class="row align-items-center">
    <div class="col">
      <h5 class="m-0" transloco="payrollProcessQueue.title"></h5>
      <!--      <h5 class="m-0">Payroll Process Queue</h5>-->
    </div>
  </div>
</div>
<ngx-simplebar style="position: relative; height: 130px;">
  <ng-container *ngIf="payrollProgress; else noNotifications">
    <div class="text-reset notification-item p-3">
      <div class="d-flex">
        <div class="flex-grow-1">
          <h6 class="mt-0 mb-1" *ngIf="payrollProgress?.action === 'process'">Payroll Process</h6>
          <h6 class="mt-0 mb-1" *ngIf="payrollProgress?.action === 'rollback'">Payroll Rollback</h6>
          <p class="mt-0 mb-1">{{payrollProgress.processPeriod}}</p>
          <div class="font-size-14 text-muted">
            <div class="row justify-content-between">
              <div class="col-auto fs-5">
                <ng-container [ngSwitch]="payrollProgress?.status">
                  <span *ngSwitchCase="'Queued'" class="badge badge-soft-primary" transloco="queued"></span>
                  <span *ngSwitchCase="'InProgress'" class="badge badge-soft-warning" transloco="inProgress"></span>
                  <span *ngSwitchCase="'Completed'" class="badge badge-soft-success" transloco="completed"></span>
                  <span *ngSwitchCase="'Failed'" class="badge badge-soft-danger" transloco="failed"></span>
                </ng-container>
              </div>
              <div class="col-auto">
                <p class="mb-1">{{payrollProgress?.percentage | number:'1.0-0'}}/100%</p>
              </div>
            </div>
            <progressbar [max]="100" [value]="payrollProgress?.percentage" type="success" [striped]="payrollProgress?.percentage < 100" [animate]="true">
              {{payrollProgress?.percentage ?? 0 | number:'1.0-0'}}%
            </progressbar>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ngx-simplebar>
<ng-template #noNotifications>
  <div class="text-reset notification-item">
    <div class="media">
      <div class="media-body">
        <p class="fs-6 ps-4" transloco="payrollProcessQueue.noProcessInProgress"></p>
      </div>
    </div>
  </div>
</ng-template>
