import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, EMPTY, of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import {AccessControlService} from '../../account/access-control/services/access-control.service';
import {SecurityKeys} from '@shared/models/ISecurity';
import {PermissionsList} from '../../account/access-control/access-permission.model';

export interface WizardStepsMetaDataChild {
  sc?: number;
  scap?: string;
  af?: number;
  mf?: number;
  wf?: number;
  sf?: number;
  gc?: number;
  so?: number;
  srl?: string;

  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  currentWizard$ = new BehaviorSubject<any>(null);
  currentWizardVW$ = new BehaviorSubject<any>(null);
  currentGroupInfo$ = new BehaviorSubject<any>(null);
  wizardMode$ = new BehaviorSubject<boolean>(false);
  currentStep$ = new BehaviorSubject<number>(null);
  currentOrder$ = new BehaviorSubject<number>(null);
  completed$ = new BehaviorSubject<boolean>(false);
  dirtyStep$ = new BehaviorSubject<boolean>(false);

  onWizardMode = false;
  wizardRoutesData = [];

  routinglinks = [
    ['process/processwizard/wizard/payPeriod'],
    ['process/processwizard/wizard/TimeSheet'],
    ['process/processwizard/wizard/PayrollProcess', 0],
    ['process/processwizard/wizard/PrintChequeAndPaystub'],
    ['process/processwizard/wizard/Labor'],
    ['process/processwizard/wizard/DataExportList', 1],
    ['report/ReportNavigator'],
    ['configuration/tools/Backup'],
    ['process/processwizard/wizard/VisaFinalize']
  ];

  constructor(
    private http: HttpClient,
    private _router: Router,
    private accessControlService: AccessControlService
  ) {
    if (
      window.location.hash.split('/').pop() === 'Wizard'
      || window.location.hash === '#/'
      // || sessionStorage.getItem('wizard_mode') === 'true'
    ) {
      this.wizardMode$.next(true);
    }

    this._router.events.pipe(filter(value => value instanceof NavigationEnd)).subscribe(() => {
      const isWizard = this._router.url.split('/').includes('wizard');
      this.updateWizardMode(isWizard);
    });
  }

  completeInvokedFromComp(completed: boolean) {
    this.completed$.next(completed);
  }

  getWizard(gc: number, fd: Date, td: Date, sl: number) {
    // let _fd = new Date(fd.toString())
    // let _td = new Date(td.toString())
    // const params = new HttpParams()
    //  .set('gc', gc.toString())
    //  .set('fd', _fd.toISOString())
    //  .set('td', _td.toISOString())
    //  .set('sl', sl.toString())
    // return this.http.get<any>(API_END_POINT.payroll.wizard + "getProcessWizardForPayPeriod", { params });
    return this.http
      .get<any>(API_END_POINT.payroll.wizard + 'getProcessWizardForPayPeriod?gc=' + gc + '&fd=' + fd + '&td=' + td + '&sl=' + sl);
  }

  getWizardVWData(isLabor: number) {
    // static of view of wizard steps
    return this.http.get<any>(API_END_POINT.payroll.wizard + 'getWizardVWData?isLabor=' + isLabor);
  }

  updateWizardVW(data: any) {
    this.getWizardVWData(data).subscribe(d => this.currentWizardVW$.next(d));
  }

  updateWizard(data: any | WizardStepsMetaDataChild[]) {
    if (JSON.stringify(data) !== JSON.stringify(this.currentWizard$.value)) {

      const reportPermissions = this.accessControlService
        .getAccessPermissions('payroll', SecurityKeys.payrollReportsMain.payrollReports) as PermissionsList;
      // const backupPermissions = this.accessControlService
      //   .getAccessPermissions('core', SecurityKeys.coreModule.systemTools.companyBackup) as PermissionsList;

      // if (!reportPermissions?.view) {
      //   const routeIndex = data.findIndex(v => v.scap === 'Reports');
      //   if (routeIndex !== -1) {
      //     data?.splice(routeIndex, 1);
      //   }
      // }
      // if (!backupPermissions?.view) {
      //   const routeIndex = data.findIndex(v => v.scap === 'Back Up');
      //   if (routeIndex !== -1) {
      //     data?.splice(routeIndex, 1);
      //   }
      // }

      this.routinglinks = data.map(
        x => x?.srl ? x.srl
            .replace('Wizard', 'payPeriod')
            .replace('processwizard', 'processwizard/wizard')
            .replace('adminsetup/interface/DataExportList', 'process/processwizard/wizard/DataExportList')
            .replace('report/ReportNavigator', 'process/processwizard/wizard/Reports')
            .replace('configuration/tools/Backup', 'process/processwizard/wizard/BackUp')
            .split(',')
          : []);
      this.currentWizard$.next(data);
    }
  }

  updatePayrollGroup(data: any) {
    // if user already select group and go to other tab and then come back in to select group should not display empty
    if (!this.currentGroupInfo$.value) {
      this.currentGroupInfo$.next(data);
    } else if (this.currentGroupInfo$.value) {
      if (data.gc !== this.currentGroupInfo$.value.gc
        || data.payrollPeriod !== this.currentGroupInfo$.value.payrollPeriod
        || data.payfrequency !== this.currentGroupInfo$.value.payfrequency
        || data.paycard_type !== this.currentGroupInfo$.value.paycard_type
        || data.cd !== this.currentGroupInfo$.value.cd
        || data.td !== this.currentGroupInfo$.value.td
        || data.fd !== this.currentGroupInfo$.value.fd) {
        this.currentGroupInfo$.next(data);
      }
    }
  }

  updateWizardMode(flg: boolean) {
    if (!flg) {
      sessionStorage.removeItem('wizard_mode');
      this.onWizardMode = false;
      this.currentWizard$.next(null);
      this.currentGroupInfo$.next(null);
      this.currentOrder$.next(null);
      this.currentStep$.next(null);
      this.wizardMode$.next(false);
    } else {
      this.onWizardMode = true;
      if (this.wizardMode$.value !== flg) {
        this.wizardMode$.next(flg);
      }
    }
  }

  updateStep(s: number) {
    if (s !== this.currentStep$.value) {
      this.currentStep$.next(s);
    }
  }

  updateDirtyStep(s: boolean) {
    this.dirtyStep$.next(s);
  }

  updateOrder(o: number) {
    if (o !== this.currentOrder$.value) {
      this.currentOrder$.next(o);
    }
  }

  updateFlag(flg: number) {
    if (this.wizardMode$.value) {
      if (this.currentGroupInfo$.value) {
        const obj = {
          flg,
          gc: this.currentGroupInfo$?.value?.gc,
          fd: this.currentGroupInfo$?.value?.fd,
          td: this.currentGroupInfo$?.value?.td,
          sl: this.currentGroupInfo$?.value?.sl,
          sc: this.currentStep$?.value
        };
        return this.http.post(API_END_POINT.payroll.wizard + 'updateStepFlag', obj);
      }
      return EMPTY;
    } else {
      return of(null);
    }
  }

}
