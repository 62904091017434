import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[appCustomMaxLength]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomMaxLengthDirective,
    multi: true
  }]
})
export class CustomMaxLengthDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {

    return ((control.value !== null) && (String(control.value).length > 10)) ? { 'overMaxLength': true } : null;
  }


  constructor() { }

}
