<ng-container *transloco="let translate; read: 'bankFile'">
  <div class="row justify-content-between mb-3" *ngIf="!_wizardService.onWizardMode">
    <h4 class="col-6">{{translate('bankFile')}}</h4>
    <div class="col-auto">
      <app-back-routing-btn [routeTo]="['/adminsetup/interface/DataExportList']" [text]="translate('backToBankInterfaceList')"></app-back-routing-btn>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="row justify-content-between">
          <div class="col-sm-8 order-sm-1 order-2">
            <label for="Bank_Name" class="form-label"><span transloco="name"></span></label>
            <div class="row">
              <div class="col-md-6 mb-2">
                <input type="text" name="BankNameCtrl" #BankNameCtrl="ngModel" [(ngModel)]="BankName" class="form-control" id="Bank_Name" required>
                <div class="invalid-feedback">
                  <span *ngIf="BankNameCtrl.errors && BankNameCtrl.errors.required"><span transloco="require"></span></span>
                </div>
              </div>
              <div class="col-sm-4 mb-2 d-flex align-items-center">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" name="isBankFileActive" [(ngModel)]="isBankFileActive" id="activeBankFileCheck" />
                  <label class="form-check-label" for="activeBankFileCheck"><span transloco="active"></span> </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-2 d-flex justify-content-end align-items-start order-sm-2 order-1">
            <button type="submit" class="btn btn-success me-2" [disabled]="!f.form.valid"><i class="fas fa-save"></i></button>
            <button type="button" class="btn btn-secondary" [disabled]="!f.form.valid || isDisabled" (click)="CreateBankFile()"><i class="fas fa-hourglass-half"></i>&nbsp;<span transloco="create"></span></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label for="bankList" class="col-form-label">{{translate('companyBank')}}</label>
                <select id="bankList" name="bankListCtrl" [(ngModel)]="bankId" class="form-select" #bankListCtrl="ngModel" (change)="onSelectedBankChanged()"
                        required>
                  <option *ngFor="let item of bankListArr" [value]="item.i">{{item.n}}</option>
                </select>
                <div class="invalid-feedback">
                  <span *ngIf="bankListCtrl.errors && bankListCtrl.errors.required"><span transloco="require"></span></span>
                </div>
              </div>
              <div class="col-12" *ngIf="(!isDisabled && ((isUS && showNextBankSequenceNumberUSA.includes(bankLayoutId)) || (!isUS && !hideNextBankSequenceNumberCanada.includes(bankLayoutId))))">
                <label for="SeqNum" class="col-form-label  mr-2">{{translate('nextBankFileSequenceNumber')}}</label>
                <input type="text" name="SeqNumCtrl" #SeqNumCtrl="ngModel" [(ngModel)]="SeqNum" class="form-control" id="SeqNum">
                <div class="invalid-feedback" *ngIf="f.submitted && SeqNumCtrl.invalid && (SeqNumCtrl.dirty || SeqNumCtrl.touched)">
                  <div *ngIf="SeqNumCtrl.errors.required">
                    {{translate('sequenceNumberIsRequired')}}
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="!isDisabled">
                <label for="depositDate" class="col-form-label  mr-2">{{translate('depositDate')}}</label>
                <input type="date" id="depositDate" class="form-control" name="depositDate" [(ngModel)]="depositDate" required
                       [attr.data-date]="sysPreference?.df ? (depositDate ? (depositDate | date: sysPreference?.df) : sysPreference?.df) :  depositDate ? fromJsonDate(depositDate) : 'yyyy-mm-dd'  ">
              </div>
              <div class="col-12" *ngIf="isUS && !isDisabled && (bankLayoutId != 171)">
                <label for="include" class="col-form-label  mr-2"><span transloco="include"></span></label>
                <select name="include" id="include" [(ngModel)]="include" class="form-select">
                  <option *ngFor="let item of include_list" [value]="item.value">{{translate(item.name)}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-12" *ngIf="!isDisabled">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" name="isAllPGs" [(ngModel)]="isAllPGs" id="activeAllPGs" (change)="valueChange()" />
                <label class="form-check-label" for="activeAllPGs">{{translate('allPayrollGroups')}}  </label>
              </div>
            </div>
            <div class="col-12" *ngIf="!isDisabled">
              <label for="PG_select" class="col-form-label">{{translate('payrollGroups')}}</label>
              <kendo-multiselect name="PG_select" class="form-select p-0" id="PG_select" [(ngModel)]="selectedGroups" [data]="payrollGroups" [textField]="'b'"
                                 [valueField]="'a'" style="width:100%" [filterable]="true" [disabled]="isAllPGs" (valueChange)="valueChange()">
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="fileModal" aria-hidden="true" id="fileModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{translate('createdFile')}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <alert type="info">{{resultMessage}}</alert>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <table class="table table-scroll">
                <tbody *ngIf="bankFileList" style="height: 35vh">
                  <tr *ngFor="let item of bankFileList; ">
                    <td>{{item.Line}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="fileName" class="col-form-label">{{translate('fileName')}}</label>
              <input type="text" name="fileName" [(ngModel)]="FileName" class="form-control" id="fileName">
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="saveFile()"><i class="fas fa-save"></i>&nbsp;{{translate(btnDownload)}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
