import {Component} from '@angular/core';

@Component({
  selector: 'app-overlay-drop',
  templateUrl: './overlay-drop.component.html',
  styleUrls: ['./overlay-drop.component.scss']
})
export class OverlayDropComponent {
  overlayText: string;

  constructor() {
  }

}
