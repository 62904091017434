import {DatePipe} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {PayrollCategoryService} from '@harmony-modules/payroll/services/payroll-setup/payroll-category.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ShowMessageService} from '@services/show-message.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {of, Subscription} from 'rxjs';
import {concatMap, take} from 'rxjs/operators';
import {IPayrollGroupsVW} from 'src/app/shared/models/ipayroll-group';
import {WizardService} from 'src/app/shared/services/wizard.service';
import { GlobalService } from '@services/global.service';


declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-payroll-group-selection',
  templateUrl: './payroll-group-selection.component.html',
  styleUrls: ['./payroll-group-selection.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'payrollGroupSelection', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class PayrollGroupSelectionComponent implements OnInit {
  groupCode: any;
  payrollGroups: IPayrollGroupsVW[];
  payPeriodList: any[];
  status: number;
  message: string = '';
  payrollPeriod: string = '';
  payfrequency: string = '';
  paycard_type: string;
  cutoff_date: string;
  selectedPayPeriod: any;
  sysPref: any;
  wizardMode: boolean;
  cateList: any[];
  /** t(payrollGroupSelection.regular, payrollGroupSelection.special, payrollGroupSelection.adjustment) */
  paycard_type_list: any = ['regular', 'special', 'adjustment'];
  $subTOoClear: Subscription;
  SearchTxt: string = '';
  categoryTypeList: any[] = [{name: '', cssClass: ''}];
  selectedCateType: string = '';
  predefinedList: number[] = [];
  currentYear: number;
  newTaxTable: boolean = false;
  lastTaxVersionOfYear: number;
  payrollYear: string;
  resultsFromMultipleServices: Subscription;

  get invalidSelectedPayPeriod() {
    return !(this.selectedPayPeriod && this.groupCode && this.paycard_type && this.cutoff_date);//new Date(this.cutoff_date)
  }


  constructor(
    private _sysPrefService: SystemPreferenceService,
    private _payrollCategoryService: PayrollCategoryService,
    private _globalService: GlobalService,
    private _datePipe: DatePipe,
    private _payrollService: PayrollGroupService,
    private _wizardService: WizardService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService) {
  }

  ngOnInit() {

    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
      this.paycard_type_list = this.paycard_type_list.map(x => this.transloco.translate(x, {}, 'payrollGroupSelection'));
    });
    this.resultsFromMultipleServices = this._globalService.GetPayrollYear().subscribe(
      res => {
        this.payrollYear = res[0];
        this.lastTaxVersionOfYear = res[1];
        this._sysPrefService.getinitSystemPreferences().subscribe(data => {
          if (data && data.length > 0) {
            this.sysPref = data[0];
            if (!this.sysPref.df) {
              this.sysPref.df = 'yyyy-MM-dd';
            }
            if (this.sysPref.y == 12) {
              if ((this.sysPref.d != null) && (this.sysPref.v != null) && (this.sysPref.d <= this.payrollYear)) {
                if (this.lastTaxVersionOfYear > (this.sysPref.d + this.sysPref.v.replace('.', ''))) {
                  this.newTaxTable = true;
                }
              }
            }
          }
          this._payrollCategoryService.getDisabledCategoryList().subscribe(data => {
            if (data && data.length > 0) {
              this.predefinedList = data;
            }
          });
          this.$subTOoClear = this._payrollService.getPayrollAllGroups_v3()
            .pipe(
              concatMap(data => {
                if (data && data.length) {
                  this.payrollGroups = data;
                }

                return this._payrollService.GetCurrentYear();
              }),
              concatMap(res => {
                if (res) {
                  this.currentYear = res;
                }
                return this._wizardService.wizardMode$;
              }
              ),
              concatMap(mode => {
                this.wizardMode = mode;
                //to keep the wizard bar when refresh
                //this._wizardService.updateWizardMode(this.wizardMode)
                this.groupCode = null;
                this.payPeriodList = null;
                this.payrollPeriod = '';
                this.payfrequency = '';
                this.paycard_type = null;
                this.cutoff_date = null;
                this.selectedPayPeriod = null;
                this.cateList = null;
                this.SearchTxt = '';
                this.categoryTypeList = [{ name: '', cssClass: '' }];
                this.selectedCateType = '';
                if (mode) {
                  return this._wizardService.currentGroupInfo$.pipe(take(1), untilDestroyed(this));
                } else {
                  return of(null);
                }
              })
            )
            .subscribe(data => {
              if (data) {
                this.onPGDropDownChange_V2({ a: data.gc });
              }
            });
        });
      });


  }

  getClassesForIcon(code: number) {
    let cssClasses = [];
    let imgClass: any;
    let colorClass: any;

    switch (code) {
      case 1: {
        imgClass = 'cImgFEIcon2';
        colorClass = 'Earning';
        break;
      }
      case 2: {
        imgClass = 'cImgFDIcon';
        colorClass = 'Deduction';
        break;
      }
      case 3: {
        imgClass = 'cImgFCIcon';
        colorClass = 'Contributions';
        break;
      }
      case 4: {
        imgClass = 'cImgBSIcon';
        colorClass = 'Basic-salary';
        break;
      }
      case 5: {
        imgClass = 'cImgFExIcon';
        colorClass = 'Exemptions';
        break;
      }
      case 6: {
        imgClass = 'cImgFLIcon';
        colorClass = 'Benefits';
        break;
      }
      case 7: {
        imgClass = 'cImgAIcon';
        colorClass = 'Accruals';
        break;
      }
      case 8: {
        imgClass = 'cImgIT';
        colorClass = 'IncomeTax';
        break;
      }
      default: {
        imgClass = '';
        colorClass = '';
        break;
      }
    }
    cssClasses.push(imgClass);
    cssClasses.push(colorClass);
    cssClasses.push('category-image-thumbnail');
    return cssClasses;
  }

  onFormSubmit() {
    this.selectedPayPeriod.g = this.cutoff_date;
    this.selectedPayPeriod.paycard_type = this.paycard_type.charAt(0).toUpperCase();

    if ((this.sysPref.y == 12) && (this.newTaxTable == true)) { // Canadian only
      if (this.selectedPayPeriod.g.replace('-', '').substring(0,6) >= this.lastTaxVersionOfYear.toString().substring(0,6)) {
        var that = this;
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        const buttonCancel = this.transloco.translate('cancel');
        const message = this.transloco.translate('alerts.continue', {}, 'payrollGroupSelection');
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [buttonOk]: {
              action: function() {
                that.updatePayPeriodForGroup();
              }
            },
            [buttonCancel]: {
              action: function() {
                return;
              }
            }
          },
          'message': message
        });
      } else {
        this.updatePayPeriodForGroup();
      }
    } else {
      this.updatePayPeriodForGroup();
    }

  }

  updatePayPeriodForGroup() {
    this._payrollService.updatePayPeriodForGroup(this.selectedPayPeriod)
      .pipe(
        concatMap(val => {
          let obj: any = {gc: this.groupCode.a, csv: ''};
          if (!this.selectedPayPeriod.SELECTED_PERIOD_DISABLED) {
            this.cateList.filter(x => x.checked)
              .map(y => {
                return {gc: this.groupCode.a, dc: y.o};
              })
              .forEach(x => {
                obj.csv += (',' + x.dc);
              });
            obj.csv = obj.csv.substring(1);
            return this._payrollService.updateSelecetedPayrollCategories(obj);
          } else {
            return of(1);
          }

        }),
        untilDestroyed(this)
      ).subscribe(suc => {
      this._wizardService.updateFlag(2).pipe(untilDestroyed(this)).subscribe(data => {
        if (data) {
          this._wizardService.updateWizard(data);
        }
      });
      var title = this.transloco.translate('success');
      var message = this.transloco.translate('recordSuccessfullyUpdated');
      showMsg({
        'type': 'nonsticky',
        'status': 'success',
          'title': title,
          'message': message,
          'delay': '1000'
        });
      }, err => {
        var title = this.transloco.translate('error');
        var message = this.transloco.translate('alerts.errorOccurredWhileUpdatingPayPeriod', {}, 'payrollGroupSelection');
        showMsg({
          'type': 'nonsticky',
          'status': 'error',
          'title': title,
          'message': message
        });
      });
  }

  onPPDropDownChange(evt: any) {
    this.selectedPayPeriod = evt;
    this.cutoff_date = this.fromJsonDate(this.selectedPayPeriod.g);
    this.paycard_type = this.selectedPayPeriod.paycard_type == 'R' ?
      this.transloco.translate('regular', {}, 'payrollGroupSelection') : (this.selectedPayPeriod.paycard_type == 'S'
        ? this.transloco.translate('special', {}, 'payrollGroupSelection') : this.transloco.translate('adjustment', {}, 'payrollGroupSelection'));
    if (this.wizardMode) {
      this.updateWizard();
    }
  }


  fromJsonDate(jDate: any): string {
    let dateStr = (new Date(jDate.substring(0, 10))).toISOString().substring(0, 10);
    //console.log(dateStr);
    return dateStr;
  }

  getDropdownListValues(data: any[]): any[] {
    this.categoryTypeList.length = 0;
    let tempArr: any[];

    if (data != null) {
      tempArr = Array.from(new Set(data.map(s => s.k))).map(k => {
        return {name: k, code: data.find(s => s.k === k).c};
      }); //p -> eraneddeduct code // c -> cat_typ_type

    }
    //console.log('tempArr==>');
    //console.log(tempArr);
    for (let i of tempArr) {
      if (i.code === 1) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgFE'});
      } else if (i.code === 2) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgFD'});
      } else if (i.code === 3) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgFC'});
      } else if (i.code === 4) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgBS'});
      } else if (i.code === 5) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgFEx'});
      } else if (i.code === 6) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgFL'});
      } else if (i.code === 7) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgA'});
      } else if (i.code === 8) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgIT'});
      } else if (i.code === 14) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgSD'});
      } else if (i.code === 15) {
        this.categoryTypeList.push({name: i.name, cssClass: 'cImgSHP'});
      }
    }
    //console.log('categoryTypeList==>');
    //console.log(this.categoryTypeList);

    return this.categoryTypeList;
  }

  onPGDropDownChange_V2(evt: any) {
    if (!evt.a) {
      return;
    } else {
      this._payrollCategoryService.GetAllCategoryList(evt.a).subscribe(data => {
        this.cateList = data && data.length ? data : null;
        if (this.cateList) {
          let result = this.cateList.filter(x => {
            return x.gc;
          });
          if (result.length == 0) {
            this.cateList.forEach(x => {
              x.checked = true;
            });
          } else {
            this.cateList.forEach(x => {
              (x.checked = x.gc || this.checkPredefineCates(x.o));
            });
          }

          this.categoryTypeList = this.getDropdownListValues(this.cateList);

        }
      });

      this.groupCode = evt;
      this._payrollService.getPayPeriodsForGroup(evt.a).subscribe(
        val => {
          this.payPeriodList = val.PayPeriodList;
          this.status = val.status;
          this.message = this.showMessage.translateErrorMessage(val.message);
          this.paycard_type = null;
          this.cutoff_date = null;
          this.selectedPayPeriod = null;
          this.payPeriodList.forEach(x => {
            x.image_path = x.timesheet || x.unlocked ? '../css/icons/UNLOCK.png' : (x.locked ? '../css/icons/lock.png' : null);
          });

          let _selected = this.payPeriodList.find(x => x.SELECTED_PERIOD == 1 || x.SELECTED_PERIOD == -1);

          switch (this.status) {
            case 0: // No message
              this.selectedPayPeriod = _selected;
              this.cutoff_date = this.fromJsonDate(this.selectedPayPeriod.g);
              this.paycard_type = this.selectedPayPeriod.paycard_type == 'R'
                ? this.transloco.translate('regular', {}, 'payrollGroupSelection') : (this.selectedPayPeriod.paycard_type == 'S'
                  ? this.transloco.translate('special', {}, 'payrollGroupSelection') : this.transloco.translate('adjustment', {}, 'payrollGroupSelection'));
              if (this.wizardMode) {
                this.updateWizard();
              }
              break;
            case 1: // Currently selected pay period is in year  and it has unprocessed paycards or timesheets. Please switch to year  and finish your payroll run there.
              var title = this.transloco.translate('error');
              const buttonOk = this.transloco.translate('ok');
              showMsg({
                'type': 'sticky',
                'status': 'error',
                'title': title,
                'buttons': {
                  [buttonOk]: function() {
                    return;
                  }
                },
                'message': this.message,
                'delay': '2000'
              });

              break;
            case 2: // Currently selected pay period is in year  . Do you want to unselect period and switch to current year?
              var that = this;
              var title = this.transloco.translate('error');
              const buttonYes = this.transloco.translate('yes');
              const buttonNo = this.transloco.translate('no');
              const labels = ['regular', 'special', 'adjustment'].map(val => this.transloco.translate(val));
              showMsg({
                'type': 'sticky',
                'status': 'error',
                'title': title,
                'buttons': {
                  [buttonYes]: {
                    action: function() {
                      let obj = that.payPeriodList.find(x => x.SELECTED_PERIOD === -1);
                      if (obj != undefined) {
                        obj.SELECTED_PERIOD = 1;
                        that.selectedPayPeriod = obj;
                        that.cutoff_date = that.fromJsonDate(that.selectedPayPeriod.g);
                        that.paycard_type = that.selectedPayPeriod.paycard_type == 'R' ? labels[0] : (that.selectedPayPeriod.paycard_type == 'S' ? labels[1] : labels[2]);
                        if (that.wizardMode) {
                          that.updateWizard();
                        }
                      }
                    }
                  },
                  [buttonNo]: {
                    action: function() {
                      that.payPeriodList.forEach(x => x.THIS_CAN_BE_NEW_PERIOD = 0);
                      return;
                    }
                  }
                },
                'message': this.message
              });
              break;
          }
        }
      );
    }
  }

  updateWizard() {
    if (this.selectedPayPeriod) {
      let x = this._datePipe.transform(this.selectedPayPeriod.fd, this.sysPref.df);
      let y = this._datePipe.transform(this.selectedPayPeriod.td, this.sysPref.df);
      this._wizardService.updatePayrollGroup({
        gc: this.selectedPayPeriod.gc,
        gn: this.payrollGroups.find(x => x.a == this.selectedPayPeriod.gc).b,
        sl: this.payrollGroups.find(x => x.a == this.selectedPayPeriod.gc).c == 1 ? 1 : 0,
        payrollPeriod: x + ' - ' + y,
        payfrequency: this.selectedPayPeriod.d,
        fd: this.selectedPayPeriod.fd,
        td: this.selectedPayPeriod.td,
        cd: this.cutoff_date,
        paycard_type: this.paycard_type,
        selectedPayPeriod: this.selectedPayPeriod
      });
      this._wizardService.updateWizardVW(this.payrollGroups.find(x => x.a == this.selectedPayPeriod.gc).c == 1 ? 1 : 0);
      this._wizardService.getWizard(this.selectedPayPeriod.gc, this.selectedPayPeriod.fd, this.selectedPayPeriod.td, this.payrollGroups.find(x => x.a == this.selectedPayPeriod.gc).c == 1 ? 1 : 0).subscribe(data => {
        this._wizardService.updateWizardMode(true);
        this._wizardService.updateWizard(data);
        this._wizardService.updateStep(data[0].sc);
        this._wizardService.updateOrder(data[0].so);
      });
    }
  }

  clearFilter() {
    this.selectedCateType = '';
    this.cateList.forEach(x => {
        if (!this.checkPredefineCates(x.o)) {
          x.checked = false;
        }
      }
    );
  }


  selectAllCate() {
    this.cateList.forEach(x => {
      x.checked = true;
    });
  }

  CheckAllByCate(str: string) {
    this.cateList.forEach(x => {

      if (x.k) {
        let result = x.k.toLowerCase() == (str.toLowerCase());
        //let a = x.k.toLowerCase();
        //let b = str.toLowerCase();

        if (result || this.checkPredefineCates(x.o)) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      }
    });


  }

  checkPredefineCates(num: number) {
    return !!this.predefinedList.find(x => x == num);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    if (this.$subTOoClear) {
      this.$subTOoClear.unsubscribe();
    }
  }

}
