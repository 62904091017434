<ng-container *transloco="let translate; read: 'payrollGroupSelection'">
  <div class="card">
    <div class="card-body">
      <form #ppGroupForm="ngForm" id="timeSheet" (ngSubmit)="onFormSubmit()">
        <div class="row mb-2 justify-content-end">
          <div class="col-auto">
            <button class="btn btn-success" [disabled]="invalidSelectedPayPeriod" type="submit">
              <i class="fas fa-save"></i>
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-6">
            <div class="row mb-2">
              <label class="col-sm-4 col-form-label">{{translate('payrollGroup')}}</label>
              <div class="col-sm-8">
                <kendo-combobox
                  type="search"
                  class="form-control p-0" [value]="groupCode"
                  name="groupCode"
                  [data]="payrollGroups"
                  [textField]="'b'"
                  [valueField]="'a'"
                  [filterable]="true"
                  [suggest]="true"
                  [clearButton]="false"
                  [placeholder]="'payrollGroupSelection.pleaseSelectGroup'|transloco"
                  (valueChange)="onPGDropDownChange_V2($event)">
                </kendo-combobox>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-4 col-form-label">{{translate('paycardType')}}</label>
              <div class="col-sm-8">
                <kendo-combobox
                  type="search" class="form-control p-0"
                  [disabled]="invalidSelectedPayPeriod"
                  name="paycard_type" [(ngModel)]="paycard_type"
                  [data]="paycard_type_list"
                  [clearButton]="false"
                  [placeholder]="'payrollGroupSelection.pleaseSelectPaycardType'|transloco"
                  (valueChange)="updateWizard()"></kendo-combobox>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-2">
              <label class="col-sm-4 col-form-label">{{translate('payrollProcessPeriod')}}</label>
              <div class="col-sm-8">
                <kendo-dropdownlist type="search"
                                    class="form-control p-0"
                                    [disabled]="!groupCode || selectedPayPeriod?.SELECTED_PERIOD_DISABLED"
                                    [data]="payPeriodList"
                                    [valuePrimitive]="false"
                                    (valueChange)="onPPDropDownChange($event)"
                                    [value]="selectedPayPeriod">
                  <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span class="template">
                      {{dataItem.fd | date: (sysPref?.df || 'yyyy-MM-dd')}} - {{dataItem.td | date: (sysPref?.df || 'yyyy-MM-dd')}}
                      <i class="fas" [ngClass]="{'fa-lock': dataItem.locked, 'fa-lock-open': dataItem?.timesheet || dataItem?.unlocked}"></i>
                    </span>
                  </ng-template>
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span class="template">
                      {{dataItem?.fd | date: (sysPref?.df || 'yyyy-MM-dd')}} - {{dataItem?.td | date: (sysPref?.df || 'yyyy-MM-dd')}}
                      <i class="fas" [ngClass]="{'fa-lock': dataItem?.locked, 'fa-lock-open': dataItem?.timesheet || dataItem?.unlocked}"></i>
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-4 col-form-label">{{translate('paymentDate')}}</label>
              <div class="col-sm-8">
                <input [disabled]="invalidSelectedPayPeriod" id="cutOff_date" class="form-control"
                       [attr.data-date]="cutoff_date | date: (sysPref?.df || 'yyyy-MM-dd')" [(ngModel)]="cutoff_date" (change)="updateWizard()"
                       name="payment_date"
                       type="date" />
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedPayPeriod && groupCode">
          <div class="row mb-3 justify-content-center">
            <div class="col-sm-6">
              <app-search-box [(ngModel)]="SearchTxt" [ngModelOptions]="{standalone: true}"></app-search-box>
            </div>
            <div class="col-auto" *ngIf="!selectedPayPeriod?.SELECTED_PERIOD_DISABLED">
              <div class="btn-group" dropdown container="body">
                <button id="button-basic" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic" [disabled]="!!selectedPayPeriod?.SELECTED_PERIOD_DISABLED">
                  <i class="fas fa-tasks"></i><span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem" (click)="CheckAllByCate(listItem.name)" *ngFor="let listItem of categoryTypeList">
                    <span class="dropdown-item">{{listItem?.name}}&nbsp;&nbsp;&nbsp;<i class="{{listItem.cssClass | payrollCategoryIcon}}"></i></span>
                  </li>
                  <li class="divider dropdown-divider"></li>
                  <li role="menuitem"><span class="dropdown-item" (click)="selectAllCate()" transloco="all"></span></li>
                  <li role="menuitem"><span class="dropdown-item" (click)="clearFilter()" transloco="clearFilter"></span></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card border">
            <div class="card-body overflow-y-auto" style="height: 37vh">
              <div class="row" [class.disabled-grid]="selectedPayPeriod?.SELECTED_PERIOD_DISABLED">
                <div class="col-md-4 col-sm-6" *ngFor="let cate of cateList | searchfilter : SearchTxt">
                  <div class="card border">
                    <div class="card-body p-2">
                      <div class="row">
                        <div class="col-sm-auto d-flex align-items-center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" [disabled]="selectedPayPeriod?.SELECTED_PERIOD_DISABLED || checkPredefineCates(cate.o)" [name]="cate.o+'checked'"
                                   [(ngModel)]="cate.checked">
                          </div>
                        </div>
                        <div class="col-sm-auto d-flex align-items-center">
                          <div class="list-icon" [ngClass]="getClassesForIcon(cate.p)">
                            <i class="{{cate.c | payrollCategoryIcon}}"></i>
                          </div>
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                          <span>{{cate.l + '  ' + cate.n}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</ng-container>
