<ng-container *transloco="let translate; read: 'twoFaVerify'">
  <div class="row" *ngIf="!hideTitle">
    <div class="col-12">
      <div>
        <h5 class="text-primary">{{ translate('welcomeToHarmony') }}</h5>
        <p class="text-muted">{{ translate('verifyOTP') }}</p>
      </div>
    </div>
  </div>
  <form>
    <div class="row mb-3">
      <div class="col-12">
        <p class="mb-2">{{ translate('enter6DigitCode') }}</p>
      </div>
      <div class="col-12 d-flex justify-content-center mb-3">
        <ng-otp-input [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input>
      </div>
      <div class="col-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="rememberBrowser" id="rememberThis" [(ngModel)]="rememberBrowser">
          <label class="form-check-label" for="rememberThis">{{ translate('rememberThisBrowser') }}</label>
        </div>
      </div>
    </div>
    <div class="row justify-content-between mb-2">
      <div class="col mb-2">
        <button
          type="button"
          (click)="verifyOtp()"
          class="btn btn-primary w-100"
          [disabled]="loading || otp?.length < 6"
        >
          <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span> {{ translate('verify') }}
        </button>
      </div>
      <div class="col-6 mb-2" *ngIf="!hideLogout">
        <button
          type="button"
          class="btn btn-secondary w-100"
          (click)="onLogout()"
          [disabled]="loading">
          <span transloco="header.user.logout"></span>
        </button>
      </div>
    </div>
  </form>
</ng-container>
