import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class PayrollSpecialProcessingService {

  private cateTypesSelected = new BehaviorSubject<any>([]);
  cateTypes = this.cateTypesSelected.asObservable();

  constructor() { }

  changeGoal(cates) {
    this.cateTypesSelected.next(cates)
  }
}
