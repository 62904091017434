import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IEntitledEmployeeAdjustments, IEntitledEmployeeAssignToWholeYear, ILoadEmployeeToEligibility} from 'src/app/shared/models/iemployee';
import {
  IPayrollCategoryDefaultEmployeeList,
  IPayrollcategoryEmpWiseList,
  IPayrollCategoryEntitlement,
  IPayrollCategoryTypes
} from 'src/app/shared/models/ipayroll-category';
import {IPayrollGroupEmployees, IPayScheculeForDefaultEmployeeList} from 'src/app/shared/models/ipayroll-group';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class PayrollCategoryEntitlementService {
  private _url: string = API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryEgilibilities";
  private _getPayrollCategoryTypesUrl: string = API_END_POINT.payroll.payrollCategory + "GetCategoryTypes";
  private _getDefaultEmpListUrl: string = API_END_POINT.payroll.payrollCategory + "GetDefaultEmployeeList";
  private _getDefaultEmpListForEmployeeUrl: string = API_END_POINT.payroll.payrollCategory + "GetDefaultEmployeeListForEmployee";
  private _getPayScheduleForDefaultEmployeeListUrl: string = API_END_POINT.payroll.payrollCategory + "GetPayScheduleForDefaultEmployeeList"
  private _addEmpUrl: string = API_END_POINT.payroll.payrollCategory + "Addemp";
  private _editEmpUrl: string = API_END_POINT.payroll.payrollCategory + "EditEntitledEmployee";
  private _assignEmpUrl: string = API_END_POINT.payroll.payrollCategory + "AssignEntitledEmployeeToWholeYear";
  private _deleteEmpUrl: string = API_END_POINT.payroll.payrollCategory + "DeleteEntitledEmployee";
  private _getPayrollGroupEmployees: string = API_END_POINT.payroll.payrollCategory + "GetPayrollGroupEmployees";
  private _loadEmployeeToEligibilityUrl: string = API_END_POINT.payroll.payrollCategory + "LoadEmployeeToEligibility";
  private _AdujustEntitledEmployeesUrl: string = API_END_POINT.payroll.payrollCategory + "AdujustEntitledEmployees";
  private _EntitledStatusUrl: string = API_END_POINT.payroll.payrollCategory + "SetPayrollCategoryEligibilityStatus";

  constructor(private _httpClient: HttpClient) { }

  getEgilibilities(): Observable<IPayrollCategoryEntitlement[]> {
    return this._httpClient.get<IPayrollCategoryEntitlement[]>(this._url);
  }

  getPayrollCategoryTypes(): Observable<IPayrollCategoryTypes[]> {
    return this._httpClient.get<IPayrollCategoryTypes[]>(this._getPayrollCategoryTypesUrl);
  }

  getDefaultEmpList(): Observable<IPayrollCategoryDefaultEmployeeList[]> {
    return this._httpClient.get<IPayrollCategoryDefaultEmployeeList[]>(this._getDefaultEmpListUrl);
  }
  getDefaultEmpListForEmployee(): Observable<IPayrollCategoryDefaultEmployeeList[]> {
    return this._httpClient.get<IPayrollCategoryDefaultEmployeeList[]>(this._getDefaultEmpListForEmployeeUrl);
  }

  getPayScheduleForDefaultEmployeeList(): Observable<IPayScheculeForDefaultEmployeeList[]> {
    return this._httpClient.get<IPayScheculeForDefaultEmployeeList[]>(this._getPayScheduleForDefaultEmployeeListUrl);
  }

  getPayScheduleForDefaultEmployeeListForEmp(): Observable<IPayScheculeForDefaultEmployeeList[]> {
    return this._httpClient.get<IPayScheculeForDefaultEmployeeList[]>(API_END_POINT.payroll.payrollCategory + "GetPayScheduleForDefaultEmployeeListForEmp");
  }
  addEmpToPayrollCategory(employee: IPayrollCategoryEntitlement): Observable<any> {
    var empObj = { 'dc': employee.dc, 'ei': employee.ei, 'bp': employee.bp, 'pa': employee.pa, 'ya': employee.ya, 'u': employee.u, 'pf': employee.pf, 'pt': employee.pt, 'ie': employee.ie, 'oa': employee.oa, 'ou': employee.ou, 'emax': employee.emax, 'emin': employee.emin };
    //console.log("service: ")
    //console.log(empObj);
    return this._httpClient.post<any>(this._addEmpUrl, empObj);
  }
  addEmpToPayrollCategoryEmpWise(employee: IPayrollcategoryEmpWiseList): Observable<any> {
    var empObj = { 'dc': employee.dc, 'ei': employee.ei, 'bp': employee.bp, 'pa': employee.ea, 'ya': employee.ya, 'u': employee.u, 'pf': employee.pf, 'pt': employee.pt, 'ie': employee.ie, 'oa': employee.oa, 'ou': employee.ou, 'emax': employee.emax, 'emin': employee.emin };
    console.log("addEmpToPayrollCategoryEmpWise -> empObj");
    console.log(empObj);
    return this._httpClient.post<any>(this._addEmpUrl, empObj);
  }
  editEntitledEmployee(employee: IPayrollCategoryEntitlement): Observable<any> {
    var empObj = { 'dc': employee.dc, 'ei': employee.ei, 'bp': employee.bp, 'pa': employee.pa, 'ya': employee.ya, 'u': employee.u, 'pf': employee.pf, 'pt': employee.pt, 'oa': employee.oa, 'ou': employee.ou, 'emax': employee.emax, 'emin': employee.emin };
    return this._httpClient.post<any>(this._editEmpUrl, empObj);
  }
  editEntitledEmployeeEmpWise(employee: IPayrollcategoryEmpWiseList): Observable<any> {
    var empObj = { 'dc': employee.dc, 'ei': employee.ei, 'bp': employee.bp, 'pa': employee.ea, 'ya': employee.ya, 'u': employee.u, 'pf': employee.pf, 'pt': employee.pt, 'oa': employee.oa, 'ou': employee.ou, 'emax': employee.emax, 'emin': employee.emin };
    return this._httpClient.post<any>(this._editEmpUrl, empObj);
  }

  deleteEntitledEmployee(employee: IPayrollCategoryEntitlement): Observable<any> {
    var empObj = { 'dc': employee.dc, 'ei': employee.ei };
    return this._httpClient.post<any>(this._deleteEmpUrl, empObj);
  }
  deleteEntitledEmployeeEmpWise(dc: number, ei: number): Observable<any> {
    var empObj = { 'dc': dc, 'ei': ei };
    return this._httpClient.post<any>(this._deleteEmpUrl, empObj);
  }

  checkEmployeePayrollCategorylockedaycard(dc: number, ei: number): Observable<number> {
    var empObj = { 'dc': dc, 'ei': ei };
    return this._httpClient.post<number>(API_END_POINT.payroll.payrollCategory + "CheckEmployeePayrollCategorylockedaycard", empObj);
  }

  getPayrollGroupEmployees(): Observable<IPayrollGroupEmployees[]> {
    return this._httpClient.get<IPayrollGroupEmployees[]>(this._getPayrollGroupEmployees);
  }

  loadEmployeeToEligibility(em: ILoadEmployeeToEligibility): Observable<any> {
    var o = { 'p': em.p, 'e': em.e, 'y': em.y, 'u': em.u, 'c': em.c, 't': em.t, 'oa': em.oa, 'ou': em.ou, 'mina': em.mina, 'maxa': em.maxa }
    return this._httpClient.post<any>(this._loadEmployeeToEligibilityUrl, o);
  }

  adjustEntitledEmployees(em: IEntitledEmployeeAdjustments): Observable<any> {
    var o = { 'p': em.p, 'e': em.e, 'y': em.y, 'u': em.u, 'i': em.i, 'c': em.c, 'f': em.f };
    console.log(o);
    return this._httpClient.post<any>(this._AdujustEntitledEmployeesUrl, o);
  }

  assignEntitledEmployeeToWholeYear(em: IEntitledEmployeeAssignToWholeYear): Observable<any> {
    console.log(em);
    return this._httpClient.post<any>(this._assignEmpUrl, em);
  }

  setEntitledEmpStatus(ei: number, dc: number, f: number): Observable<any> {
    var o = { 'ei': ei, 'dc': dc, 'f': f };
    return this._httpClient.post<any>(this._EntitledStatusUrl, o);
  }

  deleteEntitledEmployees(eis: string, dc: number): Observable<any[]> { //IEmpListDel
    var o = { 'eis': eis, 'dc': dc };
    return this._httpClient.post<any[]>(API_END_POINT.payroll.payrollCategory + "DeleteEntitledEmployees", o);
  }

  getTimeSheetCatesForEmp(ei: number, c: number, gc: number, fd: Date, td: Date) {
    const params = new HttpParams()
      .set('ei', ei.toString())
      .set('c', c.toString())
      .set('gc', gc.toString())
      .set('fd', new Date(fd).toISOString())
      .set('td', new Date(td).toISOString())

    return this._httpClient.get<any>(API_END_POINT.payroll.payrollCategory + "getTimeSheetCatesForEmp", { params });
  }

  updateEmployeeYTDWholeYearOneCategory(empId: number, proc_year: number, catId: number): Observable<number> {
    var o = { 'empId': empId, 'proc_year': proc_year, 'catId': catId };
    return this._httpClient.post<number>(API_END_POINT.payroll.payrollCategory + "UpdateEmployeeYTDWholeYearOneCategory", o);
  }
}
