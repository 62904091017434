import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Inject,
  Input,
  Optional,
  ViewContainerRef
} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FormValidationService} from '@services/form-validation.service';
import {FormErrorMessageComponent} from '@shared/directives/form-valdation-handling/form-error-message/form-error-message.component';
import {EMPTY, merge, Observable, Subject} from 'rxjs';
import {FormErrorContainerDirective} from './form-error-container.directive';
import {FORM_ERRORS} from './form-error-key-maps';
import {FormSubmitValidationErrorHandlerDirective} from './form-submit-validation-error-handler.directive';


@UntilDestroy()
@Directive({
  selector: '[appFormValidationMessageDisplay]'
})
export class FormErrorHandlerDirective implements AfterViewInit {
  submit$: Observable<Event>;
  focus$ = new Subject();
  ref: ComponentRef<FormErrorMessageComponent>;
  container: ViewContainerRef;
  @Input() customErrors = {}; // to show custom error messages

  // Use this to skip duplication when formControl name is same for two or more and wanna show one message. Ex: Radio Buttons
  @Input() skipValidationMessageForDuplicates = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private formControlDir: NgControl,
    private formControlNative: ElementRef<HTMLInputElement>,
    @Optional() @Host() private form: FormSubmitValidationErrorHandlerDirective,
    @Optional() formControlErrorContainer: FormErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors,
    private formService: FormValidationService
  ) {
    this.container = formControlErrorContainer ? formControlErrorContainer.viewContainerRef : viewContainerRef;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  @HostListener('focusout', ['$event']) onblur(): void {
    this.focus$.next({touched: true, untouched: false});
  }

  ngAfterViewInit(): void {
    this.validateControl();
  }

  validateControl(): void {
    if (!this.skipValidationMessageForDuplicates) {
      merge(
        this.submit$,
        this.formControl.valueChanges,
        this.formControl.statusChanges,
        this.focus$
      ).pipe(
        untilDestroyed(this)
      ).subscribe(
        (res) => {
          if (res) {
            if (res.type) {
              this.getError();
            }
            if (this.formControl.dirty) {
              this.getError();
            }
            if (res.touched) {
              this.getError();
            }
            if (this.controlType === 'radio') {
              this.getError();
            }
          }
        }
      );
    }
  }

  get formControl(): AbstractControl {
    return this.formControlDir.control;
  }

  get controlType(): string {
    return this.formControlNative.nativeElement.type;
  }

  getError(): void {
    const controlErrors = this.formControl.errors;
    if (controlErrors) {
      const firstKey = Object.keys(controlErrors)[0];
      const getError = this.errors[firstKey];

      let errorText: string;
      switch (true) {
        case this.customErrors.hasOwnProperty(firstKey):
          errorText = this.customErrors[firstKey];
          break;
        case this.errors.hasOwnProperty(firstKey):
          errorText = getError(controlErrors[firstKey]);
          errorText = this.formService.translateValidationMessage(errorText);
          break;
        case controlErrors[firstKey].hasOwnProperty('message'):
          errorText = controlErrors[firstKey].message;
          errorText = this.formService.translateValidationMessage(errorText);
          errorText = errorText.replace('validations.', '');
          break;
      }
      this.setError(errorText);
    } else if (this.ref) {
      this.setError(undefined);
    }
  }

  setError(text: string): void {
    if (!this.ref) {
      const component = this.cfr.resolveComponentFactory(FormErrorMessageComponent);
      this.ref = this.container.createComponent(component);
    }
    this.ref.instance.text = text;
  }
}
