<ng-container *transloco="let translate; read: 'createCompany'">
  <div class="modal-header">
    <h4 class="modal-title">{{translate('createNewCompany')}}</h4>
    <button class="btn-close" (click)="bsModalRef?.hide()"></button>
  </div>
  <form #newCompanyForm=ngForm (ngSubmit)="onSubmit(newCompanyForm)">
    <div class="modal-body">
      <div class="row g-3">
        <div class="col-12">
          <label for="companyName" class="form-label">{{translate('companyName')}}</label>
          <input
            type="text" class="form-control" id="companyName" name="companyName" ngModel
            required
            pattern="^[A-Za-z0-9 _]+$"
            appFormValidationMessageDisplay
          />
          <small
            class="error text-danger font-italic"
            *ngIf="newCompanyForm?.controls?.companyName?.errors?.pattern"
          >{{translate('validations.companyName.pattern')}}</small>
        </div>
        <div class="col-4">
          <label for="payrollYear1" class="form-label">{{translate('payrollYear')}}</label>
          <input type="number" class="form-control" id="payrollYear1" name="payrollYear" ngModel required appFormValidationMessageDisplay/>
        </div>
        <div class="col-8">
          <label for="phone" class="form-label">{{translate('phone')}}</label>
          <input type="tel" class="form-control" id="phone" name="phone" ngModel required appFormValidationMessageDisplay/>
        </div>
        <div class="col-12">
          <label for="phone" class="form-label">{{translate('address')}}</label>
          <textarea class="form-control" id="address" name="address" ngModel>
          </textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-success"
        [disabled]="newCompanyForm.invalid || loading"
      >
        <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>&nbsp;{{translate('create')}}
      </button>
    </div>
  </form>
</ng-container>
