import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {map, take} from 'rxjs/operators';
import {AccessControlService} from '../../account/access-control/services/access-control.service';

@Injectable({
  providedIn: 'root'
})
export class SystemPreferenceGuard implements CanActivate, CanActivateChild {
  constructor(
    private systemPreference: SystemPreferenceService,
    private accessControlService: AccessControlService,
    private router: Router
  ) {
  }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAccess(state);
  }

  canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAccess(state);
  }

  private checkAccess(state: RouterStateSnapshot) {
    const obs$ = this.systemPreference.systemPreference$.getValue() ? this.systemPreference.systemPreference$ : this.systemPreference.getinitSystemPreferences();
    return obs$.pipe(
      take(1),
      map(preference => {
        const permission = this.accessControlService.getAccessPermissions('payroll', 'payrollpreferences', 'view');
        if (preference?.length && !preference[0]?.y) {
          if (permission) {
            if (state.url === '/adminsetup/adminsetupsetup/SystemPref') {
              return true;
            }
            return this.router.createUrlTree(['adminsetup', 'adminsetupsetup', 'SystemPref']);
          } else {
            if (state.url === '/dashboard') {
              return true;
            }
            return this.router.createUrlTree(['dashboard']);
          }
        }
        return true;
      })
    );
  }

}
