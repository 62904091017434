import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';

declare var showMsg: any;
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class DeleteConfirmationService {

  constructor(
    private transloco: TranslocoService) { }

  deleteConfirmation(callback, msg) {
    const title = this.transloco.translate('warning');
    const buttonOK = this.transloco.translate('ok');
    const buttonCancel = this.transloco.translate('cancel');
    showMsg({
      'type': 'sticky',
      'status': 'warning',
      'title': title,
      'buttons': {
        [buttonOK]: {
          action: callback
        },
        [buttonCancel]: {
          action: function () {
            return;
          }
        }
      },
      'message': msg
    });
  }

  deleteConfirmation_paycat(callback, msg, cancelfunc) {
    const title = this.transloco.translate('warning');
    const buttonOK = this.transloco.translate('ok');
    const buttonCancel = this.transloco.translate('cancel');
    showMsg({
      'type': 'sticky',
      'status': 'warning',
      'title': title,
      'buttons': {
        [buttonOK]: {
          action: callback
        },
        [buttonCancel]: {
          action: cancelfunc
        }
      },
      'message': msg
    });
  }
}
