import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {LANGUAGES_AVAILABLE} from '@shared/i18n/i18.config';
import {CookieService} from 'ngx-cookie-service';

@Injectable({providedIn: 'root'})
export class LanguageService {

  constructor(public translate: TranslocoService, private cookieService: CookieService) {
    const language = this.getLanguageCode(this.cookieService.get('translocoLang'));
    this.setLanguage(language);
  }

  private getLanguageCode(languageCode: string): string {
    if (languageCode) {
      if (typeof languageCode === 'string') {
        return languageCode;
      }
      const langIndex = +languageCode;
      if (!Number.isNaN(languageCode)) {
        return LANGUAGES_AVAILABLE[langIndex];
      }
      return LANGUAGES_AVAILABLE[0];
    }
    return LANGUAGES_AVAILABLE[0];
  }

  public setLanguage(lang) {
    const language = this.getLanguageCode(lang);
    this.translate.setActiveLang(language);
    this.setLanguageDirection(language);
  }

  private setLanguageDirection(language: string): void {
    const rtlLanguages = ['ar'];
    if (rtlLanguages.includes(language)) {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
    document.documentElement.setAttribute('lang', language);
  }

}
