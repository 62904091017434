import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-back-routing-btn',
  templateUrl: './back-routing-btn.component.html',
  styleUrls: ['./back-routing-btn.component.scss']
})
export class BackRoutingBtnComponent implements OnInit {

  @Input() routeTo: any[];
  @Input() text: string;
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  callBack($event: MouseEvent) {
    this.onClick.emit($event);
  }


}
