import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  AlertMessageService
} from '@harmony-modules/configuration/sections/employee/employee-import/shared/alert-message-service/alert-message.service';

declare var showMsg: any;

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private transloco: TranslocoService,
    private alert: AlertMessageService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  private handleError(error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    switch (error.status) {
      case 400:
        return this.handleValidationError(error);
      case 403:
        const title = this.transloco.translate('accessDenied');
        const message = this.transloco.translate('insufficientPermission');
        const buttonOK = this.transloco.translate('ok');
        showMsg({
          type: 'nonsticky',
          status: 'error',
          title,
          message,
          [buttonOK]: {
            action() {
              return;
            }
          }
        });
        return throwError(error);
      case 500:
        if (error?.error?.Detail) {
          this.handleServerError(error.error.Detail);
          return throwError(error);
        }
        this.handleServerError();
        return throwError('Server Error');
      default:
        return throwError(error);
    }
  }

  // This is a sample message.
  example = [
    {
      key: 'a',
      params: 'asda'
    },
    {
      key: 'b',
      params: 'asdasd'
    }
  ];


  // validation message comes with success and it has an array of object with params with array and key. need to loop.
  private handleValidationError(error: HttpErrorResponse): Observable<any> {
    // const err: ILocString = error.error as ILocString;
    // if ('key' in err) {
    //   const title = this.transloco.translate('error');
    //   const buttonOK = this.transloco.translate('ok');
    //   let params: string = null;
    //   if ('parameters' in err) {
    //     params = err.parameters.join(', ');
    //   }
    //   const message = this.transloco.translate(err.key, {params});
    //
    //   showMsg({
    //     type: 'sticky',
    //     status: 'error',
    //     title,
    //     buttons: {
    //       [buttonOK]: {
    //         action() {
    //           return;
    //         }
    //       }
    //     },
    //     message
    //   });
    //
    //   return EMPTY;
    // }
    return throwError(error);
  }

  private handleServerError(error?: string): void {
    const title = this.transloco.translate('error');
    const message = error || this.transloco.translate('alerts.bc.serverError');
    const buttonOK = this.transloco.translate('ok');
    showMsg({
      type: 'nonsticky',
      status: 'error',
      title,
      message,
      [buttonOK]: {
        action() {
          return;
        }
      }
    });
  }
}
