<ng-container *transloco="let translate; read: 'laborWizard'">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="laborForm" (ngSubmit)="onSubmit()">
        <div class="row mb-3">
          <div class="col-sm-4">
            <select formControlName="payroll_group" id="payroll_group" name="payroll_group" (change)="onDropDownChange($event.target.value)"
                    class="form-select">
              <option *ngFor="let item of payrollGroups" [value]="item.PRLG_CODE">{{item.code_name}}</option>
            </select>
          </div>
          <div class="col-sm-4">
            <span class="mr-4">{{payfrequency}} {{processPeriod}}</span>
          </div>
          <div class="col-sm-4 d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" (click)="showLaborReport()" [disabled]="process_in_progress || no_payrollGroup">
              {{translate('laborReport')}}
            </button>
            <button type="button" class="btn btn-danger me-2" (click)="deleteAllRecords()" [disabled]="process_in_progress || no_payrollGroup">
              <i class="fas fa-trash"></i>
            </button>
            <button type="button" class="btn btn-primary me-2" (click)="onRollback()" [disabled]="process_in_progress || no_payrollGroup">
              <i class="fas fa-redo-alt"></i>
            </button>
            <button type="submit" class="btn btn-success me-2" [disabled]="laborForm.invalid || process_in_progress || no_payrollGroup">
              <i class="fas fa-save"></i>
            </button>
            <button type="button" class="btn btn-primary" openModal modalTarget="ImportLaborModal" title="{{translate('import')}}"
                    [disabled]="process_in_progress || no_payrollGroup">
              <i class="fas fa-file-import"></i>
            </button>
          </div>
        </div>
        <div class="row mb-3" [hidden]="no_payrollGroup">
          <div class="col-12">
            <div class="row">
              <div class="col-sm-2">
                <button type="button" class="btn btn-primary" (click)="onNewClick()" [disabled]="process_in_progress"><i class="fas fa-plus"></i>&nbsp;<span
                  transloco="new"></span></button>
              </div>
              <div class="col-sm-4">
                <kendo-combobox type="search" class="form-control p-0" formControlName="filtered_employee"
                                [data]="employees_copy"
                                [textField]="'n'"
                                [valueField]="'ID'"
                                [filterable]="true"
                                (filterChange)="onFilterChange($event)"
                                [suggest]="true"
                                [placeholder]="'laborWizard.searchEmployeeHere'|transloco"
                                (valueChange)="onEmployeeFilterChange($event)">
                </kendo-combobox>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <label class="col-form-label col-sm-6">{{translate('itemsPerPage')}}</label>
                  <div class="col-sm-6 d-flex align-items-center">
                    <select class="form-select" (change)="onItemPerPageClick($event)">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2" [hidden]="no_payrollGroup">
          <div class="col-12">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <table class="table table-striped table-scroll labor-table">
              <thead>
              <tr>
                <th class="text-center" style="width:3%;"></th>
                <th class="text-center"><span transloco="employee"></span></th>
                <th class="text-center" *ngIf="is_working_date"><span transloco="date"></span></th>
                <th class="text-center">{{translate('category')}}</th>
                <th class="text-center"><span transloco="units"></span></th>
                <th class="text-center"><span transloco="rate"></span></th>
                <th class="text-center"><span transloco="amount"></span></th>
                <ng-container *ngFor="let dim of laborSetupList">
                  <th class="text-center" *ngIf="dim?.LABOR_DIM_IS_ACTIVE_FLG==1">
                    {{dim.LABOR_DIM_CAPTION}}
                  </th>
                </ng-container>
                <th class="text-center"><span transloco="overwrite"></span></th>
                <th class="text-center" style="width: 3%;"></th>
              </tr>
              </thead>
              <tbody style="height: 300px" formArrayName="grid">
              <!--| orderBy: sortOrder:sortdirec-->
              <tr *ngFor="let s of grid.controls | paginate: {itemsPerPage: items_per_page, currentPage: p, totalItems: grid.controls.length}; let i =index"
                  [formGroupName]="getGridIndex(i)">
                <td class="text-center" style="width: 3%;">
                  <div>
                    <ng-container *ngIf="s.value.status == -2 || s.value.status == -1; else other_content">
                      <i class="fas fa-pencil-alt"></i>
                    </ng-container>
                    <ng-template #other_content>
                      <ng-container *ngIf="s.value.seq_no != 0; else other_symbol">
                        <i class="fas fa-lock"></i>
                      </ng-container>
                      <ng-template #other_symbol>
                        <i class="fas fa-check" [ngClass]="getClassesForIcon(s.value.status)"></i>
                      </ng-template>
                    </ng-template>
                  </div>
                </td>
                <td class="text-center">
                  <select formControlName="employee" class="form-select form-select-sm" (change)="onEmployeeEdit(s.value.grid_id)">
                    <option *ngFor="let emp of employees_copy" [ngValue]="emp.ID">{{emp.Number}}  {{emp.name}}</option>
                  </select>
                </td>
                <td class="text-center" *ngIf="is_working_date">
                  <input type="date" formControlName="date" [attr.data-date]="s.value.date | date: (sysPref?.df || 'yyyy-MM-dd')"
                         class="form-control form-control-sm"
                         (change)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td class="text-center">
                  <select formControlName="category" class="form-select form-select-sm" (change)="onCategoryEdit(s.value.grid_id)">
                    <option *ngFor="let cat of payrollCategories_copy" [ngValue]="cat.CAT_DTL_CODE">{{cat.CAT_DTL_USER_CODE}} {{cat.CAT_DTL_NAME}}</option>
                  </select>
                </td>
                <td class="text-center">
                  <input type="number" formControlName="units" class="form-control form-control-sm" (input)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td class="text-center">
                  <input type="number" formControlName="rate" class="form-control form-control-sm" (input)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td class="text-center">
                  <input type="number" formControlName="amount" class="form-control form-control-sm" (input)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td *ngIf="laborSetupList[0]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dim1" class="form-select form-select-sm" (change)="onDimensionCahnge($event, s.value.grid_id, laborSetupList[0])">
                    <option [value]="null"></option>
                    <option *ngFor="let d1 of array_of_dimension[getGridIndex(i)]?.list1" [value]="d1.ID_DIMENSION"
                            [ngClass]="{'inactive-dim': d1.DIM_IS_ACTIVE_FLG == 0}">
                      {{d1.DIM_NAME}}  {{d1.DIM_DESCRIPTION}}
                    </option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[1]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dim2" class="form-select form-select-sm" (change)="onDimensionCahnge($event, s.value.grid_id, laborSetupList[1])">
                    <option [value]="null"></option>
                    <option *ngFor="let d2 of array_of_dimension[getGridIndex(i)]?.list2" [value]="d2.ID_DIMENSION"
                            [ngClass]="{'inactive-dim': d2.DIM_IS_ACTIVE_FLG == 0}">
                      {{d2.DIM_NAME}}  {{d2.DIM_DESCRIPTION}}
                    </option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[2]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dim3" class="form-select form-select-sm" (change)="onDimensionCahnge($event, s.value.grid_id, laborSetupList[2])">
                    <option [value]="null"></option>
                    <option *ngFor="let d3 of array_of_dimension[getGridIndex(i)]?.list3" [value]="d3.ID_DIMENSION"
                            [ngClass]="{'inactive-dim': d3.DIM_IS_ACTIVE_FLG == 0}">
                      {{d3.DIM_NAME}}  {{d3.DIM_DESCRIPTION}}
                    </option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[3]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dim4" class="form-select form-select-sm" (change)="onDimensionCahnge($event, s.value.grid_id, laborSetupList[3])">
                    <option [value]="null"></option>
                    <option *ngFor="let d4 of array_of_dimension[getGridIndex(i)]?.list4" [value]="d4.ID_DIMENSION"
                            [ngClass]="{'inactive-dim': d4.DIM_IS_ACTIVE_FLG == 0}">
                      {{d4.DIM_NAME}}  {{d4.DIM_DESCRIPTION}}
                    </option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[4]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dim5" class="form-select form-select-sm" (change)="onDimensionCahnge($event, s.value.grid_id, laborSetupList[4])">
                    <option [value]="null"></option>
                    <option *ngFor="let d5 of array_of_dimension[getGridIndex(i)]?.list5" [value]="d5.ID_DIMENSION"
                            [ngClass]="{'inactive-dim': d5.DIM_IS_ACTIVE_FLG == 0}">
                      {{d5.DIM_NAME}} {{d5.DIM_DESCRIPTION}}
                    </option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[5]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dim6" class="form-select form-select-sm" (change)="onDimensionCahnge($event, s.value.grid_id, laborSetupList[5])">
                    <option [value]="null"></option>
                    <option *ngFor="let d6 of array_of_dimension[getGridIndex(i)]?.list6" [value]="d6.ID_DIMENSION"
                            [ngClass]="{'inactive-dim': d6.DIM_IS_ACTIVE_FLG == 0}">
                      {{d6.DIM_NAME}} {{d6.DIM_DESCRIPTION}}
                    </option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[6]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="dep" class="form-select form-select-sm" (change)="onValueEdit(s.value.grid_id)">
                    <option [value]="null"></option>
                    <option *ngFor="let dep of departmentList" [value]="dep.i">{{dep.n}}</option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[7]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <select formControlName="loc" class="form-select form-select-sm" (change)="onValueEdit(s.value.grid_id)">
                    <option [value]="null"></option>
                    <option *ngFor="let loc of locationList" [value]="loc.ID">{{loc.Code}} {{loc.Name}}</option>
                  </select>
                </td>
                <td *ngIf="laborSetupList[8]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <input type="text" formControlName="txt1" class="form-control form-control-sm" (change)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td *ngIf="laborSetupList[9]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <input type="text" formControlName="txt2" class="form-control form-control-sm" (change)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td *ngIf="laborSetupList[10]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <input type="text" formControlName="txt3" class="form-control form-control-sm" (change)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td *ngIf="laborSetupList[11]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                  <input type="text" formControlName="txt4" class="form-control form-control-sm" (change)="onValueEdit(s.value.grid_id)"/>
                </td>
                <td class="text-center">
                  <!--<div class="form-check">-->
                    <input type="checkbox" id="import{{i}}" class="form-check-input" formControlName="overwrite"
                           (change)="onOverwriteCheck($event, s.value.grid_id)"/>
                  <!--</div>-->
                </td>
                <td class="text-center" style="width: 3%;">
                  <ng-container *ngIf="s.value.seq_no == 0 || s.value.status == -1 || s.value.status == -2">
                    <button class="btn btn-outline-danger btn-sm" (click)="deleteClick(s.value.grid_id)"><i class="fas fa-trash-alt"></i></button>
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>
            <pagination-controls
              [nextLabel]="'next' | transloco"
              [previousLabel]="'previous' | transloco"
              (pageChange)="onPageChange($event)"
            ></pagination-controls><!--p = $event-->
            <div class="row my-2">
              <div class="col-12 wrap-item-center">
                <label>{{status_label}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row output-bar" *ngIf="show_output" [hidden]="no_payrollGroup">
          <div class="col-12">
            <div class="output-footer">
              <div class="row" *ngIf="show_output">
                <div class="col-12">
                  <div class="row first-row">
                    <div class="col-9 output">{{translate('outputCurrentProcessPeriod')}}&nbsp;[ {{processPeriod}} ]</div>
                    <div class="col-3"><span id="close-button" (click)="onClosedButtonClick()">&#10005;</span></div>
                  </div>
                  <div class="row second-row">
                    <div class="col-12">
                      <div data-spy="scroll">
                        <div class="row">
                          <div class="second-row-head">
                            <i class="fas fa-exclamation-circle" style="color: #70db70"><span class="msgs"> {{success}}&nbsp;<span
                              transloco="success"></span></span></i>&nbsp;
                            <i class="fas fa-exclamation-circle" style="color: red"><span class="msgs"> {{errs}}&nbsp;{{translate('errors')}}</span></i>&nbsp;
                            <i class="fas fa-exclamation-circle" style="color: orange"><span
                              class="msgs"> {{warnings}}&nbsp;{{translate('warnings')}}</span></i>&nbsp;
                            <i class="fas fa-exclamation-circle" style="color: cornflowerblue"> <span
                              class="msgs">{{messages}}&nbsp;{{translate('messages')}}</span></i>
                          </div>
                        </div>
                        <div class="row px-2" *ngFor="let item of output">
                          <i class="fas fa-exclamation-circle" [ngClass]="getClassesForIcon(item.message_type)"></i>&nbsp;
                          <ng-container *ngIf="item.emp_no != '0'; else other_msg">
                            <span>{{item.emp_no}} - {{item.date}} - {{item.category}} - {{item.units}} - {{item.message}}</span>
                          </ng-container>
                          <ng-template #other_msg>
                            <span>{{item.message}}</span>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <button type="button" class="btn btn-primary" (click)="onOutputClick()">{{translate('output')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!--<div class="container-fluid">-->
  <!--  <div class="row py-2">-->
  <!--    <div class="col wrap-item-left">-->
  <!--      <img src="/assets/image_system/Preferences 2.png" class="icon-Size mr-2"/><h5>Labor</h5>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->
  <app-import-labor [payrollPeriodBase]="payrollProcessPeriod" [prlg_code]="prlg_no" (output)="getOutputfromImport($event)"></app-import-labor>
</ng-container>
