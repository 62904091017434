import {Directive, HostListener, Input} from '@angular/core';
import {BsCustomModalService} from '@shared/bs-custom-modal/bs-custom-modal.service';

@Directive({
  selector: '[appBsCustomModalOpen], [openModal]'
})
export class BsCustomModalOpenDirective {

  @Input() modalTarget: string;

  constructor(
    private bsModalService: BsCustomModalService
  ) {
  }

  @HostListener('click', ['$event'])
  openModal(event: Event) {
    this.bsModalService.open(this.modalTarget);
  }
}
