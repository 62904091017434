import {InjectionToken} from '@angular/core';

export const defaultFormErrors = {
  required: () => `required`,
  requiredTrue: () => `required`,
  fromBackend: (err) => err,
  invalid: (err) => err,
  min: (err) => {
    switch (true) {
      case (err && (err.value != null)):
        return `min.withParams|${err.value}`;
      case (err && (err?.min != null)):
        return `min.withParams|${err.min}`;
      default:
        return `min.noParams`;
    }
  },
  max: (err) => {
    switch (true) {
      case (err && (err?.value != null)):
        return `maxWithParams|${err.value}`;
      case (err && (err?.max != null)):
        return `maxWithParams|${err.max}`;
      default:
        return `max.noParams`;

    }
  },
  maxlength: (err) => `maxlengthWithParams|${err.requiredLength}`,
  minlength: (err) => `minlengthWithParams|${err.requiredLength}`,

  /* Rx Web Validator errors */
  minNumber: (err) => {
    if (err && err.refValues && (err.refValues[1] != null)) {
      return err.message || `min.withParams|${err.refValues[1]}`;
    }
    return `min.noParams`;
  },

  maxNumber: (err) => {
    if (err && err.refValues && (err.refValues[1] != null)) {
      return err.message || `maxWithParams|${err.refValues[1]}`;
    }
    return `max.noParams`;
  },

  minLength: (err) => {
    if (err && err.refValues && (err.refValues[1] != null)) {
      return err.message || `minlength.withParams|${err.refValues[1]}`;
    }
    return `minlength.noParams`;
  },

  maxLength: (err) => {
    if (err && err.refValues && (err.refValues[1] != null)) {
      return err.message || `maxlengthWithParams|${err.refValues[1]}`;
    }
    return `maxlength.noParams`;
  },
  unique: (err) => err?.message || `unique`,
  email: (err) => err?.message || `email`,
  url: (err) => err?.message || `url`,
  alphaNumeric: (err) => err?.message || `alphaNumeric`,
  numeric: (err) => err?.message || `numeric`,
  decimalNotAllowed: (err) => err?.message || `decimalNotAllowed`,
  password: (err) => err?.password,

  otherErrors: (err) => err
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultFormErrors
});
