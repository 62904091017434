import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';

@Component({
  selector: 'app-cheque-printing-options',
  templateUrl: './cheque-printing-options.component.html',
  styleUrls: ['./cheque-printing-options.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'chequePrintingOptions', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class ChequePrintingOptionsComponent implements OnInit {

  paperSizeArr: any[] = [
    {no: 1, name: 'Letter'},
    {no: 2, name: 'Custom'}
  ];


  @Input()
  parentForm: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }

  get chequeNumberH() {
    return this.parentForm.get('chequeNumberH');
  }

  get chequeNumberV() {
    return this.parentForm.get('chequeNumberV');
  }

  get chequeDateH() {
    return this.parentForm.get('chequeDateH');
  }

  get chequeDateV() {
    return this.parentForm.get('chequeDateV');
  }

  get amountNumericH() {
    return this.parentForm.get('amountNumericH');
  }

  get amountNumericV() {
    return this.parentForm.get('amountNumericV');
  }

  get amountAlphabeticH() {
    return this.parentForm.get('amountAlphabeticH');
  }

  get amountAlphabeticV() {
    return this.parentForm.get('amountAlphabeticV');
  }

  get employeeNameH() {
    return this.parentForm.get('employeeNameH');
  }

  get employeeNameV() {
    return this.parentForm.get('employeeNameV');
  }

  get employeeAddressH() {
    return this.parentForm.get('employeeAddressH');
  }

  get employeeAddressV() {
    return this.parentForm.get('employeeAddressV');
  }

  get companyNameH() {
    return this.parentForm.get('companyNameH');
  }

  get companyNameV() {
    return this.parentForm.get('companyNameV');
  }

  get companyAddressH() {
    return this.parentForm.get('companyAddressH');
  }

  get companyAddressV() {
    return this.parentForm.get('companyAddressV');
  }

  get width() {
    return this.parentForm.get('width');
  }

  get height() {
    return this.parentForm.get('height');
  }

  get marginLeft() {
    return this.parentForm.get('marginLeft');
  }

  get marginRight() {
    return this.parentForm.get('marginRight');
  }

  get paperSize() {
    return this.parentForm.get('paperSize');
  }

}
