// copy from Core\CoreCommon\Common\SecurityGlobals.cs
export interface IPageSecurityRight {
  NoRightsDefined: any;
  HasViewUseRight: any;
  HasSelectRight: any;
  HasAddRight: any;
  HasUpdateRight: any;
  HasDeleteRight: any;
  // reserved rights are mostly used on Data Filters
  HasFirstReservedRight: any;
  HasSecondReservedRight: any;
  HasThirdReservedRight: any;
}
export abstract class SecurityKeys {

  static readonly topMenu = {
    // Top Menu
    payrollDashboard: 'payrolldashboard',
    payrollSetup: 'payrollsetup',
    payrollEmployees: 'payrollemployees',
    payrollProcessing: 'payrollprocessing',
    payrollReportsMain: 'payrollreportsmain',
    payrollForms: 'payrollforms',
    payrollYearEnd: 'payrollyearend',
    payrollAdminSettings: 'payrolladminsettings'
  };

  static payrollSetup = {
    // Payroll Setup Menu
    payrollGroup: 'payrollgroup',
    payrollCategories: 'payrollcategories',
    assignPayrollCategories: 'assignpayrollcategories',
    assignTaxFormBoxes: 'assigntaxformboxes',
    laborSetupMenu: 'laborsetupmenu',
    laborSetup: 'laborsetup',
    certifiedPayrollSetup: 'certifiedpayrollsetup',
    categoryCondition: 'categorycondition',
    generalLedgerSetup: 'generalledgersetup',
    glAccountSetup: 'glaccountsetup',
    glStructure: 'glstructure',
    jobCostSetup: 'jobCostSetup',
    jobCostStructure: 'jcStructure'
  };

  static payrollEmployees = {
    // Payroll Employees Menu
    payrollEmployeeList: 'payrollemployeelist',
    payrollCategoryEntitlement: 'payrollcategoryentitlement',
    payCardHistory: 'paycardhistory',
    payStubHistory: 'paystubhistory'
  };

  static payrollProcessing = {
    // Payroll Processing Menu
    payrollWizard: 'payrollwizard',
    selectPayPeriod: 'selectpayperiod',
    payrollTimesheets: 'payrolltimesheets',
    payrollLabor: 'payrolllabor',
    processPayroll: 'processpayroll',
    printEmailPayStub: 'printemailpaystub',
    createBankFile: 'createbankfile',
    createGlRecords: 'createglrecords',
    certifiedPayrollExport: 'certifiedpayrollexport',
    createJobCostRecords: 'createJobCostRecords'
  };

  static payrollReportsMain = {
    // Payroll Reports Main Menu
    payrollReports: 'payrollreports',
    dynamicReports: 'dynamicreports',
    customReports: 'customreports'
  };
  static payrollForms = {
    // Payroll Forms Menu
    roe: 'roe',
    createRoe: 'createroe',
    exportRoeXml: 'exportroexml',
    t4: 't4',
    createT4: 'createt4',
    printEmailT4: 'printemailt4',
    exportT4xml: 'exportt4xml',
    t4a: 't4a',
    createT4a: 'createt4a',
    printEmailT4a: 'printemailt4a',
    exportT4aXml: 'exportt4axml',
    rl11: 'rl-1',
    createRl1: 'createrl1',
    printEmailRl1: 'printemailrl1',
    exportRl1Xml: 'exportrl1xml'
  };

  static payrollAdminSettings = {
    // Payroll Admin Settings Menu
    payrollPreferences: 'payrollpreferences',
    companyBankAccounts: 'companybankaccounts',
    userDefinedFields: 'userdefinedfields',
    importPayStub: 'importpaystub',
    payrollInterface: 'payrollinterface',
    payrollInterfaceImportExport: 'payrollinterfaceimportexport',
    bankInterface: 'bankinterface',
    glExportInterface: 'glexportinterface',
    jobCostExportInterface: 'jcExportInterface'
  };

  static coreModule = {
    // Top Menu
    topMenu: {
      approvalPathSettings: 'approvalpathsettings',
      coreSetup: 'coresetup',
      systemTools: 'systemtools',
      employeeSetup: 'employeesetup'
    },
    approvalPathSettings: {
      approvalPathSetup: 'approvalpathsetup',
      assignedApprovalPath: 'assignedapprovalpath',
      approvalPathCustomGroup: 'approvalpathcustomgroup'
    },
    coreSetup: {
      companyInformationSetup: 'companyinformationsetup',
      systemPreferencesSetup: 'systempreferencessetup',
      departmentSetup: 'departmentsetup',
      locationSetup: 'locationsetup',
      calendarSetup: 'calendarsetup',
      emailSettingsSetup: 'emailsettingssetup',
      payCodesSetup: 'paycodessetup',
      payCodesImportExport: 'paycodesimportexport'
    },
    employees: {
      employeeListMenu: 'employeelistmenu',
      employeeReportsMenu: 'employeereportsmenu',
      employeeImportMenu: 'employeeimportmenu'
    },
    systemTools: {
      companyBackup: 'companybackup',
      companyRestore: 'companyrestore'
    }
  };
}
