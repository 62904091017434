import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvService} from '@services/env.service';
import {Observable} from 'rxjs';

@Injectable()
export class EnvInterceptor implements HttpInterceptor {

  constructor(private envService: EnvService) {
  }

  private glUrls: string[] = [
    'Mapping',
    'Employee/HarmonyDataFields',
    'Employee/UploadCsv/',
    'Employee/GetUpdatedEmployees/',
    'Employee/AddToHarmony',
    'Employee/UpdateInHarmony'
  ];

  private isGLUrl(url: string): boolean {
    return this.glUrls.some(value => url.includes(value));
  }

  private isTNAUrl(url: string): boolean {
    return url.includes(this.envService.tnaApiUrl);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isGLUrl(request.url)) {
      const clone = request.clone({url: this.envService.glApiUrl + request.url});
      return next.handle(clone);
    } else if (this.isTNAUrl(request.url)) {
      return next.handle(request);
    }
    return next.handle(request.clone({url: this.envService.baseApiUrl + request.url}));
  }
}
