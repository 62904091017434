import {Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => SearchBoxComponent)},
    {provide: TRANSLOCO_SCOPE, useValue: {scope: 'searchBox', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}}
  ]
})
export class SearchBoxComponent implements OnInit, ControlValueAccessor {
  @ViewChild('search', {static: true}) searchElement: ElementRef;
  @Input() iconFontClass = 'fas fa-search';
  @Input() placeholder = this.transloco.translate('search');
  @Input() debounceTime = 500;

  @Output() keyup: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() keyUpDelayed: EventEmitter<string> = new EventEmitter<string>();

  searchValue = '';

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  constructor(
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService
  ) {
  }

  ngOnInit() {
    fromEvent(this.searchElement.nativeElement, 'keyup')
      .pipe(debounceTime(this.debounceTime))
      .subscribe(($event: KeyboardEvent) => {
        this.keyUpDelayed.emit(($event?.target as HTMLInputElement).value || '');
      });
  }

  onKeyUp($event: KeyboardEvent) {
    this.keyup.emit($event);
    const val = ($event.target as HTMLInputElement)?.value;
    this.onChange(val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.searchValue = obj;
  }

  onModelChange($event: string) {
    this.search.emit($event);
  }
}
