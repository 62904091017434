import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AccessControlDirective} from './access-control.directive';
import {AddAccessControlDirective} from './add-access-control.directive';
import {DeleteAccessControlDirective} from './delete-access-control.directive';
import {DisableElementAccessControlDirective} from './disable-element-access-control.directive';
import {ModifyAccessControlDirective} from './modify-access-control.directive';
import {SaveAccessControlDirectiveDirective} from './save-access-control-directive.directive';
import {SaveButtonAccessControlDirective} from './save-button-access-control.directive';
import {ViewAccessControlDirective} from './view-access-control.directive';


@NgModule({
  declarations: [
    AccessControlDirective,
    ViewAccessControlDirective,
    ModifyAccessControlDirective,
    DeleteAccessControlDirective,
    AddAccessControlDirective,
    SaveAccessControlDirectiveDirective,
    DisableElementAccessControlDirective,
    SaveButtonAccessControlDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AccessControlDirective,
    ViewAccessControlDirective,
    ModifyAccessControlDirective,
    DeleteAccessControlDirective,
    AddAccessControlDirective,
    SaveAccessControlDirectiveDirective,
    DisableElementAccessControlDirective,
    SaveButtonAccessControlDirective
  ]
})
export class AccessControlDirectiveModule {
}
