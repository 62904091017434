<ng-container *transloco="let translate; read: 'backup'">
  <div class="row mb-3" *ngIf="!wizardService.onWizardMode"><h4 class="col-12">{{translate('compBackup')}}</h4></div>
  <ng-container *ngIf="backupPermission; else noPermission">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-12">
            <alert type="secondary">
              {{translate('backupWarning')}}
            </alert>
          </div>
        </div>
        <form [formGroup]="backupForm" (ngSubmit)="onSubmit()">
          <div class="row mb-3">
            <div class="col-sm-6">
              <label class="form-label" for="fileName">{{translate('backupFileName')}}</label>
              <input id="fileName" class="form-control" type="text" name="fileName" formControlName="fileName" [pattern]="regex" required/>
              <div class="invalid-feedback" *ngIf="fileName.invalid && (fileName.dirty || fileName.touched)">
                <span *ngIf="fileName.errors.required" class="required-error-class"><span transloco="required"></span></span>
                <span *ngIf="fileName.errors?.pattern" class="required-error-class">{{translate('invalidFileName')}}</span>
              </div>
            </div>
            <div class="col-sm-auto d-flex align-items-end pt-4">
              <div class="form-check">
                <input type="checkbox" name="chkCompress" id="chkCompress" formControlName="isCompress" class="form-check-input"
                       (change)="onCompressChanged($event)"/>
                <label class="form-check-label" for="chkCompress">{{translate('compressBackup')}}</label>
              </div>
            </div>
            <div class="col-sm-auto d-flex align-items-end">
              <button class="btn btn-primary" type="submit" [disabled]="backupForm.invalid"><i class="mdi mdi-database-arrow-up"></i> {{translate('backup')}}
              </button>
            </div>
          </div>
        </form>
        <div class="row mb-2">
          <div class="col-12">
            <progressbar
              style="height: 1rem"
              type="success"
              [max]="100"
              [value]="value"
              [animate]="true"
            >
              {{value | number:'1.0-0'}}%
            </progressbar>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <canvas baseChart [labels]="donut.labels" [datasets]="donut.datasets" chartType="doughnut" [options]="donut.options" height="260"></canvas>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button class="btn btn-primary float-end" type="button" data-bs-toggle="modal" data-bs-target="#fileModal" (click)="manageBackup()">
              {{translate('manageBackups')}}
            </button>
          </div>
        </div>
        <div class="row">
          <ngx-spinner name="pageSpinner" [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading text-white"><span transloco="pleaseWait"></span></p>
          </ngx-spinner>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="fileModal" aria-hidden="true" id="fileModal" data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{translate('compBackups')}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-7" *ngIf="userBackupDirs">
                <div class="row" *ngFor="let n of userBackupDirs">
                  <div class="col-12" id="div{{n.id}}">
                    <button type="button" class="btn btn-outline-secondary" id="tab{{n?.id}}" (click)="collapseOnClick(n.id)">{{n.dirName}}</button>
                    <div class="mycontent wrap-item-center" id="con{{n?.id}}">
                      <table class="table table-striped table-responsive" style="table-layout: fixed">
                        <thead class="thead-pm">
                        <tr>
                          <th width="40%" class="text-center" style="overflow: auto"><span transloco="name"></span></th>
                          <th width="15%" class="text-center" style="overflow: auto">{{translate('size')}}</th>
                          <th width="25%" class="text-center" style="overflow: auto">{{translate('createdTime')}}</th>
                          <th width="15%" class="text-center" style="overflow: auto"><span transloco="delete"></span></th>
                        </tr>
                        </thead>
                        <tbody style="max-height: 30vh;">
                        <ng-container *ngIf="n.files">
                          <ng-container *ngFor="let c of n.files; let i = index">
                            <tr>
                              <td width="40%" class="text-center" style="overflow: auto"><span class="sort-cell">{{c.name}}</span></td>
                              <td width="15%" class="text-center" style="overflow: auto"><span class="sort-cell">{{c.length}}</span></td>
                              <td width="25%" class="text-center" style="overflow: auto">
                                <span
                                  class="sort-cell">{{sysPreference?.df ? (c.creationTime | date: sysPreference?.df) : fromJsonDate(c.creationTime) }}</span>
                              </td>
                              <td width="15%" class="text-center" style="overflow: auto">
                                <button class="btn btn-outline-danger" (click)="deleteBackup(n.dirName, n.subDir, c.name)" type="button">
                                  <i class="fas fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <canvas baseChart [labels]="donut.labels" [datasets]="donut.datasets" chartType="doughnut" [options]="donut.options" height="260"></canvas>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><span transloco="ok"></span></button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noPermission>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-auto">
            <h5>{{'accessDeniedMessage' | transloco}}</h5>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
