import {Component, Inject, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BankListService} from '@harmony-modules/payroll/services/admin-setup/bank-list.service';
import {DataExportService} from '@harmony-modules/payroll/services/admin-setup/data-export.service';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {ChequePrintingService} from '@harmony-modules/payroll/services/process/cheque-printing.service';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ShowMessageService} from '@services/show-message.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {of, Subscription} from 'rxjs';
import {concatMap, take} from 'rxjs/operators';
import {IBank} from 'src/app/shared/models/ibank';
import {IUpdatePayrollGroupPaymentDate} from 'src/app/shared/models/icheque-printing';
import {IBankFileLines, IDataExport} from 'src/app/shared/models/idata-export';
import {IPaycardunprocessedEmployees, IPayrollGroupsWithPaymentDate, PRLG_CODE} from 'src/app/shared/models/ipayroll-group';
import {WizardService} from 'src/app/shared/services/wizard.service';


declare var showMsg: any;
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-bank-file',
  templateUrl: './bank-file.component.html',
  styleUrls: ['./bank-file.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'bankFileProcess', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class BankFileComponent implements OnInit {

  bankListArr: IBank[];
  bankId: number;
  BankName: string;
  isBankFileActive: boolean;
  isAllPGs: boolean;
  payrollGroups: IPayrollGroupsWithPaymentDate[] = [{a: null, b: null, c: null, y: null, st: null, ed: null}];
  selectedGroups: IPayrollGroupsWithPaymentDate[] = null;
  selectedGroups2: IPayrollGroupsWithPaymentDate[] = null;
  optionTypeArr: any[] = [
    {no: -1, name: 'All'},
    {no: 0, name: 'Regular'},
    {no: 1, name: 'Special'}
  ];
  optionType: number;
  //depositDate: Date = new Date(Date.now());
  depositDate: string;
  isEdit: number = 0;
  editedID: number = -1;
  isDisabled: boolean = true;
  isSaveClicked: boolean = true;
  exportConfig: IDataExport;
  SeqNum: string = '0';
  bankFileList: IBankFileLines[] = [];
  resultMessage: string;
  FileName: string;
  sysPreference: any;
  payrollgrp_list_for_unprocessed_paycards: PRLG_CODE[] = [];
  unprocessed_emp_list: string = '';
  paycardUnprocessedEmp: IPaycardunprocessedEmployees[] = [];
  paygrps_with_new_paymentdate: IUpdatePayrollGroupPaymentDate[] = [];
  multisubscription: Subscription;
  isUS: boolean = false;
  /**
   * t(bankFileProcess.transactionAndPrenotes, bankFileProcess.transactionAndPrenotes, bankFileProcess.prenotesOnly)
   */
  include_list: any[] = [
    {value: 1, name: 'transactionAndPrenotes'},
    {value: 2, name: 'transactionsOnly'},
    {value: 3, name: 'prenotesOnly'}
  ];
  include: number = 0;


  constructor(
    private _dataExportService: DataExportService,
    private _wizardService: WizardService,
    private _bankService: BankListService,
    private _sysPrefService: SystemPreferenceService,
    private _payrollService: PayrollGroupService,
    private route: ActivatedRoute,
    private _router: Router,
    private _chequePrintingService: ChequePrintingService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService) {
  }

  ngOnInit() {
    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
      this.include_list = this.include_list.map(x => ({...x, name: this.transloco.translate(x.name, {}, 'bankFileProcess')}));
    });

    this._sysPrefService.getinitSystemPreferences().pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.sysPreference = data[0];
        if ((this.sysPreference.y != null) && (this.sysPreference.y == 82)) {
          this.isUS = true;
          this.include = 1;
        }
      }
    });

    if (this.route.snapshot.params.id !== undefined) {
      this.isEdit = 1;
      this.isDisabled = false;
    }
    if (this.isEdit == 1) {
      this.editedID = this.route.snapshot.params.id;
      this._dataExportService.getExportDataByID(this.editedID).pipe(untilDestroyed(this)).subscribe(data => {
        this.exportConfig = data;
        this.bankId = this.exportConfig.b;
        this.BankName = this.exportConfig.d;
        if (this.exportConfig.a === 1) {
          this.isBankFileActive = true;
        } else {
          this.isBankFileActive = false;
        }
        this._dataExportService.getBankNextSeqNum(this.bankId).pipe(untilDestroyed(this)).subscribe(data => {
          this.SeqNum = data;
        });
      });
      this.isSaveClicked = false;
    } else {
      this.isBankFileActive = true;
    }

    this._bankService.getBankList().pipe(untilDestroyed(this)).subscribe((data) => {
      this.bankListArr = data;
      this.bankListArr = this.bankListArr.filter(b => b.c == 1); // Company banks only
    });


    this.multisubscription = this._payrollService.GetPayrollGroupsWithPaymentDate()
      .pipe(
        concatMap(
          res => {
            this.payrollGroups = res;
            return this._wizardService.wizardMode$;
          }),
        concatMap(mode => {
          if (mode) {
            this.isAllPGs = false;
            return this._wizardService.currentGroupInfo$.pipe(take(1), untilDestroyed(this));
          } else {
            this.isAllPGs = true;
            this.selectedGroups2 = [...this.payrollGroups];
            if (this.selectedGroups2) {
              this.valueChange();
            }
            return of(null);
          }
        }),
        untilDestroyed(this)
      ).subscribe(data => {
        if (data) {
          this.selectedGroups = this.payrollGroups.filter(x => x.a == data.gc);
          if (this.selectedGroups) {
            this.valueChange();
          }
        }
      }, err => {
        this.isAllPGs = true;
        this.selectedGroups2 = [...this.payrollGroups];
        if (this.selectedGroups2) {
          this.valueChange();
        }
        console.log(err);
      });

    this.optionType = -1;
    //this.depositDate = new Date(Date.now());


  }

  onSelectedBankChanged(): void {
    this._dataExportService.getBankNextSeqNum(this.bankId).pipe(untilDestroyed(this)).subscribe(data => {
      this.SeqNum = data;
    });
  }

  onSubmit(modelbyuser: NgForm) {
    this.isSaveClicked = true;
    if (this.isEdit == 0) { // New lay out
      let active;
      if (this.isBankFileActive == true) {
        active = 1;
      } else {
        active = 0;
      }
      this.exportConfig = {
        c: -1,
        a: active,
        t: 2,
        b: this.bankId,
        h: null,
        o: null,
        p: null,
        d: this.BankName,
        cr: null,
        db: null,
        hd: null,
        ft: null
      };
      this._dataExportService.addDataExport(this.exportConfig).pipe(untilDestroyed(this)).subscribe((data) => {
        this.editedID = data; // new data export ID

        if (this.editedID !== null) {
          const title = this.transloco.translate('success');
          const message = this.transloco.translate('alerts.addedSuccessfully', {}, 'bankFileProcess');
          showMsg({
            'type': 'nonsticky',
            'status': 'success',
            'title': title,
            'buttons': {},
            'message': message,
            'delay': '1000'
          });
          this.isSaveClicked = false;
          // After adding new layout , back to list
          this._router.navigate(['DataExportList']);
        } else {
          const title = this.transloco.translate('error');
          const message = this.transloco.translate('alerts.cannotAdd', {}, 'bankFileProcess');
          showMsg({
            'type': 'nonsticky',
            'status': 'error',
            'title': title,
            'buttons': {},
            'message': message,
            'delay': '1000'
          });
        }
      });
    } else if (this.isEdit == 1) // Update  layout
    {
      let active;
      if (this.isBankFileActive == true) {
        active = 1;
      } else {
        active = 0;
      }
      this.exportConfig = {
        c: this.editedID,
        a: active,
        t: 2,
        b: this.bankId,
        h: null,
        o: null,
        p: null,
        d: this.BankName,
        cr: null,
        db: null,
        hd: null,
        ft: null
      };
      //console.log(this.exportConfig);
      this._dataExportService.updateDataExport(this.exportConfig).pipe(untilDestroyed(this)).subscribe((data) => {
          const title = this.transloco.translate('success');
          const message = this.transloco.translate('recordSuccessfullyUpdated');
          showMsg({
            'type': 'nonsticky',
            'status': 'success',
            'title': title,
            'buttons': {},
            'message': message,
            'delay': '1000'
          });
          this.isSaveClicked = false;
          // After updating layout , back to list
          //this._router.navigate(['DataExportList']); });
        },
        (err) => {
          const title = this.transloco.translate('error');
          let message = this.transloco.translate('alerts.cannotUpdate', {}, 'bankFileProcess');
          message += '\n' + this.transloco.translate(err.value);
          showMsg({
            'type': 'nonsticky',
            'status': 'error',
            'title': title,
            'buttons': {},
            'message': message,
            'delay': '1000'
          });
        }
      );
    }
  }

  updatePayrollGroupPaymentDate(): void {
    this.paygrps_with_new_paymentdate = this.getPayrollGroupListToUpdatePaymentDate();
    if (this.paygrps_with_new_paymentdate) {
      this._chequePrintingService.UpdatePayrollGroupPaymentDate(this.paygrps_with_new_paymentdate).pipe(untilDestroyed(this)).subscribe(res => {
        this._payrollService.GetPayrollGroupsWithPaymentDate().pipe(untilDestroyed(this)).subscribe(
          res => {
            this.payrollGroups = res;
            this.selectedGroups2 = [...this.payrollGroups];
            if (!this.isAllPGs && this.selectedGroups.length > 0) {
              this.selectedGroups.forEach(x => x.c = new Date(this.depositDate));
            }
          });
      });
    }
  }

  getPayrollGroupListToUpdatePaymentDate(): IUpdatePayrollGroupPaymentDate[] {
    let list: IUpdatePayrollGroupPaymentDate[] = [];
    if (this.isAllPGs) {
      for (let item of this.selectedGroups2) {
        list.push({
          PRLG_CODE: item.a,
          PFREQ_SCH_YEAR: item.y,
          PFREQ_SCH_ST_DATE: item.st,
          PFREQ_SCH_END_DATE: item.ed,
          PFREQ_SCH_CUTOFF_DATE: new Date(this.depositDate)
        });
      }
    } else {
      if (this.selectedGroups.length > 0) {
        for (let item of this.selectedGroups) {
          list.push({
            PRLG_CODE: item.a,
            PFREQ_SCH_YEAR: item.y,
            PFREQ_SCH_ST_DATE: item.st,
            PFREQ_SCH_END_DATE: item.ed,
            PFREQ_SCH_CUTOFF_DATE: new Date(this.depositDate)
          });
        }
      }
    }
    return list;
  }

  CreateBankFile() {
    //let newDate: Date = new Date(this.depositDate);
    let isAll: number = 0;
    if (this.isAllPGs) {
      isAll = 1;
    }
    ;
    let pgIDs: string = '';
    if (!this.isAllPGs) {
      for (let i = 0; i < this.selectedGroups.length; i++) {
        if (pgIDs == '') {
          pgIDs = pgIDs + this.selectedGroups[i].a;
        } else {
          pgIDs = pgIDs + ',' + this.selectedGroups[i].a;
        }
      }
    } else {
      pgIDs = '-1';
    }
    // this.FileName = this.BankName + this.fromJsonDate(this.depositDate);
    this.FileName = this.BankName + this.depositDate;

    this._dataExportService.getBankFileLines(this.bankId, isAll, pgIDs, this.optionType, new Date(this.depositDate), this.SeqNum, this.include).pipe(untilDestroyed(this)).subscribe(data => {
      this.bankFileList = data;
      this.resultMessage = this.bankFileList.pop().Line;
      this.updatePayrollGroupPaymentDate();
    });

    //$('#fileModal').modal({
    //  'toggle': 'true'
    //});
    $('#fileModal').modal('show');
  }

  saveFile() {
    let fileText: string = '';
    for (let i = 0; i < this.bankFileList.length; i++) {
      fileText = fileText + this.bankFileList[i].Line + '\r\n';
    }
    var uint8 = new Uint8Array(fileText.length);
    for (var i = 0; i < uint8.length; i++) {
      uint8[i] = fileText.charCodeAt(i);
    }
    let blob = new Blob([uint8], {type: 'text/plain'}), e = document.createEvent('MouseEvents'), a = document.createElement('a');
    if (window.navigator && window.navigator.msSaveOrOpenBlob) // For IE
    {
      window.navigator.msSaveOrOpenBlob(blob, this.FileName);
    } else {
      a.download = this.FileName;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);//, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    }
    ;
    $('#fileModal').modal('hide');
  }

  checkForUnprocessedPaycards(payrollgrp_list: PRLG_CODE[]): void {
    this.unprocessed_emp_list = '';
    this._payrollService.GetPacardUnprocessedEmployeesForBankFile(payrollgrp_list).pipe(untilDestroyed(this)).subscribe(
      res => {
        this.paycardUnprocessedEmp = res;
        if (res) {
          for (let item of this.paycardUnprocessedEmp) {
            this.unprocessed_emp_list += item.eno + ' - ' + item.en + ' | ';
          }

          if (this.unprocessed_emp_list != '') {
            let warningMarkUp = '';
            this.unprocessed_emp_list.split('|').forEach(x => {
              warningMarkUp += '<span class="msg-cell-gl">' + x + '</span>';
            });
            const title = this.transloco.translate('error');
            const buttonOk = this.transloco.translate('ok');
            const message = this.transloco.translate('alerts.pleaseDeleteOrReprocess', {value: warningMarkUp}, 'bankFileProcess');
            showMsg({
              'type': 'sticky',
              'status': 'error',
              'title': title,
              'buttons': {
                [buttonOk]: {
                  action: function() {
                    return;
                  }
                }
              },
              'message': message,
              'delay': '2000'
            });
          }
        }
      }
    );
  }

  valueChange(): void {
    this.payrollgrp_list_for_unprocessed_paycards.length = 0;
    if (this.isAllPGs) {
      //console.log(this.selectedGroups2);
      this.depositDate = this.selectedGroups2[0] ? this.fromJsonDate(this.selectedGroups2[0].c) : null;
      if (this.selectedGroups2) {
        let temp: PRLG_CODE = {pc: null};
        this.selectedGroups2.forEach(x => {
          temp = {pc: x.a};
          this.payrollgrp_list_for_unprocessed_paycards.push(temp);
        });
        //console.log(this.payrollgrp_list_for_unprocessed_paycards);
        if (this.payrollgrp_list_for_unprocessed_paycards) {
          this.checkForUnprocessedPaycards(this.payrollgrp_list_for_unprocessed_paycards);
        }
      }
    } else {
      //console.log(this.selectedGroups);
      if (this.selectedGroups) {
        let temp: PRLG_CODE = {pc: null};
        this.selectedGroups.forEach(x => {
          temp = {pc: x.a};
          this.payrollgrp_list_for_unprocessed_paycards.push(temp);
        });
        //console.log(this.payrollgrp_list_for_unprocessed_paycards);
        if (this.payrollgrp_list_for_unprocessed_paycards) {
          this.checkForUnprocessedPaycards(this.payrollgrp_list_for_unprocessed_paycards);
        }
        this.depositDate = this.fromJsonDate(this.selectedGroups[0].c);
      }
    }
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate.substring(0, 10));
    return bDate.toISOString().substring(0, 10);  //Ignore time
  }

  ngOnDestroy() {
    if (this.multisubscription) {
      this.multisubscription.unsubscribe();
    }
  }
}
