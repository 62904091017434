import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccessPermissionTypes} from '../access-permission.model';
import {AccessControlService} from '../services/access-control.service';

@Directive({
  selector: '[appAddAccessControl]'
})
export class AddAccessControlDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private accessControlService: AccessControlService
  ) {
  }

  @Input() set appAddAccessControl(componentName: string) {
    if (componentName) {
      const permission = this.getPermissions(componentName, 'add');
      if (permission) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  private getPermissions(componentOrAction: string, accessType: AccessPermissionTypes) {
    const activeModule = this.accessControlService.activeModule;
    return this.accessControlService.getAccessPermissions(activeModule, componentOrAction, accessType);
  }
}
