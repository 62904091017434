<div class="row">
  <div class="col-sm-12 d-flex align-items-center">
    <div class="input-group">
      <button class="btn btn-outline-light border" (click)="onBack($event)" [disabled]="disableBack"><i class="fas fa-caret-left"></i></button>
      <input type="text" class="form-control text-center" [(ngModel)]="searchValue" [ngModelOptions]="{standalone:true}">
      <button class="btn btn-outline-light border" (click)="redirectTo(searchValue)" [disabled]="disableSearchButton"><i class="fas fa-arrow-right"></i></button>
      <button class="btn btn-outline-light border" (click)="onNext($event)" [disabled]="disableNext"><i class="fas fa-caret-right"></i></button>
    </div>
  </div>
</div>
