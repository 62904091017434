import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import {IBackup, IBackupDir, IFileName} from '@shared/models/ibackup';


@Injectable({
  providedIn: 'root'
})
export class BackupService {
  private compressBackup = new BehaviorSubject<boolean>(false);
  public compressBackupOpt: Observable<boolean>;
  private fName: IFileName = { name: "" };
  private bkCompleted = new BehaviorSubject<boolean>(false);
  currentBackupStatus = this.bkCompleted.asObservable();


  constructor(private http: HttpClient) {
    this.compressBackup = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('compressBackup')));
    this.compressBackupOpt = this.compressBackup.asObservable();
  }

  updateBackupOption(s: boolean) {
    this.compressBackup.next(s);
    localStorage.setItem('compressBackup', JSON.stringify(s));
  }

  updateBackupStatus(s: boolean) {
    this.bkCompleted.next(s);
  }

  companyBackup(bkInfo: IBackup): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.backup + "CompanyBackup", bkInfo);
  }

  getUserSpace(): Observable<any> {
    return this.http.get<any>(API_END_POINT.payroll.backup + "GetUserSpace");
  }

  getUserBackupFiles(): Observable<IBackupDir[]> {
    return this.http.get<IBackupDir[]>(API_END_POINT.payroll.backup + "GetUserBackupFiles");
  }

  deleteBackupFile(name: string): Observable<number> {
    this.fName.name = name;
    return this.http.post<number>(API_END_POINT.payroll.backup + "DeleteBackupFile", this.fName);
  }

  isSupervisor(): Observable<boolean> {
    return this.http.get<boolean>(API_END_POINT.account.user + "IsSupervisor");
  }
}
