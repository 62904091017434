import {Directive, HostListener, Input} from '@angular/core';
import {BsCustomModalService} from '@shared/bs-custom-modal/bs-custom-modal.service';

@Directive({
  selector: '[appBsCustomModalClose], [closeModal]'
})
export class BsCustomModalCloseDirective {

  @Input() modalTarget: string;

  constructor(
    private bsModalService: BsCustomModalService
  ) {
  }

  @HostListener('click', ['$event'])
  closeModal(event: Event) {
    this.bsModalService.closeByTarget(this.modalTarget);
  }

}
