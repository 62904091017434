import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'payrollCategoryIcon'
})
export class PayrollCategoryIconPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 4:
      case 'cImgBS':
      case 'cImgSE':
        return 'fas fa-file-invoice-dollar';

      case 8:
      case 'cImgIT':
        return 'bx bxs-calculator';

      case 1:
      case 5:
      case 9:
      case 'cImgFE':
      case 'cImgVE':
        return 'fas fa-hand-holding-usd';

      case 15:
      case 'cImgSHP':
        return 'bx bxs-calendar-star';


      case 6:
      case 13:
      case 'cImgFL':
      case 'cImgVL':
        return 'fas fa-hand-holding-heart';

      case 7:
      case 'cImgA':
        return 'bx bx-bar-chart-alt';

      case 2:
      case 10:
      case 'cImgVD':
      case 'cImgFD':
        return 'mdi mdi-currency-usd-off';

      case 14:
      case 'cImgSD':
        return 'fas fa-donate';

      case 3:
      case 11:
      case 'cImgFC':
        return 'fas fa-handshake';

      default:
        return '';
    }
  }

}
