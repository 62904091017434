import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccessPermissionTypes, PermissionsList} from '../access-permission.model';
import {AccessControlService} from '../services/access-control.service';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private accessControlService: AccessControlService
  ) {
  }

  @Input() set appAccessControl(inputs: { componentName: string, accessType: AccessPermissionTypes, module?: string}) {
    if (inputs.componentName && inputs.accessType) {
      const permission = this.getPermissions(inputs.componentName, inputs.accessType, inputs.module);
      if (permission) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  private getPermissions(componentOrAction: string, accessType: AccessPermissionTypes, module: string): boolean | PermissionsList {
    return this.accessControlService.getAccessPermissions(module || 'payroll', componentOrAction, accessType);
  }
}
