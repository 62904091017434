import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[appCustomUsercodeValidation]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomUsercodeValidationDirective,
    multi: true
  }]
})
export class CustomUsercodeValidationDirective implements Validator {

  @Input('appCustomUsercodeValidation') codeArr: string[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    let arr: string[] = this.codeArr
    const val: string = control.value?.toString();

    if (control.dirty && arr !== null && arr.includes(val)) {
      return { 'userCodeExist': true };
    }
    else
      return null;
  }

  registerOnValidatorChange?(fn: () => void): void {

  }


  constructor() { }

}
