import {Injectable, isDevMode} from '@angular/core';
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel, RetryContext} from '@microsoft/signalr';
import {EnvService} from '@services/env.service';
import {NewsAlertModel} from '@shared/models/news-alert.model';
import {
  AlertMessageService
} from '@harmony-modules/configuration/sections/employee/employee-import/shared/alert-message-service/alert-message.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  /* eslint-disable no-console */
  private _hubConnection: HubConnection;

  constructor(
    private envService: EnvService,
    private alert: AlertMessageService
  ) {
  }

  initialize(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.envService.documentationUrl}/newsUpdate`, {transport: HttpTransportType.WebSockets})
      .configureLogging(isDevMode() ? LogLevel.Information : LogLevel.Error)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
          if (retryContext.retryReason) {
            switch (retryContext.previousRetryCount) {
              case 0:
                return 0;
              case 1:
                return 5000;
              case 2:
                return 10000;
              case 3:
                return 20000;
              default:
                return 30000;
            }
          }
          return null;
        }
      })
      .build();

    this._hubConnection.onclose(async (error) => {
      console.debug(new Date().toLocaleString(), ': Disconnected from Notification Hub');
      error && this.reconnect(30000);
    });

    this.connect();
    this.listenToMessages();
  }

  connect(): void {
    if (this._hubConnection.state !== HubConnectionState.Connected) {
      try {
        this._hubConnection.start()
          .then(() => {
            console.debug(new Date().toLocaleString(), ': Connected to Broadcast Hub');
          })
          .catch(() => {
            console.debug(new Date().toLocaleString(), ': Failed to connect to Broadcast Hub');
            this.reconnect(30000);
          });
      } catch (e) {
        console.debug(new Date().toLocaleString(), ': Failed to locate the server');
        this.reconnect(60000);
      }
    }
  }

  private listenToMessages(): void {
    this._hubConnection.on('deliver', (message: NewsAlertModel) => this.alert.showModalAlerts(message));
  }

  private reconnect(timeOut = 10000): void {
    const message = `Server got disconnected. Trying to reconnect in ${timeOut / 1000} seconds`;
    console.debug(message);
    setTimeout(() => {
      console.debug('Reconnecting to Broadcast Hub.....');
      this.connect();
    }, timeOut);
  }

  async disconnect(): Promise<void> {
    await this._hubConnection?.stop();
  }

  get hubConnection(): HubConnection {
    return this._hubConnection;
  }
}
