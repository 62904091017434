import {Component, OnInit} from '@angular/core';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {ISystemPreferenceInit} from '@shared/models/isystem-preference';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {GlobalService} from '@services/global.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {AuthenticationService} from '@shared/services';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'footer', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  version = environment.version;
  // set the currenr year
  year: number = new Date().getFullYear();
  taxVersion: string;
  payrollYear: any;
  newTaxTable = false;
  initPreference: ISystemPreferenceInit[] = [];
  lastTaxVersionOfYear: number;

  constructor(
    private globalService: GlobalService,
    private authenticationService: AuthenticationService,
    private sysPrefService: SystemPreferenceService
  ) {
  }

  ngOnInit() {
    this.globalService.GetPayrollYear().subscribe(
      res => {
        this.payrollYear = res[0];
        this.lastTaxVersionOfYear = res[1];

        this.sysPrefService.getinitSystemPreferences().subscribe(data => {
          this.initPreference = data;

          if (this.initPreference && this.initPreference.length > 0) {
            // Canadian
            if ((this.initPreference[0].y != null) && (this.initPreference[0].y === 12)) {
              this.taxVersion = this.initPreference[0].d.toString() + '.' + this.initPreference[0].v;
              if (this.initPreference[0].d <= +this.payrollYear) {
                if (this.lastTaxVersionOfYear > +(this.initPreference[0].d + this.initPreference[0].v.replace('.', ''))) {
                  this.newTaxTable = true;
                }
              } else if (this.initPreference[0].d > +this.payrollYear) {
                this.newTaxTable = false;
              }
            }

            if ((this.initPreference[0].y !== null) && (this.initPreference[0].y === 82)) {
              this.globalService.GetSTEVersion().subscribe(tax => {
                this.taxVersion = tax;
              });
            }
            this.authenticationService.currentUser.subscribe(user => {
              if ((
                  user?.selectedCompany?.SubModules.includes('1')
                  || user?.selectedCompany?.SubModules.includes('2')
                  || user?.selectedCompany?.SubModules.includes('3')
                  || user?.selectedCompany?.SubModules.includes('4')) &&
                this.initPreference[0].l === 1
              ) {
                this.authenticationService.updateLabor(true);
                if (this.initPreference[0].lt === 1) {
                  this.authenticationService.updateBasicLabor(true);
                } else {
                  this.authenticationService.updateBasicLabor(false);
                }
                this.authenticationService.updateCertifiedPayroll(this.initPreference[0].ct === 1);
              } else {
                this.authenticationService.updateLabor(false);
                this.authenticationService.updateCertifiedPayroll(false);
              }

              if ((
                  user?.selectedCompany?.SubModules.includes('2')
                  || user?.selectedCompany?.SubModules.includes('4')
                )
                && this.initPreference[0].u === 1) {
                this.authenticationService.updateUnion(true);
              } else {
                this.authenticationService.updateUnion(false);
              }
            });
          }
        });


      }
      );
  }
}
