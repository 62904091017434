import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {AlertComponent} from 'ngx-bootstrap/alert';
import {
  AlertMessageService
} from '@harmony-modules/configuration/sections/employee/employee-import/shared/alert-message-service/alert-message.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NewsAlertModel, NewsAlertSeverity} from '@shared/models/news-alert.model';
import {tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {
  @ViewChild('alertBanner', {static: true}) alertBanner: ElementRef;

  @Output() closed = new EventEmitter<AlertComponent>();

  @Input() timeOutDuration = 30000;

  newsAlert: NewsAlertModel;
  pageTopPadding: string;

  constructor(
    private alertService: AlertMessageService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private documentRef: Document
  ) {
  }

  ngOnInit(): void {
    this.alertService.newsAlert$
      .pipe(
        tap(newsAlert => {
          this.newsAlert = newsAlert;
          this.setPageContentPadding();
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.newsAlert?.severity !== NewsAlertSeverity.Danger) {
          setTimeout(() => {
            this.newsAlert = null;
            this.setPageContentPadding();
          }, this.timeOutDuration);
        }
      });
  }

  private setPageContentPadding() {
    setTimeout(() => {
      const alertHeight = this.alertBanner.nativeElement.clientHeight;
      const pageContent = this.documentRef.querySelector('#page-content');
      if (alertHeight > 0) {
        this.pageTopPadding = this.pageTopPadding || window.getComputedStyle(pageContent).paddingTop;
        this.renderer.setStyle(pageContent, 'padding-top', `${alertHeight + parseInt(this.pageTopPadding, 10)}px`);
      } else {
        this.renderer.setStyle(pageContent, 'padding-top', `${parseInt(this.pageTopPadding, 10)}px`);
      }
    });
  }

  onCloseAlert() {
    this.newsAlert = null;
    this.setPageContentPadding();
    this.closed.emit();
  }
}
