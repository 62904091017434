import {Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {IEmployeeChequePrinting} from '@shared/models/icheque-printing';
import {IPayrollGroups} from '@shared/models/ipayroll-group';
import {ImportedReport} from '@shared/models/ireport';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
//import { EventEmitter } from 'events';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {CompanyService} from '@harmony-modules/configuration/services/setup/company.service';

@UntilDestroy()
@Component({
  selector: 'app-cheque-printing-details',
  templateUrl: './cheque-printing-details.component.html',
  styleUrls: ['./cheque-printing-details.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'chequePrintingDetails', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class ChequePrintingDetailsComponent implements OnInit {

  chqStyleArr: any[] = [];

  payMethodArr: any[] = [];

  chqSortOrderArr: any[] = [];

  dateFormatPayStubArr: string[] = [
    'mmm dd, yyyy',
    'dd mmm, yyyy',
    'dd-mmm-yy',
    'mmm-dd-yy',
    'mm/dd/yyyy',
    'dd/mm/yyyy',
    'mm/dd/yy',
    'dd/mm/yy'
  ];

  dateFormatChequeArr: string[] = [
    'mmm dd, yyyy',
    'dd mmm, yyyy',
    'dd-mmm-yy',
    'mmm-dd-yy',
    'mm/dd/yyyy',
    'dd/mm/yyyy',
    'mm/dd/yy',
    'dd/mm/yy',
    'd d m m y y y y',
    'm m d d y y y y',
    'y y y y d d m m',
    'y y y y m m d d'
  ];

  isAllselected: boolean;
  employeeSearchText: string;
  dropdownListValues: any[] = [{name: ''}];
  selectedDropdownValue: string = null;
  isDropDownvisible: boolean = true;

  _selectedGroups: IPayrollGroups[] = [];
  tempArr: number[] = [];
  filteredEmployeeList_copy: IEmployeeChequePrinting[] = [];

  filterVal: number = 0;
  filteredEmployeeList: IEmployeeChequePrinting[] = [];

  sortOrder: string = 'i';
  sortdirec: boolean = false;
  currencySymbol = '$';
  @Input()
  imprRptVisible: boolean;
  sysPref: any;
  //SIN: string;

  @Input()
  parentForm: FormGroup;
  @Input()
  employeeList: IEmployeeChequePrinting[];

  @Input()
  set filteredEmployeeListFromParent(filteredEmployeeListFromParent: IEmployeeChequePrinting[]) {
    this.filteredEmployeeList = filteredEmployeeListFromParent;
  }

  get filteredEmployeeListFromParent(): IEmployeeChequePrinting[] {
    return this.filteredEmployeeList;
  }

  @Input()
  r_list: ImportedReport[];


  @Input()
  selectedGroups_from_parent: IPayrollGroups[];
  //  this._selectedGroups = prgrp;
  //  this.tempArr.length = 0;
  //  console.log(this._selectedGroups);
  //  console.log(this.filteredEmployeeList)
  //  if (this._selectedGroups.length > 0) {
  //    for (let item of this._selectedGroups) {
  //      this.tempArr.push(item.a);
  //    }
  //  }
  //  else {
  //    this.tempArr.length = 0;
  //    for (let i of this.filteredEmployeeList) {
  //      this.filteredEmployeeList_copy.push(i);
  //    }
  //  }
  //  console.log(this.tempArr)
  //  if (this.tempArr.length > 0 && typeof(this.filteredEmployeeList) != 'undefined') {
  //    console.log("//////");
  //    this.filteredEmployeeList = this.filteredEmployeeList_copy.filter(x => (this.tempArr.indexOf(x.g) >= 0) ? x : null);
  //  }
  //  console.log(this.filteredEmployeeList_copy)
  //}

  //get selectedGroups_from_parent(): IPayrollGroups[] {
  //  return this._selectedGroups;
  //}
  @Output() filterval: EventEmitter<number> = new EventEmitter();


  constructor(private _sysPrefService: SystemPreferenceService,
    private transloco: TranslocoService,
    private _companyService: CompanyService,
    @Inject(TRANSLOCO_SCOPE) private scope) {
  }

  ngOnInit() {
    //this.dropdownListValues = this.getDropdownListValues();
    //console.log(this.selectedGroups_from_parent);
    //this.filteredEmployeeList_copy = this.filteredEmployeeList;
    this._sysPrefService.getinitSystemPreferences().subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
        if ((this.sysPref.y != null) && (this.sysPref.y == 82)) {
          //this.SIN = 'Employee SSN';
        } else {
          //this.SIN = 'Employee SIN';
        }
      }
    });
    this._companyService.GetCompanyDetails().subscribe(cur => {
      if (cur[0].currencySymbol) this.currencySymbol = cur[0].currencySymbol;
    });

    this.initData();
  }

  private initData() {
    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
      this.chqStyleArr = [
        {no: 1, name: this.transloco.translate('chequeOnly', {}, 'chequePrintingDetails')},
        {no: 2, name: this.transloco.translate('paystubOnly ', {}, 'chequePrintingDetails')},
        {no: 3, name: this.transloco.translate('chequeAndPaystub ', {}, 'chequePrintingDetails')},
        {no: 4, name: this.transloco.translate('paystubAndCheque ', {}, 'chequePrintingDetails')},
        {no: 5, name: this.transloco.translate('paystubAndChequeAndPaystub', {}, 'chequePrintingDetails')},
        {no: 6, name: this.transloco.translate('chequeAndPaystubAndPaystub', {}, 'chequePrintingDetails')},
        {no: 7, name: this.transloco.translate('paystubAndPaystubAndCheque', {}, 'chequePrintingDetails')},
        {no: 8, name: this.transloco.translate('userDefinedLayouts', {}, 'chequePrintingDetails')},
        {no: 9, name: this.transloco.translate('chequeAndLaborPaystub', {}, 'chequePrintingDetails')}
      ];

      this.payMethodArr = [
        {no: 1, name: this.transloco.translate('directDeposit', {}, 'chequePrintingDetails')},
        {no: 2, name: this.transloco.translate('cash', {}, 'chequePrintingDetails')},
        {no: 3, name: this.transloco.translate('cheque', {}, 'chequePrintingDetails')},
        {no: 4, name: this.transloco.translate('zeroOrNegativeAmount', {}, 'chequePrintingDetails')}
      ];

      this.chqSortOrderArr = [
        {no: 1, name: this.transloco.translate('employeeNumber', {}, 'chequePrintingDetails')},
        {no: 2, name: this.transloco.translate('employeeName', {}, 'chequePrintingDetails')},
        {no: 3, name: this.transloco.translate('departmentAndEmployee number', {}, 'chequePrintingDetails')},
        {no: 4, name: this.transloco.translate('departmentAndEmployee surname', {}, 'chequePrintingDetails')}
      ];
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('Form style change : =>');
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'parentForm': {
            this.parentForm.get('chqStyle').valueChanges.subscribe(val => {
              //console.log(val);
              this.imprRptVisible = false;
              if (val == 8) {
                //this.parentForm.addControl('importFile', this.fb.group({
                //  name: ['importFile', Validators.required]
                //}));
                this.imprRptVisible = true;
                //this.parentForm.addControl('importFile', new FormControl('', Validators.required));
              }

            });
          }
        }
      }
    }

  }


  sortOptionChange(header: string) {
    if (this.sortOrder == header) {
      this.sortdirec = !this.sortdirec;
    } else {
      this.sortOrder = header;
      this.sortdirec = false;
    }
  }

  getDropdownListValues(): any[] {
    this.dropdownListValues.length = 0;
    this.dropdownListValues.push({name: 'Regular'});//most likely this is leftover from obsolete feature for special payroll
    this.dropdownListValues.push({name: 'Special'});//most likely this is leftover from obsolete feature for special payroll
    return this.dropdownListValues;
  }

  onSelectList(item: string): void {
    this.selectedDropdownValue = item;
    this.isDropDownvisible = false;
  }

  searchEmployeeList(event: any) {
    let subtext: string = event.target.value.toString().toLowerCase();
    this.filteredEmployeeList = this.employeeList.filter((e) => (e.n.toLowerCase().indexOf(subtext) !== -1) || (e.i.toLowerCase().indexOf(subtext) !== -1));

    if (this.employeeSearchText == '') {
      this.filteredEmployeeList = [].concat(this.employeeList);
    }
    this.filteredEmployeeList_copy = this.filteredEmployeeList;
  }

  employeeListCheckAll(event: any) {
    if (event.target.checked) {
      this.isAllselected = true;
      this.employeeList.forEach(e => e.flg = 1);
    } else {
      this.isAllselected = false;
      this.employeeList.forEach(e => e.flg = 0);
    }
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate.substring(0, 10));
    return bDate.toISOString().substring(0, 10);  //Ignore time
  }

  get allbutton() {
    return this.parentForm.get('allbutton');
  }

  get chqStyle() {
    return this.parentForm.get('chqStyle');
  }

  get payMethod() {
    return this.parentForm.get('payMethod');
  }

  get chqSortOrder() {
    return this.parentForm.get('chqSortOrder');
  }

  get dateFormatPayStub() {
    return this.parentForm.get('dateFormatPayStub');
  }

  get dateFormatCheque() {
    return this.parentForm.get('dateFormatCheque');
  }

  get groupByPaystub() {
    return this.parentForm.get('groupByPaystub');
  }

  get empNamePaystub() {
    return this.parentForm.get('empNamePaystub');
  }

  get comNamePaystub() {
    return this.parentForm.get('comNamePaystub');
  }

  get empNumPaystub() {
    return this.parentForm.get('empNumPaystub');
  }

  get comAddrPaystub() {
    return this.parentForm.get('comAddrPaystub');
  }

  get empAddrPaystub() {
    return this.parentForm.get('empAddrPaystub');
  }

  get deptPaystub() {
    return this.parentForm.get('deptPaystub');
  }

  get empSINPaystub() {
    return this.parentForm.get('empSINPaystub');
  }

  get jobTitlePaystub() {
    return this.parentForm.get('jobTitlePaystub');
  }

  get empBankAccPaystub() {
    return this.parentForm.get('empBankAccPaystub');
  }

  get payGrpPaystub() {
    return this.parentForm.get('payGrpPaystub');
  }

  get chqNumPaystub() {
    return this.parentForm.get('chqNumPaystub');
  }

  get payPrdPaystub() {
    return this.parentForm.get('payPrdPaystub');
  }

  get chqDatePaystub() {
    return this.parentForm.get('chqDatePaystub');
  }

  get ytdAmntPaystub() {
    return this.parentForm.get('ytdAmntPaystub');
  }

  get chqNumCheque() {
    return this.parentForm.get('chqNumCheque');
  }

  get dateIdentifier() {
    return this.parentForm.get('dateIdentifier');
  }

  get comNameCheque() {
    return this.parentForm.get('comNameCheque');
  }

  get letterAmnCheque() {
    return this.parentForm.get('letterAmnCheque');
  }

  get comAddrCheque() {
    return this.parentForm.get('comAddrCheque');
  }


  get dollarSignCheque() {
    return this.parentForm.get('dollarSignCheque');
  }

  checkBoxClicked(event: any, eid: number, sp: string): void {
    let tempEmp = this.employeeList.find(x => x.j == eid && x.sp == sp);

    if (!event.target.checked) {
      this.employeeList[this.employeeList.indexOf(tempEmp)].flg = 0;
    } else if (event.target.checked) {
      this.employeeList[this.employeeList.indexOf(tempEmp)].flg = 1;
    }
  }

  onclickEmail(no: number): void {
    this.filterVal = +no;
    this.filterval.emit(this.filterVal);
  }

  onclickClear(no: number): void {
    this.filterVal = +no;
    this.filterval.emit(this.filterVal);
  }
}
