import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'replaceString'
})
export class ReplaceStringPipe implements PipeTransform {

  transform(value: string): string {
    if (value !== null) {
      return value.replace('~', '-');
    }
    else
      return value;
  }

}
