import {ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, ViewContainerRef} from '@angular/core';
import {NgChanges} from '@shared/models/types/simple-changes.type';
import {BackdropComponent} from './backdrop/backdrop.component';
import {OverlayDropComponent} from './overlay-drop/overlay-drop.component';

@Directive({
  selector: '[appBackDrop], [showOverlayBackdrop], [showOverlay]'
})
export class BackDropDirective implements OnChanges {
  overlayTextValue: string;

  @Input() loadingIndicator = false;

  @Input() showOverlay = false;

  @Input() showOverlayBackdrop = false;

  @Input() overLayText = '';

  private backdropComponentComponentRef: ComponentRef<BackdropComponent>;
  private overlayDropComponentComponentRef: ComponentRef<OverlayDropComponent>;

  constructor(
    private vcf: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {
  }

  ngOnChanges(changes: NgChanges<BackDropDirective>): void {
    switch (true) {
      case !!changes?.loadingIndicator:
        this.showBackDrop(changes?.loadingIndicator?.currentValue);
        break;
      case !!changes?.showOverlay:
        this.showBackDrop(changes?.showOverlay?.currentValue);
        break;
      case !!changes?.showOverlayBackdrop:
        this.showOverLayDrop(changes?.showOverlayBackdrop.currentValue);
        break;
      case !!changes?.overLayText:
        this.overlayTextValue = changes.overLayText.currentValue;
        if (this.overlayDropComponentComponentRef) {
          this.overlayDropComponentComponentRef.instance.overlayText = this.overlayTextValue;
        }
        break;
    }
  }

  private showBackDrop(status: boolean): void {
    if (status) {
      const factory = this.cfr.resolveComponentFactory(BackdropComponent);
      this.backdropComponentComponentRef = this.vcf.createComponent(factory);
    } else {
      if (this.backdropComponentComponentRef) {
        this.backdropComponentComponentRef.destroy();
      }
    }
  }

  private showOverLayDrop(status: boolean): void {
    if (status) {
      const factory = this.cfr.resolveComponentFactory(OverlayDropComponent);
      this.overlayDropComponentComponentRef = this.vcf.createComponent(factory);
    } else {
      if (this.overlayDropComponentComponentRef) {
        this.overlayDropComponentComponentRef.destroy();
      }
    }
  }
}
