import {marker as _i18nKey} from '@ngneat/transloco-keys-manager/marker';
import {MenuItem} from './menu.model';
import {SecurityKeys} from '@shared/models/ISecurity';

const PAYROLL_MENU: MenuItem[] = [
  {
    id: '100',
    label: _i18nKey('navigation.payroll'),
    isTitle: true
  },
  {
    id: '101',
    label: _i18nKey('navigation.dashboard'),
    icon: 'bx bx-tachometer',
    link: '/dashboard',
    accessControlKey: 'payrolldashboard'
  },
  {
    id: '102',
    label: _i18nKey('navigation.payrollSetup'),
    icon: 'bx bx-category-alt',
    accessControlKey: 'payrollsetup',
    subItems: [
      {
        id: '102.1',
        label: _i18nKey('navigation.payrollGroups'),
        link: '/payrollsetup/PayrollGroupList',
        accessControlKey: 'payrollgroup',
        parentId: '102'
      },
      {
        id: '102.2',
        label: _i18nKey('navigation.payrollCategories'),
        link: '/payrollsetup/PayrollCategory',
        accessControlKey: 'payrollcategories',
        parentId: '102'
      },
      {
        id: '102.3',
        label: _i18nKey('navigation.assignPayrollCategories'),
        link: '/payrollsetup/PayrollCategoryEntitlement',
        accessControlKey: 'assignpayrollcategories',
        parentId: '102'
      },
      {
        id: '102.4',
        label: _i18nKey('navigation.assignTaxFormBoxes'),
        link: '/payrollsetup/PayrollCategoryTaxFormBoxes',
        accessControlKey: 'assigntaxformboxes',
        parentId: '102'
      },
      {
        id: '102.5',
        label: _i18nKey('navigation.laborSetup'),
        link: '/payrollsetup/PayrollCategory',
        accessControlKey: 'laborsetupmenu',
        parentId: '102',
        subItems: [
          {
            id: '102.5.1',
            label: _i18nKey('navigation.setup'),
            link: '/payrollsetup/labor/LaborSetup',
            parentId: '102.5'
          },
          {
            id: '102.5.2',
            label: _i18nKey('navigation.categoryCondition'),
            link: '/payrollsetup/labor/CategoryCondition',
            accessControlKey: 'categorycondition',
            parentId: '102.5'
          },
          {
            id: '102.5.3',
            label: _i18nKey('navigation.certifiedPayroll'),
            link: '/payrollsetup/labor/CertifiedPayroll',
            accessControlKey: 'certifiedpayrollsetup',
            parentId: '102.5'
          }
        ]
      },
      {
        id: '102.6',
        label: _i18nKey('navigation.generalLedgerSetup'),
        link: '/payroll',
        accessControlKey: SecurityKeys.payrollSetup.generalLedgerSetup,
        parentId: '102',
        subItems: [
          {
            id: '102.6.1',
            label: _i18nKey('navigation.accountSetup'),
            link: '/payrollsetup/gl/GlAccountSetup',
            accessControlKey: SecurityKeys.payrollSetup.glAccountSetup,
            parentId: '102.6'
          },
          {
            id: '102.6.2',
            label: _i18nKey('navigation.structure'),
            link: '/payrollsetup/gl/GlStructure',
            accessControlKey: SecurityKeys.payrollSetup.glStructure,
            parentId: '102.6'
          }
        ]
      },
      {
        id: '102.7',
        label: _i18nKey('navigation.jobCostSetup'),
        link: '/payroll',
        accessControlKey: SecurityKeys.payrollSetup.jobCostSetup,
        parentId: '102',
        subItems: [
          {
            id: '102.7.1',
            label: _i18nKey('navigation.structure'),
            link: '/payrollsetup/JobCostStructure',
            accessControlKey: SecurityKeys.payrollSetup.jobCostStructure,
            parentId: '102.6'
          }
        ]
      }
    ]
  },
  {
    id: '104',
    label: _i18nKey('navigation.payrollEmployees'),
    icon: 'bx bxs-user-account',
    accessControlKey: 'payrollemployees',
    subItems: [
      {
        id: '104.1',
        label: _i18nKey('navigation.employeeList'),
        link: '/employee/EmployeeList',
        accessControlKey: 'payrollemployeelist',
        parentId: '104'
      },
      {
        id: '104.2',
        label: _i18nKey('navigation.assignPayrollCategory'),
        link: '/employee/PayrollCategoryEntitlementEmployeeWise',
        accessControlKey: 'payrollcategoryentitlement',
        parentId: '104'
      },
      {
        isLayout: true
      },
      {
        id: '104.3',
        label: _i18nKey('navigation.paycardHistory'),
        link: '/employee/PaycardHistory',
        accessControlKey: 'paycardhistory',
        parentId: '104'
      },
      {
        id: '104.4',
        label: _i18nKey('navigation.paystubHistory'),
        link: '/employee/PaystubHistory',
        accessControlKey: 'paystubhistory',
        parentId: '104'
      }
    ]
  },
  {
    id: '105',
    label: _i18nKey('navigation.processing'),
    icon: 'bx bx-dollar-circle',
    accessControlKey: 'payrollprocessing',
    subItems: [
      {
        id: '105.1',
        label: _i18nKey('navigation.payrollWizard'),
        link: '/process/processwizard',
        accessControlKey: 'payrollwizard'
      },
      {
        isLayout: true
      },
      {
        id: '105.2',
        label: _i18nKey('navigation.selectPayPeriod'),
        link: '/process/processwizard/payPeriod',
        accessControlKey: 'selectpayperiod'
      },
      {
        id: '105.3',
        label: _i18nKey('navigation.timesheets'),
        link: '/process/processwizard/TimeSheet',
        accessControlKey: 'payrolltimesheets'
      },
      {
        id: '105.4',
        label: _i18nKey('navigation.labor'),
        link: '/process/processwizard/Labor',
        accessControlKey: 'payrolllabor'
      },
      {
        id: '105.5',
        label: _i18nKey('navigation.processPayroll'),
        link: '/process/processwizard/PayrollProcess/0',
        accessControlKey: 'processpayroll'
      },
      {
        id: '105.6',
        label: _i18nKey('navigation.printEmailPaystub'),
        link: '/process/processwizard/PrintChequeAndPaystub',
        accessControlKey: 'printemailpaystub'
      },
      {
        id: '105.7',
        label: _i18nKey('navigation.createBankFile'),
        link: '/adminsetup/interface/DataExportList/1',
        accessControlKey: 'createbankfile'
      },
      {
        isLayout: true
      },
      {
        id: '105.8',
        label: _i18nKey('navigation.createGLRecords'),
        link: '/process/processgl/CreateGlRecords',
        accessControlKey: 'createglrecords'
      },
      {
        id: '105.9',
        label: _i18nKey('navigation.createJobCostRecords'),
        link: '/process/processjobcost/CreateJobCostRecords',
        accessControlKey: SecurityKeys.payrollProcessing.createJobCostRecords
      },
      {
        id: '105.10',
        label: _i18nKey('navigation.certifiedPayrollExport'),
        link: '/process/certifiedpayroll/CertifiedPayrollExport',
        accessControlKey: 'certifiedpayrollexport'
      }
    ]
  },
  {
    id: '106',
    label: _i18nKey('navigation.reports'),
    accessControlKey: 'payrollreportsmain',
    icon: 'bx bx-chart',
    subItems: [
      {
        id: '106.1',
        label: _i18nKey('navigation.reports'),
        link: '/report/reports',
        accessControlKey: 'payrollreports'
      },
      {
        id: '106.2',
        label: _i18nKey('navigation.dynamicReports'),
        link: '/report/dynamicReports/Payroll',
        accessControlKey: 'dynamicreports'
      },
      {
        id: '106.3',
        label: _i18nKey('navigation.customReports'),
        link: 'report/ReportImport',
        accessControlKey: 'customreports'
      }
    ]
  },
  {
    id: '107',
    label: _i18nKey('navigation.forms'),
    icon: 'bx bx-file',
    accessControlKey: 'payrollforms',
    subItems: []
  },
  {
    id: '108',
    label: _i18nKey('navigation.yearEnd'),
    icon: 'bx bx-calendar-event',
    accessControlKey: 'payrollyearend',
    link: '/yearend/YearEnd'
  },
  {
    id: '109',
    label: _i18nKey('navigation.adminSettings'),
    icon: 'bx bx-task',
    accessControlKey: 'payrolladminsettings',
    subItems: [
      {
        id: '109.1',
        label: _i18nKey('navigation.payrollPreferences'),
        link: '/adminsetup/adminsetupsetup/SystemPref',
        accessControlKey: 'payrollpreferences'
      },
      {
        id: '109.2',
        label: _i18nKey('navigation.bankAccounts'),
        link: '/adminsetup/adminsetupsetup/BankList',
        accessControlKey: 'companybankaccounts'
      },
      {
        id: '109.3',
        label: _i18nKey('navigation.userDefinedFields'),
        link: '/adminsetup/adminsetupsetup/UDFList',
        accessControlKey: 'userdefinedfields'
      },
      {
        id: '109.4',
        label: _i18nKey('navigation.importCustomWork'),
        link: '/adminsetup/adminsetupsetup/PaystubImport',
        accessControlKey: 'importpaystub'
      },
      {
        id: '109.5',
        label: _i18nKey('navigation.internalImportUtility'),
        link: '/adminsetup/adminsetupsetup/InternalImportUtility'
      },
      {
        id: '109.6',
        label: _i18nKey('navigation.interfaces'),
        accessControlKey: 'payrollinterface',
        subItems: [
          {
            id: '109.6.1',
            label: _i18nKey('navigation.importInterfaces'),
            link: '/adminsetup/interface/PaycardList',
            accessControlKey: 'payrollinterfaceimportexport'
          },
          {
            id: '109.6.2',
            label: _i18nKey('navigation.bankInterfaces'),
            link: '/adminsetup/interface/DataExportList',
            accessControlKey: 'bankinterface'
          },
          {
            id: '109.6.3',
            label: _i18nKey('navigation.glExportInterfaces'),
            link: '/adminsetup/interface/GLList',
            accessControlKey: 'glexportinterface'
          },
          {
            id: '109.6.4',
            label: _i18nKey('navigation.jobcostexportinterface'),
            link: '/adminsetup/interface/JobCostList',
            accessControlKey: SecurityKeys.payrollAdminSettings.jobCostExportInterface
          }
        ]
      }
    ]
  }
];

const CONFIGURATION_MENU: MenuItem[] = [
  {
    id: '200',
    label: _i18nKey('navigation.configuration'),
    isTitle: true,
    module: 'core'
  },
  {
    id: '201',
    label: _i18nKey('navigation.setup'),
    icon: 'bx bx-cog',
    link: '/configuration/configurationsetup',
    accessControlKey: 'coresetup',
    module: 'core',
    subItems: [
      {
        id: '201.1',
        label: _i18nKey('navigation.companyInformation'),
        link: '/configuration/configurationsetup/Company',
        accessControlKey: 'companyinformationsetup',
        module: 'core'
      },
      {
        id: '201.2',
        label: _i18nKey('navigation.departments'),
        link: '/configuration/configurationsetup/Departments',
        accessControlKey: 'departmentsetup',
        module: 'core'
      },
      {
        id: '201.3',
        label: _i18nKey('navigation.locations'),
        link: '/configuration/configurationsetup/Locations',
        accessControlKey: 'locationsetup',
        module: 'core'
      },
      {
        id: '201.4',
        label: _i18nKey('navigation.holidayCalendars'),
        link: '/configuration/configurationsetup/Calendars',
        accessControlKey: 'calendarsetup',
        module: 'core'
      },
      {
        id: '201.5',
        label: _i18nKey('navigation.emailSettings'),
        link: '/configuration/configurationsetup/Email',
        accessControlKey: 'emailsettingssetup',
        module: 'core',
        hide: true
      },
      {
        id: '201.6',
        label: _i18nKey('navigation.systemPreferences'),
        link: '/configuration/configurationsetup/SysPref',
        accessControlKey: 'systempreferencessetup',
        module: 'core'
      }
    ]
  },
  {
    id: '202',
    label: _i18nKey('navigation.employees'),
    icon: 'bx bx-group',
    accessControlKey: SecurityKeys.coreModule.topMenu.employeeSetup,
    module: 'core',
    subItems: [
      {
        id: '202.1',
        label: _i18nKey('navigation.employeeList'),
        link: '/configuration/configurationemployee/CoreEmployee/false',
        accessControlKey: SecurityKeys.coreModule.employees.employeeListMenu,
        module: 'core'
      },
      {
        id: '202.2',
        label: _i18nKey('navigation.import'),
        link: '/configuration/configurationemployee/GLIntegration/GLIntegrationSetup',
        accessControlKey: SecurityKeys.coreModule.employees.employeeImportMenu,
        module: 'core'
      },
      {
        id: '202.3',
        label: _i18nKey('navigation.employeeReports'),
        link: '/configuration/configurationemployee/employee-reports',
        accessControlKey: SecurityKeys.coreModule.employees.employeeReportsMenu,
        module: 'core'
      }
    ]
  },
  {
    id: '203',
    label: _i18nKey('navigation.tools'),
    icon: 'bx bx-wrench',
    accessControlKey: 'systemtools',
    module: 'core',
    subItems: [
      {
        id: '203.1',
        label: _i18nKey('navigation.companyBackup'),
        link: '/configuration/tools/Backup',
        accessControlKey: 'companybackup',
        module: 'core'
      },
      {
        id: '203.2',
        label: _i18nKey('navigation.companyRestore'),
        link: '/configuration/tools/Restore',
        accessControlKey: 'companyrestore',
        module: 'core'
      },
      {
        id: '203.3',
        label: _i18nKey('navigation.emailLog'),
        link: '/configuration/tools/email-log',
        module: 'core'
      }
    ]
  }
];


export const US_TAX_FORMS_MENU: MenuItem[] = [
  {
    id: '107.1',
    label: _i18nKey('navigation.taxForms'),
    link: '/taxforms/taxformus/TaxForms',
    accessControlKey: 'payrollforms'
  },
  {
    id: '107.2',
    label: _i18nKey('navigation.taxFormsExport'),
    link: '/taxforms/taxformus/TaxFormsExport',
    accessControlKey: 'payrollforms'
  }
];

export const CAN_TAX_FORMS_MENU: MenuItem[] = [
  {
    id: '107.1',
    label: _i18nKey('navigation.roe'),
    accessControlKey: 'roe',
    subItems: [
      {
        id: '107.1.1',
        label: _i18nKey('navigation.createROE'),
        link: '/taxforms/taxformcanada/taxformcanadaRoe/Roe',
        accessControlKey: 'createroe'
      },
      {
        id: '107.1.2',
        label: _i18nKey('navigation.exportROE_XML'),
        link: '/taxforms/taxformcanada/taxformcanadaRoe/RoeWeb',
        accessControlKey: 'exportroexml'
      }
    ]
  },
  {
    id: '107.2',
    label: _i18nKey('navigation.t4'),
    accessControlKey: 't4',
    subItems: [
      {
        id: '107.2.1',
        label: _i18nKey('navigation.createT4'),
        link: '/taxforms/taxformcanada/taxformcanadaT4/T4Forms',
        accessControlKey: 'createt4'
      },
      {
        id: '107.2.2',
        label: _i18nKey('navigation.printEmailT4'),
        link: '/taxforms/taxformcanada/taxformcanadaT4/T4Print',
        accessControlKey: 'printemailt4'
      },
      {
        id: '107.2.3',
        label: _i18nKey('navigation.exportT4_XML'),
        link: '/taxforms/taxformcanada/taxformcanadaT4/T4XML',
        accessControlKey: 'exportt4xml'
      }
    ]
  },
  {
    id: '107.3',
    label: _i18nKey('navigation.t4A'),
    accessControlKey: 't4a',
    subItems: [
      {
        id: '107.2.1',
        label: _i18nKey('navigation.createT4A'),
        link: '/taxforms/taxformcanada/taxformcanadaT4a/T4AForms',
        accessControlKey: 'createt4a'
      },
      {
        id: '107.2.2',
        label: _i18nKey('navigation.printEmailT4A'),
        link: '/taxforms/taxformcanada/taxformcanadaT4a/T4APrint/1',
        accessControlKey: 'printemailt4a'
      },
      {
        id: '107.2.3',
        label: _i18nKey('navigation.exportT4A_XML'),
        link: '/taxforms/taxformcanada/taxformcanadaT4a/T4AXML',
        accessControlKey: 'exportt4axml'
      }
    ]
  },
  {
    id: '107.4',
    label: _i18nKey('navigation.rL-1'),
    accessControlKey: 'rl-1',
    subItems: [
      {
        id: '107.2.1',
        label: _i18nKey('navigation.createRL_1'),
        link: '/taxforms/taxformcanada/taxformcanadaRl1/RL-1',
        accessControlKey: 'createrl1'
      },
      {
        id: '107.2.2',
        label: _i18nKey('navigation.printEmailRL_1'),
        link: '/taxforms/taxformcanada/taxformcanadaRl1/RL1Print/2',
        accessControlKey: 'printemailrl1'
      },
      {
        id: '107.2.3',
        label: _i18nKey('navigation.exportRL1_XML'),
        link: '/taxforms/taxformcanada/taxformcanadaRl1/RL1XML',
        accessControlKey: 'exportrl1xml'
      }
    ]
  }
];


export const NAVIGATION_MENU = Array.prototype.concat(PAYROLL_MENU, CONFIGURATION_MENU);
