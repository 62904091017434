import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ILocString} from '@shared/models/iLocalization';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ShowMessageService {

  constructor(
    private transloco: TranslocoService
  ) {
  }

  // prepareMessage(errors: ILocString[]): string {
  //   let message = '';

    // if (errors && Array.isArray(errors)) {
    //   errors.forEach(err => {
    //     if (err?.parameters && err?.parameters?.length) {
    //       err.parameters.forEach(params => {
    //         message += this.transloco.translate(err.key, {value: params});
    //       });
    //     } else {
    //       message += this.transloco.translate(err.key);
    //     }
    //   });
    // }
    // return message || null;
  // }

  translateErrorMessage(errors: ILocString): string {
    let message = '';

    if (errors?.List) {
      const errorKeys = Object.keys(errors.List);
      errorKeys.forEach(key => {
        if (errors.List[key] && errors.List[key].length) {
          errors.List[key].forEach(params => {
            if (typeof params === 'object') {
              message += '<p>' + this.transloco.translate(key, params) + '</p>';
            } else {
              message += '<p>' + this.transloco.translate(key, {value: params}) + '</p>';
            }
          });
        } else {
          message += '\n' + this.transloco.translate(key);
        }
      });
    }
    return message || '';
  }

  translateErrorMessageForm(errors: ILocString): string {
    let message = '';

    if (errors?.List) {
      const errorKeys = Object.keys(errors.List);
      errorKeys.forEach(key => {
        if (errors.List[key] && errors.List[key].length) {
          errors.List[key].forEach(params => {
            if (typeof params === 'object') {
              message += '<div>' + this.transloco.translate(key, params) + '</div>';
            } else {
              message += '<div>' + this.transloco.translate(key, {value: params}) + '</div>';
            }
          });
        } else {
          message += '<div>' + this.transloco.translate(key) + '</div>';
        }
      });
    }
    return message || '';
  }

  translateHttpError(errors: HttpErrorResponse): string {
    const err = errors?.error;
    return this.translateErrorMessage(err);
  }
}
