import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {WizardService} from '@services/wizard.service';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WizardModeResolver implements Resolve<boolean> {
  constructor(private wizardService: WizardService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route?.data?.wizardMode) {
      const isWizardMode = sessionStorage.getItem('wizard_mode');
      this.wizardService.updateWizardMode(isWizardMode && isWizardMode === 'true');
    } else {
      this.wizardService.updateWizardMode(false);
    }

    return of(true);
  }
}
