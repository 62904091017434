<div class="row" #alertBanner>
  <div class="col-12" *ngIf="newsAlert">
    <div
      class="alert alert-dismissible fade show mb-0"
      role="alert"
      [ngClass]="{
        'alert-primary': newsAlert.severity === 1,
        'alert-secondary': newsAlert.severity === 2,
        'alert-danger': newsAlert.severity === 3,
        'alert-warning': newsAlert.severity === 4,
        'alert-info': newsAlert.severity === 4
      }"
    >
      <strong>{{newsAlert?.title}}:</strong> {{newsAlert?.description}}
      <br/>
      <a [href]="newsAlert?.url" target="_blank" *ngIf="newsAlert.url">Click here to read more</a>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="onCloseAlert()"></button>
    </div>
  </div>
</div>
