import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {tap} from 'rxjs/operators';

@Injectable()
export class HttpLoaderStateInterceptor implements HttpInterceptor {

  constructor(
    private ngxSpinner: NgxSpinnerService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    setTimeout(() => this.ngxSpinner.show('fullPageLoader'), 0);
    return next.handle(request)
      .pipe(
        tap({
            next: () => setTimeout(() => this.ngxSpinner.hide('fullPageLoader', 1000), 100),
            error: () => setTimeout(() => this.ngxSpinner.hide('fullPageLoader', 1000), 100),
            complete: () => setTimeout(() => this.ngxSpinner.hide('fullPageLoader', 1000), 100)
          }
        )
      );
  }
}
