import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PaginationModel} from '@shared/models/pagination.model';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {ICoreSysPrefPicklist} from 'src/app/shared/models/icore-sys-preference';
import {
  CoreEmployeeListFilter,
  EmployeeListLookups,
  ICoreDepartment,
  ICoreEmployeeInfo,
  ICoreEmployeeList,
  ICoreEmployeeListWithModule,
  ICoreLocation,
  IEEO1RaceEthnic,
  IEmptypes,
  IEmpTypeUpdate,
  IEthnicOrigin,
  IManagerList,
  IUpdateEmpInfo
} from 'src/app/shared/models/iemployee';
import {ILocString} from 'src/app/shared/models/iLocalization';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import {CoreSysPreferenceService} from '../setup/core-sys-preference.service';
import {EnvService} from '@services/env.service';
import {concatMap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreEmployeeService {

  constructor(private http: HttpClient, private _coreSysPrefService: CoreSysPreferenceService, private envService: EnvService) {
  }

  private employee = new BehaviorSubject<ICoreEmployeeList>(null);
  currentEmployee = this.employee.asObservable();

  changeEmployee(emp: ICoreEmployeeList) {
    this.employee.next(emp);
  }

  private empNoList = new BehaviorSubject<string[]>(null);
  currentEmpNoList = this.empNoList.asObservable();

  changeEmpNoList(emp_no_list: string[]) {
    this.empNoList.next(emp_no_list);
  }

  getCoreEmployeelist(): Observable<ICoreEmployeeListWithModule> {
    return this.http.get<ICoreEmployeeListWithModule>(API_END_POINT.payroll.employee + 'GetCoreEmployeelist');
  }

  getCoreEmployeeInfo(): Observable<ICoreEmployeeInfo[]> {
    return this.http.get<ICoreEmployeeInfo[]>(API_END_POINT.payroll.employee + 'GetCoreEmployeeInfo');
  }

  getCoreEmployeeListPaged(filters: CoreEmployeeListFilter): Observable<PaginationModel<ICoreEmployeeList>> {
    return this.http.get<PaginationModel<ICoreEmployeeList>>(`${API_END_POINT.payroll.employee}GetCoreEmployeelistPaging`, {params: {...filters}});
  }

  GetPickListValuesForCoreSystemPref(code: string): Observable<ICoreSysPrefPicklist[]> {
    return this.http.get<ICoreSysPrefPicklist[]>(API_END_POINT.payroll.systemPreference + 'GetPickListValuesForCoreSystemPref?code=' + code);
  }

  GetEmployeeTypes(): Observable<IEmptypes[]> {
    return this.http.get<IEmptypes[]>(API_END_POINT.payroll.employee + 'GetEmployeeTypes');
  }

  GetEthnicOrigins(): Observable<IEthnicOrigin[]> {
    return this.http.get<IEthnicOrigin[]>(API_END_POINT.payroll.employee + 'GetEthnicOrigins');
  }

  GetEEO1RaceEthnics(): Observable<IEEO1RaceEthnic[]> {
    return this.http.get<IEEO1RaceEthnic[]>(API_END_POINT.payroll.employee + 'GetEEO1RaceEthnics');
  }

  GetManagers(): Observable<IManagerList[]> {
    return this.http.get<IManagerList[]>(API_END_POINT.payroll.employee + 'GetManagers');
  }

  GetDepartments(): Observable<ICoreDepartment[]> {
    return this.http.get<ICoreDepartment[]>(API_END_POINT.payroll.employee + 'GetDepartments');
  }

  GetLocations(): Observable<ICoreLocation[]> {
    return this.http.get<ICoreLocation[]>(API_END_POINT.payroll.employee + 'GetLocations');
  }

  GetCoreEmployeeById(id: number): Observable<ICoreEmployeeList> {
    const para = new HttpParams().set('id', String(id));
    return this.http.get<ICoreEmployeeList>(API_END_POINT.payroll.employee + 'GetCoreEmployeeById', { params: para });
  }

  GetAllDropDownListValues(gender: string, maritail_status: string, title_code: string, emp_status: string): Observable<any> {
    let response1 = this._coreSysPrefService.getcountries();
    let response2 = this._coreSysPrefService.getProvinces();
    let response3 = this.GetPickListValuesForCoreSystemPref(gender);
    let response4 = this.GetPickListValuesForCoreSystemPref(maritail_status);
    let response5 = this.GetPickListValuesForCoreSystemPref(title_code);
    let response6 = this.GetPickListValuesForCoreSystemPref(emp_status);
    let response7 = this.GetEmployeeTypes();
    let response8 = this.GetDepartments();
    let response9 = this.GetLocations();
    let response10 = this.GetManagers();
    let response11 = this._coreSysPrefService.getCoreSystemPreferences();
    let response12 = this.GetEthnicOrigins();
    let response13 = this.GetEEO1RaceEthnics();

    return forkJoin([response1, response2, response3, response4, response5, response6, response7, response8, response9, response10, response11, response12, response13]);
  }

  UpdateCoreEmployee(file: File, ob: IUpdateEmpInfo): Observable<number> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('ob', JSON.stringify(ob));
    return this.http.post<number>(API_END_POINT.payroll.employee + 'UpdateCoreEmployee', formData)
      .pipe(concatMap((res) => this.clearCache().pipe(map(() => res))));
  }

  clearCache() {
    return this.http.get<void>(this.envService.tnaApiUrl + 'common/initialdata/employee/clearcache');
  }

  //ImageFileToBytes(file: File, id: number): Observable<any> {
  //  const formData = new FormData();
  //  formData.append('file', file);
  //  formData.append('id', id.toString());
  //  return this.http.post<void>(API_END_POINT.payroll.employee + 'ImageFileToBytes', formData);
  //}

  UpdateEmployeeType(ob: IEmpTypeUpdate): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.employee + 'UpdateEmployeeType', ob);
  }

  UpdateEthnicOrigin(ob: IEthnicOrigin): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.employee + 'UpdateEthnicOrigin', ob);
  }

  CheckEmployeeTypeUsed(a: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeeTypeUsed?a=' + a);
  }

  CheckEthnicOriginUsed(a: number, result: number): Observable<ILocString> {
    return this.http.get<ILocString>(API_END_POINT.payroll.employee + 'CheckEthnicOriginUsed?a=' + a + '&result=' + result);
  }

  DeleteEmployeeType(a: number): Observable<number> {
    //return this.http.post<number>("../api/Employee/DeleteEmployeeType", a);
    let params = new HttpParams().set('a', a.toString());
    return this.http.delete<number>(API_END_POINT.payroll.employee + 'DeleteEmployeeType', {params});
  }

  DeleteEthnicOrigin(a: number): Observable<number> {
    let params = new HttpParams().set('a', a.toString());
    return this.http.delete<number>(API_END_POINT.payroll.employee + 'DeleteEthnicOrigin', {params});
  }

  GetAllEmployeeCodes() {
    return this.http.get<any[]>(API_END_POINT.payroll.employee + 'GetAllEmployeeCodes');
  }

  CheckEmployeeUnlockedPaycards(emp_id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeeUnlockedPaycards?emp_id=' + emp_id);
  }

  CheckEmployeeUnassignedTimesheets(emp_id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeeUnassignedTimesheets?emp_id=' + emp_id);
  }

  GetEmployeePayrollValidation(emp_id: number): Observable<any> {
    let res1 = this.CheckEmployeeUnlockedPaycards(emp_id);
    let res2 = this.CheckEmployeeUnassignedTimesheets(emp_id);
    return forkJoin([res1, res2]);
  }

  UpdateEmployeePayrollStatus(id: number, status: number): Observable<any> {
    var empStatusUpdate = {'id': id, 'status': status};
    return this.http.post<void>(API_END_POINT.payroll.employee + 'UpdateEmployeePayrollStatus', empStatusUpdate);
  }

  getEmployeeListLookups() {
    return this.http.get<EmployeeListLookups>(API_END_POINT.payroll.employee + 'GetEmployeeListLookups');
  }

  getImportUrl() {
    return this.http.get<string>(API_END_POINT.payroll.employee + 'GetImportUrl');
  }
}
