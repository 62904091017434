import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs/Rx';
import {
  EditPaySchedule,
  IAddPayrollGroupResponse,
  IAdjPaystub,
  IChangePayFrequency,
  ICheckForSpecialErrors,
  ICheckPayPeriodProcessed,
  ICheckProcessQueue,
  IChecksforLocking,
  ICheckSysPref,
  ICostUpdate,
  ICountProcessedRows,
  ICreateAdjstmntPaycrd,
  ICurPayPeriod,
  IDetailsAdjstmntPaystub,
  IEditAdjPaycard,
  IEditPaycardProcess,
  IEmpForTaxAdjGet,
  IEmpWithoutPay_Grp,
  IEmpWithoutPay_Grp_With_ctl_code,
  IEmpWithPG,
  IGetAdjstmntPaycrd,
  IGetPayStub,
  InputPaySchedule,
  IPaycardEdit,
  IPaycardunprocessedEmployees,
  IPayCatEmployee,
  IPayPeriodLock,
  IPayProcessUpdate,
  IPayrollActiveEmpList,
  IPayrollCatType,
  IPayrollDelete,
  IPayrollFrequency,
  IPayrollGroup,
  IPayrollGroupAll,
  IPayrollGroups,
  IPayrollGroupsVW,
  IPayrollGroupsWithPaymentDate,
  IPayrollProcessPeriod,
  IPayrollProcessStatusReturn,
  IPayScheduleForAdjstmnt,
  IPayStubReturn,
  IPreviousPayPeriod,
  IProcessedListClear,
  IProcessOutputCheck,
  IProcessPayroll,
  IRecalculatePaycard,
  IReversedPaycard,
  ITaxProvinces,
  IUSEmpTaxProvinces,
  IYearToDateInfo,
  NewPayrollGrp,
  NewPayrollGrpEdit,
  PaySchedule,
  PRLG_CODE,
  SaveEmp,
  SelectGroup,
  UpdatePayrollGroupModel,
  UpdatePaySchedule,
  UpdatePayScheduleResult
} from 'src/app/shared/models/ipayroll-group';
// import { forEach } from '@angular/router/src/utils/collection';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import { IDimensionForGlSetup, ILocationListGlSetup } from '../../../../../shared/models/igl';
import { ITimesheetDataLabor } from '../../../../../shared/models/ilabor';


@Injectable({
  providedIn: 'root'
})
export class PayrollGroupService implements OnInit {
  constructor(private http: HttpClient) { }
  savePayrollGrpId: number;

  public sun = new BehaviorSubject<number>(0);
  currentSun = this.sun.asObservable();

  private prorationMode = new BehaviorSubject<number>(0);
  currentProrationMode = this.prorationMode.asObservable();

  private days = new BehaviorSubject<number>(0);
  currentDays = this.days.asObservable();

  private sat = new BehaviorSubject<number>(0);
  currentSat = this.sat.asObservable();

  private prGroupSaveID = new BehaviorSubject<number>(null);
  currentSaveID = this.prGroupSaveID.asObservable();

  private prcode = new BehaviorSubject<string>(null);
  currentPrcode = this.prcode.asObservable();

  private prname = new BehaviorSubject<string>(null);
  currentPrname = this.prname.asObservable();

  private prTakeHome = new BehaviorSubject<number>(null);
  currentPrth = this.prTakeHome.asObservable();

  private prpf = new BehaviorSubject<number>(null);
  currentPrpf = this.prpf.asObservable();

  private prCodeArr = new BehaviorSubject<string[]>(null);
  currentPrCodeArr = this.prCodeArr.asObservable();

  private payfreq = new BehaviorSubject<IPayrollFrequency[]>(null);
  currentPayFreq = this.payfreq.asObservable();

  private manField = new BehaviorSubject<SelectGroup>(null);
  currentManField = this.manField.asObservable();

  private isSaved = new BehaviorSubject<boolean>(false);
  currentIsSaved = this.isSaved.asObservable();

  private disablePayF = new BehaviorSubject<boolean>(false);
  currentdisablePayF = this.disablePayF.asObservable();

  private payschedule = new BehaviorSubject<PaySchedule[]>(null);
  currentPayschedule = this.payschedule.asObservable();

  ngOnInit() {
  }
  changePayschedule(schedule: PaySchedule[]) {
    this.payschedule.next(schedule);
  }

  payrollSettingPageFormData(): Observable<any[]> {

    let response1;
    this.currentProrationMode.subscribe(data => { response1 = data; });
    let response2;
    this.currentSun.subscribe(data => { response2 = data; });
    let response3;
    this.currentDays.subscribe(data => { response3 = data; });
    let response4;
    this.currentSat.subscribe(data => { response4 = data; });

    return forkJoin([response1, response2, response3, response4]);
  }


  changeDisablePayF(val: boolean) {
    this.disablePayF.next(val);
  }

  changeisSaved(v: boolean) {
    this.isSaved.next(v);
  }

  changeManField(mf: SelectGroup) {
    this.manField.next(mf);
  }

  changePayfreq(freq: IPayrollFrequency[]) {
    this.payfreq.next(freq);
  }

  changePrCodeArr(arr: string[]) {
    this.prCodeArr.next(arr);
  }

  changePrpf(pf: number) {
    this.prpf.next(pf);
  }

  changePrth(th: number) {
    this.prTakeHome.next(th);
  }

  changePrname(name: string) {
    this.prname.next(name);
  }

  changePrcode(code: string) {
    this.prcode.next(code);
  }

  changeProrationMode(mode: number) {
    this.prorationMode.next(mode);
  }

  changeDays(noOfdys: number) {
    this.days.next(noOfdys);
  }

  changeSat(day: number) {
    this.sat.next(day);
  }

  changeSun(day: number) {
    this.sun.next(day);
  }

  changeSaveID(id: number) {
    this.prGroupSaveID.next(id);
  }

  getPayrollGroups(): Observable<IPayrollGroup[]> {
    return this.http.get<IPayrollGroup[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollGroupData');
  }
  getPayrollFrequencies(): Observable<IPayrollFrequency[]> {
    return this.http.get<IPayrollFrequency[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollFrequencies');
  }

  getTimeSheetGridData(empId: number, catDtlCode: number, gc: number, fd: any, td: any): Observable<any> {
    const _fd = new Date(fd.toString());
    const _td = new Date(td.toString());
    const params = new HttpParams()
      .set('empId', empId.toString())
      .set('catDtlCode', catDtlCode.toString())
      .set('gc', gc.toString())
      .set('fd', fd)
      .set('td', td);
    return this.http.get<any>(API_END_POINT.payroll.payrollGroup + 'getTimeSheetGridData', { params });
  }

  getTimeSheetGridDataBulkView(gc: number, fd: any, td: any): Observable<any> {
    const params = new HttpParams()
      .set('gc', gc.toString())
      .set('fd', fd)
      .set('td', td);
    return this.http.get<any>(API_END_POINT.payroll.payrollGroup + 'getTimesheetGridDataBulkView', { params });
  }
  getUnsignedEmpGridData(gc: number, fd: any, td: any): Observable<IEmpWithoutPay_Grp_With_ctl_code[]> {
    const _fd = new Date(fd.toString());
    const _td = new Date(td.toString());
    const params = new HttpParams()
      .set('gc', gc.toString())
      .set('fd', fd)
      .set('td', td);

    return this.http.get<IEmpWithoutPay_Grp_With_ctl_code[]>(API_END_POINT.payroll.payrollGroup + 'getUnsignedEmpGridData', { params });
  }

  getPayrunReport(sd: Date, td: Date, gc: number, yr: number, seq: number): Observable<any> {
    const params = new HttpParams()
      .set('sd', sd.toString())
      .set('td', td.toString())
      .set('gc', gc.toString())
      .set('yr', yr.toString())
      .set('seq', seq.toString());
    return this.http.get<any>(API_END_POINT.payroll.payrollGroup + 'GetPayrunReport', { params });
  }

  updateGridData(toUpdate: any[], bulk: boolean): Observable<any> {
    if (!bulk) {
      return this.http.post(API_END_POINT.payroll.payrollGroup + 'updateGridData', toUpdate);
    }
    else {
      return this.http.post(API_END_POINT.payroll.payrollGroup + 'updateGridDataBulk', toUpdate);
    }
  }
  insertTimeSheetEmpData(list: any[]): Observable<any> {
    return this.http.post(API_END_POINT.payroll.payrollGroup + 'insertTimeSheetEmpData', list);
  }
  deleteRow(ei: number, c: number, gc: number, fd: Date, td: Date) {
    const obj = { ei, c, gc, fd, td };
    return this.http.post(API_END_POINT.payroll.payrollGroup + 'deleteTimesheetEmpData', obj);
  }
  deleteRows(obj: any) {
    return this.http.post(API_END_POINT.payroll.payrollGroup + 'deleteTimesheetMultiEmpData', obj);
  }

  clearGroupDataAfterPost(obj: any) {
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'clearGroupDataAfterPost', obj);
  }

  getEmpsWithoutPayGroup(): Observable<IEmpWithoutPay_Grp[]> {
    return this.http.get<IEmpWithoutPay_Grp[]>(API_END_POINT.payroll.payrollGroup + 'GetEmpsWithOutPayrollGroup');
  }
  getEmpsWithPayGroup(prlg_code: PRLG_CODE): Observable<IEmpWithoutPay_Grp[]> {
    const params = new HttpParams().set('id', prlg_code.pc.toString());
    return this.http.get<IEmpWithoutPay_Grp[]>(API_END_POINT.payroll.payrollGroup + 'GetEmpsWithPayrollGroup', { params });
  }

  getPayPeriodsForGroup(gc: number): Observable<any> {
    const params = new HttpParams().set('gc', gc.toString());
    return this.http.get<any>(API_END_POINT.payroll.payrollGroup + 'GetPayPeriodsForGroup', { params });
  }

  updatePayPeriodForGroup(obj: any): Observable<any> {
    return this.http.post<any>((API_END_POINT.payroll.payrollGroup + 'updatePayPeriodForGroup'), obj);
  }

  updateSelecetedPayrollCategories(obj: any): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.payrollGroup + 'updateSelecetedPayrollCategories', obj);
  }

  addNewPayrollGroup(paygrp: NewPayrollGrp): Observable<IAddPayrollGroupResponse> {
    const paygrpobJ = { uc: paygrp.uc, th: paygrp.th, fc: paygrp.fc, u: paygrp.u, a: paygrp.a, d: paygrp.d, pn: paygrp.pn, pm: paygrp.pm };
    return this.http.post<IAddPayrollGroupResponse>((API_END_POINT.payroll.payrollGroup + 'AddPayrollGroup'), paygrpobJ);
  }

  saveEmployeestoPayrollGroup(o: IPayrollGroupAll): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'AddEmptoPayGroup', o);
  }

  removeAllempFromPayrollGroup(o: IPayrollGroupAll): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'RemoveAllEmpFromPayrollGroup', o);

  }

  addEmployeeToPayrollGroup(emp: IEmpWithoutPay_Grp): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'AddEmployeetoPayGroup', emp);

  }

  removeEmployeeFromPayrollGroup(emp: IEmpWithPG): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'RemoveEmployeefromPayGroup', emp);
  }

  //removeEmployeestoPayrollGroup(removeemp: SaveEmp): Observable<void> {

  //  const removeempobj = [];
  //  for (const i in removeemp.emp) {
  //    removeempobj.push({ i: removeemp.emp[i].i, pg: removeemp.pg });
  //  }

  //  return this.http.post<void>((API_END_POINT.payroll.payrollGroup + 'RemoveEmpFromPayrollGroup'), removeempobj);
  //}

  getallPayFrequencies(): Observable<IPayrollFrequency[]> {
    return this.http.get<IPayrollFrequency[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollFrequencies');
  }

  Generate_Pay_Schedule(payschedule: InputPaySchedule): Observable<PaySchedule[]> {
    const paygrpobJ = { PAYGROUP: payschedule.PAYGROUP, PAY_SCH_END_DATE: payschedule.PAY_SCH_END_DATE, PAY_SCH_CUTOFF_DATE: payschedule.PAY_SCH_CUTOFF_DATE };
    return this.http.post<PaySchedule[]>((API_END_POINT.payroll.payrollGroup + 'GeneratePaySchedule'), paygrpobJ);
  }
  Update_Pay_Schedule(payschedule: EditPaySchedule): Observable<UpdatePayScheduleResult> {
    return this.http.post<UpdatePayScheduleResult>((API_END_POINT.payroll.payrollGroup + 'UpdatePaySchedule'), payschedule);
  }
  ValidateAndUpdatePayrollCalendar(list: UpdatePaySchedule): Observable<UpdatePayScheduleResult[]> {
    return this.http.post<UpdatePayScheduleResult[]>((API_END_POINT.payroll.payrollGroup + 'ValidateAndUpdatePayrollCalendar'), list);
  }
  CheckifGeneratedandProcessed(prlg: PRLG_CODE): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'CheckifGeneratedandProcessed', prlg);
  }
  //GetGenerate_Pay_Schedule(id: number): Observable<PaySchedule[]> {
  //  const params = new HttpParams().set('id', id.toString());
  //  return this.http.get<PaySchedule[]>(API_END_POINT.payroll.payrollGroup + 'GetGeneratePaySchedule', { params });
  //}
  GroupDetbyID(prlg: PRLG_CODE): Observable<SelectGroup> {
    const paygrpobJ = { pc: prlg.pc };
    return this.http.post<SelectGroup>(API_END_POINT.payroll.payrollGroup + 'returnpayrollgroupdetailsbyid', paygrpobJ);
  }
  getpayrollschedulebyid(prlg: PRLG_CODE): Observable<PaySchedule[]> {
    const paygrpobJ = { pc: prlg.pc };
    return this.http.post<PaySchedule[]>((API_END_POINT.payroll.payrollGroup + 'returnpayrollschedulebyid'), paygrpobJ);
  }

  updatepayrolldet(prlg: NewPayrollGrpEdit): Observable<UpdatePayrollGroupModel> {

    return this.http.post<UpdatePayrollGroupModel>((API_END_POINT.payroll.payrollGroup + 'UpdatePayrollGroup'), prlg);
  }

  removePayrollGroup(prlg: IPayrollDelete): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'RemovePayrollGroup', prlg);
  }

  getPayrollProcessPeriod(pgc: number): Observable<IPayrollProcessPeriod[]> {

    const para = new HttpParams().set('pgc', String(pgc));
    return this.http.get<IPayrollProcessPeriod[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollProcessPeriod', { params: para });
  }
  GetLocationListForGlSetupForPayroll(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetLocationListForGlSetupForPayroll");
  }
  GetTimesheetDataForLabor(prgp: number, sess_id: string, from_date: Date, to_date: Date, year: number, seq_no: number): Observable<ITimesheetDataLabor[]> {
    return this.http.get<ITimesheetDataLabor[]>(API_END_POINT.payroll.payrollGroup + 'GetTimesheetDataForLabor?prgp=' + prgp + '&sess_id=' + sess_id + '&from_date=' + from_date + '&to_date=' + to_date + '&year=' + year + '&seq_no=' + seq_no);
  }
  GetLocationListForGlSetupForLabor(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetLocationListForGlSetupForLabor");
  }
  GetPayrollGroupsForLabor(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetPayrollGroupsForLabor");
  }
  GetPayrollCategoriesForLabor(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetPayrollCategoriesForLabor");
  }
  GetEmployeesForLabor(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetEmployeesForLabor");
  }
  GetDimensionsForGlSetup(): Observable<IDimensionForGlSetup[]> {
    return this.http.get<IDimensionForGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetDimensionsForGlSetup");
  }
  getDepartmentForGLAccounts(): Observable<ILocationListGlSetup[]> {
    return this.http.get<ILocationListGlSetup[]>(API_END_POINT.payroll.payrollGroup + "GetDepartmentForGLAccounts");
  }

  getExistingPayrollProcessPeriod(pgc: number): Observable<any> {

    const para = new HttpParams().set('pgc', String(pgc));
    return this.http.get<any>(API_END_POINT.payroll.payrollGroup + 'GetExistingPayrollProcessPeriod', { params: para });
  }
  updatePayrollProcess(pr: IProcessPayroll): Observable<string[]> {

    return this.http.post<string[]>(API_END_POINT.payroll.payrollGroup + 'UpdatePayrollProcess', pr);
  }
  //retryPayrollProcess(): Observable<string> {

  //  return this.http.get<string>(API_END_POINT.payroll.payrollGroup + 'RetryPayrollProcess');
  //}

  payrollProcesUpdateStatus(a: string, b: Date, c: Date, d: number, jobId: string): Observable<IPayrollProcessStatusReturn[]> {

    const para = new HttpParams().set('a', String(a)).set('b', String(b)).set('c', String(c)).set('d', String(d)).set('jobId', String(jobId));

    return this.http.get<IPayrollProcessStatusReturn[]>(API_END_POINT.payroll.payrollGroup + 'PayrollProcesReturnStatus', { params: para });

  }
  getAllPayrollActiveEmployees(prlg_code: number, from_date: Date, to_date: Date): Observable<IPayrollActiveEmpList[]> {
    const para = new HttpParams().set('prlg_code', String(prlg_code)).set('from_date', String(from_date)).set('to_date', String(to_date));
    return this.http.get<IPayrollActiveEmpList[]>(API_END_POINT.payroll.payrollGroup + 'GetAllPayrollActiveEmployees', { params: para });
  }

  getSpecialPayrollActiveEmployees(prlg_code: number, from_date: Date, to_date: Date): Observable<IPayrollActiveEmpList[]> {

    const para = new HttpParams().set('prlg_code', String(prlg_code)).set('from_date', String(from_date)).set('to_date', String(to_date));
    return this.http.get<IPayrollActiveEmpList[]>(API_END_POINT.payroll.payrollGroup + 'GetSpecialPayrollActiveEmployees', { params: para });
  }

  getPayrollAllGroups(): Observable<IPayrollGroups[]> {

    return this.http.get<IPayrollGroups[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollGroups_V1');
  }
  getPayrollAllGroupsForTimesheet(): Observable<IPayrollGroups[]> {

    return this.http.get<IPayrollGroups[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollGroupsForTimesheet');
  }
  GetPayrollGroupsWithPaymentDate(): Observable<IPayrollGroupsWithPaymentDate[]> {

    return this.http.get<IPayrollGroupsWithPaymentDate[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollGroupsWithPaymentDate');
  }
  GetPayrollGroupsWithPaymentDateForPaystub(): Observable<IPayrollGroupsWithPaymentDate[]> {

    return this.http.get<IPayrollGroupsWithPaymentDate[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollGroupsWithPaymentDateForPaystub');
  }
  getPayrollAllGroups_v2(): Observable<IPayrollGroups[]> {

    return this.http.get<IPayrollGroups[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollAllGroups_v2');
  }
  getPayrollAllGroupsForPayrollGroup(): Observable<IPayrollGroups[]> {

    return this.http.get<IPayrollGroups[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollAllGroupsForPayrollGroup');
  }
  getPayrollAllGroups_v3(): Observable<IPayrollGroupsVW[]> {

    return this.http.get<IPayrollGroupsVW[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollAllGroups_v3');
  }
  flushPayrollProcessQueue(ipl: IProcessedListClear): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'ClearPayrollProcessQueue', ipl);
  }

  checkPayProcessHasRecords(p: number): Observable<any> {
    const para = new HttpParams().set('p', String(p));
    return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'checkPayProcessHasRecords', { params: para });
  }

  removePaygroupSchedule(pc: IPayrollDelete): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'removePaygroupSchedule', pc);
  }

  removeEmployeesPaygroups(pc: IPayrollDelete): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'removeEmployeesPaygroups', pc);
  }

  requestdatafrommultipleservices(prcode: IPayrollDelete): Observable<any[]> {

    const response1 = this.removeEmployeesPaygroups(prcode);
    const response2 = this.removePaygroupSchedule(prcode);

    return forkJoin([response1, response2]);
  }

  getpayStub(data: IGetPayStub): Observable<IPayStubReturn[]> {

    const para = new HttpParams().set('sd', String(data.sd)).set('ed', String(data.ed)).set('en', String(data.en)).set('pg', String(data.pg)).set('py', String(data.py)).set('SequenceNo', String(data.SequenceNo));
    return this.http.get<IPayStubReturn[]>(API_END_POINT.payroll.payrollGroup + 'GetPayStub', { params: para });

  }
  paycardWithoutPaystubExists(q: number, y: number, g: number, sd: string, ed: string): Observable<any[]> {
    const para = new HttpParams().set('q', String(q)).set('y', String(y)).set('g', String(g)).set('sd', String(sd)).set('ed', String(ed));
    return this.http.get<any[]>(API_END_POINT.payroll.payrollGroup + 'PaycardWithoutPaystubExists', { params: para });
  }
  getSpecialpayStub(data: IGetPayStub): Observable<IPayStubReturn[]> {

    const para = new HttpParams().set('sd', String(data.sd)).set('ed', String(data.ed)).set('en', String(data.en)).set('pg', String(data.pg)).set('py', String(data.py)).set('SequenceNo', String(data.SequenceNo));
    return this.http.get<IPayStubReturn[]>(API_END_POINT.payroll.payrollGroup + 'GetSpecialPayStub', { params: para });

  }

  //GetEmpForTaxAdj(): Observable<IEmpForTaxAdjGet[]> {
  //  return this.http.get<IEmpForTaxAdjGet[]>(API_END_POINT.payroll.payrollGroup + 'GetEmpForTaxAdj');
  //}

  updatePayFrqDisabledFlag(obj: IPayPeriodLock): Observable<any> {
    return this.http.post<void>((API_END_POINT.payroll.payrollGroup + 'updatePayFrqDisabledFlag'), obj);
  }

  updatePayFrqDisabledFlagForWizard(obj: IPayPeriodLock): Observable<any> {
    return this.http.post<void>((API_END_POINT.payroll.payrollGroup + 'updatePayFrqDisabledFlagForWizard'), obj);
  }

  checkPayPeriodIsEmpty(obj: IChecksforLocking): Observable<ICountProcessedRows> {
    return this.http.post<ICountProcessedRows>((API_END_POINT.payroll.payrollGroup + 'checkPayPeriodIsEmpty'), obj);
  }

  cleanProcOutPutTablefterLocking(obj: IChecksforLocking): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'CleanProcOutPutTablefterLocking', obj);
  }

  cleanProcOutPutTablefterLockingForWizard(obj: IChecksforLocking): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'CleanProcOutPutTablefterLockingForWizard', obj);
  }

  getServiceStatus(ex: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'getServiceStatus?ex=' + ex);
  }

  clearUnprocessedEmployeesFromtheQueue(ob: ICheckProcessQueue): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'clearUnprocessedEmployeesFromQueue', ob);
  }

  checkSystemPreference(): Observable<ICheckSysPref> {
    return this.http.get<ICheckSysPref>(API_END_POINT.payroll.payrollGroup + 'CheckSystemPreference');
  }

  getPayrollCategoryTypesforExternal(): Observable<IPayrollCatType[]> {
    return this.http.get<IPayrollCatType[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollCategoriesForExternalPay');
  }

  getpaycatEmployees(): Observable<IPayCatEmployee[]> {
    return this.http.get<IPayCatEmployee[]>(API_END_POINT.payroll.payrollGroup + 'GetEmployeesForExternalPay');  // ?pc=" +pay_cat_code
  }

  getProcessOutputForLockingPayPeriod(ob: IChecksforLocking): Observable<IProcessOutputCheck> {

    return this.http.get<IProcessOutputCheck>(API_END_POINT.payroll.payrollGroup + 'GetProcessOutputForLockingPayPeriod?fromDate=' + ob.fromDate + '&toDate=' + ob.toDate + '&prlgCode=' + ob.prlgCode + '&year=' + ob.year + '&proc_seq_no=' + ob.proc_seq_no);
  }

  getProcessOutputForLockingPayPeriodForWizard(ob: IChecksforLocking): Observable<IProcessOutputCheck> {

    return this.http.get<IProcessOutputCheck>(API_END_POINT.payroll.payrollGroup + 'GetProcessOutputForLockingPayPeriodForWizard?fromDate=' + ob.fromDate + '&toDate=' + ob.toDate + '&prlgCode=' + ob.prlgCode + '&year=' + ob.year + '&proc_seq_no=' + ob.proc_seq_no);
  }

  checkErrorsInspecialPayProcess(pg: number, fromdate: Date, todate: Date, proc_year: number): Observable<ICheckForSpecialErrors[]> {
    return this.http.get<ICheckForSpecialErrors[]>(API_END_POINT.payroll.payrollGroup + 'CheckForErrorsInSpecialProcess?pg=' + pg + '&fromdate=' + fromdate + '&todate=' + todate + '&proc_year=' + proc_year);
  }

  checkSalaryProcessBeforeLocking(ob: IChecksforLocking): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'checkSalaryProcessBeforeLocking?fromDate=' + ob.fromDate + '&toDate=' + ob.toDate + '&prlgCode=' + ob.prlgCode + '&year=' + ob.year + '&proc_seq_no=' + ob.proc_seq_no);
  }

  payrollGroupLockMultipleService(ob: IChecksforLocking): Observable<any> {
    const response1 = this.getProcessOutputForLockingPayPeriod(ob);
    return forkJoin([response1]);
  }

  payrollGroupLockMultipleServiceForWizard(ob: IChecksforLocking): Observable<any> {
    const response1 = this.getProcessOutputForLockingPayPeriodForWizard(ob);
    return forkJoin([response1]);
  }

  getPaycardForEdit(ob: IGetPayStub): Observable<IPaycardEdit[]> {
    return this.http.get<IPaycardEdit[]>(API_END_POINT.payroll.payrollGroup + 'GetPaycardForEdit?sd=' + ob.sd + '&ed=' + ob.ed + '&en=' + ob.en + '&pg=' + ob.pg + '&y=' + ob.py + '&SequenceNo=' + ob.SequenceNo);
  }

  getTaxProvincesForEmployees(ob: IChecksforLocking): Observable<ITaxProvinces[]> {
    return this.http.get<ITaxProvinces[]>(API_END_POINT.payroll.payrollGroup + 'GetTaxProvincesForEmployees?frm_date=' + ob.fromDate + '&to_date=' + ob.toDate + '&pg_code=' + ob.prlgCode + '&year=' + ob.year);
  }
  GetTaxProvincesForUSEmployees(): Observable<IUSEmpTaxProvinces[]> {
    return this.http.get<IUSEmpTaxProvinces[]>(API_END_POINT.payroll.payrollGroup + 'GetTaxProvincesForUSEmployees');
  }

  ProcessEditPaycardObject(ob: IEditPaycardProcess[]): Observable<string> {
    return this.http.post<string>((API_END_POINT.payroll.payrollGroup + 'ProcessEditPaycardObject'), ob);
  }

  //updatePayprocessOnEditPaycard(ob: IPayProcessUpdate): Observable<number> {
  //  return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'UpdatePayprocessOnEditPaycard', ob);
  //}

  GetPacardUnprocessedEmployeesForBankFile(list: PRLG_CODE[]): Observable<IPaycardunprocessedEmployees[]> {
    return this.http.post<IPaycardunprocessedEmployees[]>(API_END_POINT.payroll.payrollGroup + 'GetPacardUnprocessedEmployeesForBankFile', list);
  }

  CheckPayPeriodProcessed(ob: ICheckPayPeriodProcessed): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'CheckPayPeriodProcessed?pc=' + ob.pc + '&proc_from=' + ob.proc_from + '&proc_to=' + ob.proc_to + '&proc_year=' + ob.proc_year);
  }
  ChangePayFrequency(ob: IChangePayFrequency): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'ChangePayFrequency', ob);
  }

  //GetReversePaycardInprogress(prlg_code: number, id_emp: number): Observable<number> {
  //  return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'GetReversePaycardInprogress?prlg_code=' + prlg_code + '&id_emp=' + id_emp);
  //}

  ReversePaycard(ob: IReversedPaycard): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'ReversePaycard', ob);
  }

  CheckBeforeUnassignEmpFromPayrollGrp(list: number[]): Observable<string> {
    return this.http.post<string>(API_END_POINT.payroll.payrollGroup + 'CheckBeforeUnassignEmpFromPayrollGrp', list);
  }
  GetPayScheduleForPaycardAdjustment(): Observable<IPayScheduleForAdjstmnt[]> {
    return this.http.get<IPayScheduleForAdjstmnt[]>(API_END_POINT.payroll.payrollGroup + 'GetPayScheduleForPaycardAdjustment');
  }
  CreateAdjustmentPaycard(ob: ICreateAdjstmntPaycrd): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'CreateAdjustmentPaycard', ob);
  }

  ValidateBeforeCreateAdjustment(ob: ICreateAdjstmntPaycrd): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.payrollGroup + 'ValidateBeforeCreateAdjustment?emp_id=' + ob.Emp_Number + '&year=' + ob.Proc_Year + '&prlg_code=' + ob.PayrollGroup_Id + '&frm_date=' + ob.Start_Date.toString().substring(0, 10) + '&to_date=' + ob.End_Date.toString().substring(0, 10));
  }
  GetAdjustmentPaystub(ob: IGetAdjstmntPaycrd): Observable<IAdjPaystub[]> {
    return this.http.get<IAdjPaystub[]>(API_END_POINT.payroll.payrollGroup + 'GetAdjustmentPaystub?Start_Date=' + ob.Start_Date + '&End_Date=' + ob.End_Date + '&Emp_Number=' + ob.Emp_Number + '&PayrollGroup_Id=' + ob.PayrollGroup_Id + '&Proc_Year=' + ob.Proc_Year + '&SequenceNo=' + ob.SequenceNo);
  }
  GetDetailsForAdjustmentPaystub(id_emp: number, sdate: Date, edate: Date, seqno: number, year: number, prlg_code: number): Observable<IDetailsAdjstmntPaystub> {
    return this.http.get<IDetailsAdjstmntPaystub>(API_END_POINT.payroll.payrollGroup + 'GetDetailsForAdjustmentPaystub?id_emp=' + id_emp + '&sdate=' + sdate + '&edate=' + edate + '&seqno=' + seqno + '&year=' + year + '&prlg_code=' + prlg_code);
  }
  getDataFinalAdjustmentPayStub(ob: IGetAdjstmntPaycrd): Observable<any> {
    const res1 = this.GetAdjustmentPaystub(ob);
    const res2 = this.GetDetailsForAdjustmentPaystub(ob.Emp_Number, ob.Start_Date, ob.End_Date, ob.SequenceNo, ob.Proc_Year, ob.PayrollGroup_Id);
    return forkJoin([res1, res2]);
  }

  DeleteAdjustmentPaycard(ob: IGetAdjstmntPaycrd): Observable<number> {
    //const s_date = new Date(ob.Start_Date.toString());
    //const e_date = new Date(ob.End_Date.toString());
    const params = new HttpParams()
      .set('Proc_Start_Date', ob.Start_Date.toString().substring(0, 10)) // this.formatDate(from_date)
      .set('Proc_End_Date', ob.End_Date.toString().substring(0, 10))
      .set('PayrollGroup', ob.PayrollGroup_Id.toString())
      .set('EmpNumber', ob.Emp_Number.toString())
      .set('ProcYear', ob.Proc_Year.toString())
      .set('SequenceNo', ob.SequenceNo.toString());
    return this.http.delete<number>(API_END_POINT.payroll.payrollGroup + 'DeleteAdjustmentPaycard', { params });
  }

  EditAdjustmentPaycard(list: IEditAdjPaycard[]): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.payrollGroup + 'EditAdjustmentPaycard', list);
  }
  RecalculatePaycard(ob: IRecalculatePaycard): Observable<any> {
    //const S_Date = new Date(ob.Start_Date.toString());
    //const E_Date = new Date(ob.End_Date.toString());
    //const P_Date = new Date(ob.PaymentDate.toString());
    const params = new HttpParams()
      .set('Start_Date', ob.Start_Date.toString().substring(0, 10))
      .set('End_Date', ob.End_Date.toString().substring(0, 10))
      .set('Emp_Number', ob.Emp_Number.toString())
      .set('PayrollGroup_Id', ob.PayrollGroup_Id.toString())
      .set('Proc_Year', ob.Proc_Year.toString())
      .set('SequenceNo', ob.SequenceNo.toString())
      .set('ChequeNumber', ob.ChequeNumber ? ob.ChequeNumber.toString() : '0')
      .set('PaymentDate', ob.PaymentDate)
      .set('InsurableEarnings', ob.InsurableEarnings.toString())
      .set('InsurableHours', ob.InsurableHours.toString());

    return this.http.get<any>(API_END_POINT.payroll.payrollGroup + 'RecalculatePaycard', { params });
  }
  ProcessCost(ob: ICostUpdate): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.payrollGroup + 'ProcessCost', ob);
  }

  GetCurrentYear(): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'GetCurrentYear');
  }

  GetCurPayPeriod(pgId: number, year: number): Observable<ICurPayPeriod> {
    return this.http.get<ICurPayPeriod>(API_END_POINT.payroll.payrollGroup + 'GetCurPayPeriod?pgId=' + pgId + '&year=' + year);
  }

  GetPreviousPayPeriod(pgId: number, year: number): Observable<IPreviousPayPeriod> {
    return this.http.get<IPreviousPayPeriod>(API_END_POINT.payroll.payrollGroup + 'GetPreviousPayPeriod?pgId=' + pgId + '&year=' + year);
  }

  GetYearToDateInfo(pgId: number, year: number): Observable<IYearToDateInfo> {
    return this.http.get<IYearToDateInfo>(API_END_POINT.payroll.payrollGroup + 'GetYearToDateInfo?pgId=' + pgId + '&year=' + year);
  }

  DownloadPayStubFile(payload){
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'DownloadPayStubFile', payload);
  }

  DeleteOlderPayStub(payload){
    return this.http.post<void>(API_END_POINT.payroll.payrollGroup + 'DeleteOlderPayStub', payload);
  }

  CheckPayprocessQueue(from_date: Date, to_date: Date, prlg_code: number, seq_no: number, proc_year: number): Observable<number> {
    const params = new HttpParams()
      .set('Start_Date', from_date.toString().substring(0, 10))
      .set('End_Date', to_date.toString().substring(0, 10))
      .set('SequenceNo', seq_no.toString())
      .set('PayrollGroup_Id', prlg_code.toString())
      .set('Proc_Year', proc_year.toString());
    return this.http.get<number>(API_END_POINT.payroll.payrollGroup + 'CheckPayprocessQueue', { params });
  }
}
