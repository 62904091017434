import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ILanguage, IUserPreference} from 'src/app/shared/models/iuser';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import {ChangePasswordModel} from '@shared/models/credentials.interface';

@Injectable({
  providedIn: 'root'
})
export class UserSetupService {

  constructor(private http: HttpClient) {
  }

  updateUserPassword(model: ChangePasswordModel): Observable<any> {
    return this.http.post<any>(API_END_POINT.account.account + 'ChangePassword', model);
  }

  getUserPreference(): Observable<IUserPreference> {
    return this.http.get<IUserPreference>(API_END_POINT.account.user + 'GetUserPreference');
  }

  isTwoFactorAuthenticationEnabled(): Observable<boolean> {
    return this.http.get<boolean>(API_END_POINT.account.user + 'IsTwoFactorAuthenticationEnabled');
  }

  updateUserPreference(uPref: IUserPreference): Observable<any> {
    return this.http.post<any>(API_END_POINT.account.user + 'UpdateUserPreference', uPref);
  }

  getLanguages(): Observable<ILanguage[]> {
    return this.http.get<ILanguage[]>(API_END_POINT.ess.ess + 'GetLanguages');
  }
}
