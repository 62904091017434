import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AlertModalComponent} from '@shared/components/alert-modal/alert-modal.component';
import {NewsAlertModel, NewsAlertSeverity} from '@shared/models/news-alert.model';
import {TranslocoService} from '@ngneat/transloco';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  private modalRef: BsModalRef;
  public newsAlert$ = new Subject<NewsAlertModel>();

  constructor(
    private modalService: BsModalService,
    private transloco: TranslocoService
  ) {
  }

  showModalAlerts(news: NewsAlertModel) {
    this.newsAlert$.next(news);
    // if (news?.severity === NewsAlertSeverity.Danger) {
    //   this.modalRef = this.modalService.show<AlertModalComponent>(AlertModalComponent, {
    //     initialState: {news},
    //     ignoreBackdropClick: true,
    //     keyboard: false,
    //     backdrop: 'static',
    //     focus: true,
    //     animated: true
    //   });
    // } else {
    //   this.newsAlert$.next(news);
    // }
  }

  confirmAction(options?: { message: string, title?: string }) {
    return Swal.fire({
      title: options && options.title || 'Are you sure',
      text: options && options.message || 'Do you want to continue this operation?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    });
  }

  successMessage(options?: { message: string, title?: string }) {
    Swal.fire({
      title: options && options.title || 'Success',
      text: options && options.message || 'Operation Success',
      icon: 'success'
    });
  }

  newsAlertToast(news: NewsAlertModel) {
    Swal.fire({
      title: news.title || 'Alert',
      text: news.description,
      icon: 'info',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      showCloseButton: true,
      timer: 7000,
      showClass: {
        popup: 'animated fadeInUp faster'
      },
      hideClass: {
        popup: 'animated fadeOutDown faster'
      },
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  successToastMessage(options?: { title?: string }) {
    Swal.fire({
      title: options && options.title || 'Success',
      icon: 'success',
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      showCloseButton: true,
      timer: 7000,
      showClass: {
        popup: 'animated fadeInUp faster'
      },
      hideClass: {
        popup: 'animated fadeOutDown faster'
      },
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  errorMessage(options?: { message: string, title?: string }) {
    Swal.fire({
      title: options && options.title || 'Error',
      text: options && options.message || 'Operation Failed',
      icon: 'error'
    });
  }

  httpErrorMessage(options?: { message: string, title?: string }) {
    Swal.fire({
      title: options && options.title || 'Error',
      text: options && options.message || 'Failed to communicate with server',
      icon: 'error'
    });
  }
}
