import {Injectable} from '@angular/core';
import {Resolve, Router} from '@angular/router';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/router';
import {EnvService} from '@services/env.service';
import 'jquery';
import {ISignalRConnection, SignalR, SignalRConfiguration, SignalRConnection} from 'ng2-signalr';
import {BehaviorSubject, from, of, Subject} from 'rxjs';
import 'signalr';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslocoService} from '@ngneat/transloco';
import {take, takeUntil} from 'rxjs/operators';
import {ConnectionStatus} from 'ng2-signalr/lib/services/connection/connection.status';
import {
  IPayrollActiveEmp,
  IPayrollProcessStatusReturn,
  IPayrollProcessUpdate,
  ProcessOutputMessageSummary,
  ProcessSummary
} from '@shared/models/ipayroll-group';

declare var showMsg: any;

@Injectable({providedIn: 'root'})
export class SalaryProcessMessageService implements Resolve<any> {
  connection: SignalRConnection;
  payrollProcessProgress: IPayrollProcessUpdate = null;
  payrollProcessProgress$ = new BehaviorSubject<IPayrollProcessUpdate>(null);
  processSummary$ = new Subject<ProcessSummary>();
  disconnectConnection$ = new Subject<void>();

  constructor(
    private signalR: SignalR,
    private envService: EnvService,
    private ngxSpinner: NgxSpinnerService,
    private transloco: TranslocoService,
    private router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.payrollProcessProgress?.percentage === 100) {
      this.payrollProcessProgress = null;
      this.updatePayrollProcessProgress();
    }

    if (!this.connection?.id) {
      return from(this.connect());
    }
    return of(true);
  }

  updatePayrollProcessProgress() {
    if (this.payrollProcessProgress?.percentage === 100) {
      this.payrollProcessProgress.status = 'Completed';
    } else if (this.payrollProcessProgress?.percentage < 100 && this.payrollProcessProgress?.percentage > 0) {
      this.payrollProcessProgress.status = 'InProgress';
    } else if (this.payrollProcessProgress?.percentage === 0) {
      this.payrollProcessProgress.status = 'Queued';
    } else {
      this.payrollProcessProgress = null;
    }
    this.payrollProcessProgress$.next(this.payrollProcessProgress);
  }

  private showError() {
    this.transloco.selectTranslate('').pipe(take(1)).subscribe(() => {
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.payrollProcess.salaryProcessHubError');
      showMsg({
        type: 'sticky',
        status: 'error',
        title: 'Error',
        message,
        buttons: {
          [buttonOk]: {
            action() {
            }
          }
        }
      });
    });
  }

  private async connect(): Promise<ISignalRConnection | void> {
    setTimeout(() => this.ngxSpinner.show('fullPageLoader'), 0);
    const c = new SignalRConfiguration();
    c.hubName = 'salaryProcessHub';
    c.url = this.envService.signalRHubUrl + '/salary';
    c.logging = true;

    // >= v5.0.0
    c.executeEventsInZone = true; // optional, default is true
    c.executeErrorsInZone = false; // optional, default is false
    c.executeStatusChangeInZone = true; // optional, default is true
    this.connection = this.signalR.createConnection(c);
    return await this.connection.start()
      .then(() => {
        this.connection.errors.pipe(takeUntil(this.disconnectConnection$)).subscribe((error: any) => console.log(error));
        this.connection.status.pipe(takeUntil(this.disconnectConnection$)).subscribe((status: ConnectionStatus) => {
          console.log(status);
          if (status?.value === 4) {
            setTimeout(() => this.connection.start(), 15000);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.showError();
      })
      .finally(() => {
        setTimeout(() => this.ngxSpinner.hide('fullPageLoader'), 0);
      });
  }

  initializePayCardEditProcess(filteredPRActiveEmployees: IPayrollActiveEmp[], employeeId: number, processPeriod: string) {
    this.connection.listenFor('processCompleted').pipe(take(1)).subscribe((res: IPayrollProcessUpdate) => {
      console.log(res);
      const processSummary: ProcessSummary = {processPeriod, output: this.getProcessOutputMessageSummary(null)} as ProcessSummary;
      processSummary.messages = [this.transloco.translate(res.message.g)];

      const a = res.message.f;
      const emplopyee = filteredPRActiveEmployees.filter(x => x.b === employeeId);
      const b = emplopyee[0].e + '  ' + emplopyee[0].c;
      const msg = res.message.g.split('|');
      let d = '';
      if (msg.length === 1) {
        d = this.transloco.translate(res.message.g);
      } else {
        msg.forEach(x => d += this.transloco.translate(x.trim()) + ' ');
      }
      processSummary.messages.push({a, b, d});
      processSummary.output = this.getProcessOutputMessageSummary(a);
      this.processSummary$.next(processSummary);
    });
  }

  initializeListener(
    filteredPRActiveEmployees: IPayrollActiveEmp[],
    filteredPRActiveEmployeesExternal: IPayrollActiveEmp[],
    externalPara: number,
    processPeriod: string,
    action: 'process' | 'rollback'
  ) {
    this.payrollProcessProgress = {status: 'Queued', percentage: 0, message: null, processPeriod, action} as IPayrollProcessUpdate;
    this.updatePayrollProcessProgress();
    const processedList: IPayrollProcessStatusReturn[] = [];
    this.connection.listenFor('processCompleted').pipe(takeUntil(this.disconnectConnection$))
      .subscribe((res: IPayrollProcessUpdate) => {
        this.payrollProcessProgress = {...res, action, processPeriod};
        this.updatePayrollProcessProgress();
        if (res.message !== null) {
          processedList.push(res.message);
        }
        if (processedList !== null && res.percentage === 100) {
          this.subscribePayrollHandler(res, processedList, filteredPRActiveEmployees, filteredPRActiveEmployeesExternal, externalPara,
            processPeriod);
        }
        if (!this.router?.url?.includes('PayrollProcess')) {
          this.disconnect();
        }
      });
  }

  subscribePayrollHandler(
    res: IPayrollProcessUpdate,
    processedList: IPayrollProcessStatusReturn[],
    filteredPRActiveEmployees: IPayrollActiveEmp[],
    filteredPRActiveEmployeesExternal: IPayrollActiveEmp[],
    externalPara: number,
    processPeriod: string
  ): void {
    const processSummary: ProcessSummary = {messages: [], processPeriod, output: this.getProcessOutputMessageSummary(null)};
    const getEmployeeNameAndNumber = (id: number) => {
      if (externalPara !== 1) {
        const temp = filteredPRActiveEmployees.filter(x => x.b === id);
        return temp[0].e + '  ' + temp[0].c;
      } else {
        const temp = filteredPRActiveEmployeesExternal.filter(x => x.b === id);
        return temp[0].e + '  ' + temp[0].c;
      }
    };

    for (const item of processedList) {
      if (externalPara !== 1) {
        const ind = filteredPRActiveEmployees.findIndex(x => x.b === item.e);
        filteredPRActiveEmployees[ind].d = item.f;
      } else {
        const ind = filteredPRActiveEmployeesExternal.findIndex(x => x.b === item.e);
        filteredPRActiveEmployeesExternal[ind].d = item.f;
      }

      const a = item.f;
      const b = getEmployeeNameAndNumber(item.e);
      const msg = item.g.split('|');
      let d = '';
      if (msg.length === 1) {
        d = this.transloco.translate(item.g);
      } else {
        msg.forEach(x => d += this.transloco.translate(x.trim()) + ' ');
      }
      processSummary.messages.push({a, b, d});
      processSummary.output = this.getProcessOutputMessageSummary(a);
    }

    this.processSummary$.next(processSummary);

    const title = this.transloco.translate('success');
    const message = this.transloco.translate('alerts.processingComplete', {}, 'payrollProcess');
    showMsg({
      type: 'nonsticky',
      status: 'success',
      title,
      buttons: {},
      message,
      delay: '1000'
    });
  }

  private getProcessOutputMessageSummary(a) {
    const output: ProcessOutputMessageSummary = {message: 0, error: 0, success: 0, warning: 0};
    if (a === null) {
      return output;
    }
    if (a === 1) {
      output.success += 1;
    } else if (a === 2) {
      output.warning += 1;
    } else if (a === 3) {
      output.warning += 1;
    } else {
      output.message += 1;
    }
    return output;
  }

  disconnect() {
    if (this.payrollProcessProgress == null || this.payrollProcessProgress?.percentage === 100) {
      setTimeout(() => {
        this.disconnectConnection$.next();
        this.connection.stop();
        console.log('closing hub connection');
      }, 500);
    }
  }

  clear() {
    this.payrollProcessProgress = null;
    this.updatePayrollProcessProgress();
  }

  destroy() {
    this.payrollProcessProgress = null;
    this.updatePayrollProcessProgress();
    if (this.connection) {
      this.connection.stop();
    }
    this.disconnectConnection$.next();
  }
}
