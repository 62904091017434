import {Component, OnInit} from '@angular/core';
import {SalaryProcessMessageService} from '@services/salary-process-message.service';
import {IPayrollProcessUpdate} from '@shared/models/ipayroll-group';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-process-in-progress-pop',
  templateUrl: './process-in-progress-pop.component.html',
  styleUrls: ['./process-in-progress-pop.component.scss']
})
export class ProcessInProgressPopComponent implements OnInit {

  payrollProgress: IPayrollProcessUpdate = null;

  constructor(
    private salaryProcessMessage: SalaryProcessMessageService
  ) {
  }

  ngOnInit(): void {
    this.salaryProcessMessage.payrollProcessProgress$.pipe(untilDestroyed(this)).subscribe(res => this.payrollProgress = res);
  }

}
