import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {AuthenticationService} from '@shared/services';

@Component({
  selector: 'app-two-fa-verify',
  templateUrl: './two-fa-verify.component.html',
  styleUrls: ['./two-fa-verify.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'twoFaVerify', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`)) }
    }]
})
export class TwoFaVerifyComponent implements OnInit {
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputClass: 'form-control d-inline',
    inputStyles: {
      'font-size': '1.2rem',
      width: '50px',
      height: '50px'
    }
  };
  @Input() loading = false;
  @Input() hideLogout = false;
  @Input() hideTitle = false;
  otp = '';
  rememberBrowser = false;
  @Output() verify: EventEmitter<{ code: string, rememberBrowser: boolean }> = new EventEmitter<{ code: string, rememberBrowser: boolean }>();
  @Output() logout = new EventEmitter<void>();

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
  }

  onLogout() {
    this.authenticationService.logout();
    this.logout.emit();
  }

  verifyOtp() {
    this.verify.emit({code: this.otp, rememberBrowser: this.rememberBrowser});
  }

  onOtpChange($event: string) {
    this.otp = $event;
  }
}
