<div class="input-group">
  <input
    type="text" class="form-control border-end-0"
    [(ngModel)]="searchValue"
    (keyup)="onKeyUp($event)"
    [placeholder]="placeholder"
    (ngModelChange)="onModelChange($event)"
    #search
  >
  <label class="input-group-text bg-white border-start-0"><i [ngClass]="iconFontClass"></i></label>
</div>
