import {Component, OnInit} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthenticationService} from '@shared/services';
import {Router} from '@angular/router';
import {GlobalService} from '@services/global.service';
import {AddCompanyRequestModel} from '@shared/models/companyInfo';
import {NgForm} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

declare var showMsg: any;

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: {scope: 'createCompany', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}}
  ]
})
export class CreateCompanyComponent implements OnInit {

  protected loading = false;

  constructor(
    protected bsModalRef: BsModalRef,
    private authService: AuthenticationService,
    private transloco: TranslocoService,
    private router: Router,
    private globalService: GlobalService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
  }

  onSubmit(newCompanyForm: NgForm) {
    const value = newCompanyForm.value as AddCompanyRequestModel;
    value.userId = this.authService.currentUserValue.id;
    this.loading = true;

    this.authService.createCompany(value).subscribe({
      next: () => {
        this.loading = false;
        this.bsModalRef.hide();
        const message = this.transloco.translate('alerts.companyCreated', {}, 'createCompany');
        const title = this.transloco.translate('success');
        const buttonOk = this.transloco.translate('ok');
        showMsg({
          type: 'sticky',
          status: 'success',
          title,
          message,
          buttons: {
            [buttonOk]: {
              action() {
                return;
              }
            }
          }
        });
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        const message = this.transloco.translate('errorOccurred');
        const title = this.transloco.translate('error');
        showMsg({
          'type': 'nonsticky',
          'status': 'error',
          title,
          'buttons': {},
          message: err.error?.Message,
          'delay': '5000'
        });
      },
      complete: () => {
        this.loading = false;
        this.bsModalRef.hide();
      }
    });
  }
}
