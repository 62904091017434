<div class="card border shadow-lg mb-0">
  <div class="card-header">
    <div class="row">
      <div class="col-9">
        <h6>{{title}}</h6>
      </div>
      <div class="col-3 d-flex justify-content-end align-items-start">
        <button class="btn-close" (click)="onClose($event)"></button>
      </div>
    </div>
  </div>
  <div class="card-body overflow-y-auto" style="height: 105px">
    <ng-content></ng-content>
  </div>
</div>
