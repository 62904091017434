import {Directive, ElementRef, Input, Renderer2, ViewContainerRef} from '@angular/core';
import {AccessPermissionTypes, PermissionsList} from '../access-permission.model';
import {AccessControlService} from '../services/access-control.service';

@Directive({
  selector: '[appAccessControlDisable]'
})
export class DisableElementAccessControlDirective {

  constructor(
    private accessControlService: AccessControlService,
    private vcf: ViewContainerRef,
    private renderer: Renderer2,
    private eleRef: ElementRef
  ) {
  }

  @Input() set appAccessControlDisable(component: string) {
    const permission = this.getPermissions(component, 'modify');
    const element = this.eleRef.nativeElement;
    if (!permission) {
      this.renderer.setProperty(element, 'disabled', true);
    }
  }

  private getPermissions(componentOrAction: string, accessType: AccessPermissionTypes): boolean | PermissionsList {
    const activeModule = this.accessControlService.activeModule;
    return this.accessControlService.getAccessPermissions(activeModule, componentOrAction, accessType);
  }
}
