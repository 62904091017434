import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WizardService} from '@services/wizard.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, take} from 'rxjs/operators';


@UntilDestroy()
@Component({
  selector: 'app-payroll-wizard',
  templateUrl: './payroll-wizard.component.html',
  styleUrls: ['./payroll-wizard.component.scss']
})
export class PayrollWizardComponent implements OnInit, OnDestroy {
  private subs: Subscription;

  wizardMode = false;

  constructor(
    public wizardService: WizardService,
    public router: Router
  ) {
  }

  ngOnInit() {
    console.log('wizard activated');
    sessionStorage.setItem('wizard_mode', 'true');
    this.wizardService.wizardMode$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(val => this.wizardMode = true);
    this.wizardService.updateWizardMode(true);
    // this.wizardService.updateFlag(2, 'pay wizard').pipe(untilDestroyed(this)).subscribe(data => data && this.wizardService.updateWizard(data));
    this.subs = this.wizardService.currentGroupInfo$.pipe(take(1), untilDestroyed(this)).subscribe((group) => {
      !group && !this.router.url.split('/').includes('payPeriod') && this.router.navigate(['/process/processwizard/wizard']);
    });
  }

  ngOnDestroy() {
    this.subs && this.subs.unsubscribe();
  }
}
