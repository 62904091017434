import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ShowMessageService} from '@services/show-message.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import { ILocString } from '@shared/models/iLocalization';
//import { MultiDataSet, Label } from 'ng2-charts';
//import { ChartType } from 'chart.js';
import {of, Subscription} from 'rxjs';
import {concatMap, tap} from 'rxjs/operators';
import {
  IChecksforLocking,
  IEmployeeListCheck,
  IEmployeeListCheck1,
  IPayPeriodLock,
  PaySchedule,
  PRLG_CODE
} from 'src/app/shared/models/ipayroll-group';
import {WizardService} from 'src/app/shared/services/wizard.service';
import {CompanyService} from '@harmony-modules/configuration/services/setup/company.service';

declare var showMsg: any;
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-visa-finalize',
  templateUrl: './visa-finalize.component.html',
  styleUrls: ['./visa-finalize.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'visaFinalize', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class VisaFinalizeComponent implements OnInit {


  prlgrp_code: number;
  prlg_code: PRLG_CODE;
  payschedule: PaySchedule[] = [];
  obCheckForLock: IChecksforLocking = {fromDate: null, toDate: null, prlgCode: null, year: null, proc_seq_no: null};
  payperiodLock: IPayPeriodLock = {a: null, b: null, c: null, d: null, e: null, f: null, g: null};
  enableLock: boolean = false;
  isLockClicked: boolean = false;
  empset1: IEmployeeListCheck1[] = null; //employees with processing errors
  empset2: IEmployeeListCheck[] = null;  //employees who do not want paystub emailed and whos cheques are not printed
  empset5: IEmployeeListCheck[] = null;  //employees who do not want paystub emailed and whos cheques are edited but not re-printed
  empset6: IEmployeeListCheck[] = null;  //employees who want paystub emailed but not emailed yet
  empset7: IEmployeeListCheck[] = null;  //employees who want paystub emailed and whos cheques are edited but not re-emailed
  //empset3: IEmployeeListCheck[] = null;
  empset4: IEmployeeListCheck1[] = null;  //employees with processing warning
  processWarning: boolean = false;
  chequeWarning: boolean = false;
  idx: number;
  processOutput: number = null;
  groupInfo: any;
  today: Date = new Date();
  payrunSummaryData: any;
  isUs: boolean = false;
  sysPref: any;
  empListWithError: any[];
  chartFills = ['#99CB98', '#b7997d', '#2173c5'];

  donutChartConfig: any = {};

  private getpayrollschedulebyidService: Subscription;
  private checkPayPeriodIsEmptyService: Subscription;
  private updatePayFrqDisabledFlagService: Subscription;
  private getProcessOutputForLockingPayPeriodService: Subscription;
  private groupInfo$: Subscription;
  /**
   * t(visaFinalize.employeeRemittance, visaFinalize.companyRemittance, visaFinalize.netEmployeePayments)
   */
  public doughnutChartLabels: any[] = ['employeeRemittance', 'companyRemittance', 'netEmployeePayments'];
  public doughnutChartData: any = []; //MultiDataSet
  employeeRemittance = '';
  companyRemittance = '';
  netEmployeePayments = '';
  currencySymbol = '$';
  constructor(
    private _payrollGroupService: PayrollGroupService,
    private _wizardService: WizardService,
    private router: Router,
    private _companyService: CompanyService,
    private _sysPrefService: SystemPreferenceService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService
  ) {
    this.donutChartConfig = {
      labels: [...this.doughnutChartLabels],
      datasets: [
        {
          data: [],
          backgroundColor: ['#99CB98', '#b7997d', '#2173c5'],
          hoverBackgroundColor: ['#8db98c', '#beaa99', '#6f95ba'],
          hoverBorderColor: '#fff'
        }],
      options: {
        maintainAspectRatio: false,
        legend: {
          position: 'top'
        }
      }
    };
  }

  ngOnInit() {
    this._sysPrefService.getinitSystemPreferences().pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
        this.isUs = this.sysPref.y == 82 ? true : false;
      }
    });

    this.transloco.selectTranslate(null, {}, this.scope).pipe(
      untilDestroyed(this),
      tap(() => {
        this.employeeRemittance = this.transloco.translate('employeeRemittance', {}, 'visaFinalize');
        this.companyRemittance = this.transloco.translate('companyRemittance', {}, 'visaFinalize');
        this.netEmployeePayments = this.transloco.translate('netEmployeePayments', {}, 'visaFinalize');
        this.donutChartConfig.labels = [this.employeeRemittance, this.companyRemittance, this.netEmployeePayments];
      })
    ).pipe(untilDestroyed(this)).subscribe(
      () => this._wizardService.wizardMode$
        .pipe(
          concatMap(mode => {
            return of(mode);
          }),
          concatMap(val => {
            if (val) {
              return this._wizardService.currentGroupInfo$;
            } else {
              return of(null);
            }
          }),
          concatMap(group => {
            if (group && group.gc) {
              //console.log(group);
              this._payrollGroupService.getPayrunReport(group.fd, group.td, group.gc, group.selectedPayPeriod.y, group.selectedPayPeriod.seq).pipe(untilDestroyed(this)).subscribe(
                //  this.doughnutChartLabels = this.doughnutChartLabels.map(label => this.transloco.translate(label));
                data => {
                  this.donutChartConfig.labels = [`${this.employeeRemittance}`, `${this.companyRemittance}`, `${this.netEmployeePayments}`];
                  this.payrunSummaryData = data ? data : null;
                  if (this.payrunSummaryData) {
                    this.doughnutChartData.push(this.payrunSummaryData.Federal_Employee + this.payrunSummaryData.Quebec_Employee);
                    // this.doughnutChartLabels[0] = this.doughnutChartLabels[0] + ' $' + parseFloat(this.doughnutChartData[0].toString()).toFixed(2)
                    this.doughnutChartData.push(this.payrunSummaryData.Federal_Company + this.payrunSummaryData.Quebec_Company);
                    // this.doughnutChartLabels[1] = this.doughnutChartLabels[1] + ' $' + parseFloat(this.doughnutChartData[1].toString()).toFixed(2)
                    this.doughnutChartData.push(this.payrunSummaryData.Net);
                    // this.doughnutChartLabels[2] = this.doughnutChartLabels[2] + ' $' + parseFloat(this.doughnutChartData[2].toString()).toFixed(2)

                    this.donutChartConfig.datasets[0].data = [
                      parseFloat(this.doughnutChartData[0].toString()).toFixed(2),
                      parseFloat(this.doughnutChartData[1].toString()).toFixed(2),
                      parseFloat(this.doughnutChartData[2].toString()).toFixed(2)
                    ];
                    // console.log(this.options);
                  }
                },
                err => {
                  // console.log(err);
                }
              );

              //this._payrollGroupService.getPayrunReport(group.fd, group.td, group.gc, group.selectedPayPeriod.y, group.selectedPayPeriod.seq).subscribe(
              //  data => {
              //    this.payrunSummaryData = data ? data : null;
              //    if (this.payrunSummaryData) {
              //      this.doughnutChartData.push(this.payrunSummaryData.Federal_Employee + this.payrunSummaryData.Quebec_Employee);
              //      // this.doughnutChartLabels[0] = this.doughnutChartLabels[0] + ' $' + parseFloat(this.doughnutChartData[0].toString()).toFixed(2)
              //      this.doughnutChartData.push(this.payrunSummaryData.Federal_Company + this.payrunSummaryData.Quebec_Company);
              //      // this.doughnutChartLabels[1] = this.doughnutChartLabels[1] + ' $' + parseFloat(this.doughnutChartData[1].toString()).toFixed(2)
              //      this.doughnutChartData.push(this.payrunSummaryData.Net);
              //      // this.doughnutChartLabels[2] = this.doughnutChartLabels[2] + ' $' + parseFloat(this.doughnutChartData[2].toString()).toFixed(2)

              //      this.donutChartConfig.datasets[0].data = [
              //        parseFloat(this.doughnutChartData[0].toString()).toFixed(2),
              //        parseFloat(this.doughnutChartData[1].toString()).toFixed(2),
              //        parseFloat(this.doughnutChartData[2].toString()).toFixed(2)
              //      ];
              //      // console.log(this.options);
              //    }
              //  },
              //  err => {
              //    // console.log(err);
              //  }
              //);
              this.groupInfo = group;
              return of(group.gc);
            } else {
              return of(null);
            }
          })
        ).pipe(untilDestroyed(this)).subscribe(gc => {

          if (gc) {
            this.prlg_code = {pc: gc};
            this.prlgrp_code = this.prlg_code.pc;

            this.getpayrollschedulebyidService = this._payrollGroupService.getpayrollschedulebyid(this.prlg_code).pipe(untilDestroyed(this)).subscribe(
              data => {
                this.payschedule = data;
                // console.log(this.payschedule);
              });
            this._companyService.GetCompanyDetails().pipe(untilDestroyed(this)).subscribe(cur => {
              if (cur[0].currencySymbol) this.currencySymbol = cur[0].currencySymbol;
            });
          }
        }));
  }

  postBtnClicked() {
    //console.log(this.payschedule);
    let obj = this.payschedule.find(c => {
      return c.PROC_SEQ_NO > 0 && c.z > 0;
    });
    //console.log(obj);
    this.checkToLockpayPeriod(obj?.i);
  }

  openPrintDialog() {
    window.print();
  }

  unlockpayPeriod(seq: number): void {
    let index = seq - 1;
    if (typeof this.payschedule[index + 1] !== 'undefined' && this.payschedule[index + 1].d === 1) {
      const title = this.transloco.translate('error');
      const message = this.transloco.translate('alerts.cannotUnlock', {}, 'visaFinalize');
      showMsg({
        'type': 'nonsticky',
        'status': 'error',
        'title': title,
        'buttons': {},
        'message': message,
        'delay': '1000'
      });
    } else {
      this.obCheckForLock = {
        fromDate: this.payschedule[index].s,
        toDate: this.payschedule[index].e,
        prlgCode: this.prlg_code.pc,
        year: this.payschedule[index].y,
        proc_seq_no: this.payschedule[index].PROC_SEQ_NO
      };
      this.checkPayPeriodIsEmptyService = this._payrollGroupService.checkPayPeriodIsEmpty(this.obCheckForLock).pipe(untilDestroyed(this)).subscribe(
        res => {
          if (res.count1 > 0) {
            const title = this.transloco.translate('error');
            const message = this.transloco.translate('alerts.cannotUnlockBecauseProcessed', {}, 'visaFinalize');
            showMsg({
              'type': 'nonsticky',
              'status': 'error',
              'title': title,
              'buttons': {},
              'message': message,
              'delay': '1500'
            });
          } else {
            //console.log('unlock');
            let sid = this.payschedule[index].i;
            let yr = this.payschedule[index].y;
            this.payperiodLock = {
              a: this.prlgrp_code,
              b: 0,
              c: sid,
              d: yr,
              e: this.payschedule[index].PROC_SEQ_NO,
              f: this.payschedule[index].s,
              g: this.payschedule[index].e
            };

            this.updatePayFrqDisabledFlagService = this._payrollGroupService.updatePayFrqDisabledFlag(this.payperiodLock).pipe(untilDestroyed(this)).subscribe(
              res => {
                const title = this.transloco.translate('success');
                const message = this.transloco.translate('alerts.periodUnlocked', {}, 'visaFinalize');
                showMsg({
                  'type': 'nonsticky',
                  'status': 'success',
                  'title': title,
                  'buttons': {},
                  'message': message,
                  'delay': '1000'
                });

                this.payschedule[index].d = 0;
              },

              err => {
                const title = this.transloco.translate('error');
                const message = err ? this.showMessage.translateHttpError(err) : this.transloco.translate('alerts.errorOccurred', {}, 'visaFinalize');
                showMsg({
                  'type': 'nonsticky',
                  'status': 'error',
                  'title': title,
                  'buttons': {},
                  'message': message,
                  'delay': '1000'
                });
              }
            );
          }
        }
      );
    }
  }


  checkToLockpayPeriod(seq: number): void {
    this.enableLock = false;
    this.isLockClicked = false;
    this.processWarning = false;
    this.chequeWarning = false;
    this.empset1 = null;
    this.empset2 = null;
    this.empset5 = null;
    this.empset6 = null;
    this.empset7 = null;
    this.empset4 = null;

    this.idx = seq - 1;
    let sid = this.payschedule[this.idx].i;
    let yr = this.payschedule[this.idx].y;
    this.payperiodLock = {
      a: this.prlgrp_code,
      b: 1,
      c: sid,
      d: yr,
      e: this.payschedule[this.idx].PROC_SEQ_NO,
      f: this.payschedule[this.idx].s,
      g: this.payschedule[this.idx].e
    };

    if (this.payschedule[this.idx].z == 0) //no records in PM_PR_PAY_PROCESS table
    {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.cannotLock', {}, 'visaFinalize');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    } else if (this.payschedule[this.idx].timesheet > 0) {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.cannotLockBecauseUnprocessedTimesheets', {}, 'visaFinalize');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    } else {

      this.obCheckForLock = {
        fromDate: this.payschedule[this.idx].s,
        toDate: this.payschedule[this.idx].e,
        prlgCode: this.prlg_code.pc,
        year: this.payschedule[this.idx].y,
        proc_seq_no: this.payschedule[this.idx].PROC_SEQ_NO
      };

      this.getProcessOutputForLockingPayPeriodService = this._payrollGroupService.payrollGroupLockMultipleServiceForWizard(this.obCheckForLock).pipe(untilDestroyed(this)).subscribe(
        res => {
          let resultSet1 = res[0];
          (resultSet1.employeeSet1 !== null && resultSet1.employeeSet1.length > 0) ? this.empset1 = resultSet1.employeeSet1.map(value => ({
            ...value,
            o: this.transloco.translate(value.o)
          })) : this.empset1 = null; // Translate: this.empset1[].o has translation key (no parameters)
          (resultSet1.employeeSet2 !== null && resultSet1.employeeSet2.length > 0) ? this.empset2 = resultSet1.employeeSet2 : this.empset2 = null;
          (resultSet1.employeeSet5 !== null && resultSet1.employeeSet5.length > 0) ? this.empset5 = resultSet1.employeeSet5 : this.empset5 = null;
          (resultSet1.employeeSet6 !== null && resultSet1.employeeSet6.length > 0) ? this.empset6 = resultSet1.employeeSet6 : this.empset6 = null;
          (resultSet1.employeeSet7 !== null && resultSet1.employeeSet7.length > 0) ? this.empset7 = resultSet1.employeeSet7 : this.empset7 = null;
          (resultSet1.employeeSet4 !== null && resultSet1.employeeSet4.length > 0) ? this.empset4 = resultSet1.employeeSet4.map(value => ({
            ...value,
            o: this.transloco.translate(value.o)
          })) : this.empset4 = null; // Translate: this.empset4[].o has translation key (no parameters)

          if (this.empset1 !== null && this.empset1.length > 0) {
            var t = this;
            const title = this.transloco.translate('error');
            const buttonDetail = this.transloco.translate('detail');
            const buttonCancel = this.transloco.translate('cancel');
            const message = this.transloco.translate('alerts.cannotLockBecauseProcessingErrors', {}, 'visaFinalize');
            showMsg({
              'type': 'sticky',
              'status': 'error',
              'title': title,
              'buttons': {
                [buttonDetail]: {
                  action: function() {
                    t.isLockClicked = true;
                    t.modalToggle();
                    return;
                  }
                },
                [buttonCancel]: {
                  action: function() {
                    return;
                  }
                }
              },
              'message': message
            });
          } else if (this.empset4 !== null && this.empset4.length > 0) {
            this.processWarning = true;
            if (this.empset4 !== null && this.empset4.length > 0) {
              var that = this;
              const title = this.transloco.translate('warning');
              const buttonOk = this.transloco.translate('ok');
              const buttonDetail = this.transloco.translate('detail');
              const buttonCancel = this.transloco.translate('cancel');
              const message = this.transloco.translate('alerts.continueDespiteProcessingWarnings', {}, 'visaFinalize');
              showMsg({
                'type': 'sticky',
                'status': 'warning',
                'title': title,
                'buttons': {
                  [buttonOk]: {
                    action: function() {
                      if ((that.empset5 !== null && that.empset5.length > 0) || (that.empset6 !== null && that.empset6.length > 0) || (that.empset7 !== null && that.empset7.length > 0)) {
                        setTimeout(that.checkForchequePrinting.bind(that), 1000);
                      } else {
                        that.enableLock = true;
                        setTimeout(that.lockPayPeriod.bind(that, that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock), 1000);
                      }
                      return;
                    }
                  },
                  [buttonDetail]: {
                    action: function() {
                      that.isLockClicked = true;
                      that.modalToggle();
                      return;
                    }
                  },
                  [buttonCancel]: {
                    action: function() {
                      that.enableLock = false;
                      return;
                    }
                  }
                },
                'message': message
              });
            }

          } else {
            var that = this;
            const title = this.transloco.translate('warning');
            const buttonOk = this.transloco.translate('yes');
            const buttonCancel = this.transloco.translate('no');
            const message = this.transloco.translate('alerts.confirmLocking', {}, 'visaFinalize');
            showMsg({
              'type': 'sticky',
              'status': 'warning',
              'title': title,
              'buttons': {
                [buttonOk]: {
                  action: function() {
                    that.enableLock = true;
                    that.lockPayPeriodFinal(that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock);
                    return;
                  }
                },

                [buttonCancel]: {
                  action: function() {
                    that.enableLock = false;
                    return;
                  }
                }
              },
              'message': message
            });
          }

        }
      );
    }

  }

  lockPayPeriod(ob: IPayPeriodLock, key: boolean, index: number, obj: IChecksforLocking): void {
    var that = this;
    const title = this.transloco.translate('warning');
    const buttonOk = this.transloco.translate('ok');
    const buttonCancel = this.transloco.translate('cancel');
    const message = this.transloco.translate('alerts.confirmLocking', {}, 'visaFinalize');
    showMsg({
      'type': 'sticky',
      'status': 'warning',
      'title': title,
      'buttons': {
        [buttonOk]: {
          action: function() {
            that.enableLock = true;
            that.lockPayPeriodFinal(that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock);
            return;
          }
        },

        [buttonCancel]: {
          action: function() {
            that.enableLock = false;
            return;
          }
        }
      },
      'message': message
    });
  }

  lockPayPeriodFinal(ob: IPayPeriodLock, key: boolean, index: number, obj: IChecksforLocking): void {
    if (key) {
      this._payrollGroupService.paycardWithoutPaystubExists(ob.e, ob.d, ob.a, this.fromJsonDate(ob.f), this.fromJsonDate(ob.g)).pipe(untilDestroyed(this)).subscribe(
        data => {
          if (data !== undefined && data.length > 0) {
            this.empListWithError = data;
            this.enableLock = false;
            // ErrorModal
            $('#ErrorModal').modal('show');

          } else {
            this.updatePayFrqDisabledFlagService = this._payrollGroupService.updatePayFrqDisabledFlagForWizard(ob).pipe(untilDestroyed(this)).subscribe(
              res => {this.updatePayFrqDisabledFlagService = this._payrollGroupService.cleanProcOutPutTablefterLockingForWizard(obj).pipe(untilDestroyed(this)).subscribe(
                res2 => {
                  this._wizardService.completeInvokedFromComp(true);
                  let obj =
                    this._payrollGroupService.clearGroupDataAfterPost({gc: this.prlgrp_code, fd: ob.f, td: ob.g}).pipe(untilDestroyed(this)).subscribe(
                      data => {
                        $('#redirectModal').modal('show');

                      }
                    );
                },
                err=>  {
                  const title = this.transloco.translate('error');
                  const message = err && err.error && err.error.Message ? this.transloco.translate(err.error.Message) : this.transloco.translate('alerts.errorOccurred', {}, 'visaFinalize');
                  showMsg({
                    'type': 'nonsticky',
                    'status': 'error',
                    'title': title,
                    'buttons': {},
                    'message': message,
                    'delay': '1500'
                  });
                })},
              err=>  {
                const title = this.transloco.translate('error');
                const message = err && err.error && err.error.Message ? this.transloco.translate(err.error.Message) : this.transloco.translate('alerts.errorOccurred', {}, 'visaFinalize');
                showMsg({
                  'type': 'nonsticky',
                  'status': 'error',
                  'title': title,
                  'buttons': {},
                  'message': message,
                  'delay': '1500'
                });
              }
            );
          }
        },
        err => {
          const title = this.transloco.translate('error');
          const message = err && err.error && err.error.Message ? this.transloco.translate(err.error.Message) : this.transloco.translate('alerts.errorOccurred', {}, 'visaFinalize');
          showMsg({
            'type': 'nonsticky',
            'status': 'error',
            'title': title,
            'buttons': {},
            'message': message,
            'delay': '1000'
          });
        }
      );
    }
  }

  onRedirect(code: any) {
    switch (code) {
      case 1: // Create GL Records
        this._wizardService.updateWizardMode(false);
        // sessionStorage.setItem('wizard_mode', 'false');
        this.router.navigate(['process/processgl/CreateGlRecords']);
        break;
      case 2: // Generate Reports
        this._wizardService.updateWizardMode(false);
        // sessionStorage.setItem('wizard_mode', 'false');
        this.router.navigate(['report/ReportNavigator/Payroll']);
        break;
      case 3: // Process Wizard
        this.router.navigate(['process/processwizard/Wizard']);
        break;
      case 4: // Return to Dashboard
        this._wizardService.updateWizardMode(false);
        // sessionStorage.setItem('wizard_mode', 'false');
        this.router.navigate(['dashboard']);
        break;
    }

  }

  checkForchequePrinting(): void {
    this.processWarning = false;
    this.chequeWarning = true;
    if (this.empset2 !== null && this.empset2.length > 0) {
      var that = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonDetail = this.transloco.translate('detail');
      const buttonCancel = this.transloco.translate('cancel');
      const message = this.transloco.translate('alerts.continueDespiteNotPrintedCheques', {}, 'visaFinalize');
      showMsg({
        'type': 'sticky',
        'status': 'warning',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              that.enableLock = true;
              setTimeout(that.lockPayPeriod.bind(that, that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock), 1000);
              return;
            }
          },
          [buttonDetail]: {
            action: function() {
              that.isLockClicked = true;
              that.modalToggle();
              return;
            }
          },
          [buttonCancel]: {
            action: function() {
              this.enableLock = false;
              return;
            }
          }
        },
        'message': message
      });
    } else if (this.empset5 !== null && this.empset5.length > 0) {

      var that = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonDetail = this.transloco.translate('detail');
      const buttonCancel = this.transloco.translate('cancel');
      const message = this.transloco.translate('alerts.continueDespiteNotRePrintedCheques', {}, 'visaFinalize');
      showMsg({
        'type': 'sticky',
        'status': 'warning',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              that.enableLock = true;
              setTimeout(that.lockPayPeriod.bind(that, that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock), 1000);
              return;
            }
          },
          [buttonDetail]: {
            action: function() {
              that.isLockClicked = true;
              that.modalToggle();
              return;
            }
          },
          [buttonCancel]: {
            action: function() {
              this.enableLock = false;
              //that.lockPayPeriod(that.payperiodLock, that.enableLock);
              return;
            }
          }
        },
        'message': message
      });
    } else if (this.empset6 !== null && this.empset6.length > 0) {
      var that = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonDetail = this.transloco.translate('detail');
      const buttonCancel = this.transloco.translate('cancel');
      const message = this.transloco.translate('alerts.continueDespiteNotEmailedCheques', {}, 'visaFinalize');
      showMsg({
        'type': 'sticky',
        'status': 'warning',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              that.enableLock = true;
              setTimeout(that.lockPayPeriod.bind(that, that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock), 1000);
              return;
            }
          },
          [buttonDetail]: {
            action: function() {
              that.isLockClicked = true;
              that.modalToggle();
              return;
            }
          },
          [buttonCancel]: {
            action: function() {
              this.enableLock = false;
              //that.lockPayPeriod(that.payperiodLock, that.enableLock);
              return;
            }
          }
        },
        'message': message
      });
    } else if (this.empset7 !== null && this.empset7.length > 0) {
      var that = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonDetail = this.transloco.translate('detail');
      const buttonCancel = this.transloco.translate('cancel');
      const message = this.transloco.translate('alerts.continueDespiteNotReEmailedCheques', {}, 'visaFinalize');
      showMsg({
        'type': 'sticky',
        'status': 'warning',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              that.enableLock = true;
              setTimeout(that.lockPayPeriod.bind(that, that.payperiodLock, that.enableLock, that.idx, that.obCheckForLock), 1000);
              return;
            }
          },
          [buttonDetail]: {
            action: function() {
              that.isLockClicked = true;
              that.modalToggle();
              return;
            }
          },
          [buttonCancel]: {
            action: function() {
              this.enableLock = false;
              //that.lockPayPeriod(that.payperiodLock, that.enableLock);
              return;
            }
          }
        },
        'message': message
      });
    }
  }

  modalToggle(): void {
    $('#lockmodal').modal({
      'toggle': 'true'
    });
    $('#lockmodal').modal('show');
  }

  onClickWarningOK(): void {
    if ((this.empset2 !== null && this.empset2.length > 0) && !this.chequeWarning) {

      this.checkForchequePrinting.call(this);
    } else {
      this.enableLock = true;
      this.lockPayPeriod(this.payperiodLock, this.enableLock, this.idx, this.obCheckForLock);
    }
  }

  /*..format dates..*/
  fromJsonDate(jDate): string {
    if (jDate) {
      const bDate: Date = new Date(jDate.substring(0, 10));
      return bDate.toISOString().substring(0, 10);  //Ignore time
    }
    return null;
  }


  ngOnDestroy() {
    if (this.getpayrollschedulebyidService) {
      this.getpayrollschedulebyidService.unsubscribe();
    }
    if (this.checkPayPeriodIsEmptyService) {
      this.checkPayPeriodIsEmptyService.unsubscribe();
    }
    if (this.updatePayFrqDisabledFlagService) {
      this.updatePayFrqDisabledFlagService.unsubscribe();
    }
    if (this.getProcessOutputForLockingPayPeriodService) {
      this.getProcessOutputForLockingPayPeriodService.unsubscribe();
    }
    //  this.groupInfo$.unsubscribe();
  }
}
