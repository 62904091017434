import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {
  ICoreSysPreference,
  ICoreSysPrefPicklist,
  ICountry,
  IHolidayCalender,
  IProvince,
  IUpdateCoreSysPref
} from 'src/app/shared/models/icore-sys-preference';
import {ISystemPreferenceInit} from 'src/app/shared/models/isystem-preference';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';

@Injectable({
  providedIn: 'root'
})
export class CoreSysPreferenceService {

  constructor(private http: HttpClient) { }

  getinitSystemPreferences(): Observable<ISystemPreferenceInit[]> {
    //let para = new HttpParams().set("id", String(id));
    //, { params: para }
    return this.http.get<ISystemPreferenceInit[]>(API_END_POINT.payroll.systemPreference + "GetInitialUserPreferences");
  }

  GetPickListValuesForCoreSystemPref(code: string): Observable<ICoreSysPrefPicklist[]> {
    return this.http.get<any>(API_END_POINT.payroll.systemPreference + "GetPickListValuesForCoreSystemPref?code=" + code);
  }

  getcountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(API_END_POINT.payroll.systemPreference + "Getcountries");
  }

  getProvinces(): Observable<IProvince[]> {
    return this.http.get<IProvince[]>(API_END_POINT.payroll.systemPreference + "GetProvinces");
  }

  getAllPickListData(timecode: string, clckcode: string, datecode: string, weekcode: string, namecode: string): Observable<any> {
    let response1 = this.GetPickListValuesForCoreSystemPref(timecode);
    let response2 = this.GetPickListValuesForCoreSystemPref(clckcode);
    let response3 = this.GetPickListValuesForCoreSystemPref(datecode);
    let response4 = this.GetPickListValuesForCoreSystemPref(weekcode);
    let response5 = this.GetPickListValuesForCoreSystemPref(namecode);
    let response6 = this.getcountries();
    let response7 = this.getProvinces();
    let response8 = this.GetHolidayCalenderList();

    return forkJoin([response1, response2, response3, response4, response5, response6, response7, response8]);
  }

  getCoreSystemPreferences(): Observable<ICoreSysPreference> {
    return this.http.get<ICoreSysPreference>(API_END_POINT.payroll.systemPreference + "GetCoreSystemPreferences");
  }

  updateCoreSystemPrefence(ob: IUpdateCoreSysPref): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.systemPreference + "UpdateCoreSystemPrefence", ob);
  }

  GetHolidayCalenderList(): Observable<IHolidayCalender[]> {
    return this.http.get<IHolidayCalender[]>(API_END_POINT.payroll.systemPreference + "GetHolidayCalenderList");
  }


}

