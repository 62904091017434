<ng-container *transloco="let translate; read: 'dataExportList'">
  <div class="row"><h4 class="col">{{translate('bankInterfaces')}}</h4></div>
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-center mb-3">
        <div class="col-sm-auto mb-2">
          <div class="btn-group" dropdown container="body">
            <button dropdownToggle
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    aria-controls="dropdown-animated">
              <i class="fas fa-plus"></i>&nbsp;<span transloco="new"></span><span class="caret"></span>
            </button>
            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
              <li role="menuitem" *ngFor="let listItem of dropdownListValues" [attr.disabled]="listItem.disabled" (click)="onAddList(listItem.name)">
                <span class="dropdown-item">{{listItem.name}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-7 mb-2">
          <app-search-box [(ngModel)]="searchText" (keyup)="searchList(searchText)"></app-search-box>
        </div>
        <div class="col-sm-auto mb-2">
          <div class="btn-group" dropdown placement="bottom right" container="body">
            <button dropdownToggle
                    type="button"
                    class="btn btn-secondary dropdown-toggle"
                    aria-controls="dropdown-animated">
              <i class="fas fa-filter"></i>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
              <li role="menuitem" *ngFor="let listItem of dropdownListValues" (click)="onSelectList(listItem.name)">
                <span class="dropdown-item">{{listItem.name}}</span>
              </li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem" (click)="onSelectList('')"><span class="dropdown-item"><span transloco="clearFilter"></span></span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="list-group">
            <div class="list-group-item list-group-item-action"
                 *ngFor="let a of dataExportListfiltered | layoutFilter: 't' : selectedDropdownValue ; let in=index">
              <div class="row" *ngIf="a !== null">
                <div class="col-auto d-flex align-items-center">
                  <div class="list-icon">
                    <i class="fas" [ngClass]="{'fa-bank': a.t === 2, 'fa-file-alt': a.t === 1}"></i>
                  </div>
                </div>
                <div class="col-6 d-flex align-items-center">
                  <span>{{a.d}}</span>
                </div>
                <div class="col-2 d-flex align-items-center">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="id{{a.c}}" [checked]="a.a" (click)="switchChange(a.c, $event, a.t)" />
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-end">
                  <button type="button" class="btn btn-outline-danger btn-sm me-2" (click)="delete(a.c,a.t)"><i class="fas fa-trash"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" (click)="routeToEdit(a.c,a.t)"><i class="fas fa-ellipsis-v"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
