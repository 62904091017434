import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {AccessPermissionTypes, ModuleAccessPermissions, PermissionsList, SubMenu} from '../access-permission.model';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  private permissions: ModuleAccessPermissions[];
  private activeComponentPermission: {
    activeAppModule: ModuleAccessPermissions,
    componentCode: string,
    activeSubMenu: SubMenu
  } = {
    activeAppModule: null,
    componentCode: null,
    activeSubMenu: null
  };

  constructor(
    private router: Router
  ) {
  }

  resetPermissions(): void {
    this.permissions = null;
    this.activeComponentPermission = {activeSubMenu: null, componentCode: null, activeAppModule: null};
  }

  readAccessPermissions(): void {
    this.permissions = JSON.parse(localStorage.getItem('permissions')) as ModuleAccessPermissions[];
  }

  get activeModule(): string {
    // return this.activeComponentPermission.activeAppModule ? this.activeComponentPermission.activeAppModule.module : null;
    return 'payroll';
  }

  getAccessPermissions(moduleCode: 'payroll' | 'core' | string, componentCode: string, permission?: AccessPermissionTypes): boolean | PermissionsList {
    this.readAccessPermissions();
    componentCode = componentCode?.toString()?.toLowerCase() || null;
    moduleCode = moduleCode?.toString()?.toLowerCase() || null;
    moduleCode = moduleCode && (moduleCode === 'employees' || moduleCode === 'settings') ? 'core' : moduleCode;

    if (!componentCode) {
      return true;
    }

    if (this.activeComponentPermission) {
      this.activeComponentPermission.activeSubMenu = null;
    }

    if (this.permissions) {
      if (!this.activeComponentPermission.activeAppModule) {
        this.activeComponentPermission.activeAppModule = this.permissions.find(mod => mod.module === moduleCode);
      }
      if (this.activeComponentPermission.activeAppModule && this.activeComponentPermission.activeAppModule.module !== moduleCode) {
        this.activeComponentPermission.activeAppModule = this.permissions.find(mod => mod.module === moduleCode);
      }
    } else {
      return permission ? false : {add: false, modify: false, delete: false, view: false} as PermissionsList;
    }

    if (!this.activeComponentPermission.componentCode) {
      this.activeComponentPermission.componentCode = componentCode;
    }

    if (this.activeComponentPermission.componentCode
      && this.activeComponentPermission.componentCode?.toLowerCase() !== componentCode) {
      this.activeComponentPermission.componentCode = componentCode;
    }

    if (this.activeComponentPermission.activeSubMenu
      && this.activeComponentPermission.activeSubMenu.name?.toLowerCase() === componentCode) {
      if (permission) {
        return this.activeComponentPermission.activeSubMenu.permission[permission];
      } else {
        return this.activeComponentPermission.activeSubMenu.permission;
      }
    }

    if (this.activeComponentPermission.activeAppModule) {
      for (const menu of this.activeComponentPermission.activeAppModule.menus) {
        for (const subMenu of menu.subMenus) {
          if (subMenu.name.toLowerCase() === this.activeComponentPermission.componentCode) {
            this.activeComponentPermission.activeSubMenu = subMenu;
            break;
          }
        }
        if (this.activeComponentPermission.activeSubMenu
          && this.activeComponentPermission.activeSubMenu.name.toLowerCase() === this.activeComponentPermission.componentCode) {
          break;
        }
      }
      if (this.activeComponentPermission.activeSubMenu) {
        if (permission) {
          return this.activeComponentPermission.activeSubMenu.permission[permission];
        }
        return this.activeComponentPermission.activeSubMenu.permission;
      } else {
        return permission ? true : {add: true, modify: true, delete: true, view: true} as PermissionsList;
      }
    } else {
      return permission ? true : {add: true, modify: true, delete: true, view: true} as PermissionsList;
    }
  }

  routeToAccessDenied(): UrlTree {
    const url = '/access/denied';
    return this.router.createUrlTree([url]);
  }
}
