<ng-container *transloco="let translate; read: 'reportChild'">
  <div class="k-overlay" *ngIf="opened"></div>
  <form [formGroup]="reportForm" (ngSubmit)="onSubmit(reportForm)">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button type="submit" class="btn btn-primary me-2 mb-2"
          [disabled]="!reportForm.valid || !report_sent.REPORT_ID"><i class="fas fa-file-invoice-dollar"></i>&nbsp;<span
            transloco="open"></span></button>
        <button type="button" class="btn btn-primary mb-2" [hidden]="wizard_mode || !report_sent.REPORT_ID"
          (click)="onClickFormReset()"><i class="fas fa-sync"></i>&nbsp;<span transloco="refresh"></span></button>
      </div>
    </div>
    <!--------------------->
    <div class="row" [ngStyle]="{'display' : filter ? 'flex' : 'none'}">
      <div class="col-12">
        <ng-container *ngIf="report_sent.PAR_TO_DATE == 1">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="filterBy"></span></div>
            <div class="card-body px-2 py-1">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="form-check form-check-inline" *ngFor="let date of filterByDates; let i = index">
                    <input class="form-check-input" type="radio" id="filter_{{i}}" [value]="date"
                      formControlName="filterByDt" (change)="onFilterByDtChange($event)">
                    <label class="form-check-label" for="filter_{{i}}">{{translate(date)}}</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label">{{translate('fromDate')}}</label>
                  <input class="form-control" type="date"
                    [attr.data-date]="reportForm.get('frmDate').value | date: (sysPref?.df ? sysPref.df : 'yyyy-MM-dd') "
                    formControlName="frmDate" name="frmDate" id="frmDate" (change)="onFromDateChange()" />
                  <div *ngIf="reportForm.controls.frmDate.errors?.maxDate" class="invalid-feedback">
                    {{translate('invalidDate')}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label">{{translate('toDate')}}</label>
                  <input class="form-control" type="date"
                    [attr.data-date]="reportForm.get('toDate').value | date: (sysPref?.df ? sysPref.df : 'yyyy-MM-dd') "
                    formControlName="toDate" name="toDate" id="toDate" (change)="onToDateChange()" />
                  <div *ngIf="reportForm.controls.toDate.errors?.minDate" class="invalid-feedback">
                    {{translate('invalidDate')}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="report_sent.REPORT_ID == 23">
        <div class="col-sm-12">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('selectEmployeeInfo')}}</div>
            <div class="card-body px-2 py-1">
              <div class="row">
                <div class="col-12">
                  <kendo-multiselect formControlName="emplyeeItems" [data]="empItemList" [textField]="'name'"
                    [valueField]="'id'" [placeholder]="'Select items...'"
                    (valueChange)="onEmpItemDropDownChange($event)"></kendo-multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23  || report_sent.REPORT_ID == 26">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="gender"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="gender" name="PAR_GENDER" id="gender"
                (change)="onGenderChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of genderList" [ngValue]="item">{{translate(item.name)}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23 || report_sent.REPORT_ID == 26 || report_sent.REPORT_ID == 27">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="status"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="status" name="PAR_Status" (change)="onStatusChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of statusList" [ngValue]="item">{{translate(item.name)}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23  || report_sent.REPORT_ID == 26">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="jobTitle"></span></div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="jobTitle" name="PAR_JobTitle" id="jobTitle"
                (change)="onJobTitleChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="report_sent.PAR_DEPARTMENT_ID == 1 || report_sent.REPORT_ID == 23  || report_sent.REPORT_ID == 26 || report_sent.REPORT_ID == 27">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="department"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="department" name="PAR_DEPARTMENT_ID" id="department"
                (change)="onDepartmentChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listDepartments" [ngValue]="item">{{item.Name}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="report_sent.PAR_LOCATION_ID == 1 || report_sent.REPORT_ID == 23  || report_sent.REPORT_ID == 26 || report_sent.REPORT_ID == 27">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="location"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="location" name="PAR_LOCATION_ID" id="location"
                (change)="onLocationChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listLocations" [ngValue]="item">{{item.Name}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="position"></span></div>
            <div class="card-body px-2 py-2 mb-1">
              <div class="form-check">
                <input type="checkbox" formControlName="isManager" name="PAR_IsManager" id="PAR_IsManager"
                  class="form-check-input" (change)="onIsManagerChange($event)" />
                <label class="form-check-label" for="PAR_IsManager">{{translate('isManager')}}</label>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="supervisor"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="supervisor" name="PAR_Supervisor" id="supervisor"
                (change)="onSupervisorChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listSupervisor" [ngValue]="item">{{item.FirstName + " " + item.LastName}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('alternativeManager')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="altManager" name="PAR_AltManager" id="altManager"
                (change)="onAltManagerChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listAltManager" [ngValue]="item">{{item.FirstName + " " + item.LastName}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 23  || report_sent.REPORT_ID == 26 || report_sent.REPORT_ID == 27">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('cityTown')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="city" name="PAR_City" id="city"
                (change)="onCityChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <!--<ng-container *ngIf="report_sent.REPORT_ID == 25">
    <div class="col-sm-6">
      <div class="card border mb-2">
        <div class="card-header px-2 py-1">Employee</div>
        <div class="card-body px-2 py-1">
          <select class="form-select" formControlName="employee" name="PAR_EMPLOYEE_ID" id="employee" (change)="onEmployeeChange()">
            <option [ngValue]="null">All</option>
            <option *ngFor="let item of listEmployees" [ngValue]="item">{{item.FirstName}} {{item.LastName}}</option>
          </select>
        </div>
      </div>
    </div>
  </ng-container>-->
      <ng-container *ngIf="report_sent.PAR_EMPLOYEE_ID == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="employee"></span></div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="multiEmployee" [data]="multiEmployees" [textField]="'Name'"
                [valueField]="'ID'" [placeholder]="translate('selectEmployees')"
                (valueChange)="onMultiEmpDropDownChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_EMPLOYEE_STATUS == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('employeeStatus')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="status" name="PAR_Status" (change)="onStatusChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of statusList" [ngValue]="item">{{translate(item.name)}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 36">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('employeeType')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="empType" name="PAR_EmpType" (change)="onEmpTypeChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of empTypeList" [ngValue]="item">{{item.Name}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_GROUP == 1 && report_sent.REPORT_ID != 22">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollGroup')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="payrollgrp" name="PAR_PAYROLL_GROUP"
                (change)="updateReportFilter('payrollgrp')">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listpayrollgroup" [ngValue]="item">{{item.PRLG_USER_CODE}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 22">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollGroup')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="payrollgrpStat" name="PAR_PAYROLL_GROUPStat"
                (change)="updateReportFilter('payrollgrpStat')">
                <option [ngValue]="null" [disabled]="true">{{translate('selectPayrollGroup')}}</option>
                <option *ngFor="let item of listpayrollgroup" [ngValue]="item">{{item.PRLG_USER_CODE}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_EI_GROUP == 1 && report_sent.COUNTRY != 82">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('eiGroup')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="eigroup" name="PAR_EI_GROUP" id="eigroup"
                (change)="onEIGroupChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listEIgroup" [ngValue]="item">{{item.UDF_COLL_TEXT}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_RUNS == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollRuns')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="payrollrun" name="PAR_PAYROLL_RUNS"
                id="payrollrun" (change)="onPayrollrunsChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_CALCULATION_METHOD == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('calculationMethod')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="calmethod" name="PAR_CALCULATION_METHOD"
                id="calmethod" (change)="onCalMethodChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PENSION_GROUP == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('pensionGroup')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="pensiongrp" name="PAR_PENSION_GROUP"
                id="pensiongrp" (change)="onPensionGroupChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_KIND == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollKind')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="payrollcat" name="PAR_PAYROLL_KIND" id="payrollcat"
                (change)="onPayrollCategoryChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of listpayrollCategories" [ngValue]="item">{{item.CAT_TYP_NAME}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_TYPE == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollType')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="payrolltype" name="PAR_PAYROLL_TYPE" id="payrolltype"
                (change)="onPayrollTypeChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of basicList" [ngValue]="item">{{translate(item.name)}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_script_text" name="PAR_SCRIPT_TEXT"
                    id="PAR_SCRIPT_TEXT" class="form-check-input"
                    (change)="updateReportFilter('include_script_text')" />
                  <label class="form-check-label" for="PAR_SCRIPT_TEXT">{{translate('includeScriptText')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_CLASS_TYPE == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollClassType')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="payrollclass" name="PAR_PAYROLL_CLASS_TYPE"
                id="payrollclass" (change)="onPayrollClassChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYMENT_METHOD == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('paymentMethod')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="paymentmethod" name="PAR_PAYMENT_METHOD"
                id="paymentmethod" (change)="onPaymentMethodChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_TAXATION_YEAR == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('taxationYear')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="taxyear" name="PAR_TAXATION_YEAR" id="taxyear"
                (change)="onTaxYearChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_RCT_NUMBER == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('rctNumber')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="rctno" name="PAR_RCT_NUMBER" id="rctno"
                (change)="onRCTChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_BUSINESS_NUMBER == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('businessNumber')}}</div>
            <div class="card-body px-2 py-1">
              <input class="form-control" type="text" formControlName="businessno" name="PAR_BUSINESS_NUMBER"
                id="businessno" (change)="onBusinessNumberChange()" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_CATEGORY == 1 || report_sent.PAR_PAYROLL_CATEGORY == 5">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollCategory')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="payrollcatdetail" [data]="paycatlist" [textField]="'b'"
                [valueField]="'a'" [placeholder]="translate('selectCategories')"
                (valueChange)="onPayCatDropDownChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 38">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('categoryType')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="payrollcatType" [data]="payrollCatTypeList" [textField]="'o'" [valueField]="'c'"
                                 [placeholder]="translate('selectCategoryType')" (valueChange)="onPayrollcatTypeChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_CATEGORY == 2 && report_sent.REPORT_ID != 24 && report_sent.REPORT_ID != 40">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollCategory')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="payrollcatdetail" [data]="paycatlistFiltered" [textField]="'b'"
                [valueField]="'a'" [placeholder]="translate('selectCategories')"
                (valueChange)="onPayCatDropDownChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PAYROLL_CATEGORY == 3">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollCategory')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="payrollcatdetail" [data]="paycatlistFilteredForWorkerCompensation"
                [textField]="'b'" [valueField]="'a'" [placeholder]="translate('selectCategories')"
                (valueChange)="onPayCatDropDownChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_HOLIDAY_DATE == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('holidayDate')}}</div>
            <div class="card-body px-2 py-1">
              <input type="date" class="form-control"
                [attr.data-date]="reportForm.get('holidayDate').value | date: (sysPref?.df ? sysPref.df : 'yyyy-MM-dd') "
                formControlName="holidayDate" (change)="updateReportFilter('holidayDate')" name="holidayDate"
                id="holidayDate" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_WAGES == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('Wages')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="wages" (valueChange)="updateReportFilter('wages')"
                [data]="paycatlistFilteredForStatutory" [textField]="'b'" [valueField]="'a'"
                [placeholder]="translate('selectCategories')" name="wages"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_WORKING_WEEK_START == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('workingWeekStartDate')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="working_week_start" name="PAR_WORKING_WEEK_START"
                (change)="updateReportFilter('working_week_start')">
                <!--<option [ngValue]="null">All</option>-->
                <option *ngFor="let item of working_week" [ngValue]="item">{{translate(item.value)}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_PROVINCE_RULE == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('provinceAndRule')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="province_rule" name="PAR_PROVINCE_RULE" id="province_rule"
                (change)="updateReportFilter('province_rule')">
                <!--<option [ngValue]="null">All</option>-->
                <option *ngFor="let item of province_rule_list" [ngValue]="item">{{translate(item.province_name)}} -
                  {{translate(item.rule_desc)}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_INCLUDE_ALL_EMPS == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_all_emps" name="PAR_INCLUDE_ALL_EMPS"
                    id="PAR_INCLUDE_ALL_EMPS" class="form-check-input"
                    (change)="updateReportFilter('include_all_emps')" />
                  <label class="form-check-label"
                    for="PAR_INCLUDE_ALL_EMPS">{{translate('includeAllEmployeesRegardlessOfTaxProvince')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 25">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_zero_earnings" id="PAR_INCLUDE_ZERO_EARNINGS"
                    name="PAR_INCLUDE_ZERO_EARNINGS" class="form-check-input"
                    (change)="updateReportFilter('include_zero_earnings')" />
                  <label class="form-check-label"
                    for="PAR_INCLUDE_ZERO_EARNINGS">{{translate('includeEmployeesWithZeroYTDAssessableEarnings')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 30">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="state"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="state" name="PAR_STATE_ID" id="state"
                (change)="onStateChange()">
                <option *ngFor="let item of listStates" [ngValue]="item">{{item.c}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 30">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('selectLocalTax')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="localTaxItems" [data]="localTaxList"
                [textField]="'CAT_DTL_PAYSTUB_ABBRI'" [valueField]="'CAT_DTL_CODE'" [placeholder]="'Select items...'"
                (valueChange)="onlocalTaxDropDownChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 31">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollCategory')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="wcbCategory" [data]="wcbCategoryList"
                [textField]="'CAT_DTL_PAYSTUB_ABBRI'" [valueField]="'CAT_DTL_CODE'"
                [placeholder]="translate('selectCategories')"
                (valueChange)="onWcbCategoryChange($event)"></kendo-multiselect>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="report_sent.REPORT_ID == 2 || report_sent.REPORT_ID == 3 || report_sent.REPORT_ID == 4 || report_sent.REPORT_ID == 5 || report_sent.REPORT_ID == 6 ||
                        report_sent.REPORT_ID == 13 || report_sent.REPORT_ID == 14 || report_sent.REPORT_ID == 15  || report_sent.REPORT_ID == 16 || report_sent.REPORT_ID == 17 ||
                        report_sent.REPORT_ID == 18 || report_sent.REPORT_ID == 19 || report_sent.REPORT_ID == 20  || report_sent.REPORT_ID == 21 ||
                        report_sent.REPORT_ID == 28 || report_sent.REPORT_ID == 29 || report_sent.REPORT_ID == 30 || report_sent.REPORT_ID == 31
                    || report_sent.REPORT_ID == 37 || report_sent.REPORT_ID == 39">
        <div class="col-sm-6">

          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_unlocked_paycards"
                    name="PAR_INCLUDE_UNLOCKED_PAYCARDS" id="PAR_INCLUDE_UNLOCKED_PAYCARDS" class="form-check-input"
                    (change)="updateReportFilter('include_unlocked_paycards')" />
                  <label class="form-check-label"
                    for="PAR_INCLUDE_UNLOCKED_PAYCARDS">{{translate('includeUnlockedPaycards')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 20">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_user_defined_gross"
                    id="PAR_INCLUDE_USER_DEFINED_GROSS" name="PAR_INCLUDE_USER_DEFINED_GROSS"
                    class="form-check-input" (change)="updateReportFilter('include_user_defined_gross')" />
                  <label class="form-check-label"
                    for="PAR_INCLUDE_USER_DEFINED_GROSS">{{translate('includeUserDefinedGross')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 37">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('showDimensions')}}</div>
            <div class="card-body px-2 py-1">
              <kendo-multiselect formControlName="dimensionmulti" [data]="dimList37" [textField]="'LABOR_DIM_CAPTION'"
                [valueField]="'LABOR_DIM_ID'" [placeholder]="translate('selectDimensions')"
                (valueChange)="onDimensionMultiDropDownChange($event)"></kendo-multiselect>

            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 30">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_employer_contributions"
                    id="PAR_INCLUDE_EMPLOYER_CONTRIBUTIONS" name="PAR_INCLUDE_EMPLOYER_CONTRIBUTIONS"
                    class="form-check-input" (change)="updateReportFilter('include_employer_contributions')" />
                  <label class="form-check-label"
                    for="PAR_INCLUDE_EMPLOYER_CONTRIBUTIONS">{{translate('includeEmployerContributions')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 32">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="union"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="union" name="PAR_UNION" id="union"
                (change)="onUnionChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of dimUnionList" [ngValue]="item">{{item?.DIM_NAME}} {{item?.DIM_DESCRIPTION}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 32">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('unionAgreement')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="unionAgreement" name="PAR_UNIONAGR" id="unionAgreement"
                (change)="onUnionAgreementChange()">
                <option [ngValue]="null"><span transloco="all"></span></option>
                <option *ngFor="let item of unionAgrList" [ngValue]="item">{{item?.DIM_NAME}} {{item?.DIM_DESCRIPTION}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 32">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('agreementDate')}}</div>
            <div class="card-body px-2 py-1">
              <input type="date" class="form-control"
                [attr.data-date]="reportForm.get('agrDate').value | date: (sysPref?.df ? sysPref.df : 'yyyy-MM-dd') "
                formControlName="agrDate" (change)="updateReportFilter('agrDate')" name="agrDate" id="agrDate" />
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 32">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_inactive_dates" name="PAR_INCLUDE_INACTIVE_DATES"
                    id="PAR_INCLUDE_INACTIVE_DATES" class="form-check-input"
                    (change)="updateReportFilter('include_inactive_dates')" />
                  <label class="form-check-label"
                    for="PAR_INCLUDE_INACTIVE_DATES">{{translate('includeInactiveDates')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 36">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">
              <div class="card-body px-2 py-1">
                <div class="form-check">
                  <input type="checkbox" formControlName="include_blank_note" name="PAR_INCLUDE_BLANK_NOTE"
                    id="PAR_INCLUDE_BLANK_NOTE" class="form-check-input"
                    (change)="updateReportFilter('include_blank_note')" />
                  <label class="form-check-label" for="PAR_INCLUDE_BLANK_NOTE">{{translate('includeBlankNote')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 33">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="dimension"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="dimension" name="PAR_DIM" id="dimension33"
                (change)="onDimensionChange()">
                <option *ngFor="let item of dimList33" [ngValue]="item">{{item?.LABOR_DIM_CAPTION}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 34">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="dimension"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="dimension" name="PAR_DIM" id="dimension34"
                (change)="onDimensionChange()">
                <option *ngFor="let item of dimList34" [ngValue]="item">{{item?.LABOR_DIM_CAPTION}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 35">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1">{{translate('payrollCategoryType')}}</div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="categoryType" name="PAR_CATEGORY_TYPE" id="categoryType"
                (change)="onCategoryTypeChange()">
                <option *ngFor="let item of categoryTypeList" [ngValue]="item">{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card w-100">
            <label class="card-header card-body-id">
              <input type="checkbox" formControlName="include_zero_categories" name="PAR_ZERO_CATEGORIES"
                class=" custom-checkbox" (change)="updateReportFilter('include_zero_categories')" /><span
                class="label-text">{{translate('includeZeroCategories')}}</span>
            </label>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.PAR_ORDER_BY == 1">
        <div class="col-sm-6">
          <div class="card border mb-2">
            <div class="card-header px-2 py-1"><span transloco="sortBy"></span></div>
            <div class="card-body px-2 py-1">
              <select class="form-select" formControlName="orderBy" name="PAR_ORDERBY" id="orderBy" (change)="onOrderByChange()">
                <option [ngValue]="null"></option>
                <option *ngFor="let item of orderByList" [ngValue]="item">{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="report_sent.REPORT_ID == 11">
        <div class="col-sm-6">
          <div class="card w-100">
            <label class="card-header card-body-id">
              <input type="checkbox" formControlName="include_year_to_date_amounts" name="PAR_YEAR_TO_DATE_AMOUNTS"
                class=" custom-checkbox" (change)="updateReportFilter('include_year_to_date_amounts')" /><span
                class="label-text">{{translate('showYearToDateAmounts')}}</span>
            </label>
          </div>
        </div>
        <div class="col-sm-6" hidden>
          <div class="card w-100">
            <label class="card-header card-body-id">
              <input type="checkbox" formControlName="group_by_department" name="PAR_GROUP_BY_DEPARTMENT"
                class=" custom-checkbox" (change)="updateReportFilter('group_by_department')" /><span
                class="label-text">{{translate('groupByDepartment')}}</span>
            </label>
          </div>
        </div>
      </ng-container>
    </div>
  </form>

  <kendo-window id="reportModal" class="kwindow-center" *ngIf="opened" (close)="close()" [minWidth]="250"
    [width]="1000">
    <!--[height]="890" -->
    <kendo-window-titlebar>
      <div class='k-window-title'></div>
      <button kendoWindowCloseAction></button>
    </kendo-window-titlebar>
    <ng-container *ngIf="rprtSrce" style="overflow:auto">
      <tr-viewer #viewer1 [containerStyle]="viewerContainerStyle" [serviceUrl]="baseURL" [reportSource]="reportSource"
        [viewMode]="'INTERACTIVE'" [scaleMode]="'FIT_PAGE_WIDTH'" [scale]="1.0" [sendEmail]="viewerSendEmail"
        [authenticationToken]="token">
      </tr-viewer>
    </ng-container>
  </kendo-window>
</ng-container>
