import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoService} from "@ngneat/transloco";
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {User} from '@shared/models/user';
import {CompanySwitchResponseModel} from '@shared/models/companyInfo';
import {AuthBroadcastMessage, AuthenticationService} from '@shared/services';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {GlobalService} from '@services/global.service';
import {CreateCompanyComponent} from '../create-company/create-company.component';
import {GetTokenResponseModel} from '@shared/models/auth.models';

declare var showMsg: any;

@Component({
  selector: 'app-switch-company',
  templateUrl: './switch-company.component.html',
  styleUrls: ['./switch-company.component.scss'],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: {scope: 'switchCompany', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}}
  ]
})
export class SwitchCompanyComponent implements OnInit {
  @ViewChild('otpModal') otpModal: TemplateRef<unknown>;

  user: User;
  companyList: CompanySwitchResponseModel[];
  search: string;
  loading = false;
  selectedCompany: CompanySwitchResponseModel;
  payrollYear = new Date().getFullYear();
  currentPayrollYear: number;
  otpModalRef: BsModalRef;

  constructor(
    protected bsModalRef: BsModalRef,
    private authService: AuthenticationService,
    private transloco: TranslocoService,
    private router: Router,
    private globalService: GlobalService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.companyList = this.authService.companyList;
    this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
    this.currentPayrollYear = this.globalService.currentPayrollYear;
  }

  onSelect(company: CompanySwitchResponseModel) {
    this.companyList.forEach(c => c.selected = c.CompanyId === company.CompanyId);
    this.selectedCompany = company?.selected ? company : null;
  }

  onDoubleClick(company: CompanySwitchResponseModel) {
    if (company.CompanyId !== this.user.selectedCompany.Id)
      this.onSwitch(company);
  }

  private dbMigration(tokenResponse: GetTokenResponseModel) {
    const buttonOK = this.transloco.translate('ok');
    const titleE = this.transloco.translate('error');
    this.authService.IsDatabaseUpdateRequired(tokenResponse.token)
      .pipe(take(1))
      .subscribe(data => {
          this.loading = false;
          let required: number;
          if (data != null) {
            required = data;
          } else {
            required = -1;
          }

          if (required == -1) {
            const msg = this.transloco.translate('alerts.dataIssueContactPaymateSupport', {}, 'switchCompany');
            showMsg({
              'type': 'sticky',
              'status': 'error',
              'title': titleE,
              'buttons': {
                [buttonOK]: {
                  action: function () {
                    return;
                  }
                }
              },
              'message': msg
            });
            return;
          }
          if (required == 1) {
            var that = this;
            const title = this.transloco.translate('dataBaseUpgradeHeader', {}, 'switchCompany');
            const buttonCancel = this.transloco.translate('cancel');
            const message = this.transloco.translate('alerts.confirmUpgrade', {}, 'switchCompany');
            showMsg({
              'type': 'sticky',
              'status': 'warning',
              'title': title,
              'buttons': {
                [buttonOK]: {
                  action: function () {
                    that.loading = true;
                    that.authService.UpdateDatabaseVersion(tokenResponse.token).subscribe(data => {
                        let done: boolean;
                        done = data;
                        if (done) {
                          that.loading = false;
                          that.completeSwitch(tokenResponse);
                        } else {
                          that.loading = false;
                          const messageE = that.transloco.translate('alerts.upgradeUnsuccessful', {}, 'switchCompany');
                          showMsg({
                            'type': 'sticky',
                            'status': 'error',
                            'title': titleE,
                            'buttons': {
                              [buttonOK]: {
                                action: function () {
                                  that.loading = false;
                                  return;
                                }
                              }
                            },
                            'message': messageE
                          });
                        }
                      },
                      e => {
                        that.loading = false;
                        showMsg({
                          'type': 'sticky',
                          'status': 'error',
                          'title': titleE,
                          'buttons': {
                            [buttonOK]: {
                              action: function () {
                                that.loading = false;
                                return;
                              }
                            }
                          },
                          'message': e.error.Message
                        });
                      });
                  }
                },
                [buttonCancel]: {
                  action: function () {
                    return;
                  }
                }
              },
              'message': message
            });
          } else {
            this.completeSwitch(tokenResponse);
          }
        },
        e => {
          this.loading = false;
          showMsg({
            'type': 'sticky',
            'status': 'error',
            'title': titleE,
            'buttons': {
              [buttonOK]: {
                action: function () {
                  return;
                }
              }
            },
            'message': e.error.Message
          });
        });
  }

  private completeSwitch(tokenResponse: GetTokenResponseModel) {
    this.authService.setUser(tokenResponse);
    this.authService.authBroadcast.postMessage({message: 'switch', sessionId: this.authService.sessionId} as AuthBroadcastMessage);
    this.router.navigate(['/']).then(() => window.location.reload());
  }

  onSwitch(company: CompanySwitchResponseModel, otp?: string) {
    this.loading = true;
    this.authService.switchCompany({companyId: company.CompanyId, payrollYear: this.payrollYear, password: null, otp})
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (res.otpRequired) {
            this.showOtpModal();
            return;
          }
          this.dbMigration(res);
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          const title = this.transloco.translate('error');
          showMsg({
            type: 'nonsticky',
            status: 'error',
            title,
            buttons: {},
            message: err.error.Message,
            delay: '2000' // in Milliseconds
          });
        },
        complete: () => this.loading = false
      });
  }

  private showOtpModal() {
    if (this.otpModalRef) {
      this.otpModalRef.hide();
    }
    this.otpModalRef = this.modalService.show(this.otpModal, {class: 'modal-dialog-centered', backdrop: true, ignoreBackdropClick: true});
  }

  onVerifyOtp($event: { code: string; rememberBrowser: boolean }) {
    this.onSwitch(this.selectedCompany, $event.code);
  }

  onCreateCompany() {
    this.bsModalRef.hide();
    this.modalService.show(CreateCompanyComponent, {class: 'modal-dialog-centered', backdrop: true, ignoreBackdropClick: true});
  }
}
