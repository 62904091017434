export interface IBank {

  i: number;   /*BNK_CODE*/
  u: string;     /*BNK_USER_CODE*/
  p: number;    /*BNK_INTERFACE_ID*/
  c: number;    /*IS_COMPANY_BANK_FLG*/
  n: string;    /*BNK_NAME*/
  l: string;    /*BNK_LOGO_PATH*/
  a: number;    /*IS_BANK_ACTIVE_FLG*/
  PREDEFINED: boolean;
}

export interface IBankAdd {

  i: number;   /*BNK_CODE*/
  u: string;     /*BNK_USER_CODE*/
  p: number;    /*BNK_INTERFACE_CODE*/
  c: number;    /*IS_COMPANY_BANK_FLG*/
  n: string;    /*BNK_NAME*/
  f: File;    /*image file*/
  a: number;    /*IS_BANK_ACTIVE_FLG*/
}

export enum BankInterfaceTypeEnum {
  CanadaBambora = 36,
  CanadaSeamlessBambora = 37,
  UsaBambora = 158,
  UsaSeamlessBambora = 159,
  HondurasBac = 171
}
