<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <ng-container *transloco="let translate; read: 'importPaycard'">
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ImportPaycardModal" aria-hidden="true" id="ImportPaycardModal"
         data-bs-backdrop="static">
      <div class=" modal-dialog custom-modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{translate('importPaycard')}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <label for="interface" class="col-form-label">{{translate('companyInterface')}}</label>
                <input class="form-control" type="text" name="interface" id="interface" [(ngModel)]="interfaceDesc" [disabled]="true"/>
              </div>
            </div>
            <br/>
            <ng-container *ngIf="!is_direct">
              <div class="row">
                <div class="col-12">
                  <input type="file" class="form-control" ngModel (change)="onFileSelected($event)" name="filePath" accept=".csv,.txt" id="file_path">
                </div>
              </div>
            </ng-container>
            <!--<ng-container *ngIf="is_direct">
              <div class="row">
                <div class="col-12 wrap-item-center">
                  <div class="form-check">
                    <input type="checkbox" id="approvedTimesheet" name="approvedTimesheet" class="form-check-input"
                           [checked]="onlyApprovedTimesheet == true" [(ngModel)]="onlyApprovedTimesheet" [ngModelOptions]="{standalone: true}"/>
                    <label for="approvedTimesheet" class="form-check-label">{{translate('onlyApprovedTimesheets')}}</label>
                  </div>
                </div>
              </div>
            </ng-container>-->
          </div>

          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!is_direct && (uploadedFile == null)">
              <i class="fas fa-hourglass-half"></i>&nbsp;<span transloco="process"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>

<ng-container *transloco="let translate; read: 'importPaycard'">
  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="errorModal" aria-hidden="true" id="errorModal">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div *ngIf="status == 2">
          <br/>
          <img src="./assets/image_system/newrecords.png" class="icon-Size mr-2"/>
          <span>{{translate('belowRecordsAreNotImported')}}</span>
          <br/>
        </div>
        <div class="modal-body">
          <table class="table table-scroll stripped" style="width:95%">
            <thead>
            <tr>
              <th>{{translate('employeeNumber')}}</th>
              <th>{{translate('categoryCode')}}</th>
              <th>{{translate('workDate')}}</th>
              <th><span transloco="units"></span></th>
              <th>{{translate('amountRate')}}</th>
              <th>{{translate('message')}}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="resultErrors">
              <tr *ngFor="let error of resultErrors">
                <td>
                  {{error.d}}
                </td>
                <td>
                  {{error.e}}
                </td>
                <td>
                  {{error.f}}
                </td>
                <td>
                  {{error.g}}
                </td>
                <td>
                  {{error.h}}
                </td>
                <td>
                  {{error.translatedError}}
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClickModal(1)" *ngIf="status == 1"><span
            transloco="overwrite"></span></button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClickModal(2)" *ngIf="status == 1">{{translate('skip')}}</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClickModal(3)"><span transloco="cancel"></span></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
