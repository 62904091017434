import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {LaborService} from '@harmony-modules/payroll/services/payroll-setup/labor.service';
import {ILaborSetup} from '@shared/models/ilabor';
import {ISystemPreferenceInit} from '@shared/models/isystem-preference';
import {TranslocoService} from '@ngneat/transloco';
import {EventService} from '@services/event.service';
import {GlobalService} from '@services/global.service';
import {SlideDownUp, SlideInOutAnimation} from '@shared/animations';
import {AuthenticationService} from '@shared/services';
import MetisMenu from 'metismenujs/dist/metismenujs';
import {iif, merge, of} from 'rxjs';
import {concatMap, tap} from 'rxjs/operators';
import {MenuItem} from './menu.model';
import {CAN_TAX_FORMS_MENU, NAVIGATION_MENU, US_TAX_FORMS_MENU} from './navigation.menu';
import {EmailService} from '@harmony-modules/configuration/services/setup/email.service';
import {CompanySwitchResponseModel} from '@shared/models/companyInfo';
import {User} from '@shared/models/user';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SwitchCompanyComponent} from '../../../account/switch-company/switch-company.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  //providers: [{
  //  provide: TRANSLOCO_SCOPE,
  //  useValue: {scope: 'navigationMenu', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
  //}],
  animations: [SlideInOutAnimation, SlideDownUp]
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;

  menuItems: MenuItem[] = JSON.parse(JSON.stringify(NAVIGATION_MENU));
  initPreference: ISystemPreferenceInit[] = [];
  taxVersion: string;
  newTaxTable = false;
  showLabor = false;
  showBasicLabor = false;
  isCertifiedPayroll = false;
  isUS = false;

  taxFormYear: number;
  isTaxFormAvailable = true;
  payrollYear: string;
  lastTaxVersionOfYear: number;
  companyList: CompanySwitchResponseModel[];
  user: User;

  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(
    private router: Router,
    public translate: TranslocoService,
    private laborService: LaborService,
    private authenticationService: AuthenticationService,
    private _sysPrefService: SystemPreferenceService,
    private _globalService: GlobalService,
    private emailSettings: EmailService,
    public eventService: EventService,
    private modalManager: BsModalService
  ) {
  }

  ngOnInit() {
    this._scrollElement();
    this.getSystemPreferences();
    this.checkEmailSettingsEnabled();

    this.companyList = this.authenticationService.companyList;

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });

    this.authenticationService.currentUser.subscribe(user => this.user = user);
    this._sysPrefService.systemPreferenceUpdated$.subscribe(() => this.setLabourMenuItems());
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  ngAfterViewInit() {
    if (this.sideMenu) {
      // this.menu = new MetisMenu(this.sideMenu.nativeElement);
      // this._activateMenuDropdown();
    }
  }

  setLabourMenuItems(): void {
    const labourConditions$ = merge(
      this.authenticationService.showLabor.pipe(tap(show => this.showLabor = show)),
      this.authenticationService.showBasicLabor.pipe(tap(show => this.showBasicLabor = show)),
      this.authenticationService.showCertifiedPayroll.pipe(tap(show => this.isCertifiedPayroll = show))
    );

    this.laborService.GetLaborSetup().pipe(tap(data => this.laborService.changeLaborSetupList(data))).subscribe();

    this.laborService.labourDimensionUpdated$.pipe(
      concatMap(() => labourConditions$),
      concatMap(() => iif(() => !!this.showLabor, this.laborService.laborSetup_list, of([])))
    ).subscribe(d => {
      const payrollSetupMenu = this.menuItems.find(value => value.id === '102');
      const labourMenu = payrollSetupMenu.subItems.find(value => value.id === '102.5');
      const jobCostSetupMenu = payrollSetupMenu.subItems.find(value => value.id === '102.7');
      labourMenu.hide = !this.showLabor;
      jobCostSetupMenu.hide = !this.showLabor;

      if (labourMenu && this.showLabor) {
        // labourMenu.subItems = [];
        const laborSetupList: ILaborSetup[] = d.slice(0, 6);
        const labourDiemensionMenu: MenuItem[] = laborSetupList
          .filter(value => value.LABOR_DIM_IS_ACTIVE_FLG)
          .map((value, index) => ({
            id: `${labourMenu.id}.${index + 4}`,
            label: value.LABOR_DIM_CAPTION,
            link: `payrollsetup/labor/Dimension/${value.LABOR_DIM_ID}/${value.LABOR_DIM_IS_RATE_FLG}/${value.LABOR_DIM_PARENT_CHILD}`,
            parentId: labourMenu?.id,
            skipTranslation: true
          }));
        labourMenu.subItems = labourMenu.subItems.filter(value =>
          value.id === '102.5.1' ||
          value.id === '102.5.2' ||
          value.id === '102.5.3'
        );
        labourMenu.subItems.forEach(item => {
          item.hide = (item.id === '102.5.2' && this.showBasicLabor) || (item.id === '102.5.3' && !this.isCertifiedPayroll);
        });
        labourMenu.subItems.splice(1, 0, ...labourDiemensionMenu);
      }

      const processingMenu = this.menuItems?.find(menu => menu.id === '105');
      const certifiedPayrollMenu = processingMenu?.subItems?.find(menu => menu.id === '105.10');
      certifiedPayrollMenu.hide = !this.isCertifiedPayroll;

      const labourInProcessingMenu = processingMenu?.subItems?.find(menu => menu.id === '105.4');
      labourInProcessingMenu.hide = !this.showLabor;

      const processJobCostRecordsMenu = processingMenu?.subItems?.find(menu => menu.id === '105.9');
      processJobCostRecordsMenu.hide = !this.showLabor;

      const jobCostInterfaceMenu = this.menuItems
        ?.find(value => value.id === '109')?.subItems
        ?.find(value => value.id === '109.6')?.subItems
        ?.find(value => value.id === '109.6.4');
      jobCostInterfaceMenu.hide = !this.showLabor;
    });
  }

  private checkEmailSettingsEnabled(): void {
    this.emailSettings.checkEmailSettingsEnabled().subscribe((enabled) => {
      const coreSettings = this.menuItems?.find(m => m.id === '201');
      const emailSettings = coreSettings.subItems?.find(m => m.id === '201.5');
      if (!!emailSettings) {
        emailSettings.hide = !enabled;
      }
    });
  }

  setTaxForms() {
    const taxForms = this.menuItems.find(value => value.id === '107');
    if (this.isUS) {
      taxForms.subItems = US_TAX_FORMS_MENU;
    } else {
      if (this.isTaxFormAvailable) {
        taxForms.subItems = CAN_TAX_FORMS_MENU;
      } else {
        taxForms.subItems = CAN_TAX_FORMS_MENU.slice(0, 1);
      }
    }
  }

  getSystemPreferences() {
    this._globalService.GetPayrollYear()
      .pipe(
        tap(res => {
          this.payrollYear = res[0];
          this.lastTaxVersionOfYear = res[1];
        }),
        concatMap(() => this._sysPrefService.getinitSystemPreferences())
      ).subscribe(data => {
      this.initPreference = data;
      if (this.initPreference && this.initPreference.length > 0) {
        // Canadian
        if ((this.initPreference[0].y != null) && (this.initPreference[0].y == 12)) {
          this.taxVersion = this.initPreference[0].d.toString() + '.' + this.initPreference[0].v;
          this.taxFormYear = this.initPreference[0].fd;
          this.isTaxFormAvailable = this.taxFormYear >= +this.payrollYear;
          if (this.initPreference[0].d <= +this.payrollYear) {
            if (this.lastTaxVersionOfYear > +(this.initPreference[0].d + this.initPreference[0].v.replace('.', ''))) {
              this.newTaxTable = true;
            }
          } else if (this.initPreference[0].d > +this.payrollYear) {
            this.newTaxTable = false;
          }
        }

        if ((this.initPreference[0].y != null) && (this.initPreference[0].y == 82)) {
          this.isUS = true;
          this._globalService.GetSTEVersion().subscribe(data => {
            this.taxVersion = data;
          });
        }
        this.authenticationService.currentUser.subscribe(user => {
          if ((user.selectedCompany.SubModules.includes('1')
              || user.selectedCompany.SubModules.includes('2')
              || user.selectedCompany.SubModules.includes('3')
              || user.selectedCompany.SubModules.includes('4'))
            && this.initPreference[0].l === 1) {
            this.authenticationService.updateLabor(true);
            if (this.initPreference[0].lt === 1) {
              this.authenticationService.updateBasicLabor(true);
            } else {
              this.authenticationService.updateBasicLabor(false);
            }
            this.authenticationService.updateCertifiedPayroll(this.initPreference[0].ct === 1);
          } else {
            this.authenticationService.updateLabor(false);
            this.authenticationService.updateCertifiedPayroll(false);
          }
          if (
            (user.selectedCompany.SubModules.includes('2') || user.selectedCompany.SubModules.includes('4'))
            && this.initPreference[0].u == 1
          ) {
            this.authenticationService.updateUnion(true);
          } else {
            this.authenticationService.updateUnion(false);
          }
        });

        this.authenticationService.showLabor.subscribe(x => this.showLabor = x);
        this.authenticationService.showBasicLabor.subscribe(x => this.showBasicLabor = x);
        this.authenticationService.showCertifiedPayroll.subscribe(x => this.isCertifiedPayroll = x);

        this.setTaxForms();
        this.setLabourMenuItems();
      }
    });
  }

  // toggleMenu(event) {
  //   event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  // }

  private activateMenu(url: string) {
    // this.menuItems.forEach(menu => {
    //   if (menu.subItems?.length) {
    //     menu.subItems.forEach(subMenu => {
    //       if (subMenu.link === url) {
    //         subMenu.isActive = true;
    //         menu.isActive = true;
    //       } else {
    //         subMenu.isActive = false;
    //       }
    //     });
    //   }
    // });
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName('mm-active').length > 0) {
        const currentPosition = document.getElementsByClassName('mm-active')[0]['offsetTop'];
        if (currentPosition > 500) {
          if (this.scrollRef.SimpleBar !== null) {
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
          }
        }
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    // this._removeAllClass('mm-active');
    // this._removeAllClass('mm-show');
    // const links = document.getElementsByClassName('side-nav-link-ref');
    // let menuItemEl: any;
    // // tslint:disable-next-line: prefer-for-of
    // const paths = [];
    // for (let i = 0; i < links.length; i++) {
    //   paths.push(links[i]['pathname']);
    // }
    // var itemIndex = paths.indexOf(window.location.pathname);
    // if (itemIndex === -1) {
    //   const strIndex = window.location.pathname.lastIndexOf('/');
    //   const item = window.location.pathname.substr(0, strIndex).toString();
    //   menuItemEl = links[paths.indexOf(item)];
    // } else {
    //   menuItemEl = links[itemIndex];
    // }
    // if (menuItemEl) {
    //   menuItemEl.classList.add('active');
    //   const parentEl = menuItemEl.parentElement;
    //   if (parentEl) {
    //     parentEl.classList.add('mm-active');
    //     const parent2El = parentEl.parentElement.closest('ul');
    //     if (parent2El && parent2El.id !== 'side-menu') {
    //       parent2El.classList.add('mm-show');
    //       const parent3El = parent2El.parentElement;
    //       if (parent3El && parent3El.id !== 'side-menu') {
    //         parent3El.classList.add('mm-active');
    //         const childAnchor = parent3El.querySelector('.has-arrow');
    //         const childDropdown = parent3El.querySelector('.has-dropdown');
    //         if (childAnchor) {
    //           childAnchor.classList.add('mm-active');
    //         }
    //         if (childDropdown) {
    //           childDropdown.classList.add('mm-active');
    //         }
    //         const parent4El = parent3El.parentElement;
    //         if (parent4El && parent4El.id !== 'side-menu') {
    //           parent4El.classList.add('mm-show');
    //           const parent5El = parent4El.parentElement;
    //           if (parent5El && parent5El.id !== 'side-menu') {
    //             parent5El.classList.add('mm-active');
    //             const childanchor = parent5El.querySelector('.is-parent');
    //             if (childanchor && parent5El.id !== 'side-menu') {
    //               childanchor.classList.add('mm-active');
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  onClickMenu(item: MenuItem) {
    item.isActive = !item.isActive;
  }

  onSwitchCompany() {
    this.modalManager.show(SwitchCompanyComponent, {class: 'modal-dialog-centered', ignoreBackdropClick: true});
  }
}
