import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {TelerikReportingModule} from '@progress/telerik-angular-report-viewer';
import {SharedModule} from '@shared/shared.module';
import {ArchwizardModule} from 'angular-archwizard';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ChartsModule} from 'ng2-charts';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {NarikCustomValidatorsModule} from '@narik/custom-validators';
import {OrderModule} from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BankFileComponent} from './bank/bank-file.component';
import {VisaFinalizeComponent} from './finalise/visa-finalize.component';
import {ImportLaborComponent} from './labor/import-labor/import-labor.component';
import {LaborComponent} from './labor/labor/labor.component';
import {PayrollProcessWizardRoutingModule} from './payroll-process-wizard-routing.module';
import {CostModalComponent} from './payroll-process/cost-modal/cost-modal.component';
import {PayrollProcessComponent} from './payroll-process/payroll-process/payroll-process.component';
import {PayrollWizardComponent} from './payroll-wizard/payroll-wizard.component';
import {ChequePrintingDetailsComponent} from './print-paystub/cheque-printing-details/cheque-printing-details.component';
import {ChequePrintingOptionsComponent} from './print-paystub/cheque-printing-options/cheque-printing-options.component';
import {ChequePrintingComponent} from './print-paystub/cheque-printing/cheque-printing.component';
import {PayrollGroupSelectionComponent} from './select-pay-period/payroll-group-selection.component';
import {ImportPaycardComponent} from './timesheet/import-paycard/import-paycard.component';
import {TimesheetGridComponent} from './timesheet/timesheet-grid/timesheet-grid.component';
import {WizardBarComponent} from './wizard-bar/wizard-bar.component';
import {
  AccessControlDirectiveModule
} from '../../../../../../account/access-control/access-control-directive/access-control-directive.module';


@NgModule({
  declarations: [PayrollGroupSelectionComponent,
    PayrollProcessComponent,
    CostModalComponent,
    LaborComponent,
    ImportLaborComponent,
    TimesheetGridComponent,
    ImportPaycardComponent,
    ChequePrintingComponent,
    ChequePrintingOptionsComponent,
    ChequePrintingDetailsComponent,
    VisaFinalizeComponent,
    BankFileComponent,
    PayrollWizardComponent,
    WizardBarComponent
  ],
  exports: [
    WizardBarComponent
  ],
  imports: [
    CommonModule,
    PayrollProcessWizardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NarikCustomValidatorsModule,
    NgxSpinnerModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    NgMultiSelectDropDownModule,
    SharedModule,
    OrderModule,
    WindowModule,
    TelerikReportingModule,
    NgxPaginationModule,
    ProgressbarModule,
    ArchwizardModule,
    ChartsModule,
    AccessControlDirectiveModule
  ]
})
export class PayrollProcessWizardModule { }
