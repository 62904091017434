import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  IDeleteInterfaceParamtrs,
  IImportInterfaceUpdate,
  IInterfaceParamlist,
  IInterfaceParamUpdate,
  IInternalImportFile,
  IInternalRunFixingScripts,
  IPaycard,
  IPaycardImportParam,
  IPaycardImportResult,
  IPaycardStatus,
  ISourceField,
  ITargetField
} from 'src/app/shared/models/ipaycard';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class PaycardServiceService {

  constructor(private http: HttpClient) { }


  getPaycardInterfaceList(): Observable<IPaycard[]> {
    return this.http.get<IPaycard[]>(API_END_POINT.payroll.paycard + "GetPaycardInterfaceList");
  }

  getPaycardInterfaceList_V1(): Observable<IPaycard[]> {
    return this.http.get<IPaycard[]>(API_END_POINT.payroll.paycard + "GetPaycardInterfaceList_V1");
  }

  updateImportInterfaceStatus(ob: IPaycardStatus): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.paycard + "UpdateImportInterfaceStatus", ob);
  }

  updateImportInterface(obj: IImportInterfaceUpdate): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.paycard + "UpdateImportInterface", obj);
  }

  private paycardList = new BehaviorSubject<IPaycard[]>(null);
  currentPaycardList = this.paycardList.asObservable();

  changePaycardList(list: IPaycard[]) {
    this.paycardList.next(list);
  }

  getTargetFieldsList(): Observable<ITargetField[]> {
    return this.http.get<ITargetField[]>(API_END_POINT.payroll.paycard + "GetTargetFieldList");
  }

  getSourceFields(id: number): Observable<ISourceField[]> {
    return this.http.get<ISourceField[]>(API_END_POINT.payroll.paycard + "GetSourceFields?interfaceID=" + id);
  }
  updateImportInterfaceDirectParam(list: ISourceField[]): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.paycard + "UpdateImportInterfaceDirectParam", list);
  }
  updateInterfaceParameters(list: IInterfaceParamUpdate[]): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.paycard + "UpdateInterfaceParameters", list);
  }

  deleteImportInterface(intrfce_cde: number): Observable<number> {
    var ob = { code: intrfce_cde };
    return this.http.post<number>(API_END_POINT.payroll.paycard + "DeleteImportInterface", ob);
  }

  getInterfaceParamList(interface_code: number): Observable<IInterfaceParamlist[]> {
    return this.http.get<IInterfaceParamlist[]>(API_END_POINT.payroll.paycard + "GetInterfaceParameterList?interface_code=" + interface_code);
  }

  deleteInterfaceParameters(ob: IDeleteInterfaceParamtrs): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.paycard + "DeleteInterfaceParameters", ob);
  }

  getCompanyImportPaycardInterface(): Observable<IPaycard> {
    return this.http.get<IPaycard>(API_END_POINT.payroll.paycard + "GetCompanyImportPaycardInterface");
  }

  processImportPaycard(obj: IPaycardImportParam): Observable<IPaycardImportResult[]> {
    return this.http.post<IPaycardImportResult[]>(API_END_POINT.payroll.paycard + "ProcessImportPaycard", obj);
  }

  processInternalImport(f: IInternalImportFile): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.paycard + "ProcessInternalImport", f);//?f=" + f);
  }

  processInternalRunFixingScripts(f: IInternalRunFixingScripts): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.paycard + "ProcessInternalRunFixingScripts", f);//?f=" + f);
  }

  processImportDeactivate(): Observable<any> {
    return this.http.get<void>(API_END_POINT.payroll.paycard + 'ProcessImportDeactivate');
  }
}
