import {Injectable} from '@angular/core';

import {of, Subject, Subscription} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {BreakpointObserver} from '@angular/cdk/layout';

interface Event {
  type: string;
  payload?: any;
}

type EventCallback = (payload: any) => void;
type BreakPointSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
const BreakpointQuery = {
  xs: '(max-width: 576.99px)',
  sm: '(min-width: 577px) and (max-width: 767.99px)',
  md: '(min-width: 768px) and (max-width: 991.99px)',
  lg: '(min-width: 992px) and (max-width: 1199.99px)',
  xl: '(min-width: 1200px) and (max-width: 1399.99px)',
  xxl: '(min-width: 1400px)'
};
const BreakPointQueryMap = new Map<string, BreakPointSize>([
  [BreakpointQuery.xs, 'xs'],
  [BreakpointQuery.sm, 'sm'],
  [BreakpointQuery.md, 'md'],
  [BreakpointQuery.lg, 'lg'],
  [BreakpointQuery.xl, 'xl'],
  [BreakpointQuery.xxl, 'xxl']
]);
@Injectable({
  providedIn: 'root'
})
export class EventService {
  private handler = new Subject<Event>();
  public breakPointSize: BreakPointSize;

  constructor(
    private readonly breakpointObserver: BreakpointObserver
  ) {

    this.breakpointObserver.observe([
      BreakpointQuery.xs,
      BreakpointQuery.sm,
      BreakpointQuery.md,
      BreakpointQuery.lg,
      BreakpointQuery.xl,
      BreakpointQuery.xxl
    ]).pipe(
      switchMap((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            return of(BreakPointQueryMap.get(query));
          }
        }
      })
    ).subscribe(res => this.breakPointSize = res);
  }

  /**
   * Broadcast the event
   * @param type type of event
   * @param payload payload
   */
  broadcast(type: string, payload = {}) {
    this.handler.next({type, payload});
  }

  /**
   * Subscribe to event
   * @param type type of event
   * @param callback call back function
   */
  subscribe(type: string, callback: EventCallback): Subscription {
    return this.handler.pipe(
      filter(event => event.type === type)).pipe(
      map(event => event.payload))
      .subscribe(callback);
  }
}
