import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataExportService} from '@harmony-modules/payroll/services/admin-setup/data-export.service';
import {IDataExport} from '@shared/models/idata-export';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DeleteConfirmationService} from '@services/delete-confirmation.service';
import {ShowMessageService} from '@services/show-message.service';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {of} from 'rxjs';

declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-data-export-list',
  templateUrl: './data-export-list.component.html',
  styleUrls: ['./data-export-list.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: {scope: 'dataExportList', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
  }]
})
export class DataExportListComponent implements OnInit, OnChanges {
  searchText: string = null;
  dropdownListValues: any[] = [{name: '', cssClass: ''}];
  selectedDropdownValue: number = null;
  isDropDownvisible: boolean = true;
  dataExportList: IDataExport[] = [];
  dataExportListfiltered: IDataExport[] = [];
  cssClasses: string[] = [];
  imgClass: string;
  successAlert: boolean = null;
  wizardMode: any;


  public checked: boolean = true;
  public unchecked: boolean = false;

  constructor(
    private _dataExportService: DataExportService,
    private _wizardService: WizardService,
    private _deleteConfirmation: DeleteConfirmationService,
    private _router: Router,
    private route: ActivatedRoute,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    this._dataExportService.getDataExportList().subscribe(data => {
      this.dataExportList = data;
      this.dataExportListfiltered = this.dataExportList;
      const activeBankFile: any = this.dataExportList.find(x => x.a == 1 && x.t == 2);
      if (this.route.snapshot.params.d) {
        if (activeBankFile) {
          this.routeToEdit(activeBankFile.c, activeBankFile.t);
        } else {
          let result = this.dataExportList.filter(x => {
            return x.t == 2;
          });
          if (!result) {
            var title = this.transloco.translate('error');
            var msg = this.transloco.translate('alerts.noBankFileSetUp', {}, 'dataExportList');
            var button = this.transloco.translate('ok');
            showMsg({
              'type': 'sticky',
              'status': 'error',
              'title': title,
              'buttons': {
                [button]: function() {
                  return;
                }
              },
              'message': msg

            });
            // this._router.navigate(['adminsetup/interface/DataExportList']);
          } else if (!result.filter(x => x.a).length) {
            var title = this.transloco.translate('error');
            var msg = this.transloco.translate('alerts.noActiveBankFileSetUp', {}, 'dataExportList');
            var button = this.transloco.translate('ok');
            showMsg({
              'type': 'sticky',
              'status': 'error',
              'title': title,
              'buttons': {
                [button]: function() {
                  return;
                }
              },
              'message': msg
            });
            // this._router.navigate(['adminsetup/interface/DataExportList']);

          }
        }
      }


      this._wizardService.wizardMode$.concatMap(mode => {
        if (mode) {
          this.onSelectList(this.transloco.translate('bankFile', {}, 'dataExportList'));
        }
        this.wizardMode = mode;
        return of(mode);
      }).subscribe(
        mode => {
          this.dropdownListValues = this.getDropdownListValues();
        },
        err => {
          this.dropdownListValues = this.getDropdownListValues();
        });
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    let current = JSON.stringify(changes.currentValue);

  }

  searchList(str: String) {
    var substr = str.toString().toLowerCase();
    this.dataExportListfiltered = this.dataExportList.filter(x => (x.d !== null ? x.d.toLowerCase().indexOf(substr) !== -1 : null));
  }

  switchChange(code: number, evt: any, type: number) {
    //if already 1 interface being active
    let exists = this.dataExportList.filter(x => {
      return x.t == type && x.a && x.c != code;
    });
    if (exists && exists.length) {
      evt.preventDefault();
      var that = this;
      var title = this.transloco.translate('error');
      var msg1 = this.transloco.translate('alerts.only1', {}, 'dataExportList');
      var msg2 = this.transloco.translate('alerts.gl', {}, 'dataExportList');
      var msg3 = this.transloco.translate('alerts.bank', {}, 'dataExportList');
      var msg4 = this.transloco.translate('alerts.interfaceCanBeActive', {}, 'dataExportList');
      showMsg({
        'type': 'nonsticky',
        'status': 'error',
        'title': title,
        'message': msg1 + (type == 1 ? msg2 : msg3) + msg4,
        'delay': '1000'
      });
    } else {
      let editDataExport: any = this.dataExportList.find(d => d.c == code);
      editDataExport.a = ((editDataExport.a == 1) ? 0 : 1);


      this._dataExportService.updateDataExport(editDataExport).subscribe(
        res => {
          var title = this.transloco.translate('success');
          var msg = this.transloco.translate('recordSuccessfullyUpdated');
          showMsg({
            'type': 'nonsticky',
            'status': 'success',
            'title': title,
            'buttons': {},
            'message': msg,
            'delay': '1000'
          });
        },
        err => {

        }
      );
    }
  }

  getDropdownListValues(): any[] {
    this.dropdownListValues.length = 0;
    //if (this.wizardMode)
    //  this.dropdownListValues.push({ name: 'GL', cssClass: 'cImgGlIcon iconSize',disabled:true });
    //else
    //  this.dropdownListValues.push({ name: 'GL', cssClass: 'cImgGlIcon iconSize' });


    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
      this.dropdownListValues.push({
        name: this.transloco.translate('bankFile', {}, 'dataExportList'),
        cssClass: 'cImgBankFileIcon iconSize'
      });
    });
    return this.dropdownListValues;
  }

  getImageClasses(type: number) {
    this.cssClasses.length = 0;
    switch (type) {
      case 1: {
        this.imgClass = 'cImgGlIcon';
        break;
      }
      case 2: {
        this.imgClass = 'cImgBankFileIcon';
        break;
      }
      default: {
        this.imgClass = '';
        break;
      }
    }
    this.cssClasses.push(this.imgClass);
    this.cssClasses.push('category-image');
    return this.cssClasses;
  }

  onSelectList(item: string): void {
    if (item == 'GL') {
      this.selectedDropdownValue = 1;
    } else if (item == this.transloco.translate('bankFile', {}, 'dataExportList')) {
      this.selectedDropdownValue = 2;
    } else {
      this.selectedDropdownValue = null;
    }
    this.isDropDownvisible = false;
  }

  onAddList(name: string) {
    if (name == 'GL') {
      this._router.navigate(['adminsetup/interface/DataExportWizard']);
    } else if (name == this.transloco.translate('bankFile', {}, 'dataExportList')) {
      this._router.navigate(['adminsetup/interface/BankFile']);
    }
  }

  onSwitchChange(code: number, status: boolean) {
    this.checked = status;
    let temp: number = 0;
    if (status == true) {
      temp = 1;
    }
    let editDataExport: IDataExport = this.dataExportList.find(d => d.c == code);
    editDataExport.a = temp;
    this._dataExportService.updateDataExport(editDataExport).subscribe(
      res => {
        var title = this.transloco.translate('success');
        var msg = this.transloco.translate('alerts.recordSuccessfullyUpdated', {}, 'dataExportList');
        showMsg({
          'type': 'nonsticky',
          'status': 'success',
          'title': title,
          'buttons': {},
          'message': msg,
          'delay': '1000'
        });
        if (this.checked == false) {
          this.checked = true;
        }
        if (this.unchecked == true) {
          this.unchecked = false;
        }
      },
      err => {
        this.successAlert = false;
        setTimeout(() => {
          this.successAlert = null;
        }, 2500);
      }
    );

  }

  newDataExport() {
    this._router.navigate(['adminsetup/interface/DataExportWizard']);
  }

  routeToEdit(id: number, type: number) {
    if (type == 1) {
      this._router.navigate(['adminsetup/interface/DataExportWizard', id]);
    } else if (type == 2) {
      const url = ['BankFile', id];
      this._wizardService.onWizardMode ? url.unshift('process', 'processwizard', 'wizard') : url.unshift('adminsetup', 'interface');
      this._router.navigate(url);
    }
  }

  deleteLayout(id: number) {
    this._dataExportService.deleteDataExport(id).subscribe((data) => {
        this._dataExportService.getDataExportList().subscribe(data => {
          this.dataExportList = data;
          this.dataExportListfiltered = this.dataExportList;
        });
      },
      (err) => {
        var title = this.transloco.translate('error');
        var msg = this.transloco.translate('alerts.cannotDeleteExportlayout', {}, 'dataExportList');
        msg += '\n' + this.showMessage.translateErrorMessage(err?.Value);
        showMsg({
          'type': 'nonsticky',
          'status': 'err',
          'title': title,
          'buttons': {},
          'message': msg,
          'delay': '2500'
        });
      });
  }

  deleteImp(id: number, type: number) {
    if (type == 1) { // GL
      this._dataExportService.isGLDetailsEditable(id).subscribe(data => {
        if (data == 0) {
          var title = this.transloco.translate('error');
          var msg = this.transloco.translate('alerts.cannotBeDeleted', {}, 'dataExportList');
          showMsg({
            'type': 'nonsticky',
            'status': 'error',
            'title': title,
            'buttons': {},
            'message': msg,
            'delay': '2500'
          });
        } else {
          this._dataExportService.deleteDataExportItems(id).subscribe(data => {
            this._dataExportService.deleteDataExportSources(id).subscribe(data => {
              this._dataExportService.deleteDataExportSourceConfigs(id).subscribe(data => {
                this.deleteLayout(id);
              });
            });
          });
        }
      });
    } else if (type == 2) { // Bank File
      this.deleteLayout(id);
    }
  }

  delete(id: number, type: number) {
    var msg = this.transloco.translate('alerts.areYouSureToDeleteLayout', {}, 'dataExportList');
    this._deleteConfirmation.deleteConfirmation(this.deleteImp.bind(this, id, type), msg);
  }
}
