import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';

@UntilDestroy()
@Component({
  selector: 'app-sorting-list',
  templateUrl: './sorting-list.component.html',
  styleUrls: ['./sorting-list.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'sortingList', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class SortingListComponent implements OnInit {

  @Input() datalist: any[];
  @Input() sortingOptions: any[];
  @Output() sortingChanged = new EventEmitter<any>();
  @Input() sortKey: string;
  sortAcc: boolean = false; //false

  /**
   * t(ascending, descending)
   */
  reverseOptions: any[] = [{name: 'ascending', value: false}, {name: 'descending', value: true}]; // false, true

  constructor(
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService
  ) {
  }

  ngOnInit() {

    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
      this.reverseOptions = this.reverseOptions.map(item => ({...item, name: this.transloco.translate(item.name)}));
    });
  }

  sortingKeyOnChanged(evt: any) {
    this.sortKey = evt;
    this.sortingChanged.emit({order: this.sortKey, acc: this.sortAcc});
  }

  sortingAccOnChanged(evt: any) {
    this.sortAcc = evt;
    this.sortingChanged.emit({order: this.sortKey, acc: this.sortAcc});
  }
}
