import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {of} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {AccessControlService} from 'src/app/account/access-control/services/access-control.service';
import {WizardService} from 'src/app/shared/services/wizard.service';

declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-wizard-bar',
  templateUrl: './wizard-bar.component.html',
  styleUrls: ['./wizard-bar.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'wizardBar', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class WizardBarComponent implements OnInit {
  wizardBarEle: any;
  PayrollGroupInfoEle: any;
  currentStep: number;
  currentOrder: number;
  staticWizard: any;
  rptPermission = true;
  sd = false;

  constructor(
    private _router: Router,
    private _wizardService: WizardService,
    private _accessControlService: AccessControlService,
    private _payrollService: PayrollGroupService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService) {
  }

  ngOnInit() {
    this._wizardService.updateWizardMode(true);
    this._wizardService.currentWizard$.pipe(untilDestroyed(this)).subscribe(data => {
      this.wizardBarEle = data;
    });

    this._wizardService.currentWizardVW$.pipe(untilDestroyed(this)).subscribe(d => this.staticWizard = d);

    this._wizardService.currentGroupInfo$.pipe(untilDestroyed(this)).subscribe(data => {
      this.PayrollGroupInfoEle = data;
    });

    this._wizardService.currentStep$.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.currentStep = data;
      }
    });

    this._wizardService.dirtyStep$.pipe(untilDestroyed(this)).subscribe(data => {
      this.sd = data;
    });

    this._wizardService.currentOrder$.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.currentOrder = data;
      }
    });
    this.rptPermission = !!this._accessControlService.getAccessPermissions('payroll', 'payrollreports', 'view');
  }

  getCurrentStepFlag(): any {
    if (this.currentStep && this.wizardBarEle) {
      return {
        sf: this.wizardBarEle.find(x => x.sc == this.currentStep).sf,
        wf: this.wizardBarEle.find(x => x.sc == this.currentStep).wf,
        mf: this.wizardBarEle.find(x => x.sc == this.currentStep).mf

      };
    }
  }

  changeRoute(so: number) {
    if (so == this.currentOrder) {
      return;
    }
    this.routingChangeValidation(this.wizardBarEle[so - 1].sc, so, so - 1);
  }

  next(so: number) {
    this.routingChangeValidation(this.wizardBarEle[so].sc, so + 1, so);
  }

  prev(so: number) {
    this.routingChangeValidation(this.wizardBarEle[so - 2].sc, so - 1, so - 2);
  }

  getStepByOrder(order: number) {
    return this.wizardBarEle[order - 1]; // .find(x => x.so == order)
  }

  routingChangeValidation(stepTo: number, orderTo: number, index: number) {
    if (this.wizardBarEle[index].scap === 'Reports' && !this.rptPermission) {
      orderTo++;
      stepTo++;
      index++;
    }
    if (orderTo < this.currentOrder) {
      this._wizardService.updateStep(stepTo);
      this._wizardService.updateOrder(orderTo);
      this._router.navigate(this._wizardService.routinglinks[index]);
    } else {
      const tempWarning: any[] = [];
      for (let i = this.currentOrder; i < orderTo; i++) {
        const currentStepStatus = this.getStepByOrder(i);
        if (currentStepStatus.sf != 1) {
          if (currentStepStatus.mf) {
            const title = this.transloco.translate('error');
            const buttonOk = this.transloco.translate('ok');
            const message = this.transloco.translate('alerts.isRequired', {value: currentStepStatus.scap}, 'wizardBar');
            showMsg({
              type: 'sticky',
              status: 'error',
              title,
              buttons: {
                [buttonOk]: {
                  action() {
                    return;
                  }
                }
              },
              message
            });
            return;
          }
          if (currentStepStatus.wf) {
            tempWarning.push(
              {
                scap: currentStepStatus.scap,
                so: currentStepStatus.so
              }
            );
          }
        }
      }

      if (tempWarning && tempWarning.length) {
        let msg = '';
        tempWarning.forEach(x => {
          msg += (x.scap + ',');
        });
        const that = this;
        const title = this.transloco.translate('warning');
        const buttonOk = this.transloco.translate('confirm');
        const buttonCancel = this.transloco.translate('cancel');
        const message1 = this.transloco.translate('alerts.continueDespiteStepNotCompleted', {value: msg}, 'wizardBar');
        const message2 = this.transloco.translate('alerts.continueDespiteStepsNotCompleted', {value: msg.split(',').join('')}, 'wizardBar');
        showMsg({
          type: 'sticky',
          status: 'warning',
          title,
          buttons: {
            [buttonOk]: {
              action() {
                that._wizardService.updateStep(stepTo);
                that._wizardService.updateOrder(orderTo);
                that._router.navigate(that._wizardService.routinglinks[index]);
              }
            },
            [buttonCancel]: {
              action() {
                return;
              }
            }
          },
          message: tempWarning.length > 1 ? message1 : message2
        });
      } else {
        this._wizardService.updateStep(stepTo);
        this._wizardService.updateOrder(orderTo);
        this._router.navigate(this._wizardService.routinglinks[index]);
      }
    }
  }

  formateDate(value: any): string {
    return value.split('T')[0];
  }

  updateStepFlag(flg: number) {
    this._payrollService.getExistingPayrollProcessPeriod(this.PayrollGroupInfoEle.gc).pipe(
      concatMap(
        val => {
          if (val) {
            if (val.gc != this.PayrollGroupInfoEle.gc ||
              val.fd != this.PayrollGroupInfoEle.fd ||
              val.td != this.PayrollGroupInfoEle.td ||
              val.paycard_type.toUpperCase() != this.PayrollGroupInfoEle.paycard_type.charAt(0).toUpperCase() ||
              val.cd.substring(0, 10) != this.PayrollGroupInfoEle.cd) {
              const title = this.transloco.translate('error');
              const message = this.transloco.translate('alerts.pleaseSave', {}, 'wizardBar');
              showMsg({
                type: 'nonsticky',
                status: 'error',
                title,
                buttons: {},
                message,
                delay: '2000'
            });
              return of(null);
          }

            if (this.currentStep == 2) {
            return this._payrollService.getTimeSheetGridDataBulkView(val.gc, val.fd, val.td).pipe(concatMap(d => {
              if (d && d.find((x) => x.u == 0 || x.r == 0)) {
                const that = this;
                const title = this.transloco.translate('warning');
                let message = '<div>' + this.transloco.translate('alerts.timesheetZeroUnitRate', {}, 'wizardBar') + '</div>';
                d.forEach(x => {
                  if (x.u == 0 || x.r == 0) {
                    message += '<div>' + x.en + '</div>';
                  }
                });
                const buttonOK = this.transloco.translate('ok');
                const buttonCancel = this.transloco.translate('cancel');
                showMsg({
                  type: 'sticky',
                  status: 'warning',
                  title,
                  buttons: {
                    [buttonOK]: {
                      action() {
                        that._wizardService.updateFlag(flg).pipe(untilDestroyed(that)).subscribe(data => {
                          if (data) {
                            const title = that.transloco.translate('success');
                            const message = that.transloco.translate('alerts.successfullyUpdated', {}, 'wizardBar');
                            showMsg({
                              type: 'nonsticky',
                              status: 'success',
                              title,
                              buttons: {},
                              message,
                              delay: '3000'
                            });
                            that._wizardService.updateWizard(data);
                            that._wizardService.completeInvokedFromComp(false);
                          }

                        });
                      }
                    },
                    [buttonCancel]: {
                      action() {
                      }
                    }
                  },
                  message
                });
                return of(null);
              }
              else {
                return this._wizardService.updateFlag(flg).pipe(untilDestroyed(this));
              }
            }));
          }
          else if (this.currentStep == 3) {
            return this._payrollService.CheckPayprocessQueue(val.fd, val.td, val.gc, val.seq, val.y).pipe(concatMap(d => {
              if (d == 1) {
                const title = this.transloco.translate('error');
                const message = this.transloco.translate('alerts.payPeriodBeingProcessed', {}, 'wizardBar');
                showMsg({
                  type: 'nonsticky',
                  status: 'error',
                  title,
                  buttons: {},
                  message,
                  delay: '8000'
                });
                return of(null);
              }
              else {
                return this._wizardService.updateFlag(flg).pipe(untilDestroyed(this));
              }
            }));
          }
          else {
            return this._wizardService.updateFlag(flg).pipe(untilDestroyed(this));
          }

        } else {
          const title = this.transloco.translate('error');
          const message = this.transloco.translate('alerts.pleaseSaveBeforeNextStep', {}, 'wizardBar');
          showMsg({
            type: 'nonsticky',
            status: 'error',
            title,
            buttons: {},
            message,
            delay: '2000'
          });
          return of(null);
        }
      })
    ).subscribe(data => {
      if (data) {
        const title = this.transloco.translate('success');
        const message = this.transloco.translate('alerts.successfullyUpdated', {}, 'wizardBar');
        showMsg({
          type: 'nonsticky',
          status: 'success',
          title,
          buttons: {},
          message,
          delay: '3000'
        });
        this._wizardService.updateWizard(data);
        this._wizardService.completeInvokedFromComp(false);
      }

    });
  }
}
