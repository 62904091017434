import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AccessControlService} from '../services/access-control.service';
import {SecurityKeys} from '@shared/models/ISecurity';

@Injectable({
  providedIn: 'root'
})
export class AccessControlGuard implements CanActivate, CanActivateChild {

  constructor(
    private accessControlService: AccessControlService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getPermissionsState(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getPermissionsState(next, state);
  }

  private getPermissionsState(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    const routesArray = state.url.split(/[?\[\]\r\n/\\]+/);
    routesArray.splice(0, 1);

    if (next.data) {
      if (next.data.code) {
        const permissions = this.accessControlService.getAccessPermissions(next.data.module, next.data.code);
        if (permissions === undefined) {
          return true;
        }

        let grant;
        if (typeof permissions === 'boolean') {
          grant = permissions;
        } else {
          switch (true) {
            case routesArray.includes('edit'):
              grant = 'modify' in permissions && permissions.modify;
              break;
            case routesArray.includes('add'):
            case routesArray.includes('new'):
              grant = 'modify' in permissions && permissions.add;
              break;
            case routesArray.includes('view'):
              grant = 'view' in permissions && permissions.view;
              break;
            default:
              grant = 'view' in permissions && permissions.view;
              break;
          }
        }
        if (next.data.code === SecurityKeys.topMenu.payrollDashboard) {
          return true;
        }

        if (grant !== undefined && grant === false) {
          if (next.data.code === SecurityKeys.topMenu.payrollDashboard) {
            return false;
          }
          return this.accessControlService.routeToAccessDenied();
        }
        return true;
      }
      return true;
    }
    return true;
  }
}
