import {Injectable} from '@angular/core';
import {Modal} from 'bootstrap';
import {v4 as uuidv4} from 'uuid';
import {Subject} from 'rxjs';

export interface BsCustomModal {
  modal: Modal;
  target: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class BsCustomModalService {
  private modals: BsCustomModal[] = [];
  modalClosed$ = new Subject<string>();

  constructor() {
  }

  open(target: string) {
    const element = document.getElementById(target);
    const bs: BsCustomModal = {
      modal: new Modal(element),
      id: uuidv4(),
      target
    };
    bs.modal.show();
    element.addEventListener('hidden.bs.modal', () => {
      this.removeModal(target);
      element.removeEventListener('hidden.bs.modal', () => {
      });
    }, {once: true});
    this.modals.push(bs);

    return bs;
  }

  close(id: string) {
    const modalIndex = this.modals.findIndex(m => m.id === id);
    if (modalIndex > -1) {
      this.modals[modalIndex].modal.hide();
      this.modals.splice(modalIndex, 1);
      this.modalClosed$.next(id);
    }
  }

  closeByTarget(target: string) {
    const modalIndex = this.modals.findIndex(m => m.target === target);
    if (modalIndex > -1) {
      this.modals[modalIndex].modal.hide();
      this.modals.splice(modalIndex, 1);
      this.modalClosed$.next(target);
    }
  }

  private removeModal(target: string) {
    const modalIndex = this.modals.findIndex(m => m.target === target);
    if (modalIndex > -1) {
      this.modals.splice(modalIndex, 1);
      this.modalClosed$.next(target);
    }
  }
}
