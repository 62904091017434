import {HttpClient} from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch'
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
//import { Http, Response } from '@angular/common/http'
import {
  ICatDtlCal,
  ICatDtlCalDel,
  ICopyTaxBoxAssignment,
  ICppInfoGet,
  ICppInfoWrite,
  IFormulaBase,
  IFormulaList,
  IPayGrpList,
  IPayrollCategory,
  IPayrollCategoryAddSubtos,
  IPayrollCategoryDetail,
  IPayrollcategoryEmpWiseList,
  IPayrollCategoryLIst,
  IPayrollCategoryLIst2,
  IPayrollCategoryList2,
  IPayrollCategoryListEmpWiseGet,
  IPayrollCategoryListForDelete,
  IPayrollCategoryLIstModified,
  IPayrollCategoryLIstModified2,
  IPayrollCategoryTaxConBase,
  IPayrollCategoryUpdate,
  IPayrollCatgryDetail,
  IPcatGrpAdd,
  IPcatGrpList,
  IPcatUnderProcGet,
  IProcessOrder,
  IProvinceList,
  ITaxFormBoxList,
  ITaxFormBoxSaveList,
  ITaxFormSubBoxList,
  IUdfForEditGet,
  IUpdateEmpHours
} from 'src/app/shared/models/ipayroll-category';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class PayrollCategoryService implements OnInit {
  private _url: string = API_END_POINT.payroll.payrollCategory + 'GetPayrollCategoryType';
  private prtSelection = new BehaviorSubject<number>(null);
  curPrtSelection = this.prtSelection.asObservable();
  private cateTypesSelected = new BehaviorSubject<any>([]);
  cate = this.cateTypesSelected.asObservable();
  private payrollCatList = new BehaviorSubject<IPayrollCategoryLIstModified[]>([]);
  parentList = this.payrollCatList.asObservable();

  assignCategoryFormList$ = new BehaviorSubject<IPayrollCategoryLIstModified2[]>([]);

  constructor(private http: HttpClient, private router: Router) {
  }

  resetData() {
    this.cateTypesSelected.next(null);
    this.prtSelection.next(null);
  }

  ngOnInit() {

  }

  cateChanged(c) {
    this.cateTypesSelected.next(c);
  }

  parentListChanged(l) {
    this.payrollCatList.next(l);
  }

  getPayrollCategory(): Observable<IPayrollCategory[]> {
    return this.http.get<IPayrollCategory[]>(this._url);
  }

  getPayrollCategoryType(): Observable<IPayrollCategory[]> {
    return this.http.get<IPayrollCategory[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryType")
    //.map((response: Response) => <IPayrollCategory[]>response.json())
  }

  //getPayrollCategoryTypeByCode(typeCode: number): Observable<IPayrollCategory> {
  //  return this.http.get<IPayrollCategory>("../api/PayrollCategory/GetPayrollCategoryType" /*+ typeCode*/)
  //    //.map((response: Response) => <IPayrollCategory>response.json())
  //}

  getDefaultCategorytList(expara: number): Observable<IPayrollCategoryLIst[]> {
    return this.http.get<IPayrollCategoryLIst[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryList?ep=" + expara);
  }

  GetAllCategoryList(gc: number): Observable<any[]> {
    return this.http.get<any[]>(API_END_POINT.payroll.payrollCategory + "GetAllCategoryList?gc=" + gc);
  }


  getPayrollCategorytListForTaxFormBoxes(): Observable<IPayrollCategoryLIst2[]> {
    return this.http.get<IPayrollCategoryLIst2[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryListForTaxFormBoxes");
  }

  updateActiveInactivePayCategory(catDtlCode: number, status: boolean): Observable<any> {

    //let modelIns: IActiveInactiveStatus;
    //modelIns.dtlCode = catDtlCode;

    let lStatus: number;
    if (status == true) {
      lStatus = 1;
    }
    else
      lStatus = 0;
    //modelIns.dtlCode = catDtlCode;
    //modelIns.dtlStatus = lStatus;

    var patCatData = { 'dtlCode': catDtlCode, 'dtlStatus': lStatus };

    return this.http.post<void>(API_END_POINT.payroll.payrollCategory + "UpdateActiveInactivePayCategory", patCatData);
  }

  getPayrollCategoryTypeByCode(typeCode: number): Observable<IPayrollCategory> {
    return this.http.get<IPayrollCategory>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryType" /*+ typeCode*/)
    //.map((response: Response) => <IPayrollCategory>response.json())
  }

  //addPayrollCategoryDetail(cdtl: IPayrollCategoryDetail): Observable<void> {

  //  var detail = { 'dtlcde': cdtl.dtlcde, 'typcde': cdtl.typcde, 'usrcde': cdtl.usrcde, 'isact': cdtl.isact, 'isdef': cdtl.isdef, 'isunit': cdtl.isunit };

  //  return this.http.post<void>(API_END_POINT.payroll.payrollCategory + "AddPayrollCategoryDetail", detail);
  //}

  getPayrollCategorySubjectToList(): Observable<any> {
    return this.http.get<any>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoriesForPayrollCategory");
  }

  getPayrollCategoryTaxBase(): Observable<IPayrollCategoryTaxConBase[]> {
    return this.http.get<IPayrollCategoryTaxConBase[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryTaxBase");
  }

  getPayrollCategoryConBase(): Observable<IPayrollCategoryTaxConBase[]> {
    return this.http.get<IPayrollCategoryTaxConBase[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryConBase");
  }

  AssignNewPayrollCategoryToEmployees(cat: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollCategory + "AssignNewPayrollCategoryToEmployees?cat=" + cat);
  }

  addNewPayrollCategory(a: IPayrollCategoryUpdate): Observable<number> {
    var obj = {
      'dc': a.dc, 'tc': a.tc, 'uc': a.uc, 'od': a.od, 'ia': a.ia, 'id': a.id, 'iu': a.iu, 'atn': a.atn, 'ea': a.ea, 'ya': a.ya, 'pf': a.pf, 'pm': a.pm, 'pv': a.pv, 'cr': a.cr,
      'db': a.db, 'df': a.df, 'dpo': a.dpo, 'fc': a.fc, 'f': a.f, 'psf': a.psf, 'psfer': a.psfer, 'minf': a.minf, 'minm': a.minm, 'minea': a.minea, 'minya': a.minya, 'maxf': a.maxf, 'maxm': a.maxm, 'maxea': a.maxea,
      'maxya': a.maxya, 'rof': a.rof, 'dn': a.dn, 'dpa': a.dpa, 'basedon': a.basedon, 'subto': a.subto, 'ed': a.ed, 'fdcs': a.fdcs, 'pb': a.pb, 'urt': a.urt, 'ini': a.ini, 'ttc': a.ttc, 'tpf': a.tpf, 'grpcode': a.grpcode, 'grpto': a.grpto,
      'empgrpcode': a.empgrpcode, 'eyrgrpcode': a.eyrgrpcode, 'paf': a.paf, 'yaf': a.yaf, 'comm': a.comm, 'ex': a.ex, 'rye': a.rye, 'pre_fc': a.pre_fc, 'empFlag': a.empFlag, 'eyrFlag': a.eyrFlag, 'txt': a.txt, 'txt_prov': a.txt_prov,
      'minew': a.minew, 'maxew': a.maxew, 'ft': a.ft, 'untext': a.unttxt, 'kind': a.kind, 'cdpc': a.cdpc, 'premium': a.premium, 'dif_freq': a.dif_freq, 'dif_freq_begin': a.dif_freq_begin,
      'cal_for_emp_prov': a.cal_for_emp_prov, 'daysPrior': a.daysPrior, 'ruleBased': a.ruleBased, 'rstOpnAmt': a.rstOpnAmt,'script':a.script
    };
    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "AddNewPayrollCategory", obj);
  }

  //addPayrollCategorySubtos(o: IPayrollCategoryAddSubtos): Observable<IPayrollCategoryAddSubtos> {
  //  var obj = {
  //    's': o.s, 'b': o.b, 'p': o.p
  //  };
  //  return this.http.post<IPayrollCategoryAddSubtos>(API_END_POINT.payroll.payrollCategory + "AddPayrollCategorySubtos", obj);
  //}

  copyTaxBoxAssignment(o: ICopyTaxBoxAssignment): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "CopyTaxBoxAssignment", o);
  }

  addPcatGrp(o: IPcatGrpAdd): Observable<IPcatGrpAdd> {
    var obj = {
      'gc': o.gc, 'uc': o.uc, 'pgc': o.pgc, 'gn': o.gn
    };
    return this.http.post<IPcatGrpAdd>(API_END_POINT.payroll.payrollCategory + "AddPcatGrp", obj);
  }

  getPcatPrntGrp(): Observable<IPcatGrpList[]> {
    return this.http.get<IPcatGrpList[]>(API_END_POINT.payroll.payrollCategory + "GetPcatPrntGrp");
  }

  changePrtSelection(n: number) {
    this.prtSelection.next(n);
  }

  addCatDtlCal(o: ICatDtlCal): Observable<ICatDtlCal> {
    var obj = {
      'id': o.id, 'dc': o.dc, 'fv': o.fv, 'tv': o.tv, 'ea': o.ea, 'ya': o.ya, 'ep': o.ep, 'yp': o.yp, 'el': o.el, 'yl': o.yl, 'ttc': o.ttc, 'tpf': o.tpf
    };
    return this.http.post<ICatDtlCal>(API_END_POINT.payroll.payrollCategory + "AddCatDtlCal", obj);
  }

  addCatDtlCals(o: ICatDtlCal[]): Observable<ICatDtlCal> {
    return this.http.post<ICatDtlCal>(API_END_POINT.payroll.payrollCategory + "AddCatDtlCals", o);
  }

  getCatDtlCal(): Observable<ICatDtlCal[]> {
    return this.http.get<ICatDtlCal[]>(API_END_POINT.payroll.payrollCategory + "GetCatDtlCal");
  }

  delCatDtlCal(o: ICatDtlCalDel): Observable<ICatDtlCalDel> {
    var obj = {
      'id': o.id, 'dc': o.dc
    };
    return this.http.post<ICatDtlCal>(API_END_POINT.payroll.payrollCategory + "DelCatDtlCal", obj)
  }

  editCatDtlCal(o: ICatDtlCal): Observable<ICatDtlCal> {
    var obj = {
      'id': o.id, 'dc': o.dc, 'fv': o.fv, 'tv': o.tv, 'ea': o.ea, 'ya': o.ya, 'ep': o.ep, 'yp': o.yp, 'el': o.el, 'yl': o.yl, 'ttc': o.ttc
    };
    return this.http.post<ICatDtlCal>(API_END_POINT.payroll.payrollCategory + "EditCatDtlCal", obj);
  }

  getCustomFormula(): Observable<IFormulaList[]> {
    return this.http.get<IFormulaList[]>(API_END_POINT.payroll.payrollCategory + "GetCustomFormula");
  }

  getPayrollCategoryList2(ex: number): Observable<IPayrollCategoryList2[]> {
    return this.http.get<IPayrollCategoryList2[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryList2?ex=" + ex);
  }

  getFormulaBase(): Observable<IFormulaBase[]> {
    return this.http.get<IFormulaBase[]>(API_END_POINT.payroll.payrollCategory + "GetFormulaBase");
  }

  delPayrollCategory(dc: number): Observable<number> {
    var obj = { 'dc': dc };
    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "DelPayrollCategory", obj);
  }

  deletePayrollCategories(pc: number[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "DeletePayrollCategories", pc);
  }

  deactivePayCategory(pc: number[]): Observable<number> {
    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "DeactivePayCategory", pc);
  }

  getPayrollCategoryEmpWise(ex: number): Observable<IPayrollcategoryEmpWiseList[]> {
    return this.http.get<IPayrollcategoryEmpWiseList[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryEmpWise?ex=" + ex);
  }

  getPaycatEligilityRecords(cat_dtl_code: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollCategory + "GetPayCatEligibilityRecords?cat_dtl_cde=" + cat_dtl_code);
  }

  getPayGrp(): Observable<IPayGrpList[]> {
    return this.http.get<IPayGrpList[]>(API_END_POINT.payroll.payrollCategory + "GetPayGrp")
  }

  delPcatGrp(gc: number): Observable<number> {
    var obj = { 'gc': gc };
    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "delPcatGrp", obj);
  }

  getPcatUnderProc(): Observable<IPcatUnderProcGet[]> {
    return this.http.get<IPcatUnderProcGet[]>(API_END_POINT.payroll.payrollCategory + "getPcatUnderProc");
  }

  getPayrollCategoryListEmpWise(): Observable<IPayrollCategoryListEmpWiseGet[]> {
    return this.http.get<IPayrollCategoryListEmpWiseGet[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryListEmpWise");
  }

  getCppInfo(): Observable<ICppInfoGet[]> {
    return this.http.get<ICppInfoGet[]>(API_END_POINT.payroll.payrollCategory + "GetCppInfo");
  }

  writeCppInfoEyr(o: ICppInfoWrite[]): Observable<any> {
    //var obj = [
    //  { 'gc': null, 'fc': null, 'empid': -1, 'fv': null, 'dt': null }
    //];
    //for (let i = 0; i < o.length; i++) {
    //  obj[i].gc = o[i].gc;
    //  obj[i].fc = o[i].fc;
    //  obj[i].empid = o[i].empid;
    //  obj[i].fv = o[i].fv;
    //  obj[i].dt = o[i].dt;
    //}
    return this.http.post<ICppInfoWrite[]>(API_END_POINT.payroll.payrollCategory + 'writeCppInfoEyr', o);
  }

  getUdfForEdit(gc: number, fc: number, dt: number): Observable<any> {
    return this.http.get<IUdfForEditGet[]>(API_END_POINT.payroll.payrollCategory + 'getUdfForEdit?gc=' + gc + '&fc=' + fc + '&dt=' + dt);
  }

  // public payrollCatListForTaxFormBoxes = new BehaviorSubject<IPayrollCategoryLIstModified2[]>(null);
  // currentpayrollCatListForTaxFormBoxes = this.payrollCatListForTaxFormBoxes.asObservable();


  getTaxformBoxList(ernedcode: number, catdtlcd: number, eyrLevel: boolean): Observable<ITaxFormBoxList[]> {
    return this.http.get<ITaxFormBoxList[]>(API_END_POINT.payroll.payrollCategory + 'GetTaxformBoxList?ernedCode=' + ernedcode + '&catdtlcd=' + catdtlcd + '&eyeLevel=' + eyrLevel);
  }

  getSubBoxpickListValues(): Observable<ITaxFormSubBoxList[]> {
    return this.http.get<ITaxFormSubBoxList[]>(API_END_POINT.payroll.payrollCategory + 'GetSubBoxpickListValues');
  }

  getSubBoxpickListValuesT4(form_name: string): Observable<ITaxFormSubBoxList[]> {
    return this.http.get<ITaxFormSubBoxList[]>(API_END_POINT.payroll.payrollCategory + "GetSubBoxpickListValuesT4?name=" + form_name);
  }

  updateTaxFormAssignmnet(lst: ITaxFormBoxSaveList[]): Observable<any> {
    return this.http.post<ITaxFormBoxSaveList[]>(API_END_POINT.payroll.payrollCategory + "UpdateTaxformAssignment", lst)
  }

  updateEmployeeHours(ob: IUpdateEmpHours): Observable<number> {

    return this.http.post<number>(API_END_POINT.payroll.payrollCategory + "UpdateEmployeeHours", ob)
  }
  //returnValuesfromMultipleService(): Observable<any[]> {
  //  let res1 = this.currentpayrollCatListForTaxFormBoxes;
  //  let res2 = this.getSubBoxpickListValues();

  //  return forkJoin([res1, res2]);
  //}

  CheckUnlockedPaycards(): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollCategory + "CheckUnlockedPaycards");
  }

  DeleteUnlockedPaycards(): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollCategory + "DeleteUnlockedPaycards");
  }

  DeleteUnlockedPaycardByEmpId(empId: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollCategory + "DeleteUnlockedPaycardByEmpId?empId=" + empId);
  }

  getProvincesForPayrollCategory(): Observable<IProvinceList[]> {
    return this.http.get<IProvinceList[]>(API_END_POINT.payroll.payrollCategory + "GetprovinceForPayrollCategory");
  }

  GetWCBMaxAssessableCAN(province: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.payrollCategory + "GetWCBMaxAssessableCAN?province=" + province);
  }

  getPayrollCategoryForLocalTax(): Observable<IPayrollCatgryDetail[]> {
    return this.http.get<IPayrollCatgryDetail[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryForLocalTax");
  }

  getPayrollCategoryForUSWCB(): Observable<IPayrollCatgryDetail[]> {
    return this.http.get<IPayrollCatgryDetail[]>(API_END_POINT.payroll.payrollCategory + "GetPayrollCategoryForUSWCB");
  }

  getOrderOfPayrollCategoryProcessing(): Observable<IProcessOrder[]> {
    return this.http.get<IProcessOrder[]>(API_END_POINT.payroll.payrollCategory + "GetOrderOfPayrollCategoryProcessing");
  }

  getDisabledCategoryList(): Observable<number[]> {
    return this.http.get<number[]>(API_END_POINT.payroll.payrollCategory + "GetDisabledCategoryList");
  }

  validatePayrollCategoryScript(script: string): Observable<string> {
      var obj = { 'script': script };
      return this.http.post<string>(API_END_POINT.payroll.payrollCategory + "ValidatePayrollCategoryScript", obj);
  }



}


