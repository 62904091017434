import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {
  transform(values: any[], args?: any): any {
    if (args === undefined || args === null || args === '') {
      return values;
    }
    //return value.filter(item => {
    //  for (let key in item) {
    //    if ((typeof item[key] === 'string' || item[key] instanceof String) && (item[key] === args)/*(item[key.indexOf[args[0]]] !== -1)*/) {
    //      return true;
    //    }
    //  }

    //  });
    return values.filter(value => {
      let temp: string = args.replace("-", "~");
      if (value.l !== null && value.l.indexOf(temp) !== -1) {
        return true;
      }
    });

  }
}
