import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {ICompany, IUpdateCompany} from 'src/app/shared/models/icompany';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import {CoreSysPreferenceService} from '../../services/setup/core-sys-preference.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _coreSysPrefService: CoreSysPreferenceService, private http: HttpClient) { }


  GetDataFromMultipleServices(): Observable<any> {
    let response1 = this._coreSysPrefService.getcountries();
    let response2 = this._coreSysPrefService.getProvinces();
    return forkJoin([response1, response2]);
  }

  GetCompanyDetails(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(API_END_POINT.core.company + "GetCompanyDetails");
  }

  UpdateCompanyDetails(ob: IUpdateCompany): Observable<number> {
    return this.http.post<number>(API_END_POINT.core.company + "UpdateCompanyDetails", ob);
  }
  CheckMexico(): Observable<any> {
    return this.http.get<number>(API_END_POINT.core.company + 'CheckMexico')
  }
}
