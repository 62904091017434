import {DatePipe} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {GlService} from '@harmony-modules/payroll/services/payroll-setup/gl.service';
import {LaborService} from '@harmony-modules/payroll/services/payroll-setup/labor.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {IDepartmentGLAccount, IDimensionArry, IDimensionForGlSetup, ILaborSetupGL, ILocationListGlSetup} from '@shared/models/igl';
import {
  ICertifiedPayroll,
  IEmployeeLabor,
  ILaborData,
  ILaborOutput,
  ILaborOutputList,
  IlaborOutputWithSession,
  IPaycatEligility,
  IPayCatListLabor,
  IPayrollGroupLabor,
  IPayScheduleForAdjstmntCP,
  ITimesheetDataLabor,
  ITimesheetDataLaborSave
} from '@shared/models/ilabor';
import {IPayrollProcessPeriod} from '@shared/models/ipayroll-group';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {forkJoin, Subscription} from 'rxjs';
import {concatMap, take, tap} from 'rxjs/operators';

declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-labor',
  templateUrl: './labor.component.html',
  styleUrls: ['./labor.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'laborWizard', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class LaborComponent implements OnInit {

  laborSetupList: ILaborSetupGL[] = [];
  dimensionList: IDimensionForGlSetup[] = [];
  dimensionList1: IDimensionForGlSetup[] = [];
  dimensionList2: IDimensionForGlSetup[] = [];
  dimensionList3: IDimensionForGlSetup[] = [];
  dimensionList4: IDimensionForGlSetup[] = [];
  dimensionList5: IDimensionForGlSetup[] = [];
  dimensionList6: IDimensionForGlSetup[] = [];
  array_of_dimension: IDimensionArry[] = [];
  locationList: ILocationListGlSetup[] = [];
  departmentList: IDepartmentGLAccount[] = [];
  payrollGroups: IPayrollGroupLabor[] = [];
  payrollCategories: IPayCatListLabor[] = [];
  payrollCategories_copy: IPayCatListLabor[] = [];
  employees: IEmployeeLabor[] = [];
  employees_copy: IEmployeeLabor[] = [];
  grid_data: ITimesheetDataLabor[] = [];
  paycat_eligibility_list: IPaycatEligility[] = [];
  prlg_no: number;

  payrollProcessPeriod: IPayrollProcessPeriod;
  payfrequency: string = '';
  processPeriod: string = '';
  wizardMode: boolean = false;
  sysPref: any;
  laborForm: FormGroup;
  is_working_date: boolean = false;
  p: number = 1;
  cssClasses: string[] = [];
  colorClass: string;
  loading: boolean = true;
  no_payrollGroup: boolean = true;
  status_label: string = '';
  formDirty: boolean = false;
  status_msg: string = 'Editing in progress. Please save frequently.';
  items_per_page: number = 10;
  timesheetData: ITimesheetDataLaborSave[] = [];
  dataToSave: ILaborData;
  output_list: ILaborOutput[] = [];
  show_output: boolean = false;
  success: number = 0;
  errs: number = 0;
  warnings: number = 0;
  messages: number = 0;
  sess_id: string = '0';
  output: ILaborOutputList[] = [];
  process_in_progress: boolean = false;
  sortOrder: string = 'Number';
  sortdirec: boolean = false;
  payschedule_for_all_years: IPayScheduleForAdjstmntCP[] = [];
  certified_pay_pref: ICertifiedPayroll;
  project_dim_name: string;
  wrk_clss_dim_name: string;

  private _subscription: Subscription;
  private _formSubscription: Subscription;
  private _deleteSubscription: Subscription;
  //@HostListener('window:beforeunload', ['$event'])
  //onBeforeUnloadHander(event) {
  //  console.log('******----->>>>>');
  //  this.router.navigate(['Wizard']);
  //}

  constructor(
    private _fb: FormBuilder,
    private _laborService: LaborService,
    private _glService: GlService,
    private _wizardService: WizardService,
    private _sysPrefService: SystemPreferenceService,
    private _payrollService: PayrollGroupService,
    private _datePipe: DatePipe,
    private router: Router,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService
  ) {
  }

  ngOnInit() {

    this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
      this.status_msg = this.transloco.translate('statusMessage', {}, 'laborWizard');
    });

    this.laborForm = this._fb.group({
      payroll_group: [''],
      filtered_employee: [null],
      grid: this._fb.array([])
    });
    this.getInitialData();
  }

  get payroll_group() {
    return this.laborForm.get('payroll_group');
  }

  get filtered_employee() {
    return this.laborForm.get('filtered_employee');
  }

  get grid(): FormArray {
    return this.laborForm.get('grid') as FormArray;
  }

  newGridRow(): FormGroup {
    return this._fb.group({
      id: [''],
      status: [''],
      seq_no: [''],
      employee: ['', Validators.required],
      date: ['', Validators.required],
      category: ['', Validators.required],
      units: [''],
      rate: [''],
      amount: [''],
      union: [''],
      dim1: [''],
      dim2: [''],
      dim3: [''],
      dim4: [''],
      dim5: [''],
      dim6: [''],
      dep: [''],
      loc: [''],
      txt1: [''],
      txt2: [''],
      txt3: [''],
      txt4: [''],
      id_union_agg: [''],
      overwrite: [''],
      grid_id: ['']
    });
  }

  getInitialData(): void {
    forkJoin(
      this._wizardService.wizardMode$.pipe(take(1), untilDestroyed(this)),
      this._wizardService.currentGroupInfo$.pipe(take(1), untilDestroyed(this)),
      this._sysPrefService.getinitSystemPreferences().pipe(tap(sysp => {
        this.sysPref = sysp[0];
        this.is_working_date = this.sysPref.LABOR_DATE_FLG && this.sysPref.LABOR_DATE_FLG == 1 ? true : false;
      })),
      this._laborService.GetLaborSetup().pipe(tap(lbr => {
        this.laborSetupList = lbr;
      })),
      this._payrollService.GetDimensionsForGlSetup().pipe(tap(dim => {
        this.dimensionList = dim;
        this.dimensionList1 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 1);
        this.dimensionList2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2);
        this.dimensionList3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3);
        this.dimensionList4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4);
        this.dimensionList5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5);
        this.dimensionList6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6);
      })),
      this._payrollService.getDepartmentForGLAccounts().pipe(tap(dep => {
        this.departmentList = dep;
      })),
      this._payrollService.GetLocationListForGlSetupForLabor().pipe(tap(loc => {
        this.locationList = loc;
      })),
      this._payrollService.GetPayrollGroupsForLabor().pipe(tap(pgrps => {
        this.payrollGroups = pgrps;
      })),
      this._payrollService.GetEmployeesForLabor().pipe(tap(emps => {
        this.employees = emps;
      })),
      this._payrollService.GetPayrollCategoriesForLabor().pipe(tap(cats => {
        this.payrollCategories = cats;
        this.payrollCategories_copy = [...this.payrollCategories];
      }))
    ).pipe(untilDestroyed(this)).subscribe(([mode, info, sysp, lbr, dim, dep, loc, pgrps, emps, cats]) => {
      if (mode) {
        this.wizardMode = mode;
      }
      if (info) {
        this.prlg_no = info.gc;
      }
      if (this.wizardMode && this.prlg_no) {
        this.payroll_group.setValue(this.prlg_no);
        this.payroll_group.disable();
        this.no_payrollGroup = false;
        this.onDropDownChange(this.prlg_no);
      } else {
        if (this.payrollGroups.length > 0) {
          this.payroll_group.setValue(this.payrollGroups[0].PRLG_CODE);
          this.onDropDownChange(this.payrollGroups[0].PRLG_CODE);
          this.no_payrollGroup = false;
        } else {
          this.loading = false;
          this.payroll_group.disable();
        }
      }
    });
  }

  getTimesheetGridData(prgp: number, sess_id: string): void {
    this.sortOrder = 'Number';
    this.sortdirec = false;
    this._subscription = this._payrollService.GetTimesheetDataForLabor(prgp, sess_id, this.payrollProcessPeriod.b, this.payrollProcessPeriod.c, this.payrollProcessPeriod.e, this.payrollProcessPeriod.f).pipe(untilDestroyed(this)).subscribe(data => {
      if (data?.length) {
        data = data?.map(value => ({...value, PROC_OUT_MSG: this.transloco.translate(value.PROC_OUT_MSG)}));
      }
      this.grid_data = data; //????   data is ITimesheetDataLabor[] and ITimesheetDataLabor.PROC_OUT_MSG is key fortranslation ????
      if (data) {
        this.setGridData(this.grid_data);
      }
    });
  }

  onDropDownChange(prgp: number): void {
    this.show_output = false;
    this.output.length = 0;
    this.filtered_employee.setValue(null);
    this.employees_copy = this.employees.filter(x => x.PRLG_CODE == prgp);
    this.payrollCategories_copy = this.payrollCategories.filter(x => x.PRLG_CODE == prgp);
    this.loading = true;
    this.sortOrder = 'Number';
    this.sortdirec = false;
    this._payrollService.getPayrollProcessPeriod(prgp)
      .pipe(
        concatMap(data => {
          this.payrollProcessPeriod = data[0];
          if (this.payrollProcessPeriod) {
            this.payfrequency = this.payrollProcessPeriod.d;
            let x = this.fromJsonDate(this.payrollProcessPeriod.b);
            let y = this.fromJsonDate(this.payrollProcessPeriod.c);
            this.processPeriod = x + ' - ' + y;
            this.prlg_no = prgp;
          }
          if (this.sysPref.ct == 1) {
            return this._laborService.GetPayScheduleForCpExport(this.payroll_group.value)
              .pipe(
                concatMap(pp => {
                  this.payschedule_for_all_years = pp;
                  return this._laborService.GetCertifiedPayrollData()
                    .pipe(
                      concatMap(cpdata => {
                        this.certified_pay_pref = cpdata;
                        return this._payrollService.GetTimesheetDataForLabor(prgp, this.sess_id, this.payrollProcessPeriod.b, this.payrollProcessPeriod.c, this.payrollProcessPeriod.e, this.payrollProcessPeriod.f);
                      })
                    );
                })
              );
          } else {
            return this._payrollService.GetTimesheetDataForLabor(prgp, this.sess_id, this.payrollProcessPeriod.b, this.payrollProcessPeriod.c, this.payrollProcessPeriod.e, this.payrollProcessPeriod.f);
          }
        }),
        untilDestroyed(this)).subscribe(grid_data => {
      if (grid_data?.length) {
        grid_data = grid_data?.map(value => ({...value, PROC_OUT_MSG: this.transloco.translate(value.PROC_OUT_MSG)}));
      }
      this.grid_data = grid_data;  // ????   grid_data is ITimesheetDataLabor[] and ITimesheetDataLabor.PROC_OUT_MSG is key fortranslation ????
      this.setGridData(grid_data);
    });
  }

  /******
   *
   * status => PROC_OUT_TYPE or -2 or -1
   * status => -2 => row is edited
   * status => -1 => row is deleted
   *
   * *****/
  setGridData(data: ITimesheetDataLabor[]): void {
    //console.log(data);
    this.loading = true;
    this.clearFormArray(this.grid);
    this.array_of_dimension.length = 0;
    for (let i = 0; i < data.length; i++) {
      this.grid.push(this.newGridRow());
      this.grid.controls[i].setValue({
        id: data[i].ID_TIMESHEET,
        status: data[i].PROC_OUT_TYPE,
        seq_no: data[i].PROC_SEQ_NO,
        employee: data[i].ID_EMPLOYEE,
        date: data[i].WORKING_DATE ? this.formatDate(data[i].WORKING_DATE) : this.getTodayDate(),
        category: data[i].CAT_DTL_CODE,
        units: data[i].PROC_UNITS ? data[i].PROC_UNITS.toFixed(this.sysPref.h || 2) : Number(0).toFixed(this.sysPref.i || 2),
        rate: data[i].PROC_RATE ? data[i].PROC_RATE.toFixed(this.sysPref.g || 2) : Number(0).toFixed(this.sysPref.i || 2),
        amount: data[i].PROC_AMOUNT ? data[i].PROC_AMOUNT.toFixed(this.sysPref.i || 2) : Number(0).toFixed(this.sysPref.i || 2),
        union: data[i].ID_UNION_AGREEMENT,
        dim1: data[i].ID_DIMENSION1 && this.laborSetupList[0].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DIMENSION1 : null,
        dim2: data[i].ID_DIMENSION2 && this.laborSetupList[1].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DIMENSION2 : null,
        dim3: data[i].ID_DIMENSION3 && this.laborSetupList[2].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DIMENSION3 : null,
        dim4: data[i].ID_DIMENSION4 && this.laborSetupList[3].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DIMENSION4 : null,
        dim5: data[i].ID_DIMENSION5 && this.laborSetupList[4].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DIMENSION5 : null,
        dim6: data[i].ID_DIMENSION6 && this.laborSetupList[5].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DIMENSION6 : null,
        dep: data[i].ID_DEPARTMENT && this.laborSetupList[6].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_DEPARTMENT : null,
        loc: data[i].ID_LOCATION && this.laborSetupList[7].LABOR_DIM_IS_ACTIVE_FLG == 1 ? data[i].ID_LOCATION : null,
        txt1: data[i].TEXT1,
        txt2: data[i].TEXT2,
        txt3: data[i].TEXT3,
        txt4: data[i].TEXT4,
        id_union_agg: data[i].ID_UNION_AGREEMENT,
        overwrite: data[i].OVERWRITE,
        grid_id: i + 1
      });
      let temp_dim_arry: IDimensionArry = {
        list1: this.dimensionList1,
        list2: this.dimensionList2,
        list3: this.dimensionList3,
        list4: this.dimensionList4,
        list5: this.dimensionList5,
        list6: this.dimensionList6,
        dim_array_index: i + 1
      };
      this.array_of_dimension.push(temp_dim_arry);
      if (data[i].PROC_SEQ_NO != 0) {
        this.grid.controls[i].disable();
      } else {
        this.grid.controls[i].get('rate').disable();
        this.grid.controls[i].get('amount').disable();
        let dimension: IDimensionForGlSetup = data[i].ID_DIMENSION1 && this.laborSetupList[0].LABOR_DIM_IS_ACTIVE_FLG == 1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION1) : null;
        let lbr_dim_id: number = dimension ? dimension.LABOR_DIM_ID : null;
        if (lbr_dim_id) {
          this.filterDimensions(dimension, data[i].ID_DIMENSION1, lbr_dim_id, i, 1);
        }
        dimension = data[i].ID_DIMENSION2 && this.laborSetupList[1].LABOR_DIM_IS_ACTIVE_FLG == 1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION2) : null;
        lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
        if (lbr_dim_id) {
          this.filterDimensions(dimension, data[i].ID_DIMENSION2, lbr_dim_id, i, 1);
        }
        dimension = data[i].ID_DIMENSION3 && this.laborSetupList[2].LABOR_DIM_IS_ACTIVE_FLG == 1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION3) : null;
        lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
        if (lbr_dim_id) {
          this.filterDimensions(dimension, data[i].ID_DIMENSION3, lbr_dim_id, i, 1);
        }
        dimension = data[i].ID_DIMENSION4 && this.laborSetupList[3].LABOR_DIM_IS_ACTIVE_FLG == 1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION4) : null;
        lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
        if (lbr_dim_id) {
          this.filterDimensions(dimension, data[i].ID_DIMENSION4, lbr_dim_id, i, 1);
        }
        dimension = data[i].ID_DIMENSION5 && this.laborSetupList[4].LABOR_DIM_IS_ACTIVE_FLG == 1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION5) : null;
        lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
        if (lbr_dim_id) {
          this.filterDimensions(dimension, data[i].ID_DIMENSION5, lbr_dim_id, i, 1);
        }
        dimension = data[i].ID_DIMENSION6 && this.laborSetupList[5].LABOR_DIM_IS_ACTIVE_FLG == 1 ? this.dimensionList.find(x => x.ID_DIMENSION == data[i].ID_DIMENSION6) : null;
        lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
        if (lbr_dim_id) {
          this.filterDimensions(dimension, data[i].ID_DIMENSION6, lbr_dim_id, i, 1);
        }
        if (this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[i].get('category').value) && this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[i].get('category').value).CAT_IS_UNIT_FLG == 1) {
          this.grid.controls[i].get('units').enable();
        } else {
          this.grid.controls[i].get('units').disable();
        }
      }
      if (data[i].PROC_SEQ_NO == 0 && data[i].OVERWRITE) {
        let is_cat_unit_based = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[i].get('category').value).CAT_IS_UNIT_FLG;
        if (is_cat_unit_based && is_cat_unit_based == 1) {
          this.grid.controls[i].get('rate').enable();
          this.grid.controls[i].get('amount').disable();
        } else {
          this.grid.controls[i].get('rate').disable();
          this.grid.controls[i].get('amount').enable();
        }
      }
    }
    this.loading = false;
    this.process_in_progress = false;
    this.status_label = '';
    this.p = 1;
    if (!this.wizardMode) {
      this.payroll_group.enable();
    }
    this.filtered_employee.enable();
  }

  clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  onEmployeeFilterChange(event: any): void {
    if (event && this.grid_data.length > 0) {
      this.setGridData(this.grid_data.filter(x => x.ID_EMPLOYEE == event.ID));
      this.grid.controls.forEach(function(x) {
        x.get('employee').disable();
      });
    } else {
      this.setGridData(this.grid_data);
      this.grid.controls.forEach(function(x) {
        x.get('employee').enable();
      });
    }
    //this.grid.controls[0].get('overwrite').enable();
  }

  /*..format dates..*/
  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate.substring(0, 10));
    return this._datePipe.transform(bDate.toISOString().substring(0, 10), this.sysPref && this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd');
  }

  formatDate(jDate: any): string {
    let dateStr = (new Date(jDate.substring(0, 10))).toISOString().substring(0, 10);
    return dateStr;
  }

  getTodayDate(): string {
    let today = new Date();
    let dd = today.getDate().toString();
    let mm = (today.getMonth() + 1).toString();
    let yyyy = today.getFullYear().toString();
    if (+dd < 10) {
      dd = '0' + dd;
    }
    if (+mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
  }

  /*..format dates end..*/

  getClassesForIcon(proc_status: number) {
    this.cssClasses.length = 0;
    this.colorClass = '';
    switch (proc_status) {
      case 1: {
        this.colorClass = 'tick-icon-green';
        break;
      }
      case 2: {
        this.colorClass = 'tick-icon-orange';
        break;
      }
      case 3: {
        this.colorClass = 'tick-icon-red';
        break;
      }
      default: {
        this.colorClass = 'no_icon';
        break;
      }
    }
    this.cssClasses.push(this.colorClass);
    return this.cssClasses;
  }

  onNewClick() {
    if (this.items_per_page == this.getMaximumNewRecords()) { //this.isFormDirty() && (
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.pleaseSaveBeforeAdding', {}, 'laborWizard');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    } else {
      this.sortOrder = 'Number';
      this.sortdirec = false;
      let _index;
      let new_index;
      //console.log('grid length==>>');
      //console.log(this.grid.controls.length);
      if (this.grid.controls.length > 0) {
        _index = Math.max.apply(Math, this.grid.controls.map(function(o) {
          return o.get('grid_id').value;
        }));
        //new_index = _index + 1;
      } else {
        _index = 0;
      }
      //console.log('_index ==>>');
      //console.log(_index);
      new_index = _index + 1;
      //console.log('new_index ==>>');
      //console.log(new_index);
      this.grid.insert(0, this.newGridRow());
      let temp_dim_arry: IDimensionArry = {
        list1: this.dimensionList1,
        list2: this.dimensionList2,
        list3: this.dimensionList3,
        list4: this.dimensionList4,
        list5: this.dimensionList5,
        list6: this.dimensionList6,
        dim_array_index: new_index
      };
      this.array_of_dimension.unshift(temp_dim_arry);
      this.grid.controls[0].setValue({
        id: 0,
        status: -2,
        seq_no: null,
        employee: this.filtered_employee.value ? this.filtered_employee.value['ID'] : null,
        date: this.is_working_date ? this.getTodayDate() : this.payrollProcessPeriod.c,
        category: null,
        units: null,
        rate: null,
        amount: null,
        union: null,
        dim1: null,
        dim2: null,
        dim3: null,
        dim4: null,
        dim5: null,
        dim6: null,
        dep: null,
        loc: null,
        txt1: null,
        txt2: null,
        txt3: null,
        txt4: null,
        id_union_agg: null,
        overwrite: null,
        grid_id: new_index
      });
      this.status_label = this.status_msg;
      this.grid.controls[0].get('rate').disable();
      this.grid.controls[0].get('amount').disable();
      //this.grid.controls[0].get('overwrite').disable();
      if (!this.is_working_date) {
        this.grid.controls[0].get('date').setValidators(null);
        this.grid.controls[0].get('date').updateValueAndValidity();
      }
      if (this.filtered_employee.value) {
        this.grid.controls[0].get('employee').disable();
        this.onEmployeeEdit(new_index);
      }
      this.filtered_employee.disable();
      this.p = 1;
    }
  }

  getMaximumNewRecords(): number {
    let val: number = 0;
    for (let x of this.grid.controls) {
      if (x.get('id').value == 0) {
        val += 1;
      }
    }
    return val;
  }

  deleteClick(i: number) {
    this.process_in_progress = false;
    let index = this.getIndex(i);
    this.status_label = this.status_msg;
    if (this.grid.controls[index].get('id').value == 0) {
      this.grid.removeAt(index);
      this.filtered_employee.enable();
    } else {
      this.grid.controls[index].patchValue({
        status: -1
      });
      this.grid.controls[index].markAllAsTouched();
      this.grid.controls[index].markAsDirty();
      this.grid.controls[index].disable();
      //this.laborForm.markAsTouched();
      //this.laborForm.markAsDirty();
      this.filtered_employee.disable();
    }
  }

  onPageChange(event: any): void {
    //console.log(event);
    if (this.isFormDirty()) {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.pleaseSaveBeforeMoving', {}, 'laborWizard');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    } else {
      this.p = event;
    }
  }

  isFormDirty(): boolean {
    for (let x of this.grid.controls) {
      if (x.dirty) {
        return true;
      }
    }
    return false;
  }

  getGridIndex(i: number): number {
    return (this.items_per_page * (this.p - 1) + i);
  }

  getIndex(i: number) {
    return this.grid.controls.findIndex(x => x.get('grid_id').value == i);
    // ind = return (10 * (this.p - 1) + ind);
  }

  onEmployeeEdit(i: number): void {
    let index = this.getIndex(i);
    let temp_emp: IEmployeeLabor = this.employees_copy.find(x => x.ID == this.grid.controls[index].get('employee').value);
    this.status_label = this.status_msg;
    this.filtered_employee.disable();
    let dim_id: number;
    let dimension: IDimensionForGlSetup;
    let lbr_dim_id: number;
    if (temp_emp.ID_DIMENSION1) {
      dim_id = temp_emp.ID_DIMENSION1;
      dimension = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 1);
    }
    if (temp_emp.ID_DIMENSION2) {
      dim_id = temp_emp.ID_DIMENSION2;
      dimension = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 1);
    }
    if (temp_emp.ID_DIMENSION3) {
      dim_id = temp_emp.ID_DIMENSION3;
      dimension = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 1);
    }
    if (temp_emp.ID_DIMENSION4) {
      dim_id = temp_emp.ID_DIMENSION4;
      dimension = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 1);
    }
    if (temp_emp.ID_DIMENSION5) {
      dim_id = temp_emp.ID_DIMENSION5;
      dimension = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 1);
    }
    if (temp_emp.ID_DIMENSION6) {
      dim_id = temp_emp.ID_DIMENSION6;
      dimension = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      lbr_dim_id = dimension ? dimension.LABOR_DIM_ID : null;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 1);
    }
    this.grid.controls[index].patchValue({
      status: -2,
      category: (this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == 2)) ? 2 : null,
      dim1: temp_emp.ID_DIMENSION1 ? temp_emp.ID_DIMENSION1 : null,
      dim2: temp_emp.ID_DIMENSION2 ? temp_emp.ID_DIMENSION2 : null,
      dim3: temp_emp.ID_DIMENSION3 ? temp_emp.ID_DIMENSION3 : null,
      dim4: temp_emp.ID_DIMENSION4 ? temp_emp.ID_DIMENSION4 : null,
      dim5: temp_emp.ID_DIMENSION5 ? temp_emp.ID_DIMENSION5 : null,
      dim6: temp_emp.ID_DIMENSION6 ? temp_emp.ID_DIMENSION6 : null,
      dep: temp_emp.ID_DEPARTMENT && this.laborSetupList[6].LABOR_DIM_IS_ACTIVE_FLG == 1 ? temp_emp.ID_DEPARTMENT : null,
      loc: temp_emp.ID_LOCATION && this.laborSetupList[7].LABOR_DIM_IS_ACTIVE_FLG == 1 ? temp_emp.ID_LOCATION : null
    });
    this.changeUnit(index);
  }

  onValueEdit(i: number) {
    //console.log('input event>');
    let index = this.getIndex(i);
    this.grid.controls[index].patchValue({
      status: -2
    });
    this.grid.markAsDirty();
    this.status_label = this.status_msg;
    this.filtered_employee.disable();
  }

  onOverwriteCheck(event: any, i: number) {
    let index = this.getIndex(i);
    this.grid.controls[index].patchValue({
      status: -2
    });
    this.status_label = this.status_msg;
    if (event.target.checked) {
      let is_cat_unit_based = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[index].get('category').value) ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[index].get('category').value).CAT_IS_UNIT_FLG : null;
      if (is_cat_unit_based && is_cat_unit_based == 1) {
        this.grid.controls[index].get('rate').enable();
        this.grid.controls[index].get('amount').disable();
      } else {
        this.grid.controls[index].get('rate').disable();
        this.grid.controls[index].get('amount').enable();
      }
    } else if (!event.target.checked) {
      this.grid.controls[index].get('rate').disable();
      this.grid.controls[index].get('amount').disable();
      //this.grid.controls[index].patchValue({
      //  rate: 0,
      //  amount: 0
      //});
    }
  }

  onCategoryEdit(i: number): void {
    let index = this.getIndex(i);
    this.status_label = this.status_msg;
    this.filtered_employee.disable();
    this.changeUnit(index);
  }

  changeUnit(indx: number): void {
    let temp_cat: IPayCatListLabor = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[indx].get('category').value);
    let is_unit = temp_cat ? temp_cat.CAT_IS_UNIT_FLG : null;
    let emp = this.grid.controls[indx].get('employee').value;
    // let temp: IPaycatEligility = this.paycat_eligibility_list.find(x => (x.ID_EMPLOYEE == emp && x.CAT_DTL_CODE == temp_cat.CAT_DTL_CODE));
    let unit = 0;//temp ? temp.PCAT_UNITS : null;
    if (is_unit && is_unit == 1) {
      this.grid.controls[indx].patchValue({
        status: -2,
        units: unit ? unit.toFixed(this.sysPref.h || 2) : Number(0).toFixed(this.sysPref.h || 2),
        rate: Number(0).toFixed(this.sysPref.g || 2),
        amount: Number(0).toFixed(this.sysPref.i || 2)
      });
      this.grid.controls[indx].get('units').enable();
    } else {
      this.grid.controls[indx].patchValue({
        status: -2,
        units: Number(0).toFixed(this.sysPref.h || 2),
        rate: Number(0).toFixed(this.sysPref.g || 2),
        amount: Number(0).toFixed(this.sysPref.i || 2)
      });
      this.grid.controls[indx].get('units').disable();
    }
    this.filtered_employee.disable();
  }

  onDimensionCahnge(event: any, i: number, lbrsetup: ILaborSetupGL): void {
    this.status_label = this.status_msg;
    this.filtered_employee.disable();
    let dim_id = event.target.value;
    let index = this.getIndex(i);
    if (dim_id && dim_id != 'null') {
      let dimension: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim_id);
      let lbr_dim_id: number = dimension.LABOR_DIM_ID;
      this.filterDimensions(dimension, dim_id, lbr_dim_id, index, 0);
    } else {
      this.unfilterDimensions(lbrsetup, index);
    }
    this.grid.controls[index].patchValue({
      status: -2
    });
  }

  unfilterDimensions(lbrsetup: ILaborSetupGL, index: number) {
    let lbr_dim_id: number = lbrsetup.LABOR_DIM_ID;
    if (lbrsetup.LABOR_DIM_PARENT_CHILD == 2) {
      switch (lbr_dim_id) {
        case 1: {
          this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) : null;
          this.grid.controls[index].patchValue({
            dim2: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 2), index);
          break;
        }
        case 2: {
          this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) : null;
          this.grid.controls[index].patchValue({
            dim3: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 3), index);
          break;
        }
        case 3: {
          this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) : null;
          this.grid.controls[index].patchValue({
            dim4: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 4), index);
          break;
        }
        case 4: {
          this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) : null;
          this.grid.controls[index].patchValue({
            dim5: null
          });
          this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 5), index);
          break;
        }
        case 5: {
          this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) : null;
          this.grid.controls[index].patchValue({
            dim6: null
          });
          break;
        }
      }
    } else if (lbrsetup.LABOR_DIM_PARENT_CHILD == 3) {
      switch (lbr_dim_id) {
        case 1: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 2).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) : null;
            this.grid.controls[index].patchValue({
              dim2: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 2), index);
          }
          break;
        }
        case 2: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 3).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) : null;
            this.grid.controls[index].patchValue({
              dim3: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 3), index);
          }
          break;
        }
        case 3: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 4).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) : null;
            this.grid.controls[index].patchValue({
              dim4: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 4), index);
          }
          break;
        }
        case 4: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 5).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) : null;
            this.grid.controls[index].patchValue({
              dim5: null
            });
            this.unfilterDimensions(this.laborSetupList.find(x => x.LABOR_DIM_ID == 5), index);
          }
          break;
        }
        case 5: {
          if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 6).LABOR_DIM_PARENT_CHILD == 3) {
            this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6).length > 0 ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) : null;
            this.grid.controls[index].patchValue({
              dim6: null
            });
          }
          break;
        }
      }
    }
  }

  filterDimensions(dimension: IDimensionForGlSetup, dim_id: number, lbr_dim_id: number, index: number, init: number) {
    if (dimension) {
      if (dimension.LABOR_DIM_PARENT_CHILD == 2) {
        switch (lbr_dim_id) {
          case 1: {
            //this.dimensionList2
            this.array_of_dimension[index].list2 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 2 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 2 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.grid.controls[index].patchValue({
                dim2: null
              });
            }
            break;
          }
          case 2: {
            //this.dimensionList3
            this.array_of_dimension[index].list3 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.grid.controls[index].patchValue({
                dim3: null
              });
            }
            break;
          }
          case 3: {
            //this.dimensionList4
            this.array_of_dimension[index].list4 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.grid.controls[index].patchValue({
                dim4: null
              });
            }
            break;
          }
          case 4: {
            //this.dimensionList5
            this.array_of_dimension[index].list5 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.grid.controls[index].patchValue({
                dim5: null
              });
            }
            break;
          }
          case 5: {
            //this.dimensionList6
            this.array_of_dimension[index].list6 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)) : null;
            if (init == 0) {
              this.grid.controls[index].patchValue({
                dim6: null
              });
            }
            break;
          }
          default: {
            break;
          }
        }
      } else if (dimension.LABOR_DIM_PARENT_CHILD == 3) {
        switch (lbr_dim_id) {
          case 2: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 3).LABOR_DIM_PARENT_CHILD == 3) {
              //this.dimensionList3
              this.array_of_dimension[index].list3 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 3 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim3: null
                });
              }
            }
            break;
          }
          case 3: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 4).LABOR_DIM_PARENT_CHILD == 3) {
              //this.dimensionList4
              this.array_of_dimension[index].list4 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 4 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim4: null
                });
              }
            }
            break;
          }
          case 4: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 5).LABOR_DIM_PARENT_CHILD == 3) {
              //this.dimensionList5
              this.array_of_dimension[index].list5 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 5 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim5: null
                });
              }
            }
            break;
          }
          case 5: {
            if (this.laborSetupList.find(x => x.LABOR_DIM_ID == 6).LABOR_DIM_PARENT_CHILD == 3) {
              //this.dimensionList6
              this.array_of_dimension[index].list6 = this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)).length > 0 ? this.dimensionList.filter(x => (x.LABOR_DIM_ID == 6 && x.DIM_PARENT_ID == dim_id)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim6: null
                });
              }
            }
            break;
          }
          default: {
            break;
          }
        }
      }
      if (dimension.is_union > 0) {
        let agreement_list: any[] = dimension.list.split(',').map(x => +x);
        let agreement_lbr_dim_id: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6).LABOR_DIM_ID : null;
        if (agreement_lbr_dim_id) {
          switch (agreement_lbr_dim_id) {
            case 1: {
              //this.dimensionList1
              this.array_of_dimension[index].list1 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 1 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 1 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  //dim1: this.array_of_dimension[index].list1 ? this.array_of_dimension[index].list1[0].ID_DIMENSION : null
                  dim1: null
                });
              }
              break;
            }
            case 2: {
              //this.dimensionList2
              this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim2: null
                  //this.array_of_dimension[index].list2 ? this.array_of_dimension[index].list2[0].ID_DIMENSION : null
                });
              }
              break;
            }
            case 3: {
              //this.dimensionList3
              this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim3: null
                  //this.array_of_dimension[index].list3 ? this.array_of_dimension[index].list3[0].ID_DIMENSION : null
                });
              }
              break;
            }
            case 4: {
              //this.dimensionList4
              this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim4: null
                  //this.array_of_dimension[index].list4 ? this.array_of_dimension[index].list4[0].ID_DIMENSION : null
                });
              }
              break;
            }
            case 5: {
              //this.dimensionList5
              this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim5: null
                  //this.array_of_dimension[index].list5 ? this.array_of_dimension[index].list5[0].ID_DIMENSION : null
                });
              }
              break;
            }
            case 6: {
              //this.dimensionList6
              this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6 && agreement_list.includes(x.ID_DIMENSION)) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6 && agreement_list.includes(x.ID_DIMENSION)) : null;
              if (init == 0) {
                this.grid.controls[index].patchValue({
                  dim6: null
                  //this.array_of_dimension[index].list6 ? this.array_of_dimension[index].list6[0].ID_DIMENSION : null
                });
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      } else if (dimension.is_union == 0) {
        let agreement_lbr_dim_id: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6).LABOR_DIM_ID : null;
        switch (agreement_lbr_dim_id) {
          case 1: {
            //this.dimensionList1
            this.array_of_dimension[index].list1 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 1) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 1) : null;
            //if (init == 0) {
            //  this.grid.controls[index].patchValue({
            //    dim1: null
            //      //this.array_of_dimension[index].list1 ? this.array_of_dimension[index].list1[0].ID_DIMENSION : null
            //  });
            //}
            break;
          }
          case 2: {
            //this.dimensionList2
            this.array_of_dimension[index].list2 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 2) : null;
            //if (init == 0) {
            //  this.grid.controls[index].patchValue({
            //    dim2: null
            //    //this.array_of_dimension[index].list2 ? this.array_of_dimension[index].list2[0].ID_DIMENSION : null
            //  });
            //}
            break;
          }
          case 3: {
            this.array_of_dimension[index].list3 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 3) : null;
            //if (init == 0) {
            //  this.grid.controls[index].patchValue({
            //    dim3: null
            //    //this.array_of_dimension[index].list3 ? this.array_of_dimension[index].list3[0].ID_DIMENSION : null
            //  });
            //}
            break;
          }
          case 4: {
            //this.dimensionList4
            this.array_of_dimension[index].list4 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 4) : null;
            //if (init == 0) {
            //  this.grid.controls[index].patchValue({
            //    dim4: null
            //    //this.array_of_dimension[index].list4 ? this.array_of_dimension[index].list4[0].ID_DIMENSION : null
            //  });
            //}
            break;
          }
          case 5: {
            //this.dimensionList5
            this.array_of_dimension[index].list5 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 5) : null;
            //if (init == 0) {
            //  this.grid.controls[index].patchValue({
            //    dim5: null
            //    //this.array_of_dimension[index].list5 ? this.array_of_dimension[index].list5[0].ID_DIMENSION : null
            //  });
            //}
            break;
          }
          case 6: {
            //this.dimensionList6
            this.array_of_dimension[index].list6 = this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) ? this.dimensionList.filter(x => x.LABOR_DIM_ID == 6) : null;
            //if (init == 0) {
            //  this.grid.controls[index].patchValue({
            //    dim6: null
            //    //this.array_of_dimension[index].list6 ? this.array_of_dimension[index].list6[0].ID_DIMENSION : null
            //  });
            //}
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  deleteAllRecords() {
    if (this.grid.controls.length == 0) {
      const title = this.transloco.translate('info');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.noRecordsToDelete', {}, 'laborWizard');
      showMsg({
        'type': 'sticky',
        'status': 'info',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    } else {
      let t = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonCancel = this.transloco.translate('cancel');
      const message = this.transloco.translate('alerts.deleteConfirmation', {}, 'laborWizard');
      showMsg({
        'type': 'sticky',
        'status': 'warning',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              t.deleteAll();
              return;
            }
          },
          [buttonCancel]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    }
  }

  deleteAll() {
    let prlg_code = +this.payroll_group.value;
    let from_date = this.payrollProcessPeriod.b;
    let to_date = this.payrollProcessPeriod.c;
    let seq_no = this.payrollProcessPeriod.f;
    let year = this.payrollProcessPeriod.e;
    this.show_output = false;
    this.process_in_progress = true;
    this.payroll_group.disable();
    this.filtered_employee.disable();
    this._deleteSubscription = this._laborService.DeleteAllTimesheetDataLabor(prlg_code, from_date, to_date, seq_no, year).pipe(untilDestroyed(this)).subscribe(
      res => {
        let no_of_rcrds = res;
        let t = this;
        const title = this.transloco.translate('success');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.deleted', {value: no_of_rcrds}, 'laborWizard');
        showMsg({
          'type': 'sticky',
          'status': 'success',
          'title': title,
          'buttons': {
            [buttonOk]: {
              action: function() {
                t.process_in_progress = false;
                t.getTimesheetGridData(prlg_code, t.sess_id);
              }
            }
          },
          'message': message
        });
      },
      err => {
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.errorWhileDeleting', {}, 'laborWizard');
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [buttonOk]: {
              action: function() {
                return;
              }
            }
          },
          'message': message
        });
      }
    );
  }

  onSubmit(): void {
    let errorMarkUp = '';
    let warningMarkUp = '';
    this.output_list.length = 0;
    this.success = 0;
    this.errs = 0;
    this.warnings = 0;
    this.messages = 0;
    this.show_output = false;
    // this.payroll_group.disable();
    //this.filtered_employee.disable();
    if (!this.isFormDirty()) {
      const title = this.transloco.translate('info');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.noChanges', {}, 'laborWizard');
      showMsg({
        'type': 'sticky',
        'status': 'info',
        'title': title,
        'buttons': {
          [buttonOk]: {
            action: function() {
              return;
            }
          }
        },
        'message': message
      });
    } else {
      this.payroll_group.disable();
      this.filtered_employee.disable();
      this.process_in_progress = true;
      let dateisvalid: string = this.dateIsValidUS();
      if (this.isFormDirty() && this.sysPref.ct == 1 && dateisvalid) {
        errorMarkUp += dateisvalid;
      } else if (this.isFormDirty() && this.sysPref.ct == 1 && this.certified_pay_pref && this.certified_pay_pref.PROJECT_DIM > 0 && this.certified_pay_pref.WORK_CLASS_DIM > 0) {
        for (let i = 0; i < this.grid.controls.length; i++) {
          if (this.grid.controls[i].get('status').value != -1) {
            let emp_no = this.employees_copy.find(x => x.ID == this.grid.controls[i].get('employee').value).Number;
            let date = this.grid.controls[i].get('date').value;
            let category = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[i].get('category').value).CAT_DTL_USER_CODE;
            let units = Number(this.grid.controls[i].get('units').value).toFixed(this.sysPref.h || 2);
            if (!this.validProjectWorkclass(+this.grid.controls[i].get('dim1').value, +this.grid.controls[i].get('dim2').value, +this.grid.controls[i].get('dim3').value, +this.grid.controls[i].get('dim4').value, +this.grid.controls[i].get('dim5').value, +this.grid.controls[i].get('dim6').value)) {
              let msg = this.transloco.translate('alerts.isMandatoryBecauseOfProjectWorkClassRelationship', {
                projectValue: this.project_dim_name,
                workClassValue: this.wrk_clss_dim_name
              }, 'laborWizard');
              errorMarkUp += '<div>' + emp_no + ' - ' + date + ' - ' + category + ' - ' + units + ' - ' + msg + '</div>';
            }
          }
        }
      } else {
        for (let i = 0; i < this.grid.controls.length; i++) {
          if (this.grid.controls[i].get('status').value != -1) {
            let emp_no = this.employees_copy.find(x => x.ID == this.grid.controls[i].get('employee').value).Number;
            let date = this.grid.controls[i].get('date').value;
            let category = this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == this.grid.controls[i].get('category').value).CAT_DTL_USER_CODE;
            let units = Number(this.grid.controls[i].get('units').value).toFixed(this.sysPref.h || 2);
            if (!this.dimensionParentChildRelationValid(this.grid.controls[i].get('dim1').value, this.grid.controls[i].get('dim2').value, this.grid.controls[i].get('dim3').value, this.grid.controls[i].get('dim4').value, this.grid.controls[i].get('dim5').value, this.grid.controls[i].get('dim6').value)) {
              let msg = this.transloco.translate('alerts.isInvalidParentChildRelation', {}, 'laborWizard');
              errorMarkUp += '<div>' + emp_no + ' - ' + date + ' - ' + category + ' - ' + units + ' - ' + msg + '</div>';
            } else if (!this.dimensionUnionAggreementRelationValid(this.grid.controls[i].get('dim1').value, this.grid.controls[i].get('dim2').value, this.grid.controls[i].get('dim3').value, this.grid.controls[i].get('dim4').value, this.grid.controls[i].get('dim5').value, this.grid.controls[i].get('dim6').value)) {
              let msg = this.transloco.translate('alerts.isInvalidUnionAggreementRelation', {}, 'laborWizard');
              errorMarkUp += '<div>' + emp_no + ' - ' + date + ' - ' + category + ' - ' + units + ' - ' + msg + '</div>';
            } else if (this.is_working_date && !this.dateIsValid(this.grid.controls[i].get('date').value)) {
              let msg = this.transloco.translate('alerts.dateIsOutsideOfSelectedPeriod', {}, 'laborWizard');
              warningMarkUp += '<div>' + emp_no + ' - ' + date + ' - ' + category + ' - ' + units + ' - ' + msg + '</div>';
            }
          }
        }
      }
      if (errorMarkUp) {
        this.process_in_progress = false;
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        showMsg({
          'type': 'sticky',
          'status': 'error',
          'title': title,
          'buttons': {
            [buttonOk]: {
              action: function() {
                return;
              }
            }
          },
          'message': errorMarkUp
        });
      } else if (warningMarkUp) {
        let t = this;
        const title = this.transloco.translate('warning');
        const buttonOk = this.transloco.translate('ok');
        const buttonCancel = this.transloco.translate('cancel');
        showMsg({
          'type': 'sticky',
          'status': 'warning',
          'title': title,
          'buttons': {
            [buttonOk]: {
              action: function() {
                t.saveData();
                return;
              }
            },
            [buttonCancel]: {
              action: function() {
                t.process_in_progress = false;
                return;
              }
            }
          },
          'message': warningMarkUp
        });
      } else {
        this.saveData();
      }
    }
  }

  saveData(): void {
    this.status_label = '';
    this.timesheetData.length = 0;
    for (let x of this.laborForm.getRawValue().grid) {
      if (x.status == -2 || x.status == -1) {
        let temp: ITimesheetDataLaborSave = {
          Record_Status: +x.status,
          PROC_SEQ_NO: +x.seq_no,
          ID_TIMESHEET: +x.id,
          CAT_DTL_CODE: +x.category,
          ID_EMPLOYEE: +x.employee,
          WORKING_DATE: this.is_working_date ? x.date : this.payrollProcessPeriod.c,
          PROC_UNITS: +x.units,
          PROC_RATE: +x.rate,
          PROC_AMOUNT: +x.amount,
          ID_DIMENSION1: +x.dim1 ? +x.dim1 : null,
          ID_DIMENSION2: +x.dim2 ? +x.dim2 : null,
          ID_DIMENSION3: +x.dim3 ? +x.dim3 : null,
          ID_DIMENSION4: +x.dim4 ? +x.dim4 : null,
          ID_DIMENSION5: +x.dim5 ? +x.dim5 : null,
          ID_DIMENSION6: +x.dim6 ? +x.dim6 : null,
          ID_DEPARTMENT: +x.dep ? +x.dep : null,
          ID_LOCATION: +x.loc ? +x.loc : null,
          TEXT1: x.txt1,
          TEXT2: x.txt2,
          TEXT3: x.txt3,
          TEXT4: x.txt4,
          OVERWRITE: x.overwrite,
          ID_UNION_AGREEMENT: +x.id_union_agg
        };
        if (temp) {
          this.timesheetData.push(temp);
        }
      }
    }
    let temp_data: ILaborData = {
      prlg_code: this.payroll_group.value,
      proc_seq_no: this.payrollProcessPeriod.f,
      proc_year: this.payrollProcessPeriod.e,
      from_date: this.payrollProcessPeriod.b,
      to_date: this.payrollProcessPeriod.c,
      timesheetData: this.timesheetData
    };
    //console.log('timesheetdata =>');
    //console.log(this.timesheetData);
    if (temp_data) {
      this._laborService.SaveTimesheetDataLabor(temp_data).pipe(untilDestroyed(this)).subscribe(res => {
          if (res) {
            //if (res?.list?.length) {
            //  res.list = res?.list?.map(value => ({...value, PROC_OUT_MSG: this.transloco.translate(value.PROC_OUT_MSG)}));
            //}
            this.output_list = res.list; // ???? res.list is ILaborOutput[] and ILaborOutput.PROC_OUT_MSG id key from backend to translate ????
            this.sess_id = res.session_id;
            this.getOutput(this.output_list);
          } else {
            const title = this.transloco.translate('error');
            const buttonOk = this.transloco.translate('ok');
            const message = this.transloco.translate('alerts.errorOccurred', {}, 'laborWizard');
            showMsg({
              'type': 'sticky',
              'status': 'error',
              'title': title,
              'buttons': {
                [buttonOk]: {
                  action: function() {
                    return;
                  }
                }
              },
              'message': message
            });
          }
        },
        err => {
          var that = this;
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('errorOccurred');
          showMsg({
            'type': 'sticky',
            'status': 'error',
            'title': title,
            'buttons': {
              [buttonOk]: {
                action: function() {
                  that.getTimesheetGridData(that.payroll_group.value, that.sess_id);
                  return;
                }
              }
            },
            'message': message
          });
        }
      );
    }
  }

  getOutputfromImport(outPut: IlaborOutputWithSession) {
    //console.log(outPut);
    this.output_list = outPut.list;
    this.sess_id = outPut.session_id;
    this.getOutput(this.output_list);
  }

  getOutput(list: ILaborOutput[]): void {
    if (list) {
      this.sortOrder = 'Number';
      this.sortdirec = false;
      this.output.length = 0;
      this.success = list.filter(x => x.PROC_OUT_TYPE == 1) ? list.filter(x => x.PROC_OUT_TYPE == 1).length : 0;
      this.warnings = list.filter(x => x.PROC_OUT_TYPE == 2) ? list.filter(x => x.PROC_OUT_TYPE == 2).length : 0;
      this.errs = list.filter(x => x.PROC_OUT_TYPE == 3) ? list.filter(x => x.PROC_OUT_TYPE == 3).length : 0;
      this._subscription = this._payrollService.GetTimesheetDataForLabor(this.payroll_group.value, this.sess_id, this.payrollProcessPeriod.b, this.payrollProcessPeriod.c, this.payrollProcessPeriod.e, this.payrollProcessPeriod.f).pipe(untilDestroyed(this)).subscribe(data => {
        this.grid_data = data;
        if (data) {
          if (this.filtered_employee.value) {
            this.setGridData(this.grid_data.filter(x => x.ID_EMPLOYEE == +this.filtered_employee.value['ID']));
          } else {
            this.setGridData(this.grid_data);
          }
          for (let item of list) {
            let temp_data: ITimesheetDataLabor = this.grid_data.find(x => x.ID_TIMESHEET == item.ID_TIMESHEET);
            let temp: ILaborOutputList;
              if (item.ID_EMPLOYEE != 0) {
                temp = {
                  message_type: item.PROC_OUT_TYPE,
                  emp_no: this.employees_copy.find(x => x.ID == item.ID_EMPLOYEE).Number,
                  date: temp_data ? this.formatDate(temp_data.WORKING_DATE) : null,
                  category: temp_data ? this.payrollCategories_copy.find(x => x.CAT_DTL_CODE == temp_data.CAT_DTL_CODE).CAT_DTL_USER_CODE : null,
                  units: temp_data ? temp_data.PROC_UNITS : null,
                  message: item.PROC_OUT_MSG.indexOf(' ') == -1 ? this.transloco.translate(item.PROC_OUT_MSG) : item.PROC_OUT_MSG // ???? it is key for translation from backend ?????
                };
              } else {
                temp = {
                  message_type: item.PROC_OUT_TYPE,
                  emp_no: '0',
                  date: null,
                  category: null,
                  units: null,
                  message: item.PROC_OUT_MSG.indexOf(' ') == -1 ? this.transloco.translate(item.PROC_OUT_MSG) : item.PROC_OUT_MSG // ???? it is key for translation from backend ?????
                };
              }
              this.output.push(temp);
            }
            this.show_output = true;
            this.process_in_progress = false;
            if (!this.wizardMode) {
              this.payroll_group.enable();
            }
            this.filtered_employee.enable();
          }
        }
      );
    }
  }

  validProjectWorkclass(dim1: number, dim2: number, dim3: number, dim4: number, dim5: number, dim6: number): boolean {
    //if (dim1.toString() == "null") { dim1 = null; }
    //if (dim2.toString() == "null") { dim2 = null; }
    //if (dim3.toString() == "null") { dim3 = null; }
    //if (dim4.toString() == "null") { dim4 = null; }
    //if (dim5.toString() == "null") { dim5 = null; }
    //if (dim6.toString() == "null") { dim6 = null; }
    let prjct_dim = this.certified_pay_pref.PROJECT_DIM;
    this.project_dim_name = this.laborSetupList[prjct_dim - 1].LABOR_DIM_CAPTION;
    let wrk_clss_dim = this.certified_pay_pref.WORK_CLASS_DIM;
    this.wrk_clss_dim_name = this.laborSetupList[wrk_clss_dim - 1].LABOR_DIM_CAPTION;
    let wrk_cls_mandatory: boolean = false;
    if (this.certified_pay_pref.EXPORT_INCLUDE_CERTIFIED_OPTION == 3) {
      switch (prjct_dim) {
        case 1:
          if (dim1 && this.dimensionList.find(x => x.ID_DIMENSION == dim1).CP_IS_CERTIFIED_FLG == 1) {
            wrk_cls_mandatory = true;
          }
          break;
        case 2:
          if (dim2 && this.dimensionList.find(x => x.ID_DIMENSION == dim2).CP_IS_CERTIFIED_FLG == 1) {
            wrk_cls_mandatory = true;
          }
          break;
        case 3:
          if (dim3 && this.dimensionList.find(x => x.ID_DIMENSION == dim3).CP_IS_CERTIFIED_FLG == 1) {
            wrk_cls_mandatory = true;
          }
          break;
        case 4:
          if (dim4 && this.dimensionList.find(x => x.ID_DIMENSION == dim4).CP_IS_CERTIFIED_FLG == 1) {
            wrk_cls_mandatory = true;
          }
          break;
        case 5:
          if (dim5 && this.dimensionList.find(x => x.ID_DIMENSION == dim5).CP_IS_CERTIFIED_FLG == 1) {
            wrk_cls_mandatory = true;
          }
          break;
        case 6:
          if (dim6 && this.dimensionList.find(x => x.ID_DIMENSION == dim6).CP_IS_CERTIFIED_FLG == 1) {
            wrk_cls_mandatory = true;
          }
          break;
        default:
          break;
      }
      if (wrk_cls_mandatory) {
        switch (wrk_clss_dim) {
          case 1:
            if (!dim1) {
              return false;
            }
            break;
          case 2:
            if (!dim2) {
              return false;
            }
            break;
          case 3:
            if (!dim3) {
              return false;
            }
            break;
          case 4:
            if (!dim4) {
              return false;
            }
            break;
          case 5:
            if (!dim5) {
              return false;
            }
            break;
          case 6:
            if (!dim6) {
              return false;
            }
            break;
          default:
            break;
        }
      } else {
        switch (wrk_clss_dim) {
          case 1:
            if (dim1) {
              return false;
            }
            break;
          case 2:
            if (dim2) {
              return false;
            }
            break;
          case 3:
            if (dim3) {
              return false;
            }
            break;
          case 4:
            if (dim4) {
              return false;
            }
            break;
          case 5:
            if (dim5) {
              return false;
            }
            break;
          case 6:
            if (dim6) {
              return false;
            }
            break;
          default:
            break;
        }
      }
    } else {
      switch (prjct_dim) {
        case 1:
          if (dim1) {
            wrk_cls_mandatory = true;
          }
          break;
        case 2:
          if (dim2) {
            wrk_cls_mandatory = true;
          }
          break;
        case 3:
          if (dim3) {
            wrk_cls_mandatory = true;
          }
          break;
        case 4:
          if (dim4) {
            wrk_cls_mandatory = true;
          }
          break;
        case 5:
          if (dim5) {
            wrk_cls_mandatory = true;
          }
          break;
        case 6:
          if (dim6) {
            wrk_cls_mandatory = true;
          }
          break;
        default:
          break;
      }
      if (wrk_cls_mandatory) {
        switch (wrk_clss_dim) {
          case 1:
            if (!dim1) {
              return false;
            }
            break;
          case 2:
            if (!dim2) {
              return false;
            }
            break;
          case 3:
            if (!dim3) {
              return false;
            }
            break;
          case 4:
            if (!dim4) {
              return false;
            }
            break;
          case 5:
            if (!dim5) {
              return false;
            }
            break;
          case 6:
            if (!dim6) {
              return false;
            }
            break;
          default:
            break;
        }
      } else {
        switch (wrk_clss_dim) {
          case 1:
            if (dim1) {
              return false;
            }
            break;
          case 2:
            if (dim2) {
              return false;
            }
            break;
          case 3:
            if (dim3) {
              return false;
            }
            break;
          case 4:
            if (dim4) {
              return false;
            }
            break;
          case 5:
            if (dim5) {
              return false;
            }
            break;
          case 6:
            if (dim6) {
              return false;
            }
            break;
          default:
            break;
        }
      }
    }
    return true;
  }

  dimensionParentChildRelationValid(dim1: number, dim2: number, dim3: number, dim4: number, dim5: number, dim6: number): boolean {
    let dimension1: IDimensionForGlSetup = dim1 ? this.dimensionList.find(x => x.ID_DIMENSION == dim1) : null;
    let dimension2: IDimensionForGlSetup = dim2 ? this.dimensionList.find(x => x.ID_DIMENSION == dim2) : null;
    let dimension3: IDimensionForGlSetup = dim3 ? this.dimensionList.find(x => x.ID_DIMENSION == dim3) : null;
    let dimension4: IDimensionForGlSetup = dim4 ? this.dimensionList.find(x => x.ID_DIMENSION == dim4) : null;
    let dimension5: IDimensionForGlSetup = dim5 ? this.dimensionList.find(x => x.ID_DIMENSION == dim5) : null;
    let dimension6: IDimensionForGlSetup = dim6 ? this.dimensionList.find(x => x.ID_DIMENSION == dim6) : null;
    //if (dimension1 && dimension1.LABOR_DIM_PARENT_CHILD == 2 && !dimension2) {
    //    return false;
    //}
    //else if (dimension2 && dimension2.LABOR_DIM_PARENT_CHILD == 2 && !dimension3) {
    //    return false;
    //}
    //else if (dimension3 && dimension3.LABOR_DIM_PARENT_CHILD == 2 && !dimension4) {
    //    return false;
    //}
    //else if (dimension4 && dimension4.LABOR_DIM_PARENT_CHILD == 2 && !dimension5) {
    //    return false;
    //}
    //else if (dimension5 && dimension5.LABOR_DIM_PARENT_CHILD == 2 && !dimension6) {
    //    return false;
    //}
    if (dimension2 && dimension2.LABOR_DIM_PARENT_CHILD == 3 && !dimension1) {
      return false;
    } else if (dimension2 && dimension2.LABOR_DIM_PARENT_CHILD == 3 && dimension1 && dimension1.ID_DIMENSION != dimension2.DIM_PARENT_ID) {
      return false;
    } else if (dimension3 && dimension3.LABOR_DIM_PARENT_CHILD == 3 && !dimension2) {
      return false;
    } else if (dimension3 && dimension3.LABOR_DIM_PARENT_CHILD == 3 && dimension2 && dimension2.ID_DIMENSION != dimension3.DIM_PARENT_ID) {
      return false;
    } else if (dimension4 && dimension4.LABOR_DIM_PARENT_CHILD == 3 && !dimension3) {
      return false;
    } else if (dimension4 && dimension4.LABOR_DIM_PARENT_CHILD == 3 && dimension3 && dimension3.ID_DIMENSION != dimension4.DIM_PARENT_ID) {
      return false;
    } else if (dimension5 && dimension5.LABOR_DIM_PARENT_CHILD == 3 && !dimension4) {
      return false;
    } else if (dimension5 && dimension5.LABOR_DIM_PARENT_CHILD == 3 && dimension4 && dimension4.ID_DIMENSION != dimension5.DIM_PARENT_ID) {
      return false;
    } else if (dimension6 && dimension6.LABOR_DIM_PARENT_CHILD == 3 && !dimension5) {
      return false;
    } else if (dimension6 && dimension6.LABOR_DIM_PARENT_CHILD == 3 && dimension5 && dimension5.ID_DIMENSION != dimension6.DIM_PARENT_ID) {
      return false;
    }
    return true;
  }

  dimensionUnionAggreementRelationValid(dim1: number, dim2: number, dim3: number, dim4: number, dim5: number, dim6: number): boolean {
    let lbr_dim_id_aggreement: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 6).LABOR_DIM_ID : null;
    let lbr_dim_id_union: number = this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 5) ? this.laborSetupList.find(x => x.LABOR_DIM_UNION_AGREEMENT == 5).LABOR_DIM_ID : null;
    let union_dimension: IDimensionForGlSetup;
    let aggreement_dimension: IDimensionForGlSetup;
    if (lbr_dim_id_aggreement && lbr_dim_id_union) {
      let dimension1: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim1);
      let dimension2: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim2);
      let dimension3: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim3);
      let dimension4: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim4);
      let dimension5: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim5);
      let dimension6: IDimensionForGlSetup = this.dimensionList.find(x => x.ID_DIMENSION == dim6);
      let dimensions: IDimensionForGlSetup[] = [dimension1, dimension2, dimension3, dimension4, dimension5, dimension6];
      for (let item of dimensions) {
        if (item && item.LABOR_DIM_ID == lbr_dim_id_aggreement) {
          aggreement_dimension = item;
        } else if (item && item.LABOR_DIM_ID == lbr_dim_id_union) {
          union_dimension = item;
        }
      }
      if (union_dimension && aggreement_dimension && !union_dimension.list.includes(aggreement_dimension.ID_DIMENSION.toString())) {
        return false;
      }
    }
    return true;
  }

  dateIsValidUS(): string {
    let msg = '';
    let emp_id_arr: number[] = [];
    this.grid.controls.forEach((x) => emp_id_arr.push(+x.get('employee').value));
    //console.log('emp_id_arr==>>');
    //console.log(emp_id_arr);
    let emp_id_set = [...new Set(emp_id_arr)];
    //console.log('emp_id_set==>>');
    // console.log(emp_id_set);
    for (let item of emp_id_set) {
      if (this.employees_copy.find(x => x.ID == item).CP_CERTIFIED_RATE_TYPE == 'F' || this.employees_copy.find(x => x.ID == item).CP_CERTIFIED_RATE_TYPE == 'A') {
        let tmp_arr = this.grid.controls.filter(x => x.get('employee').value == item);
        //console.log('tmp_arr==>>');
        //console.log(tmp_arr);
        let wrk_dates: Date[] = [];
        tmp_arr.forEach((x) => wrk_dates.push(x.get('date').value));
        //console.log('wrk_dates==>>');
        //console.log(wrk_dates);
        if (wrk_dates.length > 0) {
          for (let i = 0; i < wrk_dates.length; i++) {
            let pay_period: IPayScheduleForAdjstmntCP = this.payschedule_for_all_years.find(x => (new Date(wrk_dates[i]).getTime() <= new Date(x.PFREQ_SCH_END_DATE.toString().substring(0, 10)).getTime() && new Date(wrk_dates[i]).getTime() >= new Date(x.PFREQ_SCH_ST_DATE.toString().substring(0, 10)).getTime()));
            //console.log('pay_period==>>');
            // console.log(pay_period);
            if (!pay_period) {
              let emp_no = this.employees_copy.find(x => x.ID == item).Number;

              msg += '<div class="msg-cell">' + this.transloco.translate('alerts.employeeHasWorkingDatesInSeveralPeriods', {value: emp_no}, 'laborWizard') + '</div>';
              break;
            } else {
              for (let j = i + 1; j <= wrk_dates.length - 1; j++) {
                //console.log(new Date(wrk_dates[j]).getTime());
                //console.log(new Date(pay_period.PFREQ_SCH_END_DATE.toString().substring(0, 10)).getTime());
                //console.log(new Date(pay_period.PFREQ_SCH_ST_DATE.toString().substring(0, 10)).getTime());
                if (!(new Date(wrk_dates[j]).getTime() <= new Date(pay_period.PFREQ_SCH_END_DATE.toString().substring(0, 10)).getTime() && new Date(wrk_dates[j]).getTime() >= new Date(pay_period.PFREQ_SCH_ST_DATE.toString().substring(0, 10)).getTime())) {
                  let emp_no = this.employees_copy.find(x => x.ID == item).Number;
                  msg += '<div class="msg-cell">' + this.transloco.translate('alerts.employeeHasWorkingDatesInSeveralPeriods', {value: emp_no}, 'laborWizard') + '</div>';
                  break;
                }
              }
            }
          }
        }
      }
    }
    // console.log('msg==>>>');
    //console.log(msg);
    return msg;
  }

  dateIsValid(date: Date): boolean {
    let x = this.payrollProcessPeriod.b;//this.fromJsonDate(this.payrollProcessPeriod.b);
    let y = this.payrollProcessPeriod.c;//this.fromJsonDate(this.payrollProcessPeriod.c);
    if (new Date(date).getTime() <= new Date(y.toString().substring(0, 10)).getTime() && new Date(date).getTime() >= new Date(x.toString().substring(0, 10)).getTime()) {
      return true;
    } else {
      return false;
    }
  }

  onRollback(): void {
    this.process_in_progress = true;
    this.filtered_employee.disable();
    this.getTimesheetGridData(this.payroll_group.value, this.sess_id);
  }

  onItemPerPageClick(event: any) {
    this.items_per_page = event.target.value;
  }

  onOutputClick() {
    this.show_output ? this.show_output = false : this.show_output = true;
  }

  onClosedButtonClick() {
    this.show_output = false;
  }

  sortOptionChange(header: string) {
    if (this.sortOrder == header) {
      this.sortdirec = !this.sortdirec;
    } else {
      this.sortOrder = header;
      this.sortdirec = false;
    }
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this._formSubscription) {
      this._formSubscription.unsubscribe();
    }
    if (this._deleteSubscription) {
      this._deleteSubscription.unsubscribe();
    }
  }

  onFilterChange($event: any) {
    this.employees_copy = this.employees.filter((s) => s.PRLG_CODE === this.prlg_no && s.n.toLowerCase().indexOf($event.toLowerCase()) !== -1);
  }

  showLaborReport() {
    this.router.navigate([]).then((result) => {
      const urlTree = this.router.createUrlTree(['report', 'dynamicReport', '3']);
      this.router.navigate([]).then(() => {
        window.open(this.router.serializeUrl(urlTree));
      });
    });
  }
}
