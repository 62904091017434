import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  LoadingSpinnerService
} from '@harmony-modules/configuration/sections/employee/employee-import/shared/loading-spinner/loading-spinner.service';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {switchMap, tap} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/shared/services';
import {BackupService} from 'src/app/shared/services/backup.service';
import {IBackup, IBackupDir} from '@shared/models/ibackup';
import {AccessControlService} from '../../../account/access-control/services/access-control.service';
import {SecurityKeys} from '@shared/models/ISecurity';

declare var $: any;
declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: {scope: 'backup', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
  }]
})
export class BackupComponent implements OnInit {
  backupForm: FormGroup;
  infoForBackup: IBackup = {a: '', b: '', c: false};
  value: number = 0;
  regex: any;
  lblUsedDBSpace = '';
  lblUsedPaystubSpace = '';
  lblFreeSpace = '';

  donut: any = {
    labels: [this.lblUsedDBSpace, this.lblUsedPaystubSpace, this.lblFreeSpace],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#556ee6', '#b7997d', '#9bc4e8'
        ],
        hoverBackgroundColor: ['#556ee6', '#b7997d', '#9bc4e8'],
        hoverBorderColor: '#fff'
      }],
    options: {
      maintainAspectRatio: false,
      legend: {
        position: 'top'
      }
    }
  };

  fSize: string;
  userBackupDirs: IBackupDir[] = [];
  sysPreference: any;
  isSupervisor: boolean;
  freeSpace: number;
  companyName: string;
  backupPermission = true;

  constructor(
    private fb: FormBuilder,
    private _backupService: BackupService,
    private _sysPrefService: SystemPreferenceService,
    private authenticationService: AuthenticationService,
    private loading: LoadingSpinnerService,
    private accessControlService: AccessControlService,
    public wizardService: WizardService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.companyName = x.selectedCompany.Name);
  }

  ngOnInit() {
    this.backupPermission = this.accessControlService.getAccessPermissions('core', SecurityKeys.coreModule.systemTools.companyBackup, 'view') as boolean;
    this._backupService.updateBackupStatus(false);
    this.transloco.selectTranslate(null, {}, this.scope).pipe(
      untilDestroyed(this),
      tap(() => {
        this.lblUsedDBSpace = this.transloco.translate('usedDBSpace', {}, 'backup');
        this.lblUsedPaystubSpace = this.transloco.translate('usedPaystubSpace', {}, 'backup');
        this.lblFreeSpace = this.transloco.translate('freeSpace', {}, 'backup');
        this.donut.labels = [this.lblUsedDBSpace, this.lblUsedPaystubSpace, this.lblFreeSpace];
      }),
      switchMap(() => this._backupService.getUserSpace())
    ).subscribe(
      d => {
        this.freeSpace = d.freeSpace;
        this.fSize = d.freeSize;
        this.donut.labels = [`${this.lblUsedDBSpace} (${d.usedDBSize})`, `${this.lblUsedPaystubSpace} (${d.usedPaystubSize})`, `${this.lblFreeSpace} (${d.freeSize})`];
        this.donut.datasets[0].data = [d.usedDBSpace, d.usedPaystubSpace, d.freeSpace];
      });

    this.backupForm = this.fb.group({
      // directory: ['', Validators.required],
      fileName: ['', Validators.required],
      isCompress: [true]
    });
    this._backupService.isSupervisor().subscribe(data => {
      this.isSupervisor = data;
    });
    this._backupService.compressBackupOpt.subscribe(d => {
      if (d != undefined) {
        this.backupForm.patchValue({isCompress: d});
      }
    });
    this.regex = '[^</*?"\\\\>:|.]+';
    this._backupService.getUserBackupFiles().subscribe(
      x => {
        this.userBackupDirs = x;
        this.userBackupDirs.forEach(y => {
          y['id'] = y.dirName.replace(' ', '');
        });
      });

    this._sysPrefService.getinitSystemPreferences().subscribe(data => {
      if (data && data.length > 0) {
        this.sysPreference = data[0];
      //  this.companyName = this.sysPreference.j;
      }
    });
  }

  //get directory() { return this.backupForm.get('directory'); }
  get fileName() {
    return this.backupForm.get('fileName');
  }

  get isCompress() {
    return this.backupForm.get('isCompress');
  }

  onCompressChanged(event: any) {
    if (!event.target.checked) {
      this._backupService.updateBackupOption(false);
    } else if (event.target.checked) {
      this._backupService.updateBackupOption(true);
    }
  }

  onSubmit(): void {
    console.log(this.backupForm.value);
    let formValue = this.backupForm.value;
    if (formValue) {
      //this.infoForBackup.a = formValue.directory;
      this.infoForBackup.b = formValue.fileName;
      this.infoForBackup.c = formValue.isCompress;
    }
    if (this.infoForBackup.b.trim().length <= 0) {
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.backupFileNameCannotBeBlank', {}, 'backup');
      var button = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [button]: {
            action: function() {
              return;
            }
          }
        },
        'message': msg
      });
    } else if (this.freeSpace == 0) {
      var title = this.transloco.translate('error');
      var msg = this.transloco.translate('alerts.noFreeSpace', {}, 'backup');
      var button = this.transloco.translate('ok');
      showMsg({
        'type': 'sticky',
        'status': 'error',
        'title': title,
        'buttons': {
          [button]: {
            action: function() {
            }
          }
        },
        'message': msg
      });
    } else {
      var comp = this.userBackupDirs.find((val, index, ary) => {
        return val.folder == this.companyName;
      });
      if (comp && comp.files && comp.files.find((val, index, ary) => {
        return val.name == this.infoForBackup.b.trim().replace('.pym', '');
      })) {
        var that = this;
        var title = this.transloco.translate('warning');
        var msg = this.transloco.translate('alerts.overwriteTheBackupFile', {}, 'backup');
        var button1 = this.transloco.translate('yes');
        var button2 = this.transloco.translate('no');
        showMsg({
          'type': 'sticky',
          'status': 'warning',
          'title': title,
          'buttons': {
            [button1]: {
              action: function() {
                that.loading.startSpinner({spinnerName: 'bkSpinner', isFullScreen: true});
                that._backupService.companyBackup(that.infoForBackup).subscribe(
                  suc => {
                    that.value = suc;
                    console.log(that.value);
                    if (suc == -1) {
                      var title = that.transloco.translate('error');
                      var msg1 = that.transloco.translate('alerts.backupFileSizeIsMoreThan', {}, 'backup');
                      var msg2 = that.transloco.translate('alerts.manageBackups', {}, 'backup');
                      var button = that.transloco.translate('ok');
                      showMsg({
                        'type': 'sticky',
                        'status': 'error',
                        'title': title,
                        'buttons': {
                          [button]: {
                            action: function() {
                            }
                          }
                        },
                        'message': msg1 + that.fSize + msg2
                      });
                    } else {
                      if (that.value == -66) {
                        var title = that.transloco.translate('error');
                        var msg = that.transloco.translate('alerts.backupFolderOnServerIsNotExist', {}, 'backup');
                        showMsg({
                          'type': 'nonsticky',
                          'status': 'error',
                          'title': title,
                          'buttons': {},
                          'message': msg,
                          'delay': '1800'
                        });
                      } else {
                        that._backupService.updateBackupStatus(true);
                        var title = that.transloco.translate('success');
                        var msg = that.transloco.translate('alerts.companyBackupSuccessfullyCompleted', {}, 'backup');
                        var button = that.transloco.translate('ok');
                        showMsg({
                          'type': 'nonsticky',
                          'status': 'success',
                          'title': title,
                          'buttons': {},
                          'message': msg,
                          'delay': '1800'
                        });
                      }
                    }
                    that.transloco.selectTranslate(null, {}, that.scope).pipe(
                      untilDestroyed(that),
                      tap(() => {
                        this.lblUsedDBSpace = this.transloco.translate('usedDBSpace', {}, 'backup');
                        this.lblUsedPaystubSpace = this.transloco.translate('usedPaystubSpace', {}, 'backup');
                        that.lblFreeSpace = that.transloco.translate('freeSpace', {}, 'backup');
                        that.donut.labels = [this.lblUsedDBSpace, this.lblUsedPaystubSpace, that.lblFreeSpace];
                      }),
                      switchMap(() => that._backupService.getUserSpace())
                    ).subscribe(
                      d => {
                        that.fSize = d.freeSize;
                        that.freeSpace = d.freeSpace;
                        that.donut.labels = [`${that.lblUsedDBSpace} (${d.usedDBSize})`, `${that.lblUsedPaystubSpace} (${d.usedPaystubSize})`, `${that.lblFreeSpace} (${d.freeSize})`];
                        that.donut.datasets[0].data = [d.usedDBSpace, d.usedPaystubSpace, d.freeSpace];
                      });
                    that._backupService.getUserBackupFiles().subscribe(
                      x => {
                        that.userBackupDirs = x;
                        that.userBackupDirs.forEach(y => {
                          y['id'] = y.dirName.replace(' ', '');
                        });
                      });
                    that.loading.stopSpinner();
                  },
                  err => {
                    var title = that.transloco.translate('error');
                    var msg = that.transloco.translate('alerts.errorOccurredWhenBackupingTheCompany', {}, 'backup');
                    var button = that.transloco.translate('ok');
                    showMsg({
                      'type': 'nonsticky',
                      'status': 'error',
                      'title': title,
                      'buttons': {},
                      'message': msg,
                      'delay': '1800'
                    });
                    this.loading.stopSpinner();
                  }
                );
              }
            },
            [button2]: {
              action: function() {
              }
            }
          },
          'message': msg + that.infoForBackup.b.trim() + ' ?'
        });
      } else {
        this.loading.startSpinner({spinnerName: 'pageSpinner', isFullScreen: true});
        this._backupService.companyBackup(this.infoForBackup).subscribe(
          suc => {
            this.value = suc;
            console.log(this.value);
            if (suc == -1) {
              var title = this.transloco.translate('error');
              var msg1 = this.transloco.translate('alerts.backupFileSizeIsMoreThan', {}, 'backup');
              var msg2 = this.transloco.translate('alerts.manageBackups', {}, 'backup');
              var button = this.transloco.translate('ok');
              showMsg({
                'type': 'sticky',
                'status': 'error',
                'title': title,
                'buttons': {
                  [button]: {
                    action: function() {
                    }
                  }
                },
                'message': msg1 + this.fSize + msg2
              });
            } else {
              if (this.value == -66) {
                var title = this.transloco.translate('error');
                var msg = this.transloco.translate('alerts.backupFolderOnServerIsNotExist', {}, 'backup');
                showMsg({
                  'type': 'nonsticky',
                  'status': 'error',
                  'title': title,
                  'buttons': {},
                  'message': msg,
                  'delay': '1800'
                });
              } else {
                this._backupService.updateBackupStatus(true);
                var title = this.transloco.translate('success');
                var msg = this.transloco.translate('alerts.companyBackupSuccessfullyCompleted', {}, 'backup');
                var button = this.transloco.translate('ok');
                showMsg({
                  'type': 'nonsticky',
                  'status': 'success',
                  'title': title,
                  'buttons': {},
                  'message': msg,
                  'delay': '1800'
                });
              }
            }
            this._backupService.getUserBackupFiles().subscribe(
              x => {
                this.userBackupDirs = x;
                this.userBackupDirs.forEach(y => {
                  y['id'] = y.dirName.replace(' ', '');
                });
              });
            this.transloco.selectTranslate(null, {}, this.scope).pipe(
              untilDestroyed(this),
              tap(() => {
                this.lblUsedDBSpace = this.transloco.translate('usedDBSpace', {}, 'backup');
                this.lblUsedPaystubSpace = this.transloco.translate('usedPaystubSpace', {}, 'backup');
                this.lblFreeSpace = this.transloco.translate('freeSpace', {}, 'backup');
                this.donut.labels = [this.lblUsedDBSpace, this.lblUsedPaystubSpace, this.lblFreeSpace];
              }),
              switchMap(() => this._backupService.getUserSpace())
            ).subscribe(
              d => {
                this.freeSpace = d.freeSpace;
                this.fSize = d.freeSize;
                this.donut.labels = [`${this.lblUsedDBSpace} (${d.usedDBSize})`, `${this.lblUsedPaystubSpace} (${d.usedPaystubSize})`, `${this.lblFreeSpace} (${d.freeSize})`];
                this.donut.datasets[0].data = [d.usedDBSpace, d.usedPaystubSpace, d.freeSpace];
              });
            this.loading.stopSpinner();
          },
          err => {
            var title = this.transloco.translate('error');
            var msg = this.transloco.translate('alerts.errorOccurredWhenBackupingTheCompany', {}, 'backup');
            var button = this.transloco.translate('ok');
            showMsg({
              'type': 'nonsticky',
              'status': 'error',
              'title': title,
              'buttons': {},
              'message': msg,
              'delay': '1800'
            });
            this.loading.stopSpinner();
          }
        );
      }
    }
  }

  collapseOnClick(subDir: any) {
    let tabid = '#tab' + subDir;
    let divid = '#div' + subDir;
    $(tabid).toggleClass('active');
    $(divid).toggleClass('showcont');
  }

  fromJsonDate(jDate: Date): string {
    let dateStr = (new Date(jDate)).toISOString().substring(0, 10);
    return dateStr;
  }

  manageBackup() {
    this.transloco.selectTranslate(null, {}, this.scope).pipe(
      untilDestroyed(this),
      tap(() => {
        this.lblUsedDBSpace = this.transloco.translate('usedDBSpace', {}, 'backup');
        this.lblUsedPaystubSpace = this.transloco.translate('usedPaystubSpace', {}, 'backup');
        this.lblFreeSpace = this.transloco.translate('freeSpace', {}, 'backup');
        this.donut.labels = [this.lblUsedDBSpace, this.lblUsedPaystubSpace, this.lblFreeSpace];
      })).subscribe(
        () => this._backupService.getUserBackupFiles().subscribe(
          x => {
            this.userBackupDirs = x;
            $('#fileModal').modal('show');
            this._backupService.getUserSpace().subscribe(
              d => {
                this.freeSpace = d.freeSpace;
                this.fSize = d.freeSize;
                this.donut.labels = [`${this.lblUsedDBSpace} (${d.usedDBSize})`, `${this.lblUsedPaystubSpace} (${d.usedPaystubSize})`, `${this.lblFreeSpace} (${d.freeSize})`];
                this.donut.datasets[0].data = [d.usedDBSpace, d.usedPaystubSpace, d.freeSpace];
                this.userBackupDirs.forEach(y => {
                  y['id'] = y.dirName.replace(' ', '');
                });
              });
          }));
  }

  deleteBackup(dirName: string, subDir: string, file: string) {
    var that = this;
    var title = this.transloco.translate('warning');
    var msg = this.transloco.translate('alerts.deleteTheBackupFile', {}, 'backup');
    var button1 = this.transloco.translate('yes');
    var button2 = this.transloco.translate('no');
    showMsg({
      'type': 'sticky',
      'status': 'warning',
      'title': title,
      'buttons': {
        [button1]: {
          action: function() {
            that._backupService.deleteBackupFile(subDir + '\\' + file).subscribe(
              d => {
                if (d == 0) {
                  that._backupService.getUserBackupFiles().subscribe(
                    x => {
                      that.userBackupDirs = x;
                      that.userBackupDirs.forEach(y => {
                        y['id'] = y.dirName.replace(' ', '');
                      });
                    });
                  that.transloco.selectTranslate(null, {}, that.scope).pipe(
                    untilDestroyed(that),
                    tap(() => {
                      that.lblUsedDBSpace = that.transloco.translate('usedDBSpace', {}, 'backup');
                      that.lblUsedPaystubSpace = that.transloco.translate('usedPaystubSpace', {}, 'backup');
                      that.lblFreeSpace = that.transloco.translate('freeSpace', {}, 'backup');
                      that.donut.labels = [that.lblUsedDBSpace, that.lblUsedPaystubSpace, that.lblFreeSpace];
                    }),
                    switchMap(() => that._backupService.getUserSpace())
                  ).subscribe(
                    d => {
                      that.freeSpace = d.freeSpace;
                      that.fSize = d.freeSize;
                      that.donut.labels = [`${that.lblUsedDBSpace} (${d.usedDBSize})`, `${that.lblUsedPaystubSpace} (${d.usedPaystubSize})`, `${that.lblFreeSpace} (${d.freeSize})`];
                      that.donut.datasets[0].data = [d.usedDBSpace, d.usedPaystubSpace, d.freeSpace];
                    });
                  var title = that.transloco.translate('success');
                  var msg = that.transloco.translate('alerts.companyBackupFileSuccessfullyDeleted', {}, 'backup');
                  showMsg({
                    'type': 'nonsticky',
                    'status': 'success',
                    'title': title,
                    'buttons': {},
                    'message': msg,
                    'delay': '1800'
                  });
                } else {
                  var title = that.transloco.translate('error');
                  var msg = that.transloco.translate('alerts.errorOccurredWhenDeletingTheCompanyBackupFile', {}, 'backup');
                  showMsg({
                    'type': 'nonsticky',
                    'status': 'error',
                    'title': title,
                    'buttons': {},
                    'message': msg,
                    'delay': '1800'
                  });
                }
              },
              err => {
                var title = that.transloco.translate('error');
                var msg = that.transloco.translate('alerts.errorOccurredWhenDeletingTheCompanyBackupFile', {}, 'backup');
                showMsg({
                  'type': 'nonsticky',
                  'status': 'error',
                  'title': title,
                  'buttons': {},
                  'message': msg,
                  'delay': '1800'
                });
              }
            );
          }
        },
        [button2]: {
          action: function() {
          }
        }
      },
      'message': msg + dirName + ' ?'
    });
  }
}
