import {AfterViewInit, Component, OnInit} from '@angular/core';
import {EventService} from '@services/event.service';
import {TOPBAR} from '@shared/layouts/layouts.model';

@Component({
    selector: 'app-horizontal',
    templateUrl: './horizontal.component.html',
    styleUrls: ['./horizontal.component.scss']
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {

    topbar: string;

    constructor(private eventService: EventService) {
    }

    ngOnInit() {

        this.topbar = TOPBAR;

        this.eventService.subscribe('changeTopbar', (topbar) => {
            this.topbar = topbar;
            this.changeTopbar(this.topbar);
        });

        document.body.setAttribute('data-layout', 'horizontal');
        document.body.removeAttribute('data-sidebar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-sidebar-small');

        this.changeTopbar(this.topbar);
    }

    ngAfterViewInit() {
    }

    /**
     * on settings button clicked from topbar
     */
    onSettingsButtonClicked() {
        document.body.classList.toggle('right-bar-enabled');
    }

    changeTopbar(topbar: string) {
        switch (topbar) {
            case 'light':
                document.body.setAttribute('data-topbar', 'light');
                break;
            case 'dark':
                document.body.setAttribute('data-topbar', 'dark');
                break;
            case 'colored':
                document.body.setAttribute('data-topbar', 'colored');
                break;
            default:
                document.body.setAttribute('data-topbar', 'dark');
                break;
        }
    }

}
