import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-process-output-window',
  templateUrl: './process-output-window.component.html',
  styleUrls: ['./process-output-window.component.scss']
})
export class ProcessOutputWindowComponent implements OnInit {

  @Input() title: string;
  @Output() close: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(
    private vcf: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
  }

  onClose(event: MouseEvent) {
    this.close.next(event);
  }
}
