export interface NewsAlertModel {
  title: string;
  description: string;
  severity: NewsAlertSeverity;
  url?: string;
}

export enum NewsAlertSeverity {
  Primary = 1,
  Secondary,
  Danger,
  Warning,
  Info
}
